import React, { useRef, useState, useEffect } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip, 
  FormFeedback,
  Nav, NavItem, NavLink,
  Alert
} from "reactstrap";

import Select from 'react-select';

import classnames from "classnames";

// actions
import { useDispatch, useSelector } from "react-redux";
import { setVariantParams, updateEditor } from "../../store/actions";

// control style
import "./zoom.scss";


// controls for editing variant.params.motions
//
const EditMotions = ({ variant, restartFrom, setZoomMouseOverBox }) => {

	const editor = useSelector(state => state.Variant.editor);
	const dispatch = useDispatch();
	
	const full_image_label = 'Full Image';
	//const box_full_image = `000000999999:${full_image_label}`;
	const box_full_image = `000000999999`;
	const default_motion = {
		box: box_full_image,
		//label: full_image_label,
		duration: 2,
		zoom: 1,
		transition: 'pan',
	};

	const motions = variant.params?.motions || [];
	const motions_advanced = variant.params?.motions_advanced || {};

	// lookup focus desriptions
	const [ story, setStory ] = useState({});
	useEffect(() => {
		if (typeof editor.zoomIdx == 'undefined') {
			dispatch(updateEditor({ zoomIdx: 0 }));
		}

		if (variant.gpt4_result?.story) {
			setStory(variant.gpt4_result.story.reduce((acc, item) => {
				acc[item.feature] = item;
				return acc;
			}, {}));
		}
	}, []);

	const getMotionValue = (idx, field, default_value) => {
		if (idx >= motions.length) return default_value;
	
		let rv = motions[idx][field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// update params
	const setMotionParams = (idx, params) => {	
		dispatch(setVariantParams(variant.variant_id, { 
			motions: [
				...motions.slice(0, idx),
				{
					...motions[idx],
					...params
				},
				...motions.slice(idx + 1),		
			] 
		}));
		
		setZoomMouseOverBox(null);

		// restart from idx offset
		//console.log('motions', idx, motions);
		restartFrom(motions[idx]?.begin || 0);
	}

	const getAdvancedValue = (field, default_value) => {
		let rv = motions_advanced[field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	const setAdvancedParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			motions_advanced: {
				...motions_advanced,
				...params
			}
		}));
	}

	const getOption = (idx) => {
		return {
			label: getMotionValue(idx, 'label'),
			value: getMotionValue(idx, 'box')
		}
	};

	// list of focal boxes
	const getBoxOptions = (idx) => {
		if (variant.variant_status == 'loading') {
			return [{
				label: 'Loading...',
				value: 'loading'
			}];

		}
		
		let box_options = [{
			label: 'Full Image',
			value: box_full_image
		}];

		box_options = box_options.concat(Object.entries(variant.focus).map(([label, values]) => {
			return {
				label: label,
				value: values.box
			}
		}));

		const box_value = getMotionValue(idx, 'box');
		
		// already manual?
		// TODO specific to zoomIdx
		if (box_value && box_value != box_full_image && !Object.values(variant.focus).map((values) => {
			return values.box
		}).includes(box_value)) {
			box_options = box_options.concat([
				{
					label: 'Manual Focus',
					value: box_value
				},
				{
					label: 'Change Manual Focus...',
					value: 'capture'
				}
			]);
		} else {
			box_options = box_options.concat([
				{
					label: 'Set Manual Focus...',
					value: 'capture'
				}
			]);
		}
		
		// add slides
		if (variant.params.slideshow) {
			variant.params.slideshow.forEach((slide, idx) => {
				const slide_label = idx + 1;
				box_options = box_options.concat([
					{
						label: `Slide ${slide_label}`,
						value: `${slide_label}:000000999999`
					}
				]);
				// add variant slide_focus from rekognition
			});
		}

		return box_options;
	}
	
	const setFocus = (idx, option) => {

		// manual focus
		if (option.value == 'capture') {	
			dispatch(updateEditor({
				manualFocus: true
				//manualFocusZoomIdx: idx
			}))

		} else {
			// chosen from list
			setMotionParams(idx, {
				'box': option.value,
				'label': option.label, 
				'zoom': (option.value == box_full_image) ? 1 : 1.5 
			});	

			dispatch(updateEditor({
				manualFocus: false
				//manualFocusZoomIdx: undefined
			}))
		}
	}
	
	// Custom Option component
	const optionWithMouseOver = ({ innerProps, data }) => {

		return (
			<div { ...innerProps }
				onMouseOver={ () => {
					setZoomMouseOverBox(data.value);
				} }
				onMouseOut={ () => {
					setZoomMouseOverBox(null);
				} }
				className='focus-option'
			>
				{ data.label }
			</div>
		);
	};

	// calculate the lower zoom bound. lower would extend beyond one of the image borders
	const getMinBoxAdj = (idx) => {
		const _box = getMotionValue(idx, 'box');

		const box_x = parseFloat('0.'+_box.substring(0, 3));
		const box_y = parseFloat('0.'+_box.substring(3, 6));

		const box_w = parseFloat('0.'+_box.substring(6, 9));
		const box_h = parseFloat('0.'+_box.substring(9, 12));

		// calculate limits
		const west = (box_w/2) / (box_x + box_w/2);
		const east = (box_w/2) / (1 - (box_x + box_w/2));

		const north = (box_h/2) / (box_y + box_h/2);
		const south = (box_h/2) / (1 - (box_y + box_h/2));

		return Math.max(north, east, south, west);
	};

	const addMotion = () => {
		dispatch(setVariantParams(variant.variant_id, { 
			motions: [
				...motions || [],
				default_motion
			] 
		}));

		// add one!
		dispatch(updateEditor({ zoomIdx: motions.length }));

		//restartFrom(0);
	}
	
	const deleteMotion = (idx) => {

		if (motions.length == 1) {
			dispatch(setVariantParams(variant.variant_id, { 
				motions: [] 
			}));
			
			dispatch(updateEditor({ zoomIdx: 0 }));			
			
		} else {
			// adjust the previous
			dispatch(setVariantParams(variant.variant_id, { 
				motions: [
					...motions.slice(0, idx),
					...motions.slice(idx + 1)
				] 
			}));			

			if (editor.zoomIdx == idx) {
				dispatch(updateEditor({ zoomIdx: 0 }));
			}
		}
		
		//restartFrom(0);
	}

	let focalNotes
	if (editor.zoomIdx) {
		focalNotes = variant.focus[ getMotionValue(editor.zoomIdx, 'label') ]?.notes;
	}	

	// from preview manual focus
	useEffect(() => {
		//console.log('manualFocusBox', editor.zoomIdx, editor.manualFocusBox);
		if (editor.manualFocusBox) {
			setMotionParams(editor.zoomIdx, {
				box: editor.manualFocusBox,
				label: 'Manual Focus'
			});
		}
	}, [editor.manualFocusBox]);


	return (
		<>
			{ !!window.matchMedia && window.matchMedia('(prefers-reduced-motion)').matches && (
				<Alert color="info" role="alert">
					Motion is currently restricted on this device because the device is configured for <Link
						to="https://www.boia.org/blog/what-to-know-about-the-css-prefers-reduced-motion-feature"
						target="_blank"
					>
						Reduced Motion					
					</Link>. Disable this setting to see cut, fade, pan and zoom motions.
				</Alert>			
			) }
			
				<Card>		
					<CardBody>
						<Row>
							<Col>
								<div
								  className="form-check form-switch"
								>
								  <input
									type="checkbox"
									className="form-check-input"
									id="motions_active"
									defaultChecked={ !!getAdvancedValue('active', false) }
									onChange={ (event) => setAdvancedParams({
										'active': event.target.checked
									}) }
								  />
								  <label
									className="form-check-label"
									htmlFor="motions_active"
								  >
									Active
								  </label>
								</div>
							</Col>
						</Row>
					</CardBody>
				</Card>

			{ !!getAdvancedValue('active', false) && (
				<>
					<Nav tabs className="align-items-center">
						{ motions.map((motion, idx) => {
							return (
								<NavItem key={ idx }>
									<NavLink
										className={ classnames({
											active: editor.zoomIdx == idx,
										}) }
										onClick={ () => {
											dispatch(updateEditor({
												zoomIdx: idx,
												manualFocus: false
											}));
											let begin = 0;
											for (let i = 0; i < idx; i++) {
												begin += motions[i].duration + (motions[i].transition_duration || 1);
											}
											restartFrom(begin);
										} }
									>
										{ motion.label }
										<i className="mdi mdi-close-box ms-1" 
											onClick={ (event) => {
												deleteMotion(idx);
												event.stopPropagation();
											} } 
										/>
									</NavLink>
								</NavItem>									
							)
						}) }
				
						<NavItem style={{
							marginLeft: 'auto'
						}}>
							<NavLink
								onClick={ addMotion }
							>
								+ Add
							</NavLink>
						</NavItem>
					</Nav>

					{ typeof editor.zoomIdx != 'undefined' && editor.zoomIdx < motions.length && (
						<>
							<Card>		
								<CardBody>
									<Row>
										<Col>
											<label className="form-label">Focus</label>
										</Col>
									</Row>								
									<Row className="align-items-center">
										<Col>
											<Select key={ getMotionValue(editor.zoomIdx, 'box') }
												className="focus-select"
												classNamePrefix="select"
												defaultValue={ getOption(editor.zoomIdx) }
												options={ getBoxOptions(editor.zoomIdx) }
												onChange={ (option) => setFocus(editor.zoomIdx, option) }
												isSearchable={ false }
												components={{
													Option: optionWithMouseOver
												}}
											/>
										</Col>						
									</Row>

									{ !!focalNotes && (
										<Row>
											<Col>
												<small>
													<i className="mdi mdi-lightbulb-on-outline me-1" />
													{ focalNotes }
												</small>					
											</Col>
										</Row>							
									) }
							
									<Row className="align-items-top mt-3">
										<Col xs={6}>
											<label className="form-label">Duration</label>
											<Input
												type="number"
												min={ 0 }
												max={ 60 }
												step={ 0.1 }
												value={ getMotionValue(editor.zoomIdx, 'duration', 1) }
												onChange={ (event) => setMotionParams(editor.zoomIdx, {
													'duration': parseFloat(event.target.value)
												}) }
											/>
										</Col>		
										<Col xs={6}>
											<label className="form-label">Zoom</label>
											<Input
												type="range"
												value={ getMotionValue(editor.zoomIdx, 'zoom', 1) }
												onChange={ (event) => setMotionParams(editor.zoomIdx, {
													'zoom': parseFloat(event.target.value) 
												}) }
												min={ getMinBoxAdj(editor.zoomIdx) }
												max={ 2.5 }
												step={ 0.1 }
												className="mt-2 mb-2"
											/>
										</Col>						
									</Row>								

									<Row className="align-items-top mt-3">
										<Col xs={6}>
											<label className="form-label">Effect</label>
												<Input
													type="select"
													value={ getMotionValue(editor.zoomIdx, 'effect', '') }
													onChange={ (event) => setMotionParams(editor.zoomIdx, {
														'effect': event.target.value 
													}) }
												>
													<option value={ null }>-</option>
													<option value="wiggle">Wiggle</option>
													<option value="nod">Nod</option>
													<option value="shake">Shake</option>
													<option value="handheld">Handheld</option>
												</Input>
										</Col>		
										<Col xs={6}>
											<label className="form-label">Strength</label>
											<Input
												type="range"
												value={ getMotionValue(editor.zoomIdx, 'strength', 1) }
												onChange={ (event) => setMotionParams(editor.zoomIdx, {
													'strength': parseFloat(event.target.value) 
												}) }
												min={ 0.1 }
												max={ 3 }
												step={ 0.1 }
												className="mt-2 mb-2"
											/>
										</Col>						
									</Row>								

								</CardBody>
							</Card>

							{ editor.zoomIdx != motions.length - 1 && (
								<Card>
									<CardHeader>
										<strong>Transition ({ getMotionValue(editor.zoomIdx, 'label') } ... { getMotionValue(editor.zoomIdx + 1, 'label') })</strong>
									</CardHeader>						
									<CardBody>
										<Row className="align-items-top">
											<Col xs={6}>
												<label className="form-label">Effect</label>
												<Input
													type="select"
													value={ getMotionValue(editor.zoomIdx, 'transition', 'pan') }
													onChange={ (event) => setMotionParams(editor.zoomIdx, {
														'transition': event.target.value 
													}) }
												>
													<option value="cut">Cut</option>
													<option value="pan">Pan</option>
													<option value="fade">Cross-Fade</option>
												</Input>
											</Col>
											{ ['pan', 'fade'].includes(getMotionValue(editor.zoomIdx, 'transition')) && (
												<Col xs={6}>
													<label className="form-label">Duration</label>
													<Input
														type="number"
														min={ 0 }
														max={ 60 }
														step={ 0.1 }
														value={ getMotionValue(editor.zoomIdx, 'transition_duration', 1) }
														onChange={ (event) => setMotionParams(editor.zoomIdx, {
															'transition_duration': parseFloat(event.target.value)
														}) }
													/>
												</Col>
											) }
										</Row>
									</CardBody>
								</Card>
							) }	

							{ motions.length > 1 && editor.zoomIdx == motions.length - 1 && (
								<Card>
									<CardHeader>
										<strong>Repeat</strong>
									</CardHeader>						
									<CardBody>
										<Row>
											<Col xs={6}>
												<Input
													type="select"
													value={ getAdvancedValue('iterations', 1) }
													onChange={ (event) => setAdvancedParams({
														'iterations': event.target.value 
													}) }
												>
													<option value="infinite">Forever</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="10">10</option>
												</Input>
											</Col>
										</Row>
										{ getAdvancedValue('iterations', 1) != 1 && (
											<Row>
												<Col className="mt-3">
													<div
													  className="form-check form-switch form-switch-md mb-3"
													>
													  <input
														type="checkbox"
														className="form-check-input"
														id="motions-alternate"
														checked={ !!getAdvancedValue('alternate', false) }
														onChange={ (event) => setAdvancedParams({
															'alternate': event.target.checked
														}) }
													  />
													  <label
														className="form-check-label"
														htmlFor="motions-alternate"
													  >
														Alternate direction
													  </label>
													</div>
												</Col>
											</Row>
										) }
									</CardBody>
								</Card>
							) }
						</>		
					) }
				</>
			) }
		</>
	)
}

export default EditMotions;