import React, { useState, useEffect } from 'react';
import { Button, Input, Form } from 'reactstrap';

const EditableText = ({ initialText, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);

	useEffect(() => {
		setText(initialText);
	}, [initialText]);

  const handleSave = (event) => {
    onSave(text);
    setIsEditing(false);
    event.preventDefault();
  };

  return (
    <div>
      {isEditing ? (
        <Form inline="true" onSubmit={ handleSave }>
          <Input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            style={{
            	width: '200px'
            }}
          />
        </Form>
      ) : (
        <span onClick={() => setIsEditing(true)}>{text}</span>
      )}
    </div>
  );
};

export default EditableText;