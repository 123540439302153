import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { 
	Form, FormFeedback, 
	Row, Col, 
	Label, Input, 
	Modal, ModalHeader, ModalBody,
	Toast, ToastBody,
	Collapse,
	Button,
	Nav, NavItem, NavLink,
} from "reactstrap"

import classnames from "classnames";

import { useDispatch } from "react-redux";

import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import {
	postUserRequest
} from "../../store/actions";


const AddUserModal = ({ site, show, hideModal }) => {

	const dispatch = useDispatch();
	
	// success toast
	const [ toastOpen, setToastOpen ] = useState(false);

	const addUserSuccess = () => {
		hideModal();
		setToastOpen(true);
	}

	const addUserValidation = useFormik({
		enableReinitialize: true,

		initialValues: {
			email: '',
			name: '',
			role: 'editor'
		},
		validationSchema: Yup.object({
			email: Yup.string()	
				.required("Please enter email")
				.email('Invalid email address'),
			name: Yup.string()	
				.required("Please enter name"),
			role: Yup.string()
		}),
		onSubmit: values => {
			dispatch(postUserRequest(site.account_id, site.site_id, values, addUserSuccess));        
		},
	});


  return (
    <>
		<div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
			<Toast isOpen={toastOpen} role="alert">
                <div className="d-flex">
					<ToastBody>
						Invite has been sent.
					</ToastBody>
					<button 
						onClick={ () => setToastOpen(false) } 
						type="button" 
						className="btn-close me-2 m-auto" 
					></button>
				</div>
			</Toast>
		</div>
		
		<Modal 
			isOpen={ show } 
			toggle={ hideModal }
		>
			<ModalHeader 
				toggle={ hideModal }
				tag="h4"
			>
            	Add User
            </ModalHeader>
            <ModalBody>
				<Form
					onSubmit={e => {
						e.preventDefault();
						addUserValidation.handleSubmit();
						return false;
					}}
				>
					<Row className="mt-3 mb-3">
						<Col>
							<Label className="form-label">Email</Label>
							<Input
								name="email"
								type="text"
								placeholder=""
								onChange={ addUserValidation.handleChange }
								onBlur={ addUserValidation.handleBlur }
								value={ addUserValidation.values.email }
								invalid={ addUserValidation.touched.email && !!addUserValidation.errors.email }
							/>
							{ (addUserValidation.touched.email && addUserValidation.errors.email) ? (
								<FormFeedback type="invalid">
									{addUserValidation.errors.email}
								</FormFeedback>
							) : null }
						</Col>
					</Row>
					<Row className="mt-3 mb-3">
						<Col>
							<Label className="form-label">Name</Label>
							<Input
								name="name"
								type="text"
								placeholder=""
								onChange={ addUserValidation.handleChange }
								onBlur={ addUserValidation.handleBlur }
								value={ addUserValidation.values.name }
								invalid={ addUserValidation.touched.name && !!addUserValidation.errors.name }
							/>
							{ (addUserValidation.touched.name && addUserValidation.errors.name) ? (
								<FormFeedback type="invalid">
									{addUserValidation.errors.name}
								</FormFeedback>
							) : null }
						</Col>
					</Row>
					<Row className="mt-3 mb-3">
						<Col>
							<Label className="form-label">Role</Label>
							<Input
								name="role"
								type="select"
								value={ addUserValidation.values.role }
								onChange={ addUserValidation.handleChange }
								onBlur={ addUserValidation.handleBlur }
								invalid={ addUserValidation.touched.role && !!addUserValidation.errors.role }
							>
								<option value="editor">Editor</option>
								<option value="administrator">Administrator</option>
							</Input>
						</Col>
					</Row>
					<Row className="mt-3 mb-3">
						<Col className="text-right">
							<button
								type="submit"
								className="btn btn-primary"
								disabled={ !addUserValidation.values.email || !addUserValidation.values.name || addUserValidation.errors.email || addUserValidation.errors.name }
							>
								Add
							</button>
						</Col>
					</Row>
				</Form>
			</ModalBody>
		</Modal>
	</>
  )
}

export default AddUserModal
