import { call, put, takeEvery, takeLatest, all, fork, delay, take, cancel } from "redux-saga/effects";

import {
	POST_NOTIFICATION_REQUEST,
	POST_NOTIFICATION_SUCCESS,
	POST_NOTIFICATION_FAILURE,

	POST_MESSAGING_REQUEST,
	POST_MESSAGING_SUCCESS,
	POST_MESSAGING_FAILURE,

	POST_SAMPLE_REQUEST,
	POST_SAMPLE_SUCCESS,
	POST_SAMPLE_FAILURE,

} from "./actionTypes"


import { 
	postNotificationSuccess, 	postNotificationFailure,
	postMessagingSuccess, 		postMessagingFailure,
	postSampleSuccess,			postSampleFailure,
} from './actions'

import {
  postNotification, postMessage,
  postSample,
} from "../../helpers/backend_helper";


function* postNotificationSaga(action) {
  try {
  	// generate better image
    const response = yield call(postNotification, action);

	// update library
    yield put(postNotificationSuccess(response.variant));
    
    // display toast
    action.payload.sendEmailSuccess();
    
  } catch (error) {
    yield put(postNotificationFailure(error.message));
  }
}

function* postMessagingSaga(action) {
  try {
  	// generate better image
    const response = yield call(postMessage, action);

	// update library
    yield put(postMessagingSuccess(response.variant));
    
  } catch (error) {
    yield put(postMessagingFailure(error.message));
  }
}

function* postSampleSaga(action) {
  try {
  	// generate better image
    const response = yield call(postSample, action);

	// update library
    yield put(postSampleSuccess(response.sample_viewer_id));
    
  } catch (error) {
    yield put(postSampleFailure(error.message));
  }
}

export function* watchPostNotification() {
  yield takeLatest(POST_NOTIFICATION_REQUEST, postNotificationSaga);
}

export function* watchPostMessaging() {
  yield takeLatest(POST_MESSAGING_REQUEST, postMessagingSaga);
}

export function* watchPostSample() {
  yield takeLatest(POST_SAMPLE_REQUEST, postSampleSaga);
}

function* PublishSaga() {
	yield all([
		fork(watchPostNotification),  	
		fork(watchPostMessaging),  	
		fork(watchPostSample),
	])
}

export default PublishSaga;