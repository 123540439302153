import React, { useRef, useState, useEffect, useCallback } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";

// actions
// dispatch actions:  box, begin, end, zoom, transition, ? manual zoom
import { useDispatch } from "react-redux";
import { setVariantParams, updateEditor } from "../../store/actions";


// controls for editing variant.params.intro
//
const EditScroll = ({ variant, site, restartFrom }) => {

	const getScrollValue = (field, default_value) => {
		let rv = (variant.params.scroll || {})[field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// update params
    const dispatch = useDispatch();
	const setScrollParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			scroll: {
				...variant.params.scroll,
				...params
			} 
		}));
	}

	return (
		<>
			{ site.site_type == 'email' && (
				<Alert color="info" role="alert">
					<p>The following scroll settings apply when the image is used on your website.</p>
					Email clients such as Gmail do not support these scroll settings when the image is displayed in an email.
				</Alert>			
			) }

			<Card>		
				<CardHeader>
					<strong>Scroll</strong>
				</CardHeader>
				<CardBody>
					<Row className="mb-3">
						<Col>
							When a Better Image is scrolled out of view and then returns into view:
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="form-check mb-2">
							  <input
								className="form-check-input"
								type="radio"
								name="scroll"
								id="scroll_pause"
								checked={ getScrollValue('restart', false) == false }
								onChange={ (event) => setScrollParams({ 'restart': false }) }
								disabled={ site.site_type == 'email' }
							  />
							  <label
								className="form-check-label"
								htmlFor="scroll_pause"
							  >
								Pause / resume animation
							  </label>
							</div>

							<div className="form-check mb-2">
							  <input
								className="form-check-input"
								type="radio"
								name="scroll"
								id="scroll_restart"
								checked={ getScrollValue('restart', false) == true }
								onChange={ (event) => setScrollParams({ 'restart': true }) }
								disabled={ site.site_type == 'email' }
							  />
							  <label
								className="form-check-label"
								htmlFor="scroll_restart"
							  >
								Restart animation
							  </label>
							</div>
						</Col>
					</Row>
				</CardBody>
			</Card>
			
		</>		
	)
}

export default EditScroll;