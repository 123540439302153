// src/components/filter.
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { connect, useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";

// actions
import { 
	selectSite,
	showModalInbox, postInboxRequest,
	showModalDelete
} from "../../store/actions";

//import components
import DeleteModal from "../../components/Common/DeleteModal";
import TableContainer from '../../components/Common/TableContainer';
import AddEmailModal from '../../components/Common/AddEmailModal';

import {
  Spinner,
  Row, Col,
  Card, CardBody,
  Button,
  UncontrolledTooltip
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

const EmailsTable = ({ account, sites, inbox, loading, error, modals }) => {

    //meta title
    document.title = "Better Images | Emails";

    const dispatch = useDispatch();

	// filter on web vs. email
	sites = useMemo(
		() => Object.values(sites).filter((site) => site.account_id == account?.account_id && site.site_type == 'email'),
		[account?.account_id, loading]
	)

	const changeSite = (site_id) => {
		dispatch(selectSite(site_id));
	}

	// fetch aiden_inbox 
	const addEmail = () => {
		dispatch(postInboxRequest(account.account_id));
		dispatch(showModalInbox());
	};

    const columns = useMemo(
        () => [
			{
				header: 'Updated',
				accessorKey: 'updated',
			},
            {
                header: 'Email',
                enableColumnFilter: false,
                cell: ({ row }) => (
                	<div style={{
                		width: '200px',
                		height: '100px',
                		overflow: 'hidden'
                	}}>
						{ row.original.screenshots && (
							<img 
								src={ `https://crawl.${process.env.REACT_APP_CONTROLLER}/c/${row.original.account_id}/${row.original.site_id}/main-desktop.jpeg` } 
								style={{
									width: '100%',
									//height: '100%',
									objectFit: 'cover',
									objectPosition: 'top'
								}}
							/>
						) }
					</div>
                )
            },
            {
                header: 'Headers',
                enableColumnFilter: false,
                cell: ({ row }) => (
                	<div>
						<p><span className="fw-lighter">From:</span> { row.original.email_from }</p>
						<p><span className="fw-lighter">Subject:</span> { row.original.email_subject }</p>
					</div>
                )
            },
			{
				header: 'Service',
				enableColumnFilter: false,
				accessorKey: 'email_service',
			},
            {
                header: 'Status',
                enableColumnFilter: false,
                accessorKey: 'status'
            },
            {
                header: 'Role',
                cell: ({ row }) => (
                	    <span
                            className="badge badge-soft-primary font-size-11 m-1"
                        >
                            { row.original.role }
                        </span>
                )
            },
            {
                header: 'Images',
				cell: ({ row }) => (
					  <Link
						to={ `/${row.original.account_id}/${row.original.site_id}/images` }
						onClick={() => {
						  changeSite(row.original.site_id)
						}}
					  >
						View
					  </Link>
				)
            },
            {
                header: 'Users',
				cell: ({ row }) => (
					row.original.role.includes('admin') && (
					  <Link
						to={ `/${row.original.account_id}/${row.original.site_id}/users` }
						onClick={() => {
						  changeSite(row.original.site_id)
						}}
					  >
						View
					  </Link>
					)
				)
            },
            {
                header: 'Actions',
				  cell: ({ cell }) => (
					<div className="d-flex gap-3">
					  <Link
						to="#"
						className="text-success"
						onClick={() => {
						  const userData = cell.row.original;
						  //handleAddClick(userData);
						}}
					  >
						<i className="mdi mdi-pencil font-size-18" id="edittooltip" />
						<UncontrolledTooltip placement="top" target="edittooltip">
						  Edit
						</UncontrolledTooltip>
					  </Link>
					  <Link
						to="#"
						className="text-danger"
						onClick={ () => {
							dispatch(showModalDelete('email', cell.row.original))
						} }
					  >
						<i className="mdi mdi-delete font-size-18" id="deletetooltip" />
						<UncontrolledTooltip placement="top" target="deletetooltip">
						  Delete
						</UncontrolledTooltip>
					  </Link>
					</div>
				  )
            },

        ],
        []
    );

	const defaultSort = [{ id: 'updated', desc: true }];

    return (
		<React.Fragment>
			<AddEmailModal		
				inbox={ inbox }
				show={ modals.inbox }
			/>
		  <DeleteModal
			show={ !!modals.delete }
			delete_type={ modals.delete.delete_type }
			delete_params={ modals.delete.delete_params }
		  />
			<div className="page-content">
				<div className="container-fluid">
					<Row>
						<Col className="col-10">
							<div className="page-title-box d-sm-flex align-items-center">
								<h4 className="mb-0 font-size-18">
									{ !!account && `${account.account_id} / EMAILS` }
								</h4>
							</div>
							{ !!error && (
								<Alert color="danger" role="alert">
								  { error.message }
								</Alert>
							) }
						</Col>
						{ !!loading && (
							<Col className="col-2 text-end">
								<Spinner color="secondary" size="sm" className="me-2" />
							</Col>
						) }
					</Row>
					<Row>
					{ loading ? <Spinner color="primary" className='m-3' />
						:
						<>
							<Col col="12">
								<Card>
									<CardBody>
										<TableContainer
											columns={columns}
											data={ sites || [] }
											isGlobalFilter={true}
											customPageSize={10}
											isPagination={true}
											isShowingPageLength={true}
											tableClass="table align-middle table-hover"
											theadClass="table-light"
											paginationDiv="col-sm-12 col-md-7"
											pagination="pagination pagination-rounded justify-content-end mt-4"
											buttonName="Add Email"
											isAddButton={ true }
											handleAddClick={ addEmail }
										/>
									</CardBody>
								</Card>
							</Col>
						</>
					}
					</Row>
				</div>
			</div>
        </React.Fragment>
    );
}

const mapStateToProps = (state, ownProps) => {
	const { data, loading, error, modals } = state.Profile;

	// lookup account
	let account;
	if (ownProps.router.params.account_id) {
		account = state.Profile.data.accounts[ownProps.router.params.account_id];
	}

	return { account, sites: data.sites, inbox: data.inbox, loading, error, modals };
};

export default withRouter(connect(mapStateToProps)(EmailsTable));
