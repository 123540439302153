import React, { useRef, useState, useEffect, useCallback } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
} from "reactstrap";

// actions
// dispatch actions:  box, begin, end, zoom, transition, ? manual zoom
import { useDispatch } from "react-redux";
import { setVariantParams } from "../../store/actions";


// controls for editing variant.params.intro
//
const EditCrop = ({ variant, restartFrom }) => {

	const crop = variant.params?.crop || {};

	const getCropValue = (field, default_value) => {
		let rv = crop[field];

		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// update params
    const dispatch = useDispatch();
	const setParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			crop: {
				...crop,
				...params
			} 
		}));
		//restartFrom(0);
	}

	const logMin = Math.log(0.2);
	const logMax = Math.log(5);
	const logScale = logMax - logMin;

	return (
		<>
			<Card>		
				<CardHeader>
					<strong>Crop Method</strong>
				</CardHeader>
				<CardBody>
					<Row>
						<Col>
							{ variant.source_type != 'copy' && (
								<div className="form-check mb-2">
								  <input
									className="form-check-input"
									type="radio"
									name="crop_type"
									id="crop_type_auto"
									checked={ getCropValue('crop_type', 'auto') == 'auto' }
									onChange={ (event) => setParams({ 'crop_type': 'auto' }) }
									disabled={ !variant.autogen?.source_width || !variant.autogen?.source_height }
								  />
								  <label
									className="form-check-label"
									htmlFor="crop_type_auto"
								  >
									Automatic
								  </label>{" "}
				  
									<Link
										to="#"
										className="text-secondary"
									  >
										<i className="mdi mdi-help-circle" id="auto_tooltip" />
										<UncontrolledTooltip placement="top" target="auto_tooltip">
										  Match the aspect ratio of the original image ({ variant.autogen?.source_width }x{ variant.autogen?.source_height })
										</UncontrolledTooltip>
									  </Link>
								</div>
							) }

							<div className="form-check mb-2">
							  <input
								className="form-check-input"
								type="radio"
								name="crop_type"
								id="crop_type_aspect"
								checked={ getCropValue('crop_type', 'auto') == 'aspect' }
								onChange={ (event) => setParams({ 'crop_type': 'aspect' }) }
								disabled={ false }
							  />
							  <label
								className="form-check-label"
								htmlFor="crop_type_aspect"
							  >
								Aspect Ratio
							  </label>
							</div>
						
							<div className="form-check">
							  <input
								className="form-check-input"
								type="radio"
								name="crop_type"
								id="crop_type_full"
								checked={ getCropValue('crop_type', 'auto') == 'full' }
								onChange={ (event) => setParams({ 'crop_type': 'full' }) }
							  />
							  <label
								className="form-check-label"
								htmlFor="crop_type_full"
							  >
								None
							  </label>
							</div>

						</Col>
					</Row>
				</CardBody>
			</Card>

			{ getCropValue('crop_type') == 'aspect' && (
				<Card>		
					<CardHeader>
						<strong>Aspect</strong>
					</CardHeader>
					<CardBody>
						<Row>
							<Col>
								<label className="form-label">Ratio { getCropValue('aspect', 1).toFixed(2) }</label>
								<Input
									type="range"
									value={ (Math.log(getCropValue('aspect', 1)) - logMin) / logScale }
									onChange={ (event) => setParams({
										'aspect': Math.exp(logMin + logScale * event.target.value)
									}) }
									min={ 0 }
									max={ 1 }
									step={ 0.001 }
									className="mt-2"
								/>
							</Col>
						</Row>
					</CardBody>
				</Card>
			) }				
			
		</>		
	)
}

export default EditCrop;