import {
	SET_ASSET_STATE,
	
	GET_ASSET_URL_REQUEST,
	GET_ASSET_URL_SUCCESS,

	GET_ASSET_RECENTS_REQUEST,
	GET_ASSET_RECENTS_SUCCESS,

} from "./actionTypes"

const initialState = {
	error: null,
	
	// upload
	uploadFile: null,
	uploadBlob: null,
	uploadPreviewLoaded: null,
	uploadUrl: null,
	s3_upload_url: null,
	uploading: false,

	// import from url
	importPreviewLoaded: null,

	// recent
	recents: [],	
};

const Asset = (state = initialState, action) => {
	switch (action.type) {

		case SET_ASSET_STATE:
			return { 
				...state,
				...action.payload
			};

		case GET_ASSET_URL_REQUEST:
			return { 
				...state,
				uploadUrl: null,
				s3_upload_url: null,
				uploading: false,
				error: null,
			};

		case GET_ASSET_URL_SUCCESS:
			return { 
				...state,
				uploadUrl: action.payload.url,
				s3_upload_url: action.payload.s3_upload_url,
			};

		case GET_ASSET_RECENTS_REQUEST:
			return { 
				...state,
				recents: [],
				error: null,
			};

		case GET_ASSET_RECENTS_SUCCESS:
			return { 
				...state,
				recents: action.payload,
			};  

		default:
			return state;
	}
}

export default Asset