import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Navigate } from "react-router-dom";

import { fetchProfileRequest, getSpeedRequest } from '../store/actions';

// for auth pages
const Authmiddleware = (props) => {
  
	// check we have user_id in store  
	const dispatch = useDispatch();

	const accessToken = useSelector(state => state.Auth?.accessToken);

	const profile = useSelector(state => state.Profile.data);
	//const user_id = useSelector(state => state.Profile.data.user?.user_id);

	// first we need an access token
	if (!accessToken) {
		return (
			<Navigate to={{ pathname: "/auth", state: { from: props.location } }} />
		);
	}

	// check speed
	useEffect(() => {
		dispatch(getSpeedRequest());
	}, []);  

	// and we need a profile
	useEffect(() => {
		if (accessToken) {
			dispatch(fetchProfileRequest());
		}
	}, [accessToken, profile.account_id]);  

	// tawk login
	useEffect(() => {
		if (!profile.user) return;
		
		let visitor = {
			hash: profile.user.help_token,
			userId: profile.user.user_id,
			name : profile.user.name || profile.user.email,
			email : profile.user.email,
		};
		
		if (profile.account_id) {
			const account_name = profile.accounts[profile.account_id]?.account_name;
			visitor.account = account_name ? `${account_name} (${profile.account_id})` : profile.account_id;
			visitor.plan = profile.accounts[profile.account_id]?.plan_id;
			visitor.views = profile.accounts[profile.account_id]?.current_period_views || 0;
		}
		
		if (profile.site_id) {
			const sitename = profile.sites[profile.site_id]?.sitename;
			visitor.site = sitename ? `${sitename} (${profile.site_id})` : profile.site_id;
		}

		// pickup visitor info when tawk script loads
		window.Tawk_API = window.Tawk_API || {};
		window.Tawk_API.visitor = visitor;
		window.Tawk_LoadStart = new Date();
	
		// Create the script element and add the Tawk.to widget script	
		const tawkScript = document.createElement('script');
		tawkScript.src = 'https://embed.tawk.to/646bf9c7ad80445890ee79fe/1i5s1u3b1';
		tawkScript.async = true;
		tawkScript.charset='UTF-8';
		tawkScript.setAttribute('crossorigin','*');
		document.body.appendChild(tawkScript);

		// Clean up script on component unmount
		return () => {
			document.body.removeChild(tawkScript);
		};
	}, [profile]);

  
	return (
		<React.Fragment>
			{props.children}
		</React.Fragment>
	);
};

export default Authmiddleware;
