// get all variants in site, searchable
export const GET_VARIANTS_REQUEST = 'GET_VARIANTS_REQUEST';
export const GET_VARIANTS_SUCCESS = 'GET_VARIANTS_SUCCESS';
export const GET_VARIANTS_FAILURE = 'GET_VARIANTS_FAILURE';

// add new image
export const SHOW_MODAL_IMAGE = 'SHOW_MODAL_IMAGE';
export const HIDE_MODAL_IMAGE = 'HIDE_MODAL_IMAGE';
export const POST_IMAGE_REQUEST = 'POST_IMAGE_REQUEST';
export const POST_IMAGE_SUCCESS = 'POST_IMAGE_SUCCESS';
export const POST_IMAGE_FAILURE = 'POST_IMAGE_FAILURE';
export const GET_IMAGE_REQUEST = 'GET_IMAGE_REQUEST';
export const GET_IMAGE_SUCCESS = 'GET_IMAGE_SUCCESS';
export const GET_IMAGE_FAILURE = 'GET_IMAGE_FAILURE';
export const STOP_IMAGE_POLLING = 'STOP_IMAGE_POLLING';

// update status; Approve, Pause, Delete
export const VARIANT_STATUS_REQUEST = 'VARIANT_STATUS_REQUEST';
export const VARIANT_STATUS_SUCCESS = 'VARIANT_STATUS_SUCCESS';
export const VARIANT_STATUS_FAILURE = 'VARIANT_STATUS_FAILURE';

// add new variant
export const SHOW_MODAL_VARIANT = 'SHOW_MODAL_VARIANT';
export const HIDE_MODAL_VARIANT = 'HIDE_MODAL_VARIANT';
export const POST_VARIANT_REQUEST = 'POST_VARIANT_REQUEST';
export const POST_VARIANT_SUCCESS = 'POST_VARIANT_SUCCESS';
export const POST_VARIANT_FAILURE = 'POST_VARIANT_FAILURE';
export const GET_NEW_VARIANT_REQUEST = 'GET_NEW_VARIANT_REQUEST';
export const GET_NEW_VARIANT_SUCCESS = 'GET_NEW_VARIANT_SUCCESS';
export const GET_NEW_VARIANT_FAILURE = 'GET_NEW_VARIANT_FAILURE';
export const STOP_VARIANT_POLLING = 'STOP_VARIANT_POLLING';

// other card actions...
export const VARIANT_EDIT = 'VARIANT_EDIT';
export const VARIANT_RELOAD = 'VARIANT_RELOAD';
export const VARIANT_CLONE = 'VARIANT_CLONE';

