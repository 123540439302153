import React, { useRef, useState, useEffect, useCallback } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
} from "reactstrap";

// actions
// dispatch actions:  box, begin, end, zoom, transition, ? manual zoom
import { useDispatch, useSelector } from "react-redux";
import { setVariantParams } from "../../store/actions";


// controls for editing variant.params.intro
//
const EditLocalize = ({ variant, restartFrom }) => {

	let localize = variant.params?.localize || {};
	
	const speed = useSelector(state => state.Profile?.data?.speed);
	
	const getLocalizeValue = (field, default_value) => {
		let rv = localize[field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// update params
	const dispatch = useDispatch();
	const setParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			localize: {
				...localize,
				...params
			}
		}));
	}

	const hr = new Date().getHours();
	let daypart;
	if (hr < 6) daypart = 'Early';
	else if (hr < 12) daypart = 'Morning';
	else if (hr < 14) daypart = 'Lunchtime';
	else if (hr < 18) daypart = 'Afternoon';
	else if (hr < 22) daypart = 'Evening';
	else daypart = 'Night';

	return (
		<>
			<Card>		
				<CardHeader>
					<strong>Localize</strong>
				</CardHeader>
				<CardBody>
					<Row>
						<Col>
							<div
							  className="form-check form-switch"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="localize_active"
								defaultChecked={ !!getLocalizeValue('active', false) }
								onChange={ (event) => setParams({
									'active': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="localize_active"
							  >
								Active
							  </label>
							</div>
						</Col>
					</Row>
					{ !!getLocalizeValue('active') && (
						<>
							<Row>
								<Col xs={12} className="mt-3">
									<div className="mb-3">Simply include these tokens in your Captions or Buttons where you want the data to be shown.</div>
								</Col>
							</Row>
							<Row>
								<Col xs={6} className="mt-3">
									<b>Token</b>
								</Col>
								<Col xs={6} className="mt-3">
									<b>Example value</b>
								</Col>
							</Row>
							<Row>
								<Col xs={6} className="mt-3">
									#local-country#
								</Col>
								<Col xs={6} className="mt-3">
									{ speed['country'] }
								</Col>
							</Row>
							<Row>
								<Col xs={6} className="mt-3">
									#local-region#
								</Col>
								<Col xs={6} className="mt-3">
									{ speed['region'] }
								</Col>
							</Row>
							<Row>
								<Col xs={6} className="mt-3">
									#local-city#
								</Col>
								<Col xs={6} className="mt-3">
									{ speed['city'] }
								</Col>
							</Row>
							<Row>
								<Col xs={6} className="mt-3">
									#local-weekday#
								</Col>
								<Col xs={6} className="mt-3">
									{ new Intl.DateTimeFormat(navigator.language, { weekday: 'long' }).format(new Date()) }
								</Col>
							</Row>
							<Row>
								<Col xs={6} className="mt-3">
									#local-daypart#
								</Col>
								<Col xs={6} className="mt-3">
									{ daypart }
								</Col>
							</Row>
							<Row>
								<Col xs={6} className="mt-3">
									#local-month#
								</Col>
								<Col xs={6} className="mt-3">
									{ new Intl.DateTimeFormat(navigator.language, { month: 'long' }).format(new Date()) }
								</Col>
							</Row>
							<Row>
								<Col xs={6} className="mt-3">
									#local-year#
								</Col>
								<Col xs={6} className="mt-3">
									{ new Intl.DateTimeFormat(navigator.language, { year: 'numeric' }).format(new Date()) }
								</Col>
							</Row>
						</>
					) }

				</CardBody>
			</Card>
		</>
	)
}

export default EditLocalize;