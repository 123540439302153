import axios from "axios";

import { del, get, post, put } from "./api_helper";

import * as url from "./url_helper";

// create session - don't need actionContext for retry
const postSession = data => post(url.SESSION, data);
// logout
const deleteSession = () => del(url.SESSION);

// get user and accounts and sites
const getProfile = (action) => get(url.PROFILE, {
	actionContext: action
});

// get all users of the site
const getUsers = (action) => get(url.USERS + `?account_id=${action.payload.account_id}&site_id=${action.payload.site_id}`, {
	actionContext: action
});
// invite new user
const postUser = (action) => post(url.USER, {
	...action.payload.user,
	account_id: action.payload.account_id, 
	site_id: action.payload.site_id
});
// update user
const putUser = (action) => put(url.USER + `/${action.payload.user_id}`, {
	...action.payload.user
});


// library
const getVariants = (action) => get(url.VARIANTS + `?account_id=${action.payload.account_id}&site_id=${action.payload.site_id}`, {
	actionContext: action
});

// add image
const postImage = (action) => post(url.IMAGE, {
	...action.payload.image,
	account_id: action.payload.account_id, 
	site_id: action.payload.site_id
}, {
	actionContext: action
});
// check image
const getImage = (action) => get(url.IMAGE + `/${action.payload.image_id}?account_id=${action.payload.account_id}`, {
	actionContext: action
});

// add variant
const postVariant = (action) => post(url.VARIANT, { ...action.payload.params }, {
	actionContext: action
});

// send email
const postNotification = (action) => post(url.NOTIFICATION, {
	account_id: action.payload.account_id, 
	site_id: action.payload.site_id, 
	image_id: action.payload.image_id, 
	variant_id: action.payload.variant_id, 
	recipients: action.payload.recipients
}, {
	actionContext: action
});

// send message
const postMessage = (action) => post(url.NOTIFICATION, {
	account_id: action.payload.account_id, 
	site_id: action.payload.site_id, 
	image_id: action.payload.image_id, 
	variant_id: action.payload.variant_id, 
	channel: action.payload.channel,
	contact_id: action.payload.contact_id
}, {
	actionContext: action
});

// create sample_viewer_id
const postSample = (action) => post(url.SAMPLE, {
	account_id: action.payload.account_id, 
	site_id: action.payload.site_id, 
	image_id: action.payload.image_id, 
	variant_id: action.payload.variant_id, 
	recipient: action.payload.recipient
}, {
	actionContext: action
});

// sites / emails
const postInbox = (action) => post(url.INBOX, {
	account_id: action.payload.account_id
}, {
	actionContext: action
});
const getInbox = (action) => get(url.INBOX + `/${action.payload.inbox_key}?account_id=${action.payload.account_id}`, {
	actionContext: action
});

const postSite = (action) => post(url.SITE, {
	account_id: action.payload.account_id, 
	site_url: action.payload.site_url,
	suggest: action.payload.suggest
}, {
	actionContext: action
});
const getSite = (action) => get(url.SITE + `/${action.payload.site_id}?account_id=${action.payload.account_id}`, {
	actionContext: action
});

// editor - or library polling for newly added variant
const getVariant = (action) => get(url.VARIANT + `/${action.payload.variant_id}?account_id=${action.payload.account_id}&site_id=${action.payload.site_id}&image_id=${action.payload.image_id}`, {
	actionContext: action
});

const putVariant = (action, variant) => put(url.VARIANT + `/${action.payload.variant_id}`, {
	...variant,
	account_id: action.payload.account_id, 
	site_id: action.payload.site_id, 
	image_id: action.payload.image_id
}, {
	actionContext: action
});

// results
const getHeatmap = (action) => get(url.HEATMAP + `/${action.payload.variant_id}?account_id=${action.payload.account_id}&site_id=${action.payload.site_id}&image_id=${action.payload.image_id}`, {
	actionContext: action
});

// bi-controller policy refresh
const edgeRefresh = (site_id, image_id, variant_id) => get(`https://${site_id}.${process.env.REACT_APP_CONTROLLER}/i:${image_id}/v:${variant_id}/refresh`, {
	skipAuthorization: true
});

// speed request
const getSpeed = () => get(`https://speed.${process.env.REACT_APP_CONTROLLER}/speed`, {
	skipAuthorization: true
});


const putAccount = (action) => put(url.ACCOUNT + `/${action.payload.account_id}`, action.payload.account, {
	actionContext: action
});

const putSite = (action) => put(url.SITE + `/${action.payload.site_id}`, action.payload.site, {
	actionContext: action
});

// generate presigned url for asset upload to library
const getAssetUrl = (action) => post(url.ASSET, {
	account_id: action.payload.account_id,
	site_id: action.payload.site_id,
	asset_type: action.payload.asset_type,
	content_type: action.payload.content_type
}, {
	actionContext: action
});

const getAssetRecents = (action) => get(url.ASSET + `?account_id=${action.payload.account_id}&site_id=${action.payload.site_id}&asset_type=${action.payload.asset_type}`, {
	actionContext: action
});


const getBillingPortal = (action) => get(url.BILLING_PORTAL + `?account_id=${action.payload.account_id}`, {
	actionContext: action
});


export {
	postSession, deleteSession,
	
	getProfile,

	getUsers, postUser, putUser,

	getVariants,
	postImage, getImage,
	postVariant,
	
	postNotification, postMessage, postSample,
	
	postInbox, getInbox, postSite, getSite,

	getVariant,
	putVariant,
	
	getHeatmap,
	
	edgeRefresh,
	getSpeed,
	
	putAccount,
	putSite,
	
	getAssetUrl, getAssetRecents,
	
	getBillingPortal,
};
