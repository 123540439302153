// better images
export const SESSION = "/session";
export const PROFILE = "/profile";

export const ACCOUNT = '/account';
export const INBOX = '/inbox';
export const SITE = '/site';

export const USERS = "/users";
export const USER = "/user";

export const IMAGES = "/images";
export const IMAGE = "/image";

export const VARIANTS = "/variants";
export const VARIANT = "/variant";
export const HEATMAP = "/heatmap";

export const NOTIFICATION = "/notification";
export const SAMPLE = "/sample";

export const ASSET = "/asset";

export const BILLING_PORTAL = '/stripe/portal';