import React from "react";
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();

        // get path params
		let params = useParams();
		
	    // add query params
	    if (location.search) {
	    	const search_params = new URLSearchParams(location.search);

			params = {
				...params,
				...Object.fromEntries(search_params)
			}
	    }

        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter;