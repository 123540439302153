import {
  EXTERNAL_AUTH,
  AUTH_SESSION,
  SESSION_SUCCESS,
  REFRESH_SESSION,
  QUEUE_RESET,
  QUEUE_ADD,
  AUTH_LOGOUT,
  SET_ACCESS_TOKEN,
} from "./actionTypes"

export const externalAuth = () => ({
  type: EXTERNAL_AUTH
});

export const authSession = (code, state, history) => ({
  type: AUTH_SESSION,
  payload: { code, state, history },
});

export const sessionSuccess = (authUser) => ({
  type: SESSION_SUCCESS,
  payload: { authUser },
});

// original request so it can be retried
export const refreshSession = (refreshToken, axiosApi, action) => ({
  type: REFRESH_SESSION,
  payload: { refreshToken, axiosApi, action },
});

export const queueReset = (refreshQueue) => ({
  type: QUEUE_RESET,
  payload: { refreshQueue },
});

export const queueAdd = (action) => ({
  type: QUEUE_ADD,
  payload: { action },
});

export const authLogout = () => ({
  type: AUTH_LOGOUT,
  payload: null
});

export const setAccessToken = (accessToken) => ({
  type: SET_ACCESS_TOKEN,
  payload: { accessToken },
});