import React from "react"

import { Modal, ModalHeader, ModalBody } from "reactstrap"

import { useDispatch } from "react-redux";

// actions
import { 
	hideModalDelete,
	putAccountRequest,
	putSiteRequest
} from "../../store/actions";

// data.type = account | site, data.account_id = 1qw2e3r4t5 || data.site_id ...
const DeleteModal = ({ show, delete_type, delete_params }) => {

	const dispatch = useDispatch();

	const closeModal = () => {
		dispatch(hideModalDelete());
	}

	const onDeleteClick = () => {
		if (delete_type == 'account') {
			dispatch(putAccountRequest(delete_params.account_id, {
				account_status: 'deleted'
			}, closeModal));
			
		} else if (delete_type == 'site' || delete_type == 'email') {
			dispatch(putSiteRequest(delete_params.site_id, {
				account_id: delete_params.account_id,
				site_status: 'deleted'
			}, closeModal));
		}
	}

	return (
		<Modal size="md" isOpen={show} toggle={ closeModal } centered={true}>
			<ModalHeader 
				toggle={ closeModal }
				tag="h4"
			>
				Please confirm
			</ModalHeader>
			<ModalBody className="px-4 py-5 text-center">
				<p className="text-muted font-size-16 mb-4">Are you sure you want to delete the { delete_type }?</p>

				<div className="hstack gap-2 justify-content-center mb-0">
					<button type="button" className="btn btn-secondary" onClick={ closeModal }>Cancel</button>
					<button type="button" className="btn btn-danger" onClick={ onDeleteClick }>Delete</button>
				</div>
			</ModalBody>
		</Modal>
	)
}

export default DeleteModal;