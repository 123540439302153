import React, { Fragment, useEffect, useState } from "react";

import { 
	Row, Table, Button, Col,
	Input
} from "reactstrap";

import Select from "react-select";

import { Link } from "react-router-dom";

import {
  // Column,
  // Table as ReactTable,
  // ColumnFiltersState,
  // FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  SortingState,
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';
//import JobListGlobalFilter from "./GlobalSearchFilter";

// Column Filter
const Filter = ({
  column
}) => {
  const columnFilterValue = column.getFilterValue();

  return (
    <>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '')}
        onChange={value => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  );
};

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  );
};

const TableContainer = ({
  columns,
  data,
  tableClass,
  theadClass,
  divClassName,
  isBordered,
  isPagination,
  isGlobalFilter,
  paginationWrapper,
  SearchPlaceholder,
  pagination,
  buttonClass,
  buttonName,
  isAddButton,
  isCustomPageSize,
  handleAddClick,
  //isJobListGlobalFilter,
}) => {

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const [sorting, setSorting] = React.useState([{
  	id: 'updated',
  	desc: true
  }])

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank
    });
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    
    state: {
      sorting,
      columnFilters,
      globalFilter,
      columnVisibility: {
      	updated: false
      },
    },
    onSortingChange: setSorting,
 
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    // setPageSize,
    getState
  } = table;

  // useEffect(() => {
  //   Number(customPageSize) && setPageSize(Number(customPageSize));
  // }, [customPageSize, setPageSize]);

	const pg_from = getState().pagination.pageIndex * getState().pagination.pageSize + 1;
	const pg_to = Math.min(data.length, pg_from + getState().pagination.pageSize - 1);
	
	let pagination_label = null;
	if (data.length) {
		pagination_label = `Showing ${pg_from} - ${pg_to} of ${data.length}`;
	}

/*
const optionGroup = [
  {
    options: [
      { label: "Active", value: "active" },
      { label: "Suggested", value: "suggested" },
      { label: "Inactive", value: "inactive" },
      { label: "Deleted", value: "deleted" }
    ]
  }
];

  const [selectedMulti, setselectedMulti] = useState(null);
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }
*/

  return (
    <Fragment>

      <Row className="mb-2">
      
        { isCustomPageSize && (
          <Col sm={2}>
            <select
              className="form-select pageSize mb-2"
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[5, 10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}

		<Col sm={4}>
			{isGlobalFilter && <DebouncedInput
			  value={globalFilter ?? ''}
			  onChange={value => setGlobalFilter(String(value))}
			  className="form-control search-box me-2 mb-2 d-inline-block"
			  placeholder={ SearchPlaceholder || 'Search...' }
			/>}
		</Col>
		
        {false && isJobListGlobalFilter && <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />}

		{ false && (
            <Col xs={4}>
			  <Select
				value={selectedMulti}
				isMulti={true}
				onChange={() => {
				  handleMulti();
				}}
				options={optionGroup}
				className="select2-selection"
			  />
            </Col>
		) }

        {isAddButton && <Col xs={8}>
          <div className="text-end">
            <Button type="button" className={buttonClass} onClick={handleAddClick}>
              <i className="mdi mdi-plus me-1"></i> {buttonName}</Button>
          </div>
        </Col>}
        
      </Row>

      <div className={divClassName ? divClassName : "table-responsive"}>
        <Table hover className={tableClass} bordered={isBordered}>
          <thead className={theadClass}>
            {getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} className={`${header.column.columnDef.enableSorting ? "sorting sorting_desc" : ""}`}>
                      {header.isPlaceholder ? null : (
                        <React.Fragment>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {
                              {
                                asc: '',
                                desc: '',
                              }
                              [header.column.getIsSorted()] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </React.Fragment>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody>
            {getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td 
                      	key={cell.id}
                      	style={ cell.column.columnDef.cellStyle }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>


      {
        isPagination && (
          <Row>
            <Col sm={12} md={5}>
              <div className="dataTables_info">{ pagination_label }</div>
            </Col>
            <Col sm={12} md={7}>
              <div className={paginationWrapper}>
                <ul className={pagination}>
                  <li className={`paginate_button page-item previous ${!getCanPreviousPage() ? "disabled" : ""}`}>
                    <Link 
                    	to="#" 
                    	className="page-link" 
                    	onClick={ (event) => {
                    		previousPage();
                    		event.preventDefault();
                    	} }
                    ><i className="mdi mdi-chevron-left"></i></Link>
                  </li>
                  {getPageOptions().map((item, key) => (
                    <li key={key} className={`paginate_button page-item ${getState().pagination.pageIndex === item ? "active" : ""}`}>
                      <Link 
                      	to="#" 
                      	className="page-link" 
                      	onClick={ (event) => { 
                      		setPageIndex(item);
                      		event.preventDefault();
                      	} }
                      >{item + 1}</Link>
                    </li>
                  ))}
                  <li className={`paginate_button page-item next ${!getCanNextPage() ? "disabled" : ""}`}>
                    <Link 
                    	to="#" 
                    	className="page-link" 
                      	onClick={ (event) => { 
                      		setPageIndex(item);
                      		event.preventDefault();
                      	} }
                    ><i className="mdi mdi-chevron-right"></i></Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        )
      }
    </Fragment>
  );
};

export default TableContainer;