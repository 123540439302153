import React, { useRef, useState, useEffect, useCallback } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
  Alert
} from "reactstrap";

import EmojiPicker from 'emoji-picker-react';

// actions
// dispatch actions:  box, begin, end, zoom, transition, ? manual zoom
import { useDispatch } from "react-redux";
import { setVariantParams } from "../../store/actions";

const presets = {
	'let it snow': { text: '❄️', spin: 20, blur: 20, splat: 20, opacity: 0, count: 20, size: 1 },
	'fruit ninja': { text: '🍆🥕🥑🥝', spin: 20, blur: 20, splat: 20, opacity: 20, count: 20, size: 1 },
	'love is in the air': { text: '❤️👍🤣🤪', spin: 20, blur: 20, splat: 20, opacity: 20, count: 20, size: 1 },
	'bling bling': { text: '💰💵💎', spin: 20, blur: 20, splat: 20, opacity: 20, count: 20, size: 1 },
	'tunes': { text: '🎶🎵🎹🎷', spin: 20, blur: 20, splat: 20, opacity: 20, count: 20, size: 1 },
	'retail therapy': { text: '🛍️🏷🎁️💸', spin: 20, blur: 20, splat: 20, opacity: 20, count: 20, size: 1 },
	'valentines': { text: '❤️🌹💑', spin: 20, blur: 20, splat: 20, opacity: 20, count: 20, size: 1 },
	'halloween': { text: '🎃😱👻🕷️', spin: 50, blur: 20, splat: 0, opacity: 20, count: 20, size: 1 },
	'thanksgiving': { text: '🦃🍂🥧🍽️', spin: 20, blur: 20, splat: 20, opacity: 20, count: 20, size: 1 },
	'xmas': { text: '🎅🎄❄️⛄', spin: 20, blur: 20, splat: 20, opacity: 20, count: 20, size: 1 },
}

// controls for editing variant.params.magnify
//
const EditParticles = ({ variant, restartFrom }) => {
	
	const getParticlesValue = (field, default_value) => {
		const particles = variant.params.particles || {};
	
		let rv = particles[field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// update params
    const dispatch = useDispatch();
	const setParticlesParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			particles: {
				...variant.params.particles,
				...params
			} 
		}));
		
		restartFrom(0);
	}


	useEffect(() => {		
		if (variant.params.particles?.preset && presets[variant.params.particles.preset]) {
			setParticlesParams(presets[variant.params.particles.preset]);
		}		
	}, [variant.params.particles?.preset]);


	useEffect(() => {
		if (getParticlesValue('text', '').length) {

			let emojiCodes;
			if (typeof Intl.Segmenter != 'undefined') {
				const segmenter = new Intl.Segmenter("en", {granularity: "grapheme"});
				emojiCodes = Array.from(segmenter.segment(getParticlesValue('text', ''))).map((grapheme) => {
					const codePoints = [];
					for (let i = 0; i < grapheme.segment.length; i++) {
						const codePoint = grapheme.segment.codePointAt(i);
						codePoints.push(codePoint);
						if (codePoint > 0xFFFF) {
							i++; // Skip the next index if the current code point is a surrogate pair
						}
					}
					return codePoints;
				});
			
			} else {
				// Firefox cannot detect complex emojis
				emojiCodes = Array.from(getParticlesValue('text', '')).map((e) => {
					return [ e.codePointAt(0) ];
				});
			}

			setParticlesParams({ emojiCodes });
			
		} else {
			setParticlesParams({
				emojiCodes: []
			});
		}
		
	}, [variant.params.particles?.text]);


	const [ showPicker, setShowPicker ] = useState();

	return (
		<>
			{ !!window.matchMedia && window.matchMedia('(prefers-reduced-motion)').matches && (
				<Alert color="info" role="alert">
					Particles are currently disabled on this device because the device is configured for <Link
						to="https://www.boia.org/blog/what-to-know-about-the-css-prefers-reduced-motion-feature"
						target="_blank"
					>
						Reduced Motion					
					</Link>. Disable this setting to preview your particles.
				</Alert>			
			) }
			<Card>		
				<CardHeader>
					<strong>Particles</strong>
				</CardHeader>
				<CardBody>
					<Row className="mb-3">
						<Col>
							<div
							  className="form-check form-switch"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="particles_active"
								defaultChecked={ !!getParticlesValue('active', false) }
								onChange={ (event) => setParticlesParams({
									'active': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="particles_active"
							  >
								Active
							  </label>
							</div>
						</Col>
					</Row>

					{ !!getParticlesValue('active') && (
						<>
							<Row className="mb-3">
								<Col>
									<label className="form-label">Preset</label>
									<Input
										type="select"
										value={ getParticlesValue('preset') }
										onChange={ (event) => setParticlesParams({
											'preset': event.target.value 
										}) }
									>
										<option value="-">-</option>
										{ Object.keys(presets).map((preset, idx) => {
											return (
												<option key={ idx } 
													value={ preset }
												>{ preset.replace(
													/\w\S*/g, (txt) => { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }
												) }</option>
											)
										}) }
									</Input>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col>
									<label className="form-label">Emoji(s)</label>
									<Input
										type="text"
										style={{
											fontFamily: 'Noto Color Emoji',
											fontSize: '32px'
										}}
										value={ getParticlesValue('text', '') }
										onFocus={ () => setShowPicker(true) }
										onChange={ (event) => setParticlesParams({
											text: event.target.value
										}) }
									/>				
									{ false && JSON.stringify(getParticlesValue('emojiCodes')) }
								</Col>
							</Row>

							{ !!showPicker && (
								<Row className="mb-3">
									<Col>
										<div style={{
											textAlign: 'right'
										}}>
											<Button 
												type="button" className="btn-sm"
												onClick={ () => setShowPicker(false) }
											>
												Close
											</Button>
										</div>					

										<EmojiPicker 
											width="calc(100% - 24px)"
											//height={ 300 }
											style={{
												position: 'relative !important',
												left: '12px',
												top: '42px',
												bottom: 'unset !important'
											}}
											emojistyle="google"
											previewConfig={{
												showPreview: false
											}}
											autoFocusSearch={ false }
											onEmojiClick={ (emojiData) => {
												//console.log(emojiData);
												setParticlesParams({
													text: getParticlesValue('text', '') + emojiData.emoji
												});						
											} }
										/>
									</Col>
								</Row>
							) }

							<Row className="mb-3">
								<Col xs={ 6 }>
											<label className="form-label">Size</label>
											<Input
												type="range"
												value={ getParticlesValue('size', 1) }
												onChange={ (event) => setParticlesParams({
													'size': parseFloat(event.target.value) 
												}) }
												min={ 0.1 }
												max={ 3 }
												step={ 0.1 }
												className="mt-2 mb-2"
											/>
								</Col>
								<Col xs={ 6 }>
											<label className="form-label">Number</label>
											<Input
												type="range"
												value={ getParticlesValue('count', 20) }
												onChange={ (event) => setParticlesParams({
													'count': parseInt(event.target.value) 
												}) }
												min={ 0 }
												max={ 50 }
												step={ 1 }
												className="mt-2 mb-2"
											/>
								</Col>
							</Row>

							<Row className="mb-3">
								<Col xs={ 6 }>
											<label className="form-label">Spin</label>
											<Input
												type="range"
												value={ getParticlesValue('spin', 20) }
												onChange={ (event) => setParticlesParams({
													'spin': parseInt(event.target.value) 
												}) }
												min={ 0 }
												max={ 100 }
												step={ 1 }
												className="mt-2 mb-2"
											/>
								</Col>
								<Col xs={ 6 }>
											<label className="form-label">Splat</label>
											<Input
												type="range"
												value={ getParticlesValue('splat', 20) }
												onChange={ (event) => setParticlesParams({
													'splat': parseInt(event.target.value) 
												}) }
												min={ 0 }
												max={ 100 }
												step={ 1 }
												className="mt-2 mb-2"
											/>
								</Col>
							</Row>

							<Row className="mb-3">
								<Col xs={ 6 }>
											<label className="form-label">Blur</label>
											<Input
												type="range"
												value={ getParticlesValue('blur', 20) }
												onChange={ (event) => setParticlesParams({
													'blur': parseInt(event.target.value) 
												}) }
												min={ 0 }
												max={ 100 }
												step={ 1 }
												className="mt-2 mb-2"
											/>
								</Col>
								<Col xs={ 6 }>
											<label className="form-label">Transparency</label>
											<Input
												type="range"
												value={ getParticlesValue('opacity', 20) }
												onChange={ (event) => setParticlesParams({
													'opacity': parseInt(event.target.value) 
												}) }
												min={ 0 }
												max={ 100 }
												step={ 1 }
												className="mt-2 mb-2"
											/>
								</Col>
							</Row>
						</>
					) }

				</CardBody>
			</Card>

		</>
	)
}

export default EditParticles;