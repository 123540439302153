import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  
  POST_USER_REQUEST,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,
  
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  
} from "./actionTypes"

export const fetchUsersRequest = (account_id, site_id) => ({
  type: FETCH_USERS_REQUEST,
  payload: { account_id, site_id }
});
export const fetchUsersSuccess = (users) => ({
  type: FETCH_USERS_SUCCESS,
  payload: users,
});
export const fetchUsersFailure = (error) => ({
  type: FETCH_USERS_FAILURE,
  payload: error,
});

export const postUserRequest = (account_id, site_id, user, addUserSuccess) => ({
  type: POST_USER_REQUEST,
  payload: { account_id, site_id, user, addUserSuccess }
});
export const postUserSuccess = (user) => ({
  type: POST_USER_SUCCESS,
  payload: user
});
export const postUserFailure = (error) => ({
  type: POST_User_FAILURE,
  payload: error,
});

export const updateUserRequest = (user_id, user) => ({
  type: UPDATE_USER_REQUEST,
  payload: { user_id, user }
});
export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user
});
export const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  payload: error
});