// send email
export const POST_NOTIFICATION_REQUEST = 'POST_NOTIFICATION_REQUEST';
export const POST_NOTIFICATION_SUCCESS = 'POST_NOTIFICATION_SUCCESS';
export const POST_NOTIFICATION_FAILURE = 'POST_NOTIFICATION_FAILURE';

// messaging
export const POST_MESSAGING_REQUEST = 'POST_MESSAGING_REQUEST';
export const POST_MESSAGING_SUCCESS = 'POST_MESSAGING_SUCCESS';
export const POST_MESSAGING_FAILURE = 'POST_MESSAGING_FAILURE';

// get sample_viewer_id for tracking
export const POST_SAMPLE_REQUEST = 'POST_SAMPLE_REQUEST';
export const POST_SAMPLE_SUCCESS = 'POST_SAMPLE_SUCCESS';
export const POST_SAMPLE_FAILURE = 'POST_SAMPLE_FAILURE';
