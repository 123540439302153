import { call, put, takeEvery, takeLatest, all, fork, delay, select } from "redux-saga/effects";

import {
	GET_VARIANT_REQUEST,
	PUT_VARIANT_REQUEST,
	POST_VARIANT_INTRO_REQUEST,

	GET_EDGE_REQUEST,

	// get heatmap
	GET_HEATMAP_REQUEST,

} from "./actionTypes"

import { 
  getVariantRequest, getVariantSuccess, getVariantFailure,
  setVariantParams,
  putVariantSuccess, putVariantFailure,
  getEdgeRequest, getEdgeSuccess, getEdgeFailure,
  getHeatmapSuccess, getHeatmapFailure,
} from './actions'

import {
  getVariant,
  putVariant,
  edgeRefresh,
  getHeatmap,
} from "../../helpers/backend_helper";


function* getVariantSaga(action) {
  try {
    const response = yield call(getVariant, action);

    yield put(getVariantSuccess(response.variant));
    
  } catch (error) {
    yield put(getVariantFailure(error.message));
  }
}

function* getHeatmapSaga(action) {
  try {
    const response = yield call(getHeatmap, action);

    yield put(getHeatmapSuccess(response.heatmap));
    
  } catch (error) {
    yield put(getHeatmapFailure(error.message));
  }
}

// poll = refresh/poll the controller to check asset status
function* putVariantSaga(action) {
  try {
  	// only save label and status and params (not events data)
    const response = yield call(putVariant, action, {
    	label: action.payload.variant.label,
    	variant_status: action.payload.variant.variant_status,
    	params: action.payload.variant.params
    });

	// same as get
    yield put(putVariantSuccess(response.variant));

	// check new assets have arrived at the edge - colors, intro, upscale
	let edgeChecks = {};
	if (action.payload.variant.params.colors?.status == 'dirty') {
		edgeChecks.colors = 'web';
	}
	if (action.payload.variant.params.intro?.status == 'dirty') {
		// or requested for stability
		edgeChecks.intro = 'intro';
	}
	if (action.payload.variant.params.email?.status == 'dirty') {
		// or gmail asset generated
		edgeChecks.email = action.payload.variant.params.email.gmail == 'full' ? 'gmailfull' : 'gmail';
	}
	if (action.payload.variant.params.upscale?.status == 'requested') {
		edgeChecks.upscale = 'upscale';
	}
	if (Object.keys(edgeChecks).length) {
		yield put(getEdgeRequest(action.payload.site_id, action.payload.image_id, action.payload.variant_id, edgeChecks, response.variant.target_version, 5000));
	}

  } catch (error) {
    yield put(putVariantFailure(error.message));
  }
}


// check controller for asset versions
function* getEdgeSaga({ payload: { site_id, image_id, variant_id, edgeChecks, target_version, wait } }) {
  try {
	yield delay(wait);

	const response = yield call(edgeRefresh, site_id, image_id, variant_id);

	// continue polling?
	if (Object.values(edgeChecks).some((check) => !response.refresh.versions[check] || response.refresh.versions[check] < target_version)) {
		console.log('target_version not found', edgeChecks, target_version, response.refresh);

		yield put(getEdgeSuccess({
			...response.refresh,
			status: 'stale'
		}));

		// continue polling
		wait += 5000;
		yield put(getEdgeRequest(site_id, image_id, variant_id, edgeChecks, target_version, wait));

	} else {
		// done
		console.log('target_version found', edgeChecks, target_version, response.refresh);

		yield put(getEdgeSuccess({
			...response.refresh,
			status: 'fresh'
		}));

		// check same variant is still being edited
		const variant = yield select(state => state.Variant.data);
		if (variant_id == variant.variant_id) {
			if (edgeChecks.colors) {		
				// backend makes this update too but we don't want to fetch variant again in case other changes being made on app
				yield put(setVariantParams(variant_id, {
					colors: {
						...variant.params.colors,
						recolor: response.refresh.assets.recolor,
						status: 'ready'
					}
				}))
			}

			if (edgeChecks.intro) {
				// public/FfrP2nftnf/ER8CaiTxfk/intro-avif.1715757560.avif
				// https://d50hk11p0nbn9.dev.betterimages.ai/i:FfrP2nftnf/v:ER8CaiTxfk/f:intro-avif.1715757560.avif
				const asset_parts = response.refresh.assets.intro.split('/');
				const filename = asset_parts.pop();
				//const src_avif = `https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/f:${filename}`;		
				// update variant.compiled
				yield put(getVariantSuccess({
					...variant,
					compiled: {
						...variant.compiled,
						intro: {
							...variant.compiled.intro,
							duration: variant.params.intro.manual_src_duration,
							src_avif: filename
						}
					},
					params: {
						...variant.params,
						intro: {
							...variant.params.intro,
							manual_src: null,
							manual_src_duration: null,
							status: 'ready'
						}
					}
				}));		
			}

			if (edgeChecks.email) {
				yield put(setVariantParams(variant_id, {
					email: {
						...variant.params.email,
						status: 'ready'
					}
				}))
			}

			if (edgeChecks.upscale) {
				const asset_parts = response.refresh.assets.upscale.split('/');
				const filename = asset_parts.pop();

				yield put(getVariantSuccess({
					...variant,
					compiled: {
						...variant.compiled,
						upscale: {
							src: filename
						}
					},
					params: {
						...variant.params,
						upscale: {
							...variant.params.upscale,
							status: 'ready'
						}
					}
				}));		
			}

		}
	}
    
  } catch (error) {
    yield put(getEdgeFailure(error.message));
  }
}


export function* watchGetVariant() {
  yield takeLatest(GET_VARIANT_REQUEST, getVariantSaga);
}

export function* watchGetHeatmap() {
  yield takeLatest(GET_HEATMAP_REQUEST, getHeatmapSaga);
}

export function* watchPutVariant() {
  yield takeLatest(PUT_VARIANT_REQUEST, putVariantSaga);
}

export function* watchGetEdge() {
  yield takeLatest(GET_EDGE_REQUEST, getEdgeSaga);
}


function* VariantSaga() {
  yield all([
  	fork(watchGetVariant),
  	fork(watchGetHeatmap),
  	fork(watchPutVariant),
  	fork(watchGetEdge),
  ])
}

export default VariantSaga;