import React, { useRef, useState, useEffect } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
  Nav, NavItem, NavLink,
} from "reactstrap";

import AssetLibrary from '../../components/Common/AssetLibrary';
import VariantPreview from '../../components/Common/VariantPreview';

import classnames from "classnames";

import { useDispatch, useSelector } from "react-redux";
import { setVariantParams, updateEditor } from "../../store/actions";


const EditEmail = ({ variant, site, restartFrom }) => {

	const editor = useSelector(state => state.Variant.editor);
	const dispatch = useDispatch();
	
	const email = variant.params?.email || {};

	// default state
	/*
	useEffect(() => {
		if (!variant.params.email) {
			dispatch(setVariantParams(variant.variant_id, { 
				email: {}
			}));
		}
	}, []);
	*/

	const getEmailValue = (field, default_value) => {
		let rv = email[field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}
	const setEmailParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			email: {
				...email,
				...params
			}
		}));
	}

	// refresh previews whenever status changes
	const [ cacheBuster, setCacheBuster ] = useState();
	useEffect(() => {
		setCacheBuster(new Date().getTime())
	}, [email.status]);

	return (

		<>
			<Nav tabs className="align-items-center">
				{ ['Gmail', 'Apple Mail', 'Outlook'].map((client, idx) => {
					return (
						<NavItem key={ idx }>
							<NavLink
								className={ classnames({
									active: editor.email_client == client,
								}) }
								onClick={ () => dispatch(updateEditor({ email_client: client })) }
							>
								{ client }
							</NavLink>
						</NavItem>									
					)
				}) }
			</Nav>

			{ editor.email_client == 'Gmail' && (
				<>
					<Card>		
						<CardBody>
							<p>Choose to display an animated or high-resolution image for Gmail users:</p>
							<Row className="align-items-center">
								<Col>
									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="radio"
											name="adapt_gmail"
											id="gmail_full"
											value="full"
											checked={ getEmailValue('gmail') == 'full' }
											onChange={ () => setEmailParams({
												'gmail': 'full',
												'status': 'dirty'
											}) }
										/>{" "}
										<label
											className="form-check-label"
											htmlFor="gmail_full"
										>
											Full Motion
										</label>
									</div>

									<div className="form-check mb-2">
										<input
											className="form-check-input"
											type="radio"
											name="adapt_gmail"
											id="gmail_optimal"
											value="gif"
											checked={ getEmailValue('gmail', 'optimal') == 'optimal' }
											onChange={ () => setEmailParams({
												'gmail': 'optimal',
												'status': 'dirty'
											}) }
										/>{" "}
										<label
											className="form-check-label"
											htmlFor="gmail_optimal"
										>
											Optimal Motion (Recommended)
										</label>
									</div>

									<div className="form-check mb-2">								
										<input
											className="form-check-input"
											type="radio"
											name="adapt_gmail"
											id="gmail_static"
											value="static"
											checked={ getEmailValue('gmail', 'optimal') == 'static' }
											onChange={ () => setEmailParams({
												'gmail': 'static',
												'status': 'ready'
											}) }
										/>{" "}
										<label
											className="form-check-label"
											htmlFor="gmail_static"
										>
											High Resolution, No Motion
										</label>
									</div>
								</Col>									
							</Row>
						</CardBody>
					</Card>
					
					<Card>		
						<CardHeader>
							Inbox Preview
						</CardHeader>					
						<CardBody>
							{ getEmailValue('gmail') == 'full' && (
								<Row>
									<Col>
										<div style={{
											background: `url('https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/w:600/c:static/ts:${cacheBuster}') 50% 50% / cover`
										}}>
											<img 
												width="100%"
												src={ `https://${variant?.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/w:600/c:gmailfull/ts:${cacheBuster}` } 
											/>						
										</div>			
									</Col>
								</Row>
							) }

							{ getEmailValue('gmail') == 'optimal' && (
								<Row>
									<Col>
										<div style={{
											background: `url('https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/w:600/c:static/ts:${cacheBuster}') 50% 50% / cover`
										}}>
											<img 
												width="100%"
												src={ `https://${variant?.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/w:600/c:gmail/ts:${cacheBuster}` } 
											/>						
										</div>			
									</Col>
								</Row>
							) }

							{ getEmailValue('gmail') == 'static' && (
								<Row>
									<Col>
										<img 
											width="100%"
											src={ `https://${variant?.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/w:600/c:static/ts:${cacheBuster}` } 
										/>									
									</Col>
								</Row>
							) }

						</CardBody>
					</Card>
				</>
			) }

			{ editor.email_client == 'Apple Mail' && (
				<>
					<Card>		
						<CardBody>
							Here's how your image looks on Apple Mail for iPhone, iPad and Mac:
						</CardBody>
					</Card>					
					<Card>		
						<CardHeader>
							Inbox Preview
						</CardHeader>					
						<CardBody>
							{ variant.adapt?.applemail ? (
								<video 
									width="100%"
									autoplay="autoplay" muted="muted"
									src={ `https://${variant?.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/w:600/c:applemail/ts:${cacheBuster}` } 
								>
								</video>									

							) : (
								<VariantPreview 
									key={ variant.variant_id }
									variant={ variant }
									width={ 600 }
									animationRestart={{ begin: 0 }}
									pointerEvents="none"
								/>
							) }
						</CardBody>
					</Card>
				</>
			) }

			{ editor.email_client == 'Outlook' && (
				<>
					<Card>		
						<CardBody>
							Here's how your image looks for Outlook users:
						</CardBody>
					</Card>					
					<Card>		
						<CardHeader>
							Inbox Preview - Outlook for iPhone, Android, Mac, and Outlook.com
						</CardHeader>					
						<CardBody>
							<VariantPreview 
								key={ variant.variant_id }
								variant={ variant }
								width={ 600 }
								animationRestart={{ begin: 0 }}
								pointerEvents="none"
							/>
						</CardBody>
					</Card>
					<Card>		
						<CardHeader>
							Inbox Preview - Outlook for Windows
						</CardHeader>					
						<CardBody>
							<img 
								width="100%"
								src={ `https://${variant?.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/w:600/c:static/ts:${cacheBuster}` } 
							/>									
						</CardBody>
					</Card>
				</>
			) }


		</>
	)
}

export default EditEmail;