import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { 
	Form, FormFeedback, 
	Row, Col, 
	Label, Input, 
	Modal, ModalHeader, ModalBody,
	Toast, ToastBody,
	Collapse,
	Button,
	Nav, NavItem, NavLink,
	Spinner,
} from "reactstrap"

import classnames from "classnames";

import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import {
	setVariantParams,
	putVariantRequest,
	postNotificationRequest, postMessagingRequest,
	postSampleRequest,
} from "../../store/actions";


const PublishingModal = ({ site, variant, show, hideModal, defaultTab, defaultOption }) => {

	const state_publish = useSelector(state => state.Publish);

    const dispatch = useDispatch();

	// tab: web, email, messaging
	const [activeTab, setActiveTab] = useState(defaultTab || 'web');
	useEffect(() => {
		if (defaultTab) {
			setActiveTab(defaultTab);
		}
	}, [defaultTab]);	

	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};
	useEffect(() => {
		if (!activeTab) {
			setActiveTab(site?.site_type);
		}
	}, [site?.site_type]);	

  	//const [ expandWebAdvanced, setExpandWebAdvanced ] = useState(false);
  	const [ expandSample, setExpandSample ] = useState(false);
  	const [ expandGmailTracking, setExpandGmailTracking ] = useState(false);
  	const [ expandSampleMessaging, setExpandSampleMessaging ] = useState(false);

	const sendEmailSuccess = () => {
		setToastOpen(true);
	}

	const sendEmailValidation = useFormik({
		enableReinitialize: true,

		initialValues: {
			recipients: '',
		},
		validationSchema: Yup.object({
		  recipients: Yup.array()
			.transform((value, originalValue) => {
			  // Split the string into an array of emails
			  return originalValue ? originalValue.trim().split(/\s*,\s*/) : [];
			})
			.test('is-valid-emails', 'Invalid email address', (value) => {
			  // Check each email in the array
			  return (value || []).every(email => Yup.string().email().isValidSync(email));
			})
		}),
		onSubmit: values => {
			dispatch(postNotificationRequest(variant.account_id, variant.site_id, variant.image_id, variant.variant_id, values['recipients'], sendEmailSuccess));        
		},
	});

	// success toast
	const [ toastOpen, setToastOpen ] = useState(false);
	
	const getCode = (target, addVariantId, tracking) => {
		if (variant) {
			const filename = (variant.params?.seo?.filename || variant.label || 'image').toLowerCase().replace(/[ :"/]/g, '-');
	
			const alt_parts = [];
			if (variant.svg_title) alt_parts.push(variant.svg_title);
			if (variant.params?.captions?.active && variant.params?.captions?.text) {
				const captions_text = variant.params.captions.text.replaceAll('~', ' ');
				alt_parts.push(`Captions read '${captions_text}'`);
			}
			if (variant.params?.buttons) {
				const buttons_text = variant.params.buttons.map((button) => button.text).join(', ');
				alt_parts.push(`${variant.params.buttons.length > 1 ? 'Buttons read' : 'Button reads'} '${buttons_text}'`);
			}			
			const alt = alt_parts.map((part) => part.replace(/\.$/, "")).join('. ').replace('"', '&quot;')
		
			if (target == 'web') {
				return `<img src="https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/${filename}.jpeg" alt="${alt}" />`;
			
			} else if (target == 'wordpress') {
				return `https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/${filename}.jpeg`;

			} else if (target == 'messaging') {
				return `https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/${filename}.jpeg`;

			} else {
				// email
				let src_bg = `https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/w:600/bg`;			
				let src = `https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/w:800/${filename}.jpeg`;
				
				if (addVariantId) {
					src_bg = src_bg.replace('/w:', `/v:${variant.variant_id}/w:`);
					src = src.replace('/w:', `/v:${variant.variant_id}/w:`);
				}
				
				if (tracking) {
					src = src.replace('/w:', `${tracking}/w:`);
				}
				
				return `<img width="800" style="width:100%;max-width:800px;display:block;aspect-ratio:${variant.width}/${variant.height} auto;background:url('${src_bg}') 50% 50% / cover" src="${src}" alt="${alt}" />`;
			}
		}
	}

	const copyToClipboard = async (code) => {
		const blobPlain = new Blob([code], { type: "text/plain" });
		const blobHtml = new Blob([code], { type: "text/html" });
		const data = [new ClipboardItem({
			["text/plain"]: blobPlain,
			["text/html"]: blobHtml
		})];

		try {
			await navigator.clipboard.write(data);	
		} catch (error) {
			console.log(error);
		}
	}

	const CodeCopy = ({ code, help, block }) => {
	
		const [ copying, setCopying ] = useState(false);

		const handleCopy = async () => {
			try {
				setCopying(true);
				await copyToClipboard(code)
				setTimeout(() => setCopying(false), 1000);
			} catch (err) {
				console.error('Failed to copy text: ', err);
			}
		};
	
		return (
			<>
				{ block !== false && (
					<Row className="mt-3">
						<Col>
							<code>
								{ code }
							</code>
						</Col>
					</Row>
				) }
				<Row className="mt-3">
					<Col xs={ !!help ? 9 : 12 }>
						<button											  
							className="btn btn-light"
							onClick={ () => handleCopy(code) }
						>
							<i className="mdi mdi-content-copy me-1" />
							{ copying ? 'Copied' : 'Copy to clipboard' }
						</button>
					</Col>

					{ !!help && (
						<Col xs={3}>
							<div className="text-end">
								<Button
									color="link"
									href={ help }
									target="_blank"
								>
									Help
								</Button>
							</div>
						</Col>
					) }
				</Row>
			</>
		);
	}


	// setup tracker code and copy code to clipboard
	const [ trackerRecipient, setTrackerRecipient ] = useState('');
	const [ trackerLink, setTrackerLink ] = useState(`https://betterimages.ai`);
	const trackerSubmit = (event) => {
		// fetch sample_viewer_id
		dispatch(postSampleRequest(variant.account_id, variant.site_id, variant.image_id, variant.variant_id, trackerRecipient));
	}

	// when we have sample viewer id, copy to clipboard
	useEffect(() => {	
		if (variant && state_publish?.sample_viewer_id) {
			const ts = Date.now();
			let code = getCode('email', true, `/st/ve:${state_publish.sample_viewer_id}/ts:${ts}`);
			code = `Better Image:<br><a href="${trackerLink}">${code}</a><br>Original imagery:<br><img src="${variant.source_url}" height="80" /> `;
			if (variant.params?.slideshow) {
				code += variant.params.slideshow.map((slide) => {
					return `<img src="${slide.src}" height="80" />`;
				}).join(' ');
			}
			copyToClipboard(code);
			console.log('code copied to clipboard');
		}
	}, [state_publish?.sample_viewer_id]);


	const [ messagingContactId, setMessagingContactId ] = useState('01J05538QR97N8PRKNZ4K7S8VF');
	
	const messagingSubmit = (event) => {
		dispatch(postMessagingRequest(variant.account_id, variant.site_id, variant.image_id, variant.variant_id, 'messenger', messagingContactId));        
	};


	const [ webPublisher, setWebPublisher ] = useState('wordpress.gutenberg');
	const webOptions = {
		'wordpress.gutenberg': 'WordPress + Block Editor (Gutenberg)',
		'wordpress.elementor': 'WordPress + Elementor',
		'wordpress.wpbakery': 'WordPress + WPBakery',
		'wordpress': 'WordPress',
		'webflow': 'Webflow',
		'wix': 'Wix',
		'drupal': 'Drupal',
		'squarespace': 'Squarespace',
		'other': 'Other'
	}	

	const [ emailServiceProvider, setEmailServiceProvider ] = useState('mailchimp');
	
	useEffect(() => {
		if (defaultTab == 'email' && defaultOption) {
			setEmailServiceProvider(defaultOption);
		}
	}, [defaultTab, defaultOption]);
	
	const emailOptions = {
		'mailchimp': 'Mailchimp',
		'gmail': 'Gmail',
		'sfmc': 'Salesforce Marketing Cloud',
		'hubspot': 'Hubspot',
		'cc': 'Constant Contact',
		'marketo': 'Marketo',
		'sendgrid': 'SendGrid',
		'eloqua': 'Eloqua',
		'klaviyo': 'Klaviyo',
		'cm': 'Campaign Monitor',
		'adobe': 'Adobe Campaign',
		'braze': 'Braze',
		'active': 'Active Campaign',
		'dotdigital': 'Dotdigital',
		'mailgun': 'Mailgun',
		'ses': 'Amazon SES',
		'other': 'Other'
	}

	const [ messagingType, setMessagingType ] = useState('whatsapp');	
	const messagingOptions = {
		'whatsapp': 'WhatsApp',
		'facebook': 'Facebook Messenger',
		'sinch': 'Sinch',
		'twilio': 'Twilio',
		'apple': 'Apple Messages for Business',
		'rcs': 'Rich Communication Service (RCS)',
		'mms': 'MMS',
		'other': 'Other'
	}


	const emailArticles = {
		'mailchimp': 'https://help.betterimages.ai/article/mailchimp-with-better-images',
		'sfmc': 'https://help.betterimages.ai/article/salesforce-marketing-cloud-sfmc-with-better-images',
		'hubspot': 'https://help.betterimages.ai/article/hubspot-with-better-images',
		'cc': 'https://help.betterimages.ai/article/constant-contact-with-better-images',
		'marketo': 'https://help.betterimages.ai/article/marketo-with-better-images',
		'sendgrid': 'https://help.betterimages.ai/article/sendgrid-with-better-images',
		'eloqua': 'https://help.betterimages.ai/article/eloqua-with-better-images',
		'klaviyo': 'https://help.betterimages.ai/article/klaviyo-with-better-images',
		'cm': 'https://help.betterimages.ai/article/campaign-monitor-with-better-images',
		'adobe': 'https://help.betterimages.ai/article/adobe-campaign-with-better-images',
		'braze': 'https://help.betterimages.ai/article/braze-with-better-images',
		'active': 'https://help.betterimages.ai/article/active-campaign-with-better-images',
		'dotdigital': 'https://help.betterimages.ai/article/dotdigital-with-better-images',
		'mailgun': 'https://help.betterimages.ai/article/mailgun-with-better-images',
		'ses': 'https://help.betterimages.ai/article/amazon-ses-with-better-images',
	}

	if (!variant || !site) return null;

  return (
    <>
		<div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
			<Toast isOpen={toastOpen} role="alert">
                <div className="d-flex">
					<ToastBody>
						Sample has been sent.
					</ToastBody>
					<button 
						onClick={ () => setToastOpen(false) } 
						type="button" 
						className="btn-close me-2 m-auto" 
					></button>
				</div>
			</Toast>
		</div>
		
		<Modal 
			isOpen={ show } 
			toggle={ hideModal }
		>
			<ModalHeader 
				tag="h4"
			>
            	Publishing Instructions
            </ModalHeader>
            <ModalBody>
				<Nav tabs className="bi-apps align-items-center">
					{ ['web', 'email', 'messaging'].map((site_type, idx) => {
						
						const label = site_type.charAt(0).toUpperCase() + site_type.slice(1);
						
						return (
							<NavItem key={ idx }>
							  <NavLink
								className={classnames({
								  active: activeTab === site_type,
								})}
								onClick={() => {
								  toggleTab(site_type);
								}}
							  >
								{ label }
							  </NavLink>
							</NavItem>									
						)
					}) }
				</Nav>

				{ activeTab == 'web' && (
					<>
						<Row className="mt-3 mb-3">
							<Col xs={12}>
								How do you publish your site?
							</Col>
						</Row>

						<Row className="mt-3 mb-3">
							<Col xs={12}>
								<Input
									id="contact_id"
									type="select"
									value={ webPublisher }
									onChange={ (event) => setWebPublisher(event.target.value) }
								>
									{ Object.entries(webOptions).map(([value, label]) => {
										return (
											<option 
												key={ value }
												value={ value }
											>
												{ label }
											</option>
										)
									}) }
								</Input>
							</Col>
						</Row>

						<Row className="mt-3 mb-3">
							<Col xs={12}>
								<div className="mb-3">
									1. Add this tag to your site to start displaying all your Better Images:
								</div>
								<CodeCopy
									code={ `<script src="https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/enhance.js"></script>` }
									help="https://help.betterimages.ai/search?q=wpcode%20or%20%22google%20tag%20manager%22"
								/>
								{ false && (
									<>
										<div className="mt-3">
											We recommend using <a href="https://help.betterimages.ai/search?q=google%20tag%20manager" target="_blank">Google Tag Manager</a> 
											{ webPublisher.startsWith('wordpress') && (
												<>
													{' '} or {' '}
													<a href="https://help.betterimages.ai/search?q=wpcode" target="_blank">WPCode</a> 
												</>
											) }
											{' '}
											for doing this.
										</div>
										<div className="mt-3">This will automatically replace your original images, including:
											<br/> 
											<em style={{
												maxWidth: '100%',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												display: 'inline-block'
											}}>
												{ variant.source_url }
											</em>
										</div>
									</>
								) }
							</Col>
						</Row>

						{ webPublisher == 'wordpress.gutenberg' && (
							<>
								<div className="mb-3">2. Add your image by pasting the code into a Custom HTML block:</div>
								<CodeCopy
									code={ getCode('web') }
									help="https://help.betterimages.ai/article/wordpress-block-editor-gutenberg-with-better-images"
								/>
							</>
						) }

						{ webPublisher == 'wordpress.elementor' && (
							<>
								<div className="mb-3">2. Add your image by pasting the code into an HTML block:</div>
								<CodeCopy
									code={ getCode('web') }
									help="https://help.betterimages.ai/article/wordpress-elementor-with-better-images"
								/>
							</>
						) }

						{ webPublisher == 'wordpress.wpbakery' && (
							<>
								<div className="mb-3">2. Add your image by pasting the code into a Raw HTML element:</div>
								<CodeCopy
									code={ getCode('web') }
									help="https://help.betterimages.ai/article/wordpress-wpbakery-with-better-images"
								/>
							</>
						) }

						{ webPublisher == 'wordpress' && (
							<>
								<div className="mb-3">2. Install the <a href="https://wordpress.org/plugins/exmage-wp-image-links/" target="_blank">EXMAGE</a> plugin and add your image using this URL:</div>
								<CodeCopy
									code={ getCode('wordpress') }
									help="https://help.betterimages.ai/article/wordpress-with-better-images"
								/>
							</>
						) }

						{ webPublisher == 'wix' && (
							<>
								<div className="mb-3">2. Add your image by pasting the code into a HTML Code block:</div>
								<CodeCopy
									code={ getCode('web') }
									help="https://help.betterimages.ai/article/wix-with-better-images"
								/>
							</>
						) }

						{ !webPublisher.startsWith('wordpress') && webPublisher != 'wix' && (
							<>
								<div className="mb-3">2. Add your image by pasting the code into an HTML Code block:</div>
								<CodeCopy
									code={ getCode('web') }
								/>
							</>
						) }
						
					</>			
				) }

				{ activeTab == 'email' && (
				
					<>
						<Row className="mt-3 mb-3">
							<Col xs={12}>
								How do you send your emails?
							</Col>
						</Row>

						<Row className="mt-3 mb-3">
							<Col xs={12}>
								<Input
									id="contact_id"
									type="select"
									value={ emailServiceProvider }
									onChange={ (event) => setEmailServiceProvider(event.target.value) }
								>
									{ Object.entries(emailOptions).map(([value, label]) => {
										return (
											<option 
												key={ value }
												value={ value }
											>
												{ label }
											</option>
										)
									}) }
								</Input>
							</Col>
						</Row>

						{ emailServiceProvider == 'gmail' && (
							<>
								<div>Copy and paste the image into a Gmail message:</div>
								<CodeCopy
									code={ getCode('email', true, `/ts:${Date.now()}`) }
									block={ false }
									help="https://help.betterimages.ai/article/gmail-with-better-images"
								/>

							   { site.role == 'superadmin' && (
								   <div className="accordion mt-3" id="accordion-gmail-tracking">
									  <div className="accordion-item">
										<h2 className="accordion-header" id="headingOne">
										  <button
											className={classnames(
											  "accordion-button",
											  "fw-medium",
											  { collapsed: !expandGmailTracking }
											)}
											type="button"
											onClick={ () => setExpandGmailTracking(!expandGmailTracking) }
											style={{ cursor: "pointer" }}
										  >
											Tracking
										  </button>
										</h2>

										<Collapse isOpen={ expandGmailTracking } className="accordion-collapse">
											<div className="accordion-body">
												<Row>
													<Col>
														<label className="form-label">Viewer ID (e.g. email address)</label>
														<Input
														  name="recipients"
														  type="text"
														  placeholder="john@smith.com"
														  value={ trackerRecipient }
														  onChange={ (event) => setTrackerRecipient(event.target.value) }
														/>
													</Col>
												</Row>
												<Row className="mt-3">
													<Col>
														<label className="form-label">Link</label>
														<Input
														  name="link"
														  type="text"
														  placeholder="https://betterimages.ai"
														  value={ trackerLink }
														  onChange={ (event) => setTrackerLink(event.target.value) }
														/>
													</Col>
												</Row>
												<Row className="mt-3">
													<Col>
													  <div className="text-start">
														<button
														  type="submit"
														  className="btn btn-light"
														  onClick={ trackerSubmit }
														>
															{ !!state_publish?.loading ? (
																<Spinner size="sm" className="me-2" />
															) : (
																<i className="mdi mdi-content-copy me-1" />
															) }
														  Copy to Clipboard
														</button>
													  </div>
													</Col>									
												  </Row>
											</div>						  
										</Collapse>
									  </div>
									</div>
								) }
							</>
						) }

						{ emailServiceProvider != 'gmail' && (
							<>
								<div>Use this image tag in your email template:</div>
								<CodeCopy
									code={ getCode('email') }
									help={ emailArticles[emailServiceProvider] }
								/>
							</>
						) }

					   <div className="accordion mt-3" id="accordion-send-sample">
						  <div className="accordion-item">
							<h2 className="accordion-header" id="headingOne">
							  <button
								className={classnames(
								  "accordion-button",
								  "fw-medium",
								  { collapsed: !expandSample }
								)}
								type="button"
								onClick={ () => setExpandSample(!expandSample) }
								style={{ cursor: "pointer" }}
							  >
								Send Sample Email
							  </button>
							</h2>

							<Collapse isOpen={ expandSample } className="accordion-collapse">
								<div className="accordion-body">
									<Form
									  onSubmit={e => {
										e.preventDefault();
										sendEmailValidation.handleSubmit();
										return false;
									  }}
									>
									  <Row>
										<Col xs={8}>
											<Input
											  name="recipients"
											  type="text"
											  placeholder="john@smith.com"
											  onChange={ sendEmailValidation.handleChange }
											  onBlur={ sendEmailValidation.handleBlur }
											  value={ sendEmailValidation.values.recipients }
											  invalid={ sendEmailValidation.touched.recipients && !!sendEmailValidation.errors.recipients }
											/>
											{ (sendEmailValidation.touched.recipients && sendEmailValidation.errors.recipients) ? (
											  <FormFeedback type="invalid">
												{sendEmailValidation.errors.recipients}
											  </FormFeedback>
											) : null }
										</Col>
										<Col xs={4}>
										  <div className="text-end">
											<button
											  type="submit"
											  className="btn btn-success"
											  disabled={ !sendEmailValidation.values.recipients || sendEmailValidation.errors.recipients }
											>
											  Send
											</button>
										  </div>
										</Col>									
									  </Row>
									</Form>
						 		</div>
						  
							</Collapse>
						  </div>
						</div>
					</>
				) }

				{ activeTab == 'messaging' && (
					<>
						<Row className="mt-3 mb-3">
							<Col xs={12}>
								How do you message your audience?
							</Col>
						</Row>

						<Row className="mt-3 mb-3">
							<Col xs={12}>
								<Input
									id="contact_id"
									type="select"
									value={ messagingType }
									onChange={ (event) => setMessagingType(event.target.value) }
								>
									{ Object.entries(messagingOptions).map(([value, label]) => {
										return (
											<option 
												key={ value }
												value={ value }
											>
												{ label }
											</option>
										)
									}) }
								</Input>
							</Col>
						</Row>
						
						<div className="mb-3">Use this URL to display your image:</div>
						<CodeCopy
							code={ getCode('messaging') }
						/>
						
					   { site.role == 'superadmin' && (
						   <div className="accordion mt-3" id="accordion-send-messaging">
							  <div className="accordion-item">
								<h2 className="accordion-header" id="headingOne">
								  <button
									className={classnames(
									  "accordion-button",
									  "fw-medium",
									  { collapsed: !expandSampleMessaging }
									)}
									type="button"
									onClick={ () => setExpandSampleMessaging(!expandSampleMessaging) }
									style={{ cursor: "pointer" }}
								  >
									Send Sample Message
								  </button>
								</h2>

								<Collapse isOpen={ expandSampleMessaging } className="accordion-collapse">
									<div className="accordion-body">
										  <Row>
											<Col xs={9}>
												<Input
													id="contact_id"
													type="select"
													value={ messagingContactId }
													onChange={ (event) => setMessagingContactId(event.target.value) }
												>
													<option value="01J05538QR97N8PRKNZ4K7S8VF">Bob Hitching (Facebook Messenger)</option>
												</Input>
											</Col>
											<Col xs={3}>
											  <div className="text-end">
												<button
												  className="btn btn-success"
													onClick={ messagingSubmit }
												>
												  Send
												</button>
											  </div>
											</Col>									
										  </Row>
									</div>
								</Collapse>
							  </div>
							</div>
						) }
					</>
				) }

              </ModalBody>
            </Modal>
	</>
  )
}

export default PublishingModal
