import React, { useRef, useMemo, useState, useEffect } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";

import FontControls from '../../components/Common/FontControls';

// actions
// dispatch actions:  box, begin, end, zoom, transition, ? manual zoom
import { useDispatch } from "react-redux";
import { setVariantParams, updateEditor } from "../../store/actions";

// controls for editing variant.params.captions
//
const EditCaptions = ({ variant, restartFrom }) => {

	const captions = variant.params?.captions || {};

	// update params
    const dispatch = useDispatch();
	const setParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			captions: {
				...captions,
				...params
			} 
		}));
		restartFrom(0);
	}

	function getCaptionsValue(field, default_value) {
		let rv = captions[field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// colors from computer vision
	const palette = useMemo(
		() => {
			const cvColors = (variant.palette?.Foreground?.DominantColors || []).concat(variant.palette?.Background?.DominantColors || []).map((color) => (
				color.HexCode
			));

			return [...new Set(["#ffffff", "#000000"].concat(cvColors))];
		}, [variant.palette]
	)

	return (
		<>
			{ !!window.matchMedia && window.matchMedia('(prefers-reduced-motion)').matches && (
				<Alert color="info" role="alert">
					Captions are currently displayed with reduced motion on this device because the device is configured for <Link
						to="https://www.boia.org/blog/what-to-know-about-the-css-prefers-reduced-motion-feature"
						target="_blank"
					>
						Reduced Motion					
					</Link>. Disable this setting to preview your captions with full motion.
				</Alert>			
			) }

			<Card>		
				<CardBody>
					<Row>
						<Col>
							<div
							  className="form-check form-switch"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="captions_active"
								defaultChecked={ !!getCaptionsValue('active', false) }
								onChange={ (event) => setParams({
									'active': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="captions_active"
							  >
								Active
							  </label>
							</div>
						</Col>
					</Row>
				</CardBody>
			</Card>

			{ !!getCaptionsValue('active', false) && (
				<>
					<Card>
						<CardHeader>
							<strong>Message</strong>
						</CardHeader>
						<CardBody>
							<Row className="mb-3">
								<Col>
									{ (variant.gpt4_result?.captions || []).map((caption, idx) => {				
										return (
											<div key={ idx } className="form-check mb-2">
											  <input
												className="form-check-input"
												type="radio"
												name="text"
												id={ `text-ai-${idx}` }
												value={ caption }
												checked={ caption == getCaptionsValue('text') }
												onChange={ (event) => setParams({ 'text': event.target.value }) }
											  />
											  <label
												className="form-check-label"
												htmlFor={ `text-ai-${idx}` }
											  >
												{ caption }
											  </label>
											</div>
										)
									}) }

									<div key="custom" className="form-check mb-1">
									  <input
										className="form-check-input"
										style={{
											marginTop: '12px'
										}}
										type="radio"
										name="text"
										id="text-ai-custom"
										value={ getCaptionsValue('text') }
										checked={ getCaptionsValue('text') && variant.gpt4_result?.captions && !variant.gpt4_result?.captions.includes(getCaptionsValue('text')) }
										readOnly={ true }
									  />
										<Input
											type="text"
											value={ getCaptionsValue('text', '') }
											onChange={ (event) => setParams({
												'text': event.target.value
											}) }
										/>
									</div>
								</Col>
							</Row>

							<Row className="align-items-top mb-3">
								<Col className="col-6">
									<label className="form-label">Begin</label>
									<Input
										type="number"
										min={ 0 }
										value={ getCaptionsValue('begin', 0) }
										onChange={ (event) => setParams({
											'begin': parseFloat(event.target.value)
										}) }
									/>
								</Col>		
								<Col className="col-6">
									<label className="form-label">Speed</label>
									<Input
										type="range"
										value={ getCaptionsValue('speed', 10) }
										onChange={ (event) => {
											dispatch(updateEditor({
												captionFreeze: false
											}));
											setParams({
												'speed': parseInt(event.target.value)
											});
										} }
										min={ 1 }
										max={ 20 }
										step={ 1 }
										className="mt-2 mb-2"
									/>
								</Col>	
							</Row>
							<Row className="align-items-top">
								<Col className="col-6">
									<label className="form-label">Angle</label>
									<Input
										type="range"
										value={ getCaptionsValue('rotate', 0) }
										onChange={ (event) => {
											dispatch(updateEditor({
												captionFreeze: true
											}));
											setParams({
												'rotate': parseInt(event.target.value)
											});
										} }
										onMouseUp={ () => { 
											dispatch(updateEditor({
												captionFreeze: false
											}));
										} }
										onTouchEnd={ () => { 
											dispatch(updateEditor({
												captionFreeze: false
											}));
										} }
										min={ -90 }
										max={ 90 }
										step={ 5 }
										className="mt-2 mb-2"
									/>
								</Col>	
								<Col className="col-6">
									<label className="form-label">Spin</label>
									<Input
										type="range"
										value={ getCaptionsValue('spin', 0) }
										onChange={ (event) => {
											dispatch(updateEditor({
												captionFreeze: true
											}));
											setParams({
												'spin': parseInt(event.target.value)
											});
										} }
										onMouseUp={ () => { 
											dispatch(updateEditor({
												captionFreeze: false
											}));
										} }
										onTouchEnd={ () => { 
											dispatch(updateEditor({
												captionFreeze: false
											}));
										} }
										min={ -720 }
										max={ 720 }
										step={ 10 }
										className="mt-2 mb-2"
									/>
								</Col>												
							</Row>								


						</CardBody>
					</Card>

					<FontControls 
						fontState={ captions } 
						setFontState={ setParams } 
						defaultPalette={ palette }				
					/>
				</>
			) }
		</>
	)
}

export default EditCaptions;