import { call, put, takeEvery, takeLatest, all, fork, delay, select } from "redux-saga/effects";

import {
	SET_ASSET_STATE,
	
	GET_ASSET_URL_REQUEST,
	GET_ASSET_URL_SUCCESS,

	GET_ASSET_RECENTS_REQUEST,
	GET_ASSET_RECENTS_SUCCESS,

} from "./actionTypes"

import { 
	setAssetState,
	getAssetUrlSuccess,
	getAssetRecentsSuccess

} from './actions'

import {
  getAssetUrl,
  getAssetRecents
  
} from "../../helpers/backend_helper";


function* getAssetUrlSaga(action) {
  try {
    const response = yield call(getAssetUrl, action);

    yield put(getAssetUrlSuccess(response.url, response.s3_upload_url));
    
  } catch (error) {
    yield put(setAssetState({
    	error: error.message
    }));
  }
}
export function* watchGetAssetUrl() {
  yield takeLatest(GET_ASSET_URL_REQUEST, getAssetUrlSaga);
}

function* getAssetRecentsSaga(action) {
  try {
    const response = yield call(getAssetRecents, action);

    yield put(getAssetRecentsSuccess(response.recents));
    
  } catch (error) {
    yield put(setAssetState({
    	error: error.message
    }));
  }
}
export function* watchGetAssetRecents() {
  yield takeLatest(GET_ASSET_RECENTS_REQUEST, getAssetRecentsSaga);
}

function* AssetSaga() {
  yield all([
  	fork(watchGetAssetUrl),
  	fork(watchGetAssetRecents),
  ])
}

export default AssetSaga;