import React, { useRef, useState, useEffect } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
  Nav, NavItem, NavLink,
} from "reactstrap";

import AssetLibrary from '../../components/Common/AssetLibrary';

import classnames from "classnames";

import { useDispatch, useSelector } from "react-redux";
import { setVariantParams, updateEditor } from "../../store/actions";


const EditSlideshow = ({ variant, site, restartFrom }) => {

	const editor = useSelector(state => state.Variant.editor);
	const dispatch = useDispatch();

	//const slideshow = variant.params?.slideshow || [];
	//const slideshow_advanced = variant.params?.slideshow_advanced || {};

	// default editor state
	useEffect(() => {
		if (typeof variant.params.slideshow == 'undefined') {
			dispatch(setVariantParams(variant.variant_id, { 
				slideshow: []
			}));
		}

		if (typeof editor.slideIdx == 'undefined') {
			dispatch(updateEditor({ slideIdx: 0 }));
		}
	}, []);

	const getSlideshowValue = (idx, field, default_value) => {
		let rv = variant.params.slideshow[idx][field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}
	const setSlideshowParams = (idx, params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			slideshow: [
				...variant.params.slideshow.slice(0, idx),
				{
					...variant.params.slideshow[idx],
					...params
				},
				...variant.params.slideshow.slice(idx + 1)
			] 
		}));
	}

	const addSlide = () => {
		dispatch(setVariantParams(variant.variant_id, { 
			slideshow: [
				...variant.params.slideshow,
				{
					src: null,
					width: null,
					height: null,
					preload: site?.site_type == 'email'
				}
			] 
		}));

		// add one!
		dispatch(updateEditor({ slideIdx: variant.params.slideshow.length }));
	}

	const deleteSlide = (idx) => {
		dispatch(setVariantParams(variant.variant_id, { 
			slideshow: [
				...variant.params.slideshow.slice(0, idx),
				...variant.params.slideshow.slice(idx + 1)
			] 
		}));
		
		dispatch(updateEditor({ slideIdx: 0 }));
		
		// update motions		
		if (variant.params.motions.length) {
			const slide_idx = idx + 1;
			let motions = [];
					
			variant.params.motions.forEach((motion) => {
				const box_parts = motion.box.split(':');
				if (box_parts.length > 1) {
					let box_slide_id = parseInt(box_parts[0]);
				
					// delete motion
					if (box_slide_id == slide_idx) return;
					
					// adjust box
					if (box_slide_id > slide_idx) {
						const new_slide_id = box_slide_id - 1;
						motion.box = `${new_slide_id}:${box_parts[1]}`;
						motion.label = `Slide ${new_slide_id}`;
					}
					
					motions.push(motion);
				}
			});

			dispatch(setVariantParams(variant.variant_id, { 
				motions: motions
			}));
		
			restartFrom(0);
		}
	}


	const postSlide = (idx, result) => {
		setSlideshowParams(idx, {
			src: result.source_url,
			width: result.width,
			height: result.height
		})

		// add to motions
		let motions = variant.params.motions || [];
		if (motions.length) {
			motions[motions.length-1].transition = 'fade';
			motions[motions.length-1].transition_duration = 1;
		} else {
			motions.push({
				box: '000000999999',
				label: 'Full Image',
				duration: 2,
				zoom: 1,
				transition: 'fade',
				transition_duration: 1
			});
		}
		const slide_idx = idx + 1;
		motions.push({
			box: `${slide_idx}:000000999999`,
			label: `Slide ${slide_idx}`,
			duration: 2,
			zoom: 1
		});

		dispatch(setVariantParams(variant.variant_id, { 
			motions: motions
		}));
		
		restartFrom(0);
	}

	return (

		<>
			<Nav tabs className="align-items-center">
				{ variant.params.slideshow && Array.isArray(variant.params.slideshow) && variant.params.slideshow.map((slide, idx) => {
					return (
						<NavItem key={ idx }>
							<NavLink
								className={ classnames({
									active: editor.slideIdx == idx,
								}) }
								onClick={ () => dispatch(updateEditor({ slideIdx: idx })) }
							>
								<img src={ slide.src }
									style={{
										maxWidth: '40px',
										maxHeight: '20px'
									}}
								/>
								<i className="mdi mdi-close-box ms-1" 
									onClick={ () => deleteSlide(idx) } 
								/>
							</NavLink>
						</NavItem>									
					)
				}) }
				
				<NavItem style={{
					marginLeft: 'auto'
				}}>
					<NavLink
						onClick={ addSlide }
					>
						+ Add
					</NavLink>
				</NavItem>
			</Nav>

			{ variant.params.slideshow && typeof editor.slideIdx != 'undefined' && editor.slideIdx < variant.params.slideshow.length && (
				<Card>		
					<CardBody>

						{ !getSlideshowValue(editor.slideIdx, 'src') && (
							<Row className="mb-3">
								<Col>
									<AssetLibrary
										account_id={ variant.account_id }
										site_id={ variant.site_id }
										image_id={ variant.image_id }
										assetType='slide'
										acceptMatch='image/*'
										onResult={ (result) => postSlide(editor.slideIdx, result) }
									/>
								</Col>
							</Row>
						) }

						{ !!getSlideshowValue(editor.slideIdx, 'src') && (
							<>
								<Row>
									<Col>							
										<img
											src={ getSlideshowValue(editor.slideIdx, 'src') }
											style={{
												width: '100%'
											}}
										/>
									</Col>
								</Row>
							
								<Row>
									<Col className="mt-3">
											<div
											  className="form-check form-switch form-switch-md mb-3"
											>
											  <input
												type="checkbox"
												className="form-check-input"
												id="slide-preload"
												checked={ !!getSlideshowValue(editor.slideIdx, 'preload', false) }
												onChange={ (event) => setSlideshowParams(editor.slideIdx, {
													'preload': event.target.checked
												}) }
											  />
											  <label
												className="form-check-label"
												htmlFor="slide-preload"
											  >
												Preload
											  </label>
											</div>
									</Col>
								</Row>	
							</>
						) }

					</CardBody>
				</Card>
			) }
		</>
	)
}

export default EditSlideshow;