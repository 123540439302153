
// src/components/filter.
import React, { useRef, useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";

import { connect, useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";

// actions
import {
	getVariantsRequest,
	showModalImage,
	showModalVariant,
	getHeatmapRequest,
} from "../../store/actions";

//import components
import VariantCard from '../../components/Common/VariantCard';

//import VariantPreview from '../../components/Common/VariantPreview';

import h337 from 'heatmap.js';

import {
	Spinner,
	Row, Col,
	Card, CardBody,
	Alert,
	Button,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";


const ImageResults = ({ account, site, image_id, image, loading, error }) => {

    const dispatch = useDispatch();
    
    const heatmapRawData = useSelector(state => state.Variant.heatmap);
  
	// get variants data
	useEffect(() => {
		if (account?.account_id && site?.site_id) {
			dispatch(getVariantsRequest(account.account_id, site.site_id));
		}
	}, [account?.account_id, site?.site_id]);


	// calc totals for mix %s
	const events = useMemo(
		() => {
			let events = { view: 0, click: 0, lead: 0, cart: 0, sale: 0 };
			
			Object.values(image?.variants || {}).forEach(variant => {
				Object.keys(events).forEach(event => {
					events[event] += variant.events[event];
				});
			});

			// sort stars desc
			if (image?.variants) {
				image.variants = Object.fromEntries(Object.entries(image.variants).sort(([, a], [, b]) => b.events?.stars - a.events?.stars || 0));
			}

			return events;
		}, [image]
	)
	
    document.title = "Better Images";

	const StarRating = ({ score }) => {
	  const renderStars = () => {
		const stars = [];
		for (let i = 1; i <= 5; i++) {
		  if (score >= i) {
			stars.push(<i key={i} className="mdi mdi-star"></i>); // Full star
		  } else if (score >= i - 0.5) {
			stars.push(<i key={i} className="mdi mdi-star-half-full"></i>); // Half star
		  } else {
			stars.push(<i key={i} className="mdi mdi-star-outline"></i>); // Outline star
		  }
		}
		return stars;
	  };

	  return renderStars();
	};

	const EventMix = ({ variant, event }) => {
		
		if (variant?.events[event] && events[event]) {
			const viewMix = parseInt(100 * variant.events.view / events.view);			
		
			const eMix = parseInt(100 * variant.events[event] / events[event]);
			
			let mixStr = `${eMix}%`;
			
			if (event != 'view' && eMix > viewMix) {
				return (
					<span 
						style={{
							backgroundColor: 'rgb(52, 195, 143)',
							color: 'white',
							padding: '0 4px'
						}}
					>
						{ eMix }%
					</span>
				)
			} else {
				return `${eMix}%`;
			}
			
		} else {
			return '-';
		}
	}

	// which variant to heatmap?
	const [ heatmapVariantId, setHeatmapVariantId ] = useState();
	useEffect(() => {
		if (heatmapVariantId) {
			dispatch(getHeatmapRequest(account.account_id, site.site_id, image_id, heatmapVariantId));
		}
	}, [heatmapVariantId]);


	// TODO time series, clustering
	const heatmapData = useMemo(
		() => {
			if (heatmapRawData?.image) {
				
				return {
					max: 1,
					data: heatmapRawData.image.map(([x, y]) => ({
						x: x,
						y: y,
						value: 1
					}))
				}
			
			} else {
				return {
					max: 0, 
					data: []
				}
			}

		}, [heatmapRawData]
	)
	


	const HeatmapComponent = ({ data }) => {
	  const heatmapContainerRef = useRef(null);

	  useEffect(() => {
		// Create a heatmap instance
		const heatmapInstance = h337.create({
		  container: heatmapContainerRef.current,
		  radius: 10,   // <--- bigger when less points
		  maxOpacity: 0.6,
		  minOpacity: 0.1,
		  //blur: 0.75,
		});

		// always 256ile
		heatmapInstance._renderer.setDimensions(256, 256);

		// Set the data for the heatmap
		heatmapInstance.setData(data);

		// Cleanup the heatmap instance when the component unmounts
		return () => {
		  heatmapInstance._renderer.canvas.remove();
		};
	  }, [data]);

	  return (
		<div
			  style={{
			  	// to overlay and cover the image
				position: 'absolute',
				width: '100%',
				height: '100%',
			  }}
		>
			<style>{ `
				.heatmap-canvas {
					width: 100%;
					height: 100%;
				}
			` }</style>	  
			<div
			  ref={heatmapContainerRef}
			  style={{
				width: '100%',
				height: '100%',
			  }}
			>
			  {/* Heatmap.js will render the heatmap inside this div */}
			</div>
		</div>
	  );
	};  	
  	
  	
	
    return (
		<React.Fragment>

			<div className="page-content">
				<div className="container-fluid">
					<Row>
						<Col className="col-10">
							<div className="page-title-box d-sm-flex align-items-center">
								<h4 className="mb-0 font-size-18">
									{ site?.site_type == 'web' ? site?.sitename : site?.email_subject }
								</h4>
							</div>
							
							{ !!error && false && (
								<Alert color="danger" role="alert">
								  { error }
								</Alert>
							) }
						</Col>
						{ !!loading && (
							<Col className="col-2 text-end">
								<Spinner color="secondary" size="sm" className="me-2" />
							</Col>
						) }
					</Row>
					<Row>
						<Col xs={ 6 }>
							<Card>
								<CardBody
									style={{
										overflowX: 'scroll'
									}}
								>				
									<table 
										border="0" 
										cellPadding="10"
									>
										<thead>
											<tr>
												<th></th>								
												{ Object.entries(image?.variants || {}).map(([variant_id, variant]) => (
														<th 
															key={ variant_id }
															width={ 180 }
														>
															<VariantCard 
																variant={ variant }
															/>
														</th>
												)) }
											</tr>
										</thead>
										<tbody>
											<tr>
												<th>Performance</th>
												{ Object.entries(image?.variants || {}).map(([variant_id, variant]) => (
														<td 
															key={ variant_id }
															align="center"
															className="star-rating"
														>
															<StarRating score={ variant?.events?.stars || 0 } />
														</td>
												)) }
											</tr>
											<tr>
												<th>Views</th>
												{ Object.entries(image?.variants || {}).map(([variant_id, variant]) => (
														<td 
															key={ variant_id }
															align="center"
														>
															<EventMix
																variant={ variant }
																event="view"
															/>
														</td>
												)) }
											</tr>
											<tr>
												<th>Clicks</th>
												{ Object.entries(image?.variants || {}).map(([variant_id, variant]) => (
														<td 
															key={ variant_id }
															align="center"
														>
															<EventMix
																variant={ variant }
																event="click"
															/>
															
															{ !!variant?.events['click'] && (
																<Button
																	size="sm"
																	className="ms-2"
																	color="link"
																	onClick={ () => setHeatmapVariantId(variant_id) }
																>
																	Map
																</Button>			
															) }
															
														</td>
												)) }
											</tr>
											<tr>
												<th>Leads</th>
												{ Object.entries(image?.variants || {}).map(([variant_id, variant]) => (
														<td 
															key={ variant_id }
															align="center"
														>
															<EventMix
																variant={ variant }
																event="lead"
															/>
														</td>
												)) }
											</tr>
											<tr>
												<th>Carts</th>
												{ Object.entries(image?.variants || {}).map(([variant_id, variant]) => (
														<td 
															key={ variant_id }
															align="center"
														>
															<EventMix
																variant={ variant }
																event="cart"
															/>
														</td>
												)) }
											</tr>
											<tr>
												<th>Sales</th>
												{ Object.entries(image?.variants || {}).map(([variant_id, variant]) => (
														<td 
															key={ variant_id }
															align="center"
														>
															<EventMix
																variant={ variant }
																event="sale"
															/>
														</td>
												)) }
											</tr>
											
											
																		
										</tbody>
									</table>

								</CardBody>
							</Card>
						</Col>

						{ !!heatmapData.data.length && (
						
							<Col xs={ 6 }>
								<Card>
									<img 
										src={ `https://${site?.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${image_id}/v:${heatmapVariantId}/vw:admin/ts:${Date.now()}/w:800/c:static` }
										style={{
											width: '100%'
											
										}}
									/>
									<HeatmapComponent 
										data={ heatmapData } 
									/>
								</Card>
							</Col>
						
						) }
						
					</Row>


				</div>
			</div>

        </React.Fragment>
    );
}

const mapStateToProps = (state, ownProps) => {
	let { data, loading, error } = state.Images;

	let account = {};
	let site = {};
	let image_id;
	let image = {};

	// .../images specific to account / site
	if (ownProps.router.params.account_id) {
		account = state.Profile.data.accounts[ownProps.router.params.account_id];
	}

	if (ownProps.router.params.site_id) {
		site = state.Profile.data.sites[ownProps.router.params.site_id];
	}

	if (ownProps.router.params.image_id) {
		image_id = ownProps.router.params.image_id;
		image = data.images[image_id];
	}
	
	return { account, site, image_id, image, loading, error };
};

export default withRouter(connect(mapStateToProps)(ImageResults));
