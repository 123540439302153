import {
	POST_NOTIFICATION_REQUEST,
	POST_NOTIFICATION_SUCCESS,
	POST_NOTIFICATION_FAILURE,

	POST_MESSAGING_REQUEST,
	POST_MESSAGING_SUCCESS,
	POST_MESSAGING_FAILURE,

	POST_SAMPLE_REQUEST,
	POST_SAMPLE_SUCCESS,
	POST_SAMPLE_FAILURE,
	
} from "./actionTypes"


export const postNotificationRequest = (account_id, site_id, image_id, variant_id, recipients, sendEmailSuccess) => ({
  type: POST_NOTIFICATION_REQUEST,
  payload: { account_id, site_id, image_id, variant_id, recipients, sendEmailSuccess },
})
export const postNotificationSuccess = () => ({
  type: POST_NOTIFICATION_SUCCESS,
  payload: {},
})
export const postNotificationFailure = (error) => ({
  type: POST_NOTIFICATION_FAILURE,
  payload: error,
});


export const postMessagingRequest = (account_id, site_id, image_id, variant_id, channel, contact_id) => ({
  type: POST_MESSAGING_REQUEST,
  payload: { account_id, site_id, image_id, variant_id, channel, contact_id },
})
export const postMessagingSuccess = () => ({
  type: POST_MESSAGING_SUCCESS,
  payload: {},
})
export const postMessagingFailure = (error) => ({
  type: POST_MESSAGING_FAILURE,
  payload: error,
});


export const postSampleRequest = (account_id, site_id, image_id, variant_id, recipient) => ({
  type: POST_SAMPLE_REQUEST,
  payload: { account_id, site_id, image_id, variant_id, recipient },
})
export const postSampleSuccess = (sample_viewer_id) => ({
  type: POST_SAMPLE_SUCCESS,
  payload: { sample_viewer_id },
})
export const postSampleFailure = (error) => ({
  type: POST_SAMPLE_FAILURE,
  payload: error,
});