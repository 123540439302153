export const SET_ASSET_STATE = 'SET_ASSET_STATE';

// get presigned url
export const GET_ASSET_URL_REQUEST = 'GET_ASSET_URL_REQUEST';
export const GET_ASSET_URL_SUCCESS = 'GET_ASSET_URL_SUCCESS';

// get recents
export const GET_ASSET_RECENTS_REQUEST = 'GET_ASSET_RECENTS_REQUEST';
export const GET_ASSET_RECENTS_SUCCESS = 'GET_ASSET_RECENTS_SUCCESS';


