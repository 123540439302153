import axios from "axios";

import store from "../store";

import {
	externalAuth,
	refreshSession,
	queueAdd
} from "../store/actions"

//apply base url for axios
const axiosApi = axios.create({
	baseURL: process.env.REACT_APP_API,
});

axiosApi.interceptors.request.use(
	config => {
		const currentState = store.getState();      
		const accessToken = currentState?.Auth?.accessToken;

		// skip auth when calling edge refresh
		if (accessToken && !config.skipAuthorization) {
			config.headers.Authorization = `Bearer ${accessToken}`;      
		}
		return config;
	},
	error => {
		// Do something with request error
		return Promise.reject(error);
	}
);

axiosApi.interceptors.response.use(
	response => {
		// Handle a successful response
		return response;
	},
	error => {
		//console.log(error);

		const currentState = store.getState();      
   
		// Handle an error response
		const actionContext = error.config.actionContext;

		// cognito retry
		if (error.code === 'ERR_NETWORK' && !error.response && actionContext && !actionContext._retry) {

			// only try this once
			actionContext._retry = true;

			const refreshToken = currentState?.Auth?.refreshToken;

			if (!refreshToken) {
				//console.log('no refresh token found');
				return store.dispatch(externalAuth());

			} else {
				const refreshQueue = currentState?.Auth?.refreshQueue;

				// already mid-refresh, enqueue request for retry
				if (refreshQueue.length) {
					return store.dispatch(queueAdd(actionContext));
				}

				// Trigger a saga to refresh the token
				return store.dispatch(refreshSession(refreshToken, axiosApi, actionContext));
			}
  
		} else if (['AUTH_SESSION', 'REFRESH_SESSION'].includes(actionContext.type)) {
			// invalid code or refresh token has expired - login again
			return store.dispatch(externalAuth());
		}

    // Return any other error
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
	return await axiosApi
		.get(url, { ...config })
		.then((response) => response.data);
}

export async function post(url, data, config = {}) {
	return axiosApi
		.post(url, { ...data }, { ...config })
		.then((response) => response.data);
}

export async function put(url, data, config = {}) {
	return axiosApi
		.put(url, { ...data }, { ...config })
		.then((response) => response.data);
}

export async function del(url, config = {}) {
	return await axiosApi
		.delete(url, { ...config })
		.then((response) => response.data);
}

export default axiosApi;