import React, { useRef, useState, useEffect, useCallback } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";

// actions
// dispatch actions:  box, begin, end, zoom, transition, ? manual zoom
import { useDispatch } from "react-redux";
import { setVariantParams, updateEditor } from "../../store/actions";


// controls for editing variant.params.magnify
//
const EditMagnify = ({ variant, restartFrom }) => {

	const magnify = variant.params?.magnify || { active: false };

	const getMagnifyValue = (field, default_value) => {
		let rv = magnify[field];

		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// update params
    const dispatch = useDispatch();
	const setParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			magnify: {
				...magnify,
				...params
			} 
		}));
		//restartFrom(0);
	}

	const source_dimensions = variant.source_asset.replace('source-', '').split('x');
	
	// TODO calc based on viewbox / source dimensions
	const max_zoom = 5;
	
	// 4096 width tbc
	const max_zoom_if_upscaled = 8;
	
	return (
		<>
			<Card>		
				<CardHeader>
					<strong>Magnify</strong>
				</CardHeader>
				<CardBody>
					<Row className="mb-3">
						<Col>
							<div
							  className="form-check form-switch"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="magnify_active"
								defaultChecked={ !!getMagnifyValue('active', false) }
								onChange={ (event) => setParams({
									'active': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="magnify_active"
							  >
								Active
							  </label>
							</div>
						</Col>
					</Row>
					<Row>
						<Col xs={ 6 }>
							<label className="form-label">Maximum Zoom</label>
							<Input
								type="range"
								value={ getMagnifyValue('zoom', 5) }
								onChange={ (event) => setParams({
									'zoom': Math.min(max_zoom, parseFloat(event.target.value))
								}) }
								min={ 3 }
								max={ max_zoom_if_upscaled }
								tooltip="on"
								step={ 0.1 }
								className="mt-2 mb-2"
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>Viewers zoom into the image using a mouse wheel or pinch-to-zoom gestures.</p>
							{ max_zoom_if_upscaled > max_zoom && (
								<Alert color="info" role="alert">
									Higher zoom becomes possible when you 
									{" "}<Link
										to="#"
										className="text-danger"
										onClick={ () => dispatch(updateEditor({ app_id: 'upscale' })) }
									>Upscale</Link>{" "}
									your image to 4K resolution.
								</Alert>			
							) }
						</Col>
					</Row>
				</CardBody>
			</Card>
		</>
	)
}

export default EditMagnify;