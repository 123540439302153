import React, { useEffect } from "react";

//redux
import { useDispatch } from "react-redux";

// actions
import { authLogout } from "../../store/actions";

const Logout = props => {

	//meta title
	document.title = "Logout...";

	const dispatch = useDispatch();

	dispatch(authLogout());
};

export default Logout;