import React, { useRef, useState, useEffect, useMemo } from "react"

// actions
import { useDispatch, useSelector } from "react-redux";
import { setVariantParams, updateEditor } from "../../store/actions";

import fontData from './webfonts.json';

// define sub functions here so they are not redefined on every render
// requires params to be passed around
const getSVGProps = (app_id, viewBox, maxHeight, magnifier, variant, imageLoading, introLoading, pointerEvents) => {

	let svg_style = {
		display: 'block',
		// hide when framed (canva)
		overflow: window.self === window.top ? 'visible' : 'hidden',
		opacity: (imageLoading || introLoading) ? 0 : 1
	};
	
	if (app_id) {
		// Editor
		svg_style = {
			...svg_style,
			maxHeight: maxHeight,
			margin: '0 auto',
		}
	
	} else {
		// Library
		svg_style = {
			...svg_style,
			width: '100%',
			height: 'auto',
		}	
	}

	if (variant.params.tilt?.active) {
		svg_style.transformOrigin = '50% 50%'
	}

	if (pointerEvents) {
		svg_style.pointerEvents = pointerEvents;
	}

	let className = `bi-${variant.variant_id}`;
	if (magnifier.visible) {
		className += ' bi-magnified'
	}

	return {
		version: '1.1',
		xmlns: 'http://www.w3.org/2000/svg',
		viewBox: `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`,
		//preserveAspectRatio: "xMidYMid slice",
		className: className,
		style: svg_style,
	}
}


const getImageHref = (variant, width, editor, edge) => {
	
	// legacy
	if (variant.legacy) {
		// legacy Image+ url		
		const href = `https://imgwb.com/vw:admin/a:${variant.account_id}/i:${variant.image_id}/w:640/view?variant=${variant.variant_id}`;	
		if (variant.variant_type == 'baseline') {
			return `${href}&codec=jpeg|webp|avif`;
		}
		return href;
	}
	
	// use source_asset for editor.colors
	if (editor.app_id == 'colors' && variant.variant_type != 'baseline') {
		return `https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/f:${variant.source_asset}`;
	}
	
	// baseline doesn't need ts
	const version = edge?.versions?.web || variant.web_assets_updated;
	if (!version || variant.variant_type == 'baseline') {
		return `https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/vw:admin/w:${width}/c:web`;
	}
	
	// variant, codecs to avoid svg
	return `https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/vw:admin/ts:${version}/w:${width}/c:web`;
}


// calculate x, y translation and scale from focus box
const getTransform = (_box, zoom, variant) => {

	let yOrigin = 0;
	let width = variant.width;
	let height = variant.height;

	const _box_parts = _box.split(':')
	if (_box_parts.length > 1) {
		yOrigin = variant.height * parseInt(_box_parts[0]);
		_box = _box_parts[1];
	}

	const box_x = parseFloat('0.'+_box.substring(0, 3));
	const box_y = parseFloat('0.'+_box.substring(3, 6));
	const box_w = parseFloat('0.'+_box.substring(6, 9));
	const box_h = parseFloat('0.'+_box.substring(9, 12));

	let scale = Math.max(1, zoom / Math.max(box_w, box_h));
	
	// not too much zoom because the image quality will suffer
	scale = Math.min(3, scale);
	
	// center the box
	let x = -scale * width * (box_x + box_w/2) + width/2;
	let y = -scale * height * (box_y + box_h/2) + height/2

	// make sure no border space
	x = Math.min(0, Math.max(x, -scale * width + width));
	y = Math.min(0, Math.max(y, -scale * height + height));

	return {
		x: parseFloat(x).toFixed(1).replace('-0.0', '0'),
		y: parseFloat(y - yOrigin * scale).toFixed(1).replace('-0.0', '0'),
		scale: parseFloat(scale).toFixed(2)
	}
}

// handle zoomMouseOverBox
const getMotionProps = (magnifier, variant, editor, zoomMouseOverBox) => {

	// full image if we're magnifying
	if (magnifier.visible) return;
	
	// bail if manual focus
	if (editor.manualFocus) return;
	
	// or adjusting colors
	if (editor.app_id == 'colors') return;

	// hover on options
	if (zoomMouseOverBox) {	
		// full image if mouseover manual focus
		if (zoomMouseOverBox == 'capture') {
			return;
			
		} else {
			// default zoom 1.5
			const zoom = (zoomMouseOverBox.includes('000000999999') ? 1 : 1.5);
			const transform = getTransform(zoomMouseOverBox, zoom, variant);

			return {
				transform: `translate(${transform.x}, ${transform.y}) scale(${transform.scale})`
			}
		}
	}

	if (!variant.params?.motions || !variant.params?.motions_advanced?.active) return;

	// 0,0 (1)
	if (!variant.params.motions[0].box) return;

	const transform = getTransform(variant.params.motions[0].box, variant.params.motions[0].zoom, variant);

	// reduced motion will show initial transform
	return {
		transform: `translate(${transform.x}, ${transform.y}) scale(${transform.scale})`
	}
}

// opacity is normally 0, 1 when in caption freeze
const getCaptionStyle = (_caption, opacity) => {
	let rv = {
		textAnchor: 'middle',
		opacity: opacity
	};

	if (_caption.font_weight) rv.fontWeight = _caption.font_weight;
	if (_caption.italic) rv.fontStyle = 'italic';

	return rv;
}


const PreviewTitle = ({ variant, magnifier }) => {
	if (magnifier.visible) return;

	return (
		<title>
			{ variant.svg_title }
		</title>
	)
}

// unique list of fonts to download
// also handle custom font_url
const FontStyle = ({ variant, editor }) => {

	// import or fetch font css, depending on content security policy
	const [loadMethod, setLoadMethod] = useState(null);
	useEffect(() => {
		const detectCSP = () => {
			return new Promise((resolve) => {
				const link = document.createElement('link');
				link.rel = 'stylesheet';
				link.href = 'data:text/css;base64,LyogZXhwbGFpbih0aGlzLHRvPSdjYXJlZXJzQGJldHRlcmltYWdlcy5haScpLnRoZW4oY2xhaW1Zb3VyUHJpemUoKSk7ICov';

				link.onload = () => {
					document.head.removeChild(link);
					resolve('import');
				};

				link.onerror = () => {
					document.head.removeChild(link);
					resolve('fetch');
				};

				document.head.appendChild(link);
			});
		};

		detectCSP().then(setLoadMethod);
	}, []);

	// captions and buttons that use a font
	const fonts_used = useMemo(() => {
	
		let fonts = [];
		if (variant.params?.captions) fonts = [ variant.params.captions ];
		if (variant.params?.buttons) fonts = fonts.concat(variant.params.buttons);

		if (editor.fontFamilyMouseOver) {
			fonts.push({
				font_family: editor.fontFamilyMouseOver.replaceAll('"', '')
			});
		}
		return fonts;
	
	}, [variant.params.captions, variant.params.buttons, editor.fontFamilyMouseOver]);

	const [ fontsGoogle, setFontsGoogle ] = useState({});
	const [ fontsCustom, setFontsCustom ] = useState({});
	// determine google and custom fonts whenever fonts_used is updated
	useEffect(() => {
		let fonts_google = {};
		let fonts_custom = {};

		fonts_used.forEach(function(_params) {
			if (_params.font_source == 'custom' && _params.font_family_url && !_params.font_family_url.includes('fonts.gstatic.com')) {
				fonts_custom[_params.font_family_custom] = _params.font_family_url;
	
			} else {
	
				let font_family;
				if (_params.font_source == 'custom') {
					// might be custom with fonts.gstatic.com
					font_family = _params.font_family_custom;
				} else {
					font_family = _params.font_family;
				}
		
				const fontFamilyData = fontData[font_family];

				if (fontFamilyData) {
					let key;
					if (fontFamilyData?.variable) {
						// use variable font files
						if (_params.italic) {
							key = '1,' + fontFamilyData.italic[0] + '..' + fontFamilyData.italic[1];
						} else {
							key = '0,' + fontFamilyData.regular[0] + '..' + fontFamilyData.regular[1];
						}

					} else {
						// check if weight is possible
						let weight = _params.font_weight || 400;
						const possible = _params.italic ? fontFamilyData.italic : fontFamilyData.regular;
						if (!(weight in possible)) {
							weight = 400;
						}				
						key = _params.italic ? `1,${weight}` : `0,${weight}`;
					}

					if (!fonts_google[font_family]) fonts_google[font_family] = {};
					fonts_google[font_family][key] = true;
				}
			}
		});			
		
		setFontsGoogle(fonts_google);
		setFontsCustom(fonts_custom);
		
	}, [fonts_used]);
	
	// calc google fonts url
	const googleFontsUrl = useMemo(() => {
		if (!fontsGoogle) return;
		
		const families_google = Object.entries(fontsGoogle).map(([family, weights]) => {
			// Google Fonts API - tuples must be sorted
			const weights_sorted = Object.keys(weights).sort();

			const weights_list_ital = weights_sorted.join(';');
			const ital = (weights_list_ital.indexOf('1,') != -1);
			const weights_list_regular = weights_sorted.map((weight) => {
				return weight.split(',')[1];
			}).join(';');

			return 'family=' + family.replaceAll(' ', '+') + ':' + (ital ? 'ital,' : '') + 'wght@' + (ital ? weights_list_ital : weights_list_regular);
		});
		
		return 'https://fonts.googleapis.com/css2?' + families_google.join('&');
	
	}, [fontsGoogle]);


	// fetch font css if we're constrained by content security policy
	useEffect(() => {
		if (loadMethod === 'fetch') {
			const loadFont = async () => {
				try {
					const response = await fetch(googleFontsUrl);
					const cssText = await response.text();

					const style_id = 'bi-font-style';
					let style_el = document.getElementById(style_id);
					if (style_el) style_el.remove();

					style_el = document.createElement('style');
					style_el.id = style_id;
					style_el.textContent = cssText;
					
					// so variable fonts are available for preview
					document.head.appendChild(style_el);

				} catch (error) {

					//console.error('Error loading font:', error);
				}
			};

			loadFont();
		}
	}, [googleFontsUrl, loadMethod]);

	return (
		<style>
			{ loadMethod == 'import' && googleFontsUrl && `@import url('${googleFontsUrl}');` }

			{ Object.entries(fontsCustom).map(([font_family, font_family_url]) => {
				if (font_family_url) {
					return `@font-face { font-family: '${font_family}'; src: url('${font_family_url}'); }\n`;		
				} else {
					return `@font-face { font-family: '${font_family}'; }\n`;		
				}
			}) }
		</style>
	);

}


const getBestShadowColor = (textColor) => {
	const hex = textColor.replace('#', '');
	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

	// If luminance is greater than 0.5 (bright color), return dark shadow, else return light shadow
	return luminance > 0.5 ? '#00000080' : '#FFFFFF80';	
}


const getFontEffect = (fontObj) => {

	const shadowColor = getBestShadowColor(fontObj.color || '#FFFFFF');
	
	switch (fontObj.effect) {
		case 'shadow':
			return {
				//textShadow: `3px 3px 0 ${shadowColor}`
				textShadow: `0.02em 0.02em 0 ${shadowColor}`
			}
			
		case 'anaglyph':
			return {
				textShadow: `-0.05em 0 red, 0.05em 0 cyan`
			}
			
		case 'neon':
			return {
				textShadow: `0 0 0.01em #fff, 0 0 0.02em #fff, 0 0 0.03em #fff, 0 0 0.05em #f7f,0 0 0.08em #f0f, 0 0 0.13em #f0f, 0 0 0.21em #f0f, 0 0 0.34em #f0f`
			}

		case 'outline':
			return {
				textShadow: `0 1px 3px ${shadowColor}, 0 -1px 3px ${shadowColor}, 1px 0 3px ${shadowColor}, -1px 0 3px ${shadowColor}`
			}

		case 'emboss':
			return {
				textShadow: `0px 1px 3px #fff, 0 -1px 3px #000`
			}

		case 'fire':
			return {
				textShadow: `0 -0.05em 0.2em #FFF, 0.01em -0.02em 0.15em #FE0, 0.01em -0.05em 0.15em #FC0, 0.02em -0.15em 0.2em #F90, 0.04em -0.20em 0.3em #F70, 0.05em -0.25em 0.4em #F70, 0.06em -0.2em 0.9em #F50, 0.1em -0.1em 1.0em #F40`
			}

	}
}

// when defined inside svg, only applies within that svg - hmmm not sure
const PreviewStyle = ({ variant, editor }) => {
	return (
		<>
		<FontStyle
			variant={ variant }
			editor={ editor }
		/>
	
		<style type="text/css">
			{ (variant.params?.buttons || variant.params?.stickers) && `@keyframes bi-reveal {
  0% {
	visibility: hidden;
	opacity: 0;
  }
  100% {
	visibility: visible;
	opacity: var(--opacity);
  }
}` }

		</style>
		</>
	)
}

const PreviewFilters = ({ variant, editor }) => {
	if (editor.app_id == 'colors' && variant.params?.colors) {
		return (
			<defs>
				<filter id={ `bi-${variant.variant_id}-colors` }>
					<feColorMatrix type="hueRotate" values={ variant.params?.colors?.hue_rotate || 0 } />
					<feColorMatrix type="saturate" values={ typeof variant.params?.colors?.saturate != 'undefined' ? variant.params.colors.saturate : 1 } />
				</filter>
			</defs>
		)	
	}
}


const PreviewImage = ({ svgRect, viewBox, magnifier, variant, width, onImageLoad, onImageError, editor, zoomMouseOverBox, edge, animationRestart, introDuration }) => {

	const dispatch = useDispatch();

	// need two images if we're fading
	const image_refs = useRef([0, 1].map(() => React.createRef()));
	
	// for wiggle, handheld
	const effect_ref = useRef();

	// setup web animations
	useEffect(() => {

		if (window.matchMedia && window.matchMedia('(prefers-reduced-motion)').matches) return;

		// bail if manual focus
		if (editor.manualFocus) return;

		// bail if no motion or intro camera adjustment
		if ((!variant.params?.motions || !variant.params?.motions_advanced?.active) && variant.params?.intro?.intro_type != 'camera') return;
		
		// no animation if we're magnifying
		if (magnifier.visible) return;

		// or adjusting colors
		if (editor.app_id == 'colors') return;

		// no animation if mouseover
		if (zoomMouseOverBox) return;

		// total
		let duration = 0;

		// calc total duration
		if (variant.params?.motions && variant.params?.motions_advanced?.active) {
			for (let idx = 0; idx < variant.params.motions.length; idx++) {
				const motion = variant.params.motions[idx];
				duration += motion.duration || 0.1;
	
				// ignore final transition
				if (idx == variant.params.motions.length-1) break;
	
				// include transition duration (default 1s)
				if (['pan', 'fade'].includes(motion.transition)) {
					duration += motion.transition_duration || 1;
				} else if (motion.transition == 'cut') {
					duration += 0.01;
				}
			}
		}
		
		// values for each use_idx
		let key_times = [ [], [] ];
		let anim_values = [ [], [] ];
		
		// times and values for camera effects
		let effect_times = [];
		let effect_values = [];

		let begin = 0;

		// intro camera adjustment
		if (variant.params?.intro?.intro_type == 'camera' && introDuration) {
			begin = introDuration
			duration += introDuration;

			// some movement behind the intro fading out
			key_times[0].push(0);
			anim_values[0].push({
				opacity: 1,
				transform: `translate(0,0) scale(1)`
			});

			// zoom scale matches the intro end-frame, from how much had to be cropped to get a stability-sized intro video
			const transform = getTransform('000000999999', variant.params.intro.zoom || 1, variant);
			key_times[0].push((introDuration - 0.01) / duration);
			anim_values[0].push({
				opacity: 1,
				transform: `translate(${transform.x}px,${transform.y}px) scale(${transform.scale})`
			});
			
		} else if (variant.params?.intro?.intro_type == 'manual' && introDuration) {
			begin = introDuration
			duration += introDuration;		
		}

		if (variant.params?.motions && variant.params?.motions_advanced?.active) {
			for (let image_idx in [0, 1]) {

				let offset = 0;

				// which image to use when cross-fading between them
				let use_idx = 0;	

				let previous_crossfade;

				for (let idx = 0; idx < variant.params.motions.length; idx++) {
					const motion = variant.params.motions[idx];

					const transform = getTransform(motion.box, motion.zoom, variant);

					// add previous so fade in doesn't move
					if (previous_crossfade && image_idx == use_idx) {
						const previous_transition_duration = variant.params.motions[idx-1].transition_duration || 1;
					
						key_times[image_idx].push((begin + offset - previous_transition_duration) / duration);
						anim_values[image_idx].push({
							opacity: 0,
							transform: `translate(${transform.x}px,${transform.y}px) scale(${transform.scale})`
						});
					}
	
					// focus start
					if (image_idx == use_idx) {
						key_times[image_idx].push(((begin + offset) / duration));
						anim_values[image_idx].push({
							opacity: 1,
							transform: `translate(${transform.x}px,${transform.y}px) scale(${transform.scale})`
						});
					}
					
					const motion_duration = motion.duration || 0.01;
					
					// effect - on first iteration of image_idx only
					if (image_idx == 0 && motion.effect && motion_duration > 0.2) {
						// strength 0.1 to 3
						const strength = motion.strength || 1;
					
						// fixed -0.1 to +0.1 seconds for effects to work
						let e_times = [-0.1, -0.05, 0.05, 0.1].map((t) => (begin + offset + motion_duration/2 + t)/duration);
						
						let e_values = [];
						if (motion.effect == 'wiggle') {
							e_values = [0, 5*strength, -5*strength, 0].map((val) => ({ transform: `rotate(${val}deg)` }));

						} else if (motion.effect == 'nod') {
							e_values = [[0, 0], [variant.height*0.1*strength, 25*strength], [variant.height*-0.1*strength, -10*strength], [0, 0]].map(([ty, rx]) => ({ transform: `translateY(${ty}px) rotateX(${rx}deg)` }));

						} else if (motion.effect == 'shake') {
							e_values = [[0, 0], [variant.width*0.1*strength, 30*strength/2], [variant.width*-0.1*strength, -30*strength/2], [0, 0]].map(([tx, ry]) => ({ transform: `translateX(${tx}px) rotateY(${ry}deg)` }));

						} else if (motion.effect == 'handheld') {
							e_times = [(begin + offset)/duration];
							e_values = [{ transform: 'translate(0,0) rotate(0) scale(1)' }];
							for (let t = 0.1; t < motion_duration - 0.1; t += 0.1) {
								e_times.push((begin + offset + t)/duration);
								const tx = variant.width * (-0.05 + 0.1*Math.random()) * strength;
								const ty = variant.height * (-0.05 + 0.1*Math.random()) * strength;
								const r = (-2 + 4*Math.random()) * strength;
								const s = 1 + 0.1 * Math.random() * strength;
								e_values.push({
									transform: `translate(${tx}px,${ty}px) rotate(${r}deg) scale(${s})`
								})
							}
							e_times.push((begin + offset + motion_duration)/duration);
							e_values.push({ transform: 'translate(0,0) rotate(0) scale(1)' });
						}
						effect_times.push(...e_times);
						effect_values.push(...e_values);
					}
										
					// focus end
					offset += motion_duration;

					if (image_idx == use_idx) {
						key_times[image_idx].push(((begin + offset) / duration));
						anim_values[image_idx].push({
							opacity: 1,
							transform: `translate(${transform.x}px,${transform.y}px) scale(${transform.scale})`
						});
					}

					previous_crossfade = false;

					if (motion.transition == 'cut') {
						offset += 0.01;
				
					} else if (motion.transition == 'pan') {
						offset += (motion.transition_duration || 1);
	
					} else if (motion.transition == 'fade') {
						offset += (motion.transition_duration || 1);
					
						// add next so fade out doesn't move
						if (idx != variant.params.motions.length-1 && image_idx == use_idx) {
							key_times[image_idx].push((begin + offset) / duration);
							anim_values[image_idx].push({
								opacity: 0,
								transform: `translate(${transform.x}px,${transform.y}px) scale(${transform.scale})`
							});
						}
					
						use_idx	= (use_idx + 1) % 2;
						previous_crossfade = true;
					}
				}	
			}
		}
		
		// need clean animations from 0 to 1 - pad
		[0, 1].forEach((image_idx) => {
			if (key_times[image_idx].length) {
				if (key_times[image_idx][0] != 0) {
					key_times[image_idx].unshift(0);
					anim_values[image_idx].unshift(anim_values[image_idx][0]);
				}
				if (key_times[image_idx].slice(-1)[0] != 1) {
					key_times[image_idx].push(1);
					anim_values[image_idx].push(anim_values[image_idx].slice(-1)[0]);
				}
			}		
		})		
		if (effect_times.length) {
			if (effect_times[0] != 0) {
				effect_times.unshift(0);
				effect_values.unshift(effect_values[0]);
			}
			if (effect_times.slice(-1)[0] != 1) {
				effect_times.push(1);
				effect_values.push(effect_values.slice(-1)[0]);
			}
		}		

		let animations = [];

		// setup animations
		image_refs.current.forEach((ref, image_idx) => {
			if (ref.current) {		
				const frames = anim_values[image_idx].map((values, idx) => ({
					offset: key_times[image_idx][idx],
					...values
				}));

				let options = {
					duration: 1000 * duration,
					//easing: 'cubic-bezier(.2,.9,0,1)',
					fill: 'forwards'
				};
				if (variant.params.motions_advanced?.alternate) options.direction = 'alternate';
				if (variant.params.motions_advanced?.iterations) {
					options.iterations = variant.params.motions_advanced.iterations == 'infinite' ? Infinity : variant.params.motions_advanced.iterations;
				}

				const animation = ref.current.animate(frames, options);
				animation.currentTime = 1000 * (animationRestart.begin || 0);
				animations.push(animation);
			}
		});
		
		// wiggle, handheld
		if (effect_ref.current && effect_times.length) {
				const frames = effect_values.map((values, idx) => ({
					offset: effect_times[idx],
					...values
				}));

				let options = {
					duration: 1000 * duration,
					//easing: 'cubic-bezier(.2,.9,0,1)',
					fill: 'forwards'
				};
				if (variant.params.motions_advanced?.alternate) options.direction = 'alternate';
				if (variant.params.motions_advanced?.iterations) {
					options.iterations = variant.params.motions_advanced.iterations == 'infinite' ? Infinity : variant.params.motions_advanced.iterations;
				}

				const animation = effect_ref.current.animate(frames, options);
				animation.currentTime = 1000 * (animationRestart.begin || 0);
				animations.push(animation);
		}
		
		// cancel animations
		return () => {
			animations.forEach((anim) => {
				anim.cancel();
			});
		};
		
	}, [variant.params.motions, variant.params.motions_advanced, introDuration, animationRestart, zoomMouseOverBox, editor.app_id, magnifier.visible]);

	// manual focus
	const [ isDrawing, setIsDrawing ] = useState(false);
	const [ startPoint, setStartPoint ] = useState({ x: 0, y: 0 });
	const [ rect, setRect] = useState({ x: 0, y: 0, w: 0, h: 0 });
	const scale = svgRect.width / viewBox.width;

	const handleMouseDown = (e) => {	
		e.preventDefault();
		
		setIsDrawing(true);
		
		const x = e.clientX - svgRect.left;
		const y = e.clientY - svgRect.top;
		
		setStartPoint({ x, y });
		setRect({ x, y, w: 0, h: 0 });
	};

	const handleMouseMove = (e) => {		
		if (!isDrawing) return;
		
		const x = e.clientX - svgRect.left;
		const y = e.clientY - svgRect.top;
	
		setRect({
			x: Math.min(x, startPoint.x),
			y: Math.min(y, startPoint.y),
			w: Math.abs(x - startPoint.x),
			h: Math.abs(y - startPoint.y),
		});

		// stops the image being highlighted/greyed
		e.preventDefault();
	}

	const handleMouseUp = () => {		
		if (!isDrawing) return;
	
		const x = (rect.x / svgRect.width).toFixed(3).substring(2, 5);
		const y = (rect.y / svgRect.height).toFixed(3).substring(2, 5);
		const w = (rect.w / svgRect.width).toFixed(3).substring(2, 5);
		const h = (rect.h / svgRect.height).toFixed(3).substring(2, 5);
		const box = `${x}${y}${w}${h}`;

		setIsDrawing(false);
	
		dispatch(updateEditor({
			manualFocus: false,
			manualFocusBox: box
		}))
	}

	// layer is nested in svg with overflow:hidden, so magnifier/effect layers can overflow
	return (
		<svg 
			x={ viewBox.x } 
			y={ viewBox.y } 
			width={ viewBox.width } 
			height={ viewBox.height } 
			viewBox={ `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}` }
			overflow="hidden"
			className={`bi-${variant.variant_id}-image${editor.manualFocus ? ' bi-focus-capture' : ''}`}
			onMouseDown={ editor.manualFocus ? handleMouseDown : undefined }
			onMouseMove={ editor.manualFocus ? handleMouseMove : undefined }
			onMouseUp={ editor.manualFocus ? handleMouseUp : undefined }
			onMouseLeave={ editor.manualFocus ? handleMouseUp : undefined }
		>
			<defs>
				<g 
					id={ `bi-${variant.variant_id}-image` }
				>
					<image
						href={ getImageHref(variant, width, editor, edge) }
						crossOrigin="anonymous"
						x={ magnifier.view_delta_x } 
						y={ magnifier.view_delta_y }
						width={ variant.width }
						height={ variant.height }
						preserveAspectRatio="xMidYMid slice"
						onLoad={ onImageLoad }
						onError={ onImageError }
						filter={ (editor.app_id == 'colors' && variant.params?.colors) ? `url(#bi-${variant.variant_id}-colors)` : null }
					/>
					{ !!variant.params.slideshow && !!variant.params.slideshow.length && variant.params.slideshow.map((slide, idx) => {
						//const yPos = variant.params.slideshow.slice(0, idx).reduce((sum, img) => sum + slide.height, variant.height);	
						const yPos = variant.height * (1 + idx);
					
						return (
							<image
								key={ idx }
								href={ slide.src }
								crossOrigin="anonymous"
								x={ 0 } 
								y={ yPos }
								width={ variant.width }
								height={ variant.height }
								preserveAspectRatio="xMidYMid slice"
								filter={ (editor.app_id == 'colors' && variant.params?.colors) ? `url(#bi-${variant.variant_id}-colors)` : null }
							/>
						)
					}) }
				</g>
			</defs>
			<g 
				ref={ effect_ref }
				className={ `bi-${variant.variant_id}-effect` }
				style={{
					transformOrigin: '50% 75%'
				}}	
			>
				{ !!zoomMouseOverBox && (
					<g 
						{ ...getMotionProps(magnifier, variant, editor, zoomMouseOverBox) }
					>
						<use 
							href={ `#bi-${variant.variant_id}-image` } 
						/>			
					</g>
				) }
			
				{ !zoomMouseOverBox && !!variant.params.click?.link && (
					<a href={ variant.params.click.link } target={ variant.params.click?.target || '_self' }>			
						{ [0, 1].map((idx) => {
							return (
								<g 
									key={ `bi-${variant.variant_id}-ic${idx}` }
									ref={ image_refs.current[idx] }	
									style={{
										opacity: idx ? 0 : 1
									}}
								>
									<use 
										href={ `#bi-${variant.variant_id}-image` } 
									/>
								</g>
							)
						}) }					
					</a>
				) }

				{ !zoomMouseOverBox && !variant.params.click?.link &&
					[0, 1].map((idx) => {
						return (
							<g 
								key={ `bi-${variant.variant_id}-ic${idx}` }
								ref={ image_refs.current[idx] }	
								style={{
									opacity: idx ? 0 : 1
								}}
							>
								<use 
									href={ `#bi-${variant.variant_id}-image` } 
								/>
							</g>
						)
					})					
				}
			</g>
			
			{ isDrawing && (
				<rect 
					x={ viewBox.x + (rect.x / scale) } 
					y={ viewBox.y + (rect.y / scale) } 
					width={ rect.w / scale } 
					height={ rect.h / scale }
					fill="none" 
					stroke="red" 
					strokeWidth={ 2 / scale }
				/>
			) }
			
			{ !!introDuration && (
				<animate attributeName="opacity" attributeType="css" dur="0.1s" from="0" to="1" fill="freeze" />
			) }
		</svg>
	)
}

// video intro
// introVideoRestartRef used for restart
const PreviewIntro = ({ variant, onIntroLoad, onIntroError, viewBox, editor, introDuration }) => {

	if (window.matchMedia && window.matchMedia('(prefers-reduced-motion)').matches) {
		onIntroLoad();
		return;
	}
	
	if (editor.manualFocus) return;

	let intro_src, intro_el = 'img';
	if (variant.params.intro?.intro_type == 'camera') {
		const src_filename = variant.compiled?.intro?.src_avif;
		intro_src = `https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/f:${src_filename}`;
	
	} else if (variant.params.intro?.intro_type == 'fx') {
		const fx = variant.params.intro.fx || 'curtains';
		let fx_file = `${fx}.avif`;
		if (navigator.vendor.match(/apple/i)) {
			fx_file = `${fx}-hevc.avif`;
		}
		
		intro_src = `https://static.${process.env.REACT_APP_CONTROLLER}/s/intro-fx/${fx_file}`;
	
	} else if (variant.params.intro?.intro_type == 'manual') {
		const src_filename = variant.compiled?.intro?.src_avif;
		intro_src = `https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/f:${src_filename}`;

		if (variant.params.intro.manual_src) {
			intro_src = variant.params.intro.manual_src;
			intro_el = 'video';
		}
	}
	
	if (!intro_src) return;
	//let duration = Math.min(2, variant.params.intro?.duration || 2);
	const fadeStart = (introDuration - 0.1) / introDuration;

	// safari has an svg bug showing foreignObject atop captions :(
	// safari supports mp4 in img tag as the fix :) force avc1 until we can get h265 working
	// actually avif sequence is better all round, avoids foreignObject, supports alpha fx
	// but safari doesn't honor avif transparency so yes hevc video is best for safari
	return (
		<svg x={ viewBox.x } y={ viewBox.y } width={ viewBox.width } height={ viewBox.height } 
			viewBox={ `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}` }
			overflow="hidden" 
			className="bi-intro"
		>
			<g>
				{ (intro_el == 'img' || navigator.vendor.match(/apple/i)) ? (
					<image
						href={ intro_src }
						crossOrigin="anonymous"
						x={ viewBox.x } y={ viewBox.y }
						width={ viewBox.width }
						height={ viewBox.height }
						preserveAspectRatio="xMidYMid slice"
						onLoad={ onIntroLoad }
						onError={ onIntroError }
						filter={ (editor.app_id == 'colors' && variant.params?.colors) ? `url(#bi-${variant.variant_id}-colors)` : null }
					/>
				) : (				
					<foreignObject 
						x={ viewBox.x } y={ viewBox.y }
						width={ viewBox.width }
						height={ viewBox.height }
					>
						<video 
							xmlns="http://www.w3.org/1999/xhtml" 
							style={{
								objectFit: 'cover',
								filter: (editor.app_id == 'colors' && variant.params?.colors) ? `url(#bi-${variant.variant_id}-colors)` : null
							}} 
							width={ viewBox.width }
							height={ viewBox.height }
							autoPlay="autoplay" muted="muted" playsInline="playsinline" 
							crossOrigin="anonymous" 
							src={ intro_src }
							onCanPlayThrough={ onIntroLoad }
							onError={ onIntroError }
						>
						</video>
					</foreignObject>				
				) }
				<animate 
					attributeName="opacity" 
					values="1;1;0" 
					keyTimes={ `0; ${fadeStart}; 1` }
					dur={ introDuration }
					fill="freeze"
				/>
			</g>
		</svg>
	)
}


// replace #data- and #local-
const personalizeAndLocalize = (text, variant, localContext, conjoin) => {

	if (!text) return;
	
	const _regex_personalization = /#(data|local)-(\w+)#/g;
	
	const user = useSelector(state => state.Profile?.data?.user);
	
	let dataset = {};
	if (variant.params?.personalize?.defaults) {
		dataset = Object.fromEntries(variant.params.personalize.defaults);
	}
	
	return text.replace(_regex_personalization, (_match, _type, _attribute) => {
		if (_type == 'data') {
			const value = dataset[_attribute];
			return value !== undefined ? value : _match;
		} else if (_attribute == 'daypart') {
			const hr = new Date().getHours();
			if (hr < 6) return 'Early';
			if (hr < 12) return 'Morning';
			if (hr < 14) return 'Lunchtime';
			if (hr < 18) return 'Afternoon';
			if (hr < 22) return 'Evening';
			return 'Night';
		} else if (['weekday', 'month', 'year'].includes(_attribute)) {
			return new Intl.DateTimeFormat(navigator.language, { [_attribute]: _attribute == 'year' ? 'numeric' : 'long' }).format(new Date());
		} else {
			const rv = localContext[_attribute] || localContext['region'] || localContext['country'];
			if (conjoin) {
				// captions
				return rv.replaceAll(' ', '~');
			} else {
				// buttons
				return rv;
			}
		}
	});
}


const PreviewCaptions = ({ viewBox, variant, editor, localContext, zoomMouseOverBox, animationRestart }) => {

	// skip if zoom mouseover
	if (zoomMouseOverBox) return;

	const captions = variant.params?.captions || {};

	if (!captions.active || !captions.text) return;

	const words = personalizeAndLocalize(captions.text, variant, localContext, true).split(' ');

	// font size based on max word length, adjusted by scale param (0.1 to 2)
	const size = (captions.size || 1) * variant.width / Math.max(...words.map(word => word.replaceAll('.', '').length));
	
	const sentenceEndExp = /(\?|!|:|,)$/g;
	
	const x = variant.width / 2;
	const y = variant.height / 2;

	// web animations api smoother than css
	const word_refs = useMemo(() => words.map(() => React.createRef()), [words]);
	
	useEffect(() => {

		if (editor.captionFreeze) return;

		// duration per letter; param ranges from 1 to 20
		const speed = 1 / (captions.speed || 10);

		let begin = captions.begin || 0;
	
		let animations = [];

		word_refs.forEach((ref, idx) => {
			if (ref.current) {
				const word = words[idx];

				const isSentenceEnd = sentenceEndExp.test(word) || idx == words.length - 1;
				const periodCount = (word.match(/\./g) || []).length;
				const duration = speed * (Math.max(5, word.length) + (periodCount * 5) + (isSentenceEnd ? 6 : 0));
					
				let frames;
				if (window.matchMedia && window.matchMedia('(prefers-reduced-motion)').matches) {
					frames = {
						offset: [0, 0.09, 0.1, 0.89, 0.9, 1],
						opacity: [0, 0, 1, 1, 0, 0],
						transform: `rotate(${captions.rotate || 0}deg)`
					}

				} else {
					frames = [
						{ offset: 0, opacity: 0, transform: `rotate(${(captions.rotate || 0) - (captions.spin || 0)}deg) scale(0)`, easing: 'cubic-bezier(.1,0,.1,1)' },
						{ offset: 0.8, opacity: 1, transform: `rotate(${captions.rotate || 0}deg) scale(1)`, easing: 'cubic-bezier(.9,0,.9,1)' },
						{ offset: 1, opacity: 0, transform: `rotate(${(captions.rotate || 0) + (captions.spin || 0)}deg) scale(2)` }
					];
				}
				
				animations.push(ref.current.animate(frames, {
					duration: 1000 * duration,
					delay: 1000 * begin,
					fill: 'forwards'
				}));

				// slight overlap
				begin += duration - speed + (isSentenceEnd ? speed * 6 : 0);
			}
		});
		
		return () => {
			animations.forEach((anim) => {
				anim.cancel();
			});
		}

	}, [captions, variant.params?.intro, animationRestart, editor.captionFreeze]);

	let font_family;
	if (editor.fontFamilyMouseOver && editor.app_id == 'captions') {
		font_family = editor.fontFamilyMouseOver;
	} else {
		font_family = (captions.font_source == 'custom' ? captions.font_family_custom : captions.font_family) || 'Open Sans';
	}

	return (
		<svg x={ viewBox.x } y={ viewBox.y } width={ viewBox.width } height={ viewBox.height } 
			viewBox={ `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}` }
			overflow="visible"
			className={ `bi-${variant.variant_id}-captions` }
		>
			 <style type="text/css">{ `.bi-${variant.variant_id}-captions text {
	user-select: none; 
	pointer-events: none;
	text-anchor: middle;
	font-family: ${font_family};
	font-weight: ${captions.font_weight || 400};
	font-style: ${captions.italic ? 'italic' : 'normal'};
	transform: rotate(${captions.rotate || 0}deg);	
}` }</style>
			{ words.map((word, idx) => {
				// only show first word when frozen
				if (editor.captionFreeze && idx) return;
			
				// remove suffix punctuation except ! and ?
				const word_clean = word.replaceAll('~', ' ').replaceAll('.', '').replace(/(:|,)$/g, '');

				let word_style = {
					opacity: editor.captionFreeze ? 1 : 0,
					fill: captions.color || '#FFFFFF',
					fontSize: `${size}px`,
				}
				
				if (captions.effect) {
					word_style = {
						...word_style,
						...getFontEffect(captions)
					}
				}

				if (editor.captionFreeze) {
					word_style.transform = `rotate(${captions.rotate || 0}deg)`;
				}

				return (			
					<text 
						key={ `word-${idx}` }
						ref={ word_refs[idx] }
						transform-origin={ `${x} ${y}` } 
						x={ x } y={ y }
						dominantBaseline="central" 
						style={ word_style }
					> 
						{ word_clean }
					</text>	
				);
			}) }
		</svg>
	);
}


const PreviewStickers = ({ svgRect, viewBox, variant, editor, zoomMouseOverBox }) => {

	// skip if zoom mouseover
	if (zoomMouseOverBox) return;

	let stickers = variant.params?.stickers || [];
	
	const containerRef = useRef(null);
	// currently dragging button - so we don't have to querySelector
	const dragRef = useRef(null);

	const dispatch = useDispatch();

	const [ dragIdx, setDragIdx ] = useState(null);

	const [ offset, setOffset ] = useState({ x: 0, y: 0 });
	const offsetRef = useRef();
	useEffect(() => {
		offsetRef.current = offset;
	}, [offset]);

	// store position state here until mouseup
	const [ position, setPosition ] = useState({ x: 0, y: 0 });
	const positionRef = useRef();
	useEffect(() => {
		positionRef.current = position;
	}, [position]);

	// refs so useEffect can see the latest values
	const stickersRef = useRef();
	useEffect(() => {
		stickersRef.current = stickers;
	}, [stickers]);

	// sync to editor app
	useEffect(() => {
		if (dragIdx !== null) {
			dispatch(updateEditor({
				stickerIdx: dragIdx
			}))
		}
	}, [dragIdx]);
	
	// setup and cleanup draggable event listeners
	useEffect(() => {
		if (editor.app_id != 'stickers') return;
	
		const grid = parseInt(Math.max(viewBox.width, viewBox.height) / 128);
		const scale = svgRect.width / viewBox.width;

		const handleMouseDown = (idx, e) => {
			e.preventDefault();
			
			setDragIdx(idx);

			setOffset({
				x: e.clientX - svgRect.left - scale * stickersRef.current[idx].x, 
				y: e.clientY - svgRect.top - scale * stickersRef.current[idx].y
			});

			setPosition({
				x: stickersRef.current[idx].x,
				y: stickersRef.current[idx].y
			});
		};

		const handleMouseMove = (e) => {
			e.preventDefault();
			if (dragIdx === null || !dragRef.current) return;

			const buttonRect = dragRef.current.getBoundingClientRect();
			
			const min_x = viewBox.x;
			const min_y = viewBox.y;
			const max_x = viewBox.x + viewBox.width - buttonRect.width / scale;
			const max_y = viewBox.y + viewBox.height - buttonRect.height / scale;
			
			const x = Math.min(max_x, Math.max(min_x, (e.clientX - svgRect.left - offsetRef.current.x) / scale));
			const y = Math.min(max_y, Math.max(min_y, (e.clientY - svgRect.top - offsetRef.current.y) / scale));				

			if (e.ctrlKey) {
				setPosition({
					x: x,
					y: y
				})					
			} else {
				if (parseInt(x/grid) != parseInt(positionRef.current.x/grid) || parseInt(y/grid) != parseInt(positionRef.current.y/grid)) {
					setPosition({
						x: parseInt(x/grid) * grid,
						y: parseInt(y/grid) * grid
					})					
				}
			}			
		};

		const handleMouseUp = () => {
			if (dragIdx === null) return;

			// ensue we get the latest position
			const params = {
				x: positionRef.current.x,
				y: positionRef.current.y
			}

			dispatch(setVariantParams(variant.variant_id, { 
				stickers: [
					...stickersRef.current.slice(0, dragIdx),
					{
						...stickersRef.current[dragIdx],
						...params
					},
					...stickersRef.current.slice(dragIdx + 1)
				]
			}));

			setDragIdx(null);
		};

		if (containerRef.current) {
			containerRef.current.addEventListener('mousemove', handleMouseMove);
			containerRef.current.addEventListener('mouseup', handleMouseUp);
			containerRef.current.addEventListener('mouseleave', handleMouseUp);
			
			containerRef.current.querySelectorAll('image').forEach(
				(button, idx) => button.addEventListener('mousedown', (event) => handleMouseDown(idx, event))
			);
		}

		return () => {
			if (containerRef.current) {
				containerRef.current.removeEventListener('mousemove', handleMouseMove);
				containerRef.current.removeEventListener('mouseup', handleMouseUp);
				containerRef.current.removeEventListener('mouseleave', handleMouseUp);
			
				containerRef.current.querySelectorAll('image').forEach(
					(button, idx) => button.removeEventListener('mousedown', (event) => handleMouseDown(idx, event))
				);
			}
		}
	}, [editor.app_id, containerRef.current, dragRef.current]);
	
	const base_width = Math.max(viewBox.width, viewBox.height) / 10;

	return (
		<svg x={ viewBox.x } y={ viewBox.y } width={ viewBox.width } height={ viewBox.height } 
			viewBox={ `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}` }
			overflow="visible"
			className="bi-stickers"
			ref={ containerRef }
		>
			{ stickers.map((sticker, idx) => {

				let style = {
					cursor: editor.app_id == 'stickers' ? 'move' : 'default',
				};
		
				// reveal
				if (sticker.begin && idx != dragIdx) {
					style = {
						...style,
						visibility: (editor.app_id != 'stickers' || idx != editor.stickerIdx) ? 'hidden' : 'visible',
						opacity: (editor.app_id != 'stickers' || idx != editor.stickerIdx) ? 0 : sticker.opacity,
						'--opacity': sticker.opacity,
						willChange: 'opacity',
						animation: `bi-reveal 0.4s ${sticker.begin}s forwards`
					}
				}

				return (
					<image 
						key={ `sticker-${idx}` }
						ref={ (editor.app_id == 'stickers' && idx == dragIdx) ? dragRef : null }
						style={ style }
						x={ idx == dragIdx ? position.x : sticker.x } 
						y={ idx == dragIdx ? position.y : sticker.y } 
						width={ sticker.scale * base_width }
						opacity={ typeof sticker.opacity != 'undefined' ? sticker.opacity : 0.7 }
						href={ sticker.url }
					/>
				);
			}) }
		</svg>
	);

}


const PreviewButtons = ({ svgRect, viewBox, variant, editor, localContext, zoomMouseOverBox, displayMenu }) => {

	// skip if zoom mouseover
	if (zoomMouseOverBox) return;

	// disable on certain other apps so as not to get in the way
	if (['stickers'].includes(editor.app_id)) return;

	let buttons = variant.params?.buttons || [];
	
	// so size consistent across button groups
	const base_font_size = viewBox.width / Math.max(...buttons.map(button => Math.max(5, (button.text || '').length)));

	const containerRef = useRef(null);
	// currently dragging button - so we don't have to querySelector
	const dragRef = useRef(null);

	const dispatch = useDispatch();

	const [ dragIdx, setDragIdx ] = useState(null);

	const [ offset, setOffset ] = useState({ x: 0, y: 0 });
	const offsetRef = useRef();
	useEffect(() => {
		offsetRef.current = offset;
	}, [offset]);

	// store position state here until mouseup
	const [ position, setPosition ] = useState({ x: 0, y: 0 });
	const positionRef = useRef();
	useEffect(() => {
		positionRef.current = position;
	}, [position]);
	
	// refs so useEffect can see the latest values
	const buttonsRef = useRef();
	useEffect(() => {
		buttonsRef.current = buttons;
	}, [buttons]);

	// sync to editor tabs
	useEffect(() => {
		if (dragIdx !== null) {
			dispatch(updateEditor({
				buttonIdx: dragIdx
			}))
		}
	}, [dragIdx]);
	
	// setup and cleanup draggable event listeners
	useEffect(() => {
		if (editor.app_id != 'buttons') return;
	
		const grid = parseInt(Math.max(viewBox.width, viewBox.height) / 128);
		const scale = svgRect.width / viewBox.width;

		const handleMouseDown = (idx, e) => {
			e.preventDefault();
			
			setDragIdx(idx);

			setOffset({
				x: e.clientX - svgRect.left - scale * buttonsRef.current[idx].x,
				y: e.clientY - svgRect.top - scale * buttonsRef.current[idx].y
			});		

			setPosition({ 
				x: buttonsRef.current[idx].x, 
				y: buttonsRef.current[idx].y 
			});
		};

		const handleMouseMove = (e) => {
			e.preventDefault();
			if (dragIdx === null || !dragRef.current) return;

			const buttonRect = dragRef.current.getBBox();

			const min_x = viewBox.x + (0.5 * buttonRect.width);
			const min_y = viewBox.y + (0.5 * buttonRect.height);

			const max_x = viewBox.x + viewBox.width - (0.5 * buttonRect.width);
			const max_y = viewBox.y + viewBox.height - (0.5 * buttonRect.height);
			
			const x = Math.min(max_x, Math.max(min_x, (e.clientX - svgRect.left - offsetRef.current.x) / scale));
			const y = Math.min(max_y, Math.max(min_y, (e.clientY - svgRect.top - offsetRef.current.y) / scale));				

			if (e.ctrlKey) {
				setPosition({
					x: x,
					y: y
				})					
			} else {
				if (parseInt(x/grid) != parseInt(positionRef.current.x/grid) || parseInt(y/grid) != parseInt(positionRef.current.y/grid)) {
					setPosition({
						x: parseInt(x/grid) * grid,
						y: parseInt(y/grid) * grid
					})					
				}
			}			
		};

		const handleMouseUp = () => {
			if (dragIdx === null) return;

			// ensue we get the latest position
			const params = {
				x: positionRef.current.x,
				y: positionRef.current.y
			}

			dispatch(setVariantParams(variant.variant_id, { 
				buttons: [
					...buttonsRef.current.slice(0, dragIdx),
					{
						...buttonsRef.current[dragIdx],
						...params
					},
					...buttonsRef.current.slice(dragIdx + 1)
				]
			}));

			setDragIdx(null);
		};

		if (containerRef.current) {
			// attach some to parent (svg root) so mouse doesn't escape
			containerRef.current.parentNode.addEventListener('mousemove', handleMouseMove);
			containerRef.current.parentNode.addEventListener('mouseup', handleMouseUp);
			containerRef.current.parentNode.addEventListener('mouseleave', handleMouseUp);
			
			containerRef.current.querySelectorAll('g').forEach(
				(button, idx) => button.addEventListener('mousedown', (event) => handleMouseDown(idx, event))
			);
		}

		return () => {
			if (containerRef.current) {
				containerRef.current.parentNode.removeEventListener('mousemove', handleMouseMove);
				containerRef.current.parentNode.removeEventListener('mouseup', handleMouseUp);
				containerRef.current.parentNode.removeEventListener('mouseleave', handleMouseUp);
			
				containerRef.current.querySelectorAll('g').forEach(
					(button, idx) => button.removeEventListener('mousedown', (event) => handleMouseDown(idx, event))
				);
			}
		}
	}, [editor.app_id, containerRef.current, dragRef.current]);


	const getGroupProps = (variant, button, idx, position, displayMenu) => {
		let style = {
			cursor: editor.app_id == 'buttons' ? 'move' : 'pointer',
		};
		
		// reveal
		if (button.begin && idx != dragIdx) {
			style = {
				...style,
				visibility: (editor.app_id != 'buttons' || idx != editor.buttonIdx) ? 'hidden' : 'visible',
				opacity: (editor.app_id != 'buttons' || idx != editor.buttonIdx) ? 0 : button.opacity,
				'--opacity': button.opacity,
				willChange: 'opacity',
				animation: `bi-reveal 0.4s ${button.begin}s forwards`
			}
		}

		let left, top;
		if (idx == dragIdx && position.x) {
			left = position.x;
			top = position.y;
			
		} else {
			left = button.x;
			top = button.y;
		}

		let props = {
			style: style,
			transform: `translate(${left},${top})`
		};

		if (displayMenu && variant.params.menu?.button && (variant.params.menu.button_idx || 0) == idx) {
			props.onClick = (event) => {
				displayMenu('button', event);
			}
		}

		return props;
	}

	const getRectProps = (variant, button, idx, position) => {
		const font_size = 0.5 * button.size * base_font_size;

		let props = {
			x: - button.width/2 - font_size * 0.75,
			y: - 1.5*button.height/2 - font_size * 0.375,
			width: button.width + font_size * 1.5,
			height: 1.5*button.height + font_size * 0.75,
			strokeWidth: font_size * 0.05,
			rx: font_size * 0.25,
			ry: font_size * 0.25,
			style: {
				// active radius
				'--ds': `${font_size * 0.25}px`,
				'--sw': `${font_size * 0.15}px`
			}
		}
	
		return props;
	}

	const getTextStyle = (variant, button, idx, position) => {

		let font_family;
		if (editor.app_id == 'buttons' && editor.fontFamilyMouseOver && idx == editor.buttonIdx) {
			font_family = editor.fontFamilyMouseOver;
		} else {
			font_family = button.font_source == 'custom' ? button.font_family_custom : button.font_family;
		}
		
		const font_size = 0.5 * button.size * base_font_size;

		let style = {
			cursor: editor.app_id == 'buttons' ? 'move' : 'pointer',
			fontSize: font_size,
			fontFamily: font_family,
			fontWeight: button.font_weight || 400,
			textAnchor: 'middle',
		}
		
		if (button.effect) {
			style = {
				...style,
				...getFontEffect(button)
			}
		}
		
		if (button.italic) {
			style.fontStyle = 'italic';
		}
			
		return style;
	}

	const darkenColor = (hex, percent) => {
		// Remove the '#' symbol if present
		hex = hex.replace('#', '');

		// Convert hex to RGB
		let r = parseInt(hex.substring(0, 2), 16);
		let g = parseInt(hex.substring(2, 4), 16);
		let b = parseInt(hex.substring(4, 6), 16);

		// Darken each component
		r = Math.round(r * (1 - percent / 100));
		g = Math.round(g * (1 - percent / 100));
		b = Math.round(b * (1 - percent / 100));

		// Ensure the values are within the valid range (0-255)
		r = Math.min(255, Math.max(0, r));
		g = Math.min(255, Math.max(0, g));
		b = Math.min(255, Math.max(0, b));

		// Convert RGB back to hex
		return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
	}

	const contrastColor = (hex) => {
		hex = hex.replace('#', '');

		// Convert hex to RGB
		let r = parseInt(hex.substring(0, 2), 16);
		let g = parseInt(hex.substring(2, 4), 16);
		let b = parseInt(hex.substring(4, 6), 16);

		// Calculate the relative luminance
		const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

		// Determine the contrast color based on luminance
		return luminance > 0.7 ? '#000000' : '#ffffff';
	}
	
	const get_button_css = (button_type, button_color) => {	
		const button_id = button_color.replace('#', '');
		const contrast = contrastColor(button_color);

		if (button_type == 'solid') {
			const darker = darkenColor(button_color, 20);
			
			return `
.bbtn.bbtn-${button_type}-${button_id} rect {
    fill: ${button_color};
    stroke: ${button_color}
}
.bbtn.bbtn-${button_type}-${button_id} text {
    fill: ${contrast};
}
.bbtn.bbtn-${button_type}-${button_id}:hover rect {
    fill: ${darker};
    stroke: ${darker}
}
.bbtn.bbtn-${button_type}-${button_id}:hover text {
    fill: ${contrast};
}
.bbtn.bbtn-${button_type}-${button_id}:active {
    opacity: 1;
}
.bbtn.bbtn-${button_type}-${button_id}:active rect {
    filter: drop-shadow(0 0 var(--ds) ${button_color});
    stroke-width: var(--sw);
}`;
		
		} else if (button_type == 'outline') {
			return `
.bbtn.bbtn-${button_type}-${button_id} rect {
    stroke: ${button_color}
}
.bbtn.bbtn-${button_type}-${button_id} text {
    fill: ${button_color};
}
.bbtn.bbtn-${button_type}-${button_id}:hover rect {
    fill: ${button_color};
    stroke: ${button_color}
}
.bbtn.bbtn-${button_type}-${button_id}:hover text {
    fill: ${contrast};
}
.bbtn.bbtn-${button_type}-${button_id}:active {
    opacity: 1;
}
.bbtn.bbtn-${button_type}-${button_id}:active rect {
    filter: drop-shadow(0 0 var(--ds) ${button_color});
    stroke-width: var(--sw);
}`;
		
		} else if (button_type == 'contrast') {
			return `
.bbtn.bbtn-${button_type}-${button_id} rect {
    fill: ${contrast};
    stroke: ${button_color}
}
.bbtn.bbtn-${button_type}-${button_id} text {
    fill: ${button_color};
}
.bbtn.bbtn-${button_type}-${button_id}:hover rect {
    fill: ${button_color};
    stroke: ${button_color}
}
.bbtn.bbtn-${button_type}-${button_id}:hover text {
    fill: ${contrast};
}
.bbtn.bbtn-${button_type}-${button_id}:active {
    opacity: 1;
}
.bbtn.bbtn-${button_type}-${button_id}:active rect {
    filter: drop-shadow(0 0 var(--ds) ${button_color});
    stroke-width: var(--sw);
}`;
		}
	}

	const buttonCss = useMemo(
		() => {
			let css = '';
			
			const uniqueButtonStyles = new Set();
			buttons.forEach(button => {
				uniqueButtonStyles.add([button.button_type, button.color]);
			});
			
			uniqueButtonStyles.forEach(buttonStyle => {
				css += get_button_css(...buttonStyle)
			})
		
			return css;
		}, [buttons]
	);
	
	return (
		<svg x={ viewBox.x } y={ viewBox.y } width={ viewBox.width } height={ viewBox.height } 
			viewBox={ `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}` }
			overflow="visible"
			className="bi-buttons"
			ref={ editor.app_id == 'buttons' ? containerRef : null }
		>
			<style type="text/css">
				{ buttonCss }
			</style>
			{ buttons.map((button, idx) => {
				if (editor.app_id != 'buttons') {
					return (
						<a 
							key={ `button-${idx}` }
							href={ button.href }
							target={ button.target || '_self' }
						>
							<g { ...getGroupProps(variant, button, idx, position, displayMenu) }
								className={ `bbtn bbtn-${button.button_type}-${button.color.replace('#', '')}` }
							>
								<rect { ...getRectProps(variant, button, idx, position) }></rect>
								<text 
									dominantBaseline="central"
									style={ getTextStyle(variant, button, idx, position) }
								>
									{ personalizeAndLocalize(button.text, variant, localContext, false) }
									{ variant.params.menu?.button && (variant.params.menu.button_idx || 0) == idx && ` ▾` }
								</text>
							</g>
						</a>
					);
				}

				// editing
				return (
					<g { ...getGroupProps(variant, button, idx, position) }
						key={ `button-${idx}` }
						ref={ idx == dragIdx ? dragRef : null }					
						className={ `bbtn bbtn-${button.button_type}-${button.color.replace('#', '')}` }
					>
						<rect { ...getRectProps(variant, button, idx, position) }></rect>
						<text 
							dominantBaseline="central"
							style={ getTextStyle(variant, button, idx, position) }
						>
							{ personalizeAndLocalize(button.text, variant, localContext, false) }
							{ variant.params.menu?.button && (variant.params.menu.button_idx || 0) == idx && ` ▾` }
						</text>
					</g>
				);
			}) }			
		</svg>
	);

}


const PreviewMagnify = ({ viewBox, magnifier, variant, editor, edge }) => {

	if (!magnifier.visible) return;

	const [ loading, setLoading ] = useState(true);

	const cx = viewBox.x + viewBox.width * magnifier.x;
	const cy = viewBox.y + viewBox.height * magnifier.y;
	const radius = Math.max(viewBox.width/5, viewBox.height/7);

	// adjust for edges
	const offset = [
		(radius*2 * magnifier.x) - radius,
		(radius*2 * magnifier.y) - radius,
	];

	// TODO upscale to 4K will replace source filename
	let source_filename, magnified_src;
	if (variant.params.colors?.recolor) {
		source_filename = variant.params.colors.recolor.split('/')[3];
		magnified_src = `https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/f:${source_filename}`;
	
	} else if (variant.source_asset && variant.width > 2048) {
		magnified_src = `https://${variant.site_id}.${process.env.REACT_APP_CONTROLLER}/i:${variant.image_id}/v:${variant.variant_id}/f:${variant.source_asset}`;

	} else {
		magnified_src = getImageHref(variant, 2048, editor, edge);
	}

	// adjusted for crop. magnify disables zooms so we don't have to adjust for final zoom
	const x = -(magnifier.zoom-1) * (viewBox.width * magnifier.x + viewBox.x - magnifier.view_delta_x) + offset[0];
	const y = -(magnifier.zoom-1) * (viewBox.height * magnifier.y + viewBox.y - magnifier.view_delta_y) + offset[1];

	return (
		<svg x={ viewBox.x } y={ viewBox.y } width={ viewBox.width } height={ viewBox.height } 
			viewBox={ `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}` }
			overflow="visible"
			className="bi-magnify"
		>
			<image
				x={ x } 
				y={ y }
				width={ magnifier.zoom * variant.width }
				height={ magnifier.zoom * variant.height }
				href={ magnified_src }
				style={{
					clipPath: `circle(${radius}px at ${cx-x}px ${cy-y}px)`
				}}
				onLoad={ (event) => setLoading(false) }
			/>			
			<circle 
				cx={ cx } 
				cy={ cy }
				r={ radius } 
				strokeWidth={ radius/24 }
				fill={ loading ? '#cccccc' : 'none' }
				stroke="#222222"
			>
				<animate attributeName="r" from="0" to={ radius } dur="0.4s" fill="freeze" />
			</circle>
		</svg>
	)
}


// for wix
const PreviewBranding = ({ variant, viewBox }) => {

	// only show in upgrade mode
	if (!variant.params?.branding) return null;
	
	const banner_height = viewBox.width / 4;

	return (
		<svg x={ viewBox.x } y={ viewBox.y } width={ viewBox.width } height={ viewBox.height } 
			viewBox={ `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}` }
			overflow="hidden"
		>
			<style>{ `
				@font-face {
					font-family: "Anton-branding";
					src: url("data:font/woff2;base64,d09GMgABAAAAAAVYAA4AAAAACQQAAAUGAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAGhwbYBwoBmAAchEICocshh0LGAABNgIkAywEIAWEDAcgG2wHyMQH6NR/dxdfjVRKam4ur0KaXgEmgzIDweasH0LuuVc18/kXyBCOUnLJvqy9KYLx2Jg2V1vAWnkCFpirIJ7PIdfLqHwQAIwwl5ITCwHBTCoCEuLXieCJUJDpE6YxMGmWdbfCBBxNUYeR1cR2tkLw4J9sHJDOXNhImBEJF9gAFRSCEXwKjBMieMObTbeAsYjPf7FR+4ml4NZxwziPAKy/SUj011KzsJcoQK5TNv2wtbJ93bjK5m4tbPivooqOJ7eLQawQu7lveXr4IAaVaMRNajVFAgQHFW+1gBkokBAjFZMhA4s6tKA7Sl1+SZ03DF8ZrhjOGbYbthg2G9YlrWMPybHzIchWn8EAUzGVJtuELQHCCwZizwg5E+RGR0EWjKBAfiPBoSiKMheKOGKRs9DV2MvY15YeMh8YrDTqr6wYGCpjyozpIWOVBlCoGY0GCrVaNEirdAShFIkGVASh1IpE5C56yHhAtkCrJZQ6XWEhoRQtAIxWKwmljlZpNBAYEWp1qUpHUGr1QKuQGV3gKnX0IhIKxoRUaK55JzP2WjtIrzYsUINdoNXmFmqEBrVIH1hb8gnjAYW6QkVQWvmkQkMPGUOs1AWmFiqhu6q+y7UToUY1uUCthkC2kArKco1WjW2ZBdpwQ2jQyG8fFNPz4/qBsgVagi+iaMqYpEkRT7GPVvlgpToTPH01eHpHq7R9Dgz/1MXxR+OPPHdtBHfjx0epz/c5MJD/a99dPXxeN324aF2Jba9z9tstB5IMexZtEDCTJ4U4To04lj91Chm+fv6Mt1MDCxJT/s9BInh6Bjw9ikxHZnRJ7ynmRxye0dbp/W7q+oGsSdUlZXx7M/3z85/1X2Jadw321Ry3L++TJ1kHuk4O2D7ZOaek6NpsfGO69VJfzsctpc9+0D4/urflNpUmcPcVTnPziXasylxak783d/xmp6l8yf77OT4TfAMkaQzeUPUrrXoitvY/njF9ZkFeS9EK/3Xnm2aycYeaHAfCzirreuStcgtmXwu4PMPXrzX3mLX/+0ny/cLtp4X+H+kgwyRw31Ycti3tkrWXjObap/lObp1WNzKnMfzi8CQ2c9kUn5Wm030i2EypTbnU95wp4+cB/c/evMe+YKasJiBwwKJUbDWEcdOL1/vqj4YNBGb2VOdUHby8vDijfINKObjnfk7vyRNOnTFBLnlB07Jz/nu3rW7KwkcH1D++w5DpsprBsT2sOZtYlsias3leI+52Hr+eGLEp+1DKL5DOxz9WzNxbaRr/m8BU8M/7xvD8/G3lW/7Bg/+t+o+lD9GxQF4N5jsIa/rwuv9W/dtJH6qD70tT/yOTUiOTeIpE6j38qC8RzxFAwjPHJPI2JlEP4UPoAbIDbqPlaBSBjypwOcj5tApQWz8bOEIAh/HnC1pQxfdgkDCBnkEhGEcZHDhgNYOLCchm8OCASKnJ9DRQj250ox1diIUU0mmlHJ1oQDu6N4QEXdJmvKwNnaiDFFmYgGlIQSu60Ta1BSG3lVrVg2bI0IkCsAAHUFwSIxQShCAEkYiDOJhYLWTocbTVo4HxdzF80YsQSBANCcLgx+Yk7mBxMV4WfehGXFS6fLSZ5KahDe0YSCbWZQBEE4NPi8VgUA8W4ihYstE5ijWChdwQUxKA9ZmCXeuu2WirQ4NyUw+qIYEcbWgRWNrQNow1g0UtFzK5VxqMYoJAIXFGv/6Dtxj0/yPUYg8A") format("woff2");
				}
				@keyframes bi-${variant.variant_id}-branding {
					0%,60% { transform: translate(${viewBox.x}px, ${viewBox.y - banner_height}px); }
					60.98% { transform: translate(${viewBox.x}px, ${viewBox.y + banner_height/16}px); }
					61%,100% { transform: translate(${viewBox.x}px, ${viewBox.y}px); }
				}
			` }</style>
			<a href="https://betterimages.ai" target="_blank">
				<g 
					style={{
						transform: `translate(${viewBox.x}px, ${viewBox.y - banner_height}px)`,
						animation: `bi-${variant.variant_id}-branding 20s 3s infinite alternate`
					}}
				>
					<rect 
						width="100%" 
						height={ banner_height }
						fill="lightgreen" 
						style={{
							opacity: 0.5
						}}
					/>
					<svg 
						x={ viewBox.width / 4 }
						y={ -viewBox.height / 2 + banner_height / 2 }
						width="50%"
						viewBox="0 0 1650 825"
					>
						<title>Better Images For Your Websites, Emails and Messaging</title>
						<circle fill="#4e3bfc" cx="256" cy="413" r="200" stroke="#4e3bfc" strokeWidth="48"></circle>
						<text x="192" y="542" fontSize="300" fill="white" fontFamily="Anton-branding">B</text>
						<text x="520" y="497" fontSize="200" fill="#4e3bfc" fontFamily="Anton-branding">Better</text>
						<text x="1010" y="497" fontSize="200" fill="black" fontFamily="Anton-branding">Images</text>
					</svg>
				</g>
			</a>
		</svg>
	)
}


const PreviewParticles = ({ viewBox, variant, editor }) => {

	if (!variant.params.particles?.active) return;

	if (window.matchMedia && window.matchMedia('(prefers-reduced-motion)').matches) return;

	const [ particles, setParticles ] = useState([]);
	const [ particleIdx, setParticleIdx ] = useState(null);
	
	const containerRef = useRef(null);
	
	// build list of random particles
	useEffect(() => {

		let ps = [];

		const getParticlesValue = (field, default_value) => {
			const particles = variant.params.particles || {};

			let rv = particles[field];
			if (typeof rv == 'undefined') rv = default_value;
			return rv;
		}

		const getNewParticle = (idx) => {
			const size_random = Math.random();
			const size = getParticlesValue('size', 1) * Math.min(viewBox.width, viewBox.height) * (0.05 + size_random * 0.2);
			
			// emojiCodes is an array of array of code points
			const emojiCount = getParticlesValue('emojiCodes', []).length;
			if (!emojiCount) return;
			let emojiCodes = getParticlesValue('emojiCodes')[idx % emojiCount];
		
			const newParticle = {
				emojiCodes: emojiCodes,
				duration: 3 + 4 * Math.random(),
				top_left: viewBox.x + viewBox.width * ( -0.25 + Math.random() * 0.75 ),
				bottom_left: viewBox.x + viewBox.width * (0.25 + Math.random() * 0.8),
				size: size,
				rotate: 0,
				opacity: 1,
				blur: 0
			};
			if (getParticlesValue('spin', 20) / 100 > (1-size_random)) {
				newParticle.rotate = (-5 + 10 * Math.random()) * getParticlesValue('spin', 20);
			}
			if (getParticlesValue('opacity', 20) / 100 > (1-size_random)) {
				newParticle.opacity = 0.7 + 0.25 * Math.random();
			}
			if (getParticlesValue('splat', 20) / 100 > (1-size_random)) {
				newParticle.splat = true;
			}
			if (getParticlesValue('blur', 20) / 100 > (1-size_random)) {
				newParticle.blur = 1 + Math.floor(Math.random() * 3);
			}		
		
			newParticle.delay = idx * Math.min(5, 20 / getParticlesValue('count', 20));
		
			return newParticle;
		}

		for (let idx = 0; idx < getParticlesValue('count', 20); idx++) {
			const particle = getNewParticle(idx);
			if (particle) ps.push(particle);
		}

		setParticles(ps);

	}, [variant.params.particles]);
	
	// font-family: "Noto Color Emoji";
	
	return (
		<svg x={ viewBox.x } y={ viewBox.y } width={ viewBox.width } height={ viewBox.height } 
			viewBox={ `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}` }
			overflow="hidden"
			className={ `bi-${variant.variant_id}-particles` }
			ref={ containerRef }
		>
			<style>{ ` 
			.bi-${variant.variant_id}-particles text {
				visibility: hidden;
				font-family: "Twemoji Mozilla", "Apple Color Emoji", "Segoe UI Emoji", "Noto Color Emoji", "EmojiOne Color";
				font-weight: 400;
				user-select: none;
				fill: #ffffff;
				transform-origin: 50% 50%;
				transform-box: fill-box;
				will-change: transform;
				backface-visibility: hidden;
				animation: bi-${variant.variant_id}-fall var(--duration) var(--delay) linear infinite;
 			}
			.bi-${variant.variant_id}-particles text.bi-splat {
				animation: bi-${variant.variant_id}-splat var(--duration) var(--delay) linear infinite;
 			}
			@keyframes bi-${variant.variant_id}-fall {
				0% { transform: translate(var(--x1), ${viewBox.y - viewBox.height * 0.2}px) rotate(0deg); visibility: visible; }
				100% { transform: translate(var(--x2), ${viewBox.y + viewBox.height * 1.1}px) rotate(var(--rotate)); }
			}
			@keyframes bi-${variant.variant_id}-splat {
				0% { transform: translate(var(--x1), ${viewBox.y - viewBox.height * 0.2}px) rotate(0deg); opacity: 0.8; visibility: visible; }
				80% { transform: translate(var(--x2), ${viewBox.y + viewBox.height * 0.6}px) rotate(var(--rotate)); opacity: 0.8; }
				85% { transform: translate(var(--x2), ${viewBox.y + viewBox.height * 0.7}px) rotate(var(--rotate)) scale(2); opacity: 0.9; }
				100% { transform: translate(var(--x2), ${viewBox.y + viewBox.height * 0.8}px) rotate(var(--rotate)) scale(2); opacity: 0; }
			}
			@media (prefers-reduced-motion: reduce) {
				@keyframes bi-${variant.variant_id}-fall {}
				@keyframes bi-${variant.variant_id}-splat {}
			}
			` }
			</style>
			<defs>
				<filter id="blur1">
				  <feGaussianBlur in="SourceGraphic" stdDeviation="2"/>
				</filter>
				<filter id="blur2">
				  <feGaussianBlur in="SourceGraphic" stdDeviation="3"/>
				</filter>
				<filter id="blur3">
				  <feGaussianBlur in="SourceGraphic" stdDeviation="5"/>
				</filter>
			</defs>
			{ particles.map((particle, idx) => (
					<text 
						key={ idx }
						fontSize={ particle.size }
						filter={ particle.blur ? `url(#blur${particle.blur})` : null }
						opacity={ particle.opacity }
						className={ particle.splat ? 'bi-splat' : null }
						style={{
							'--duration': `${particle.duration}s`,
							'--delay': `${particle.delay}s`,
							'--x1': `${particle.top_left}px`,
							'--x2': `${particle.bottom_left}px`,
							'--rotate': `${particle.rotate}deg`,
						}}
					>
						{ particle.emojiCodes.map((codePoint) => {
							return String.fromCodePoint(codePoint);
						}) }
					</text>
			)) }
		</svg>
	)
}


const PreviewMenu = ({ variant, kebab, displayMenu, menuStyle }) => {

	return (
		<>
			{ variant.params.menu?.kebab && (
				<div
					className="bi-kebab"
					style={{
						fontSize: '16px',
						position: 'absolute',
						top: `${kebab.top}px`,
						left: `${kebab.left}px`,
						color: '#FFFFFF',
						textShadow: '1px 1px 0px #555555',
						transition: 'opacity 0.3s'
					}}
					onClick={ (event) => displayMenu('kebab', event) }
				>
					&#8942;
				</div>
			) }

			{ variant.params.menu?.items && (
				<div
					className={ `bi-menu bi-${variant.variant_id}-menu` }
					style={ menuStyle }
				>
					<ul>
						{ (variant.params.menu?.items || []).map((item, idx) => {		
							if (item.text == '-') {
								return (
									<li 
										key={ idx} 
										className="separator"
									></li>
								)
							}
									
							return (
								<a 
									key={ idx }
									href={ item.link }
									target="_blank"
								>
									<li>
										{ item.text }
									</li>
								</a>
							)
						}) }						
					</ul>
				</div>
			) }
		</>					
	)
}


const PreviewFooter = ({ viewBox, variant }) => {
	if (variant.svg_footer) {
		return (
    		<g 
    			className="bi-footer" 
    			dangerouslySetInnerHTML={{ __html: variant.svg_footer }} 
    		/>
		)
	}
}


// construct svg
// animationRestart - passed with changing .key to force restart, and .begin 
// state.editor:
// .app_id (formerly editorTab) - when Edit/ current tab. null for Library
// [manualFocus] - when Edit/Zooms manual focus, - just show <img>
// [zoomMouseOverBox] zoomMouseOverBox - when Edit/Zooms, 123345678456 for focus mouseover to set zoomProps and disable animations
// [captionFreeze] captionFreeze - when Edit/Captions, freeze first caption while changing scale, weight, color, etc.
// [fontFamilyMouseOver] fontFamilyMouseOver - when Edit/Captions, font family for captions
const VariantPreview = ({ variant, width, animationRestart, zoomMouseOverBox, observeElement, edge, pointerEvents, maxHeightStatic }) => {

	// restart animations and video
	// animationRestart .key to force effect, .begin
	const svgRootRef = useRef(null);

	const editor = useSelector(state => state.Variant.editor);
	const localContext = useSelector(state => state.Profile.data.speed);

	// resize for Editing
	const [ maxHeight, setMaxHeight ] = useState('80vh');
	const [ svgRect, setSvgRect ] = useState({});
	useEffect(() => {
		if (!editor.app_id) return;
		
		const adjustImageMaxHeight = () => {
			if (maxHeightStatic) {
				setMaxHeight(maxHeightStatic);

			} else if (svgRootRef.current) {
				const distanceFromTopOfViewport = svgRootRef.current.getBoundingClientRect().top;
				// minimum, bottom border
				const calculatedMaxHeight = Math.max(320, window.innerHeight - distanceFromTopOfViewport - 20);
				setMaxHeight(`${calculatedMaxHeight}px`);
			}
		}

		adjustImageMaxHeight();
		window.addEventListener('resize', adjustImageMaxHeight);
		
		return () => {
			window.removeEventListener('resize', adjustImageMaxHeight);		
		};
	}, [editor.app_id, svgRootRef.current, editor.manualFocus]);

	// context menu
	useEffect(() => {
		const displayContextMenu = (event) => {
			const bi_context = event.target.closest(`.bi-${variant.variant_id}`) || event.target.closest(`.bi-kebab`);

			// hide menu if not within svg
			if (!bi_context) {
				setMenuStyle({
					display: 'none'
				})
				
			} else if (variant.params.menu?.context) {
				displayMenu('context', event);
			}
		}
		
		document.addEventListener('contextmenu', displayContextMenu);

		return () => {
			document.removeEventListener('contextmenu', displayContextMenu);
		};
	}, [variant.params.menu?.context]);

	// viewbox used in svgprops and intro overlay
	const [ viewBox, setViewBox ] = useState({
		x: 0, y: 0,
		width: variant.width || 0,
		height: variant.height || 0
	});

	const [ kebab, setKebab ] = useState({});

	// used for draggable scaling
	useEffect(() => {
		if (svgRootRef.current) {
			const rect = svgRootRef.current.getBoundingClientRect()
		
			setSvgRect(rect);
			
			const containerRect = svgRootRef.current.parentNode.getBoundingClientRect();
			setKebab({
				left: rect.left - containerRect.left + rect.width - 25,
				top: rect.top - containerRect.top + 10,
			})
		}
	}, [maxHeight, variant.params?.crop, viewBox]);

	// setup observer
	useEffect(() => {
		if (observeElement && svgRootRef.current) {
			observeElement(svgRootRef.current);
		}
	}, [svgRootRef.current]);


	useEffect(() => {
		if (!svgRootRef.current) return;
	
		const handleMouseMove = (_e) => {
			const rect = svgRootRef.current.getBoundingClientRect();
		
			const movement = typeof variant.params.tilt?.movement != 'undefined' ? variant.params.tilt.movement : 10;
			const calcX = -movement * (_e.clientY - rect.y - (rect.height * 0.5)) / window.innerHeight;
			const calcY = movement * (_e.clientX - rect.x - (rect.width * 0.5)) / window.innerWidth;
			
			// scale so we don't see borders
			const scale = 1 + (Math.abs(calcX) + Math.abs(calcY)) / 200;
		
			svgRootRef.current.style.transform = `perspective(900px) rotateX(${calcX}deg) rotateY(${calcY}deg) scale(${scale})`;		
		}

		if (variant.params.tilt?.active) {
			document.addEventListener('mousemove', handleMouseMove);
		} else {
			svgRootRef.current.style.transform = 'none';
		}
	
		return () => {
			if (variant.params.tilt?.active) {
				document.removeEventListener('mousemove', handleMouseMove);
			}
		}
	}, [svgRootRef.current, variant.params.tilt]);


	useEffect(() => {
		const crop = variant.params?.crop;
	
		if (crop?.crop_type == 'full' || editor.manualFocus) {
			setViewBox({
				x: 0, 
				y: 0,
				width: variant.width,
				height: variant.height
			});
		
		} else if (crop?.crop_type == 'auto') {
			// match to aspect ratio of source variant	
			const source_width = variant.generation?.source_width;
			const source_height = variant.generation?.source_height;
			if (source_width && source_height) {
				const source_aspect = source_width / source_height;		
				const variant_aspect = variant.width / variant.height;
			
				if (variant_aspect >= source_aspect) {
					// wider; adjust x and width 
					const width = variant.height * source_aspect;
					setViewBox((viewBox) => {
						return {
							...viewBox,
							x: (variant.width - width) / 2,
							width						
						}
					});
				} else {
					// taller
					const height = variant.width / source_aspect;
					setViewBox((viewBox) => {
						return {
							...viewBox,
							y: (variant.height - height) / 2,
							height
						}						
					});
				}
			}

		} else if (crop?.crop_type == 'aspect') {			
			const variant_aspect = variant.width / variant.height;

			if (crop.aspect >= variant_aspect) {
				// taller 
				const height = variant.width / (crop.aspect || 1);
				setViewBox((viewBox) => {
					return {
						...viewBox,
						y: (variant.height - height) / 2,
						height
					}						
				});
							
			} else {
				// wider
				const width = variant.height * (crop.aspect || 1);
				setViewBox((viewBox) => {
					return {
						...viewBox,
						x: (variant.width - width) / 2,
						width						
					}
				});

			}
		}

	}, [variant.params?.crop, editor.manualFocus]);

	// sync to editor
	const dispatch = useDispatch();
	useEffect(() => {
		if (editor.app_id == 'buttons') {
			dispatch(updateEditor({
				viewBox: viewBox
			}))
		}
	}, [editor.app_id, viewBox]);
	
	// setup listeners for magnifier
	const [ magnifier, setMagnifier ] = useState({ visible: false, zoom: 3, view_delta_x: 0, view_delta_y: 0 });
	useEffect(() => {
		// cannot attach any listeners without this
		if (!svgRootRef.current) return;
				
		// when editing only show on editor tab
		if (editor.app_id && editor.app_id != 'magnify') return;

		// ... when active
		if (!variant.params?.magnify?.active) return;

		// note timestamp so we can wait until nudge
		const magnifyMouseEnter = (event) => {	
			setMagnifier((magnifier) => {
				return {
					...magnifier,
					enter: new Date()
				}
			});
		}

		const magnifyMouseMove = (event) => {	
			// % mouse co-ords
			const rect = svgRootRef.current.getBoundingClientRect();
			const x = (event.clientX - rect.left) / rect.width;
			const y = (event.clientY - rect.top) / rect.height;

			// leave if outside
			if (x < 0 || x > 1 || y < 0 || y > 1) {
				magnifyMouseLeave();
				return;
			}
			
			// crop-scroll 1% on edges
			const edge_scroll = 0.01;
			const delta_x = (x < 0.1 ? edge_scroll : (x > 0.9 ? -edge_scroll : 0)) * variant.width;
			const delta_y = (y < 0.1 ? edge_scroll : (y > 0.9 ? -edge_scroll : 0)) * variant.height;

			setMagnifier((magnifier) => {
				return {
					...magnifier,
					visible: true,
					// used to draw the circle and calculate the image position
					x: x,
					y: y,
					// used to nudge the image at the edges
					view_delta_x: Math.min(viewBox.x, Math.max(viewBox.width + viewBox.x - variant.width, magnifier.view_delta_x + (new Date()-magnifier.enter < 1000 ? 0 : delta_x))),
					view_delta_y: Math.min(viewBox.y, Math.max(viewBox.height + viewBox.y - variant.height, magnifier.view_delta_y + (new Date()-magnifier.enter < 1000 ? 0 : delta_y))),
				}
			});
		};

		const magnifyMouseLeave = (event) => {
			setMagnifier((magnifier) => {
				return {
					...magnifier,
					visible: false,
					zoom: 3,
					// leave deltas - will be reset in Editing by restart
				}
			});
		};

		const magnifyWheel = (event) => {
		    event.preventDefault();

			setMagnifier((magnifier) => {
				return {
					...magnifier,
					// scale from 2x to 8x
					zoom: Math.max(2, Math.min(8, magnifier.zoom + (event.deltaY > 0 ? -0.5 : 0.5)))
				}
			});
		};

		svgRootRef.current.addEventListener('mouseenter', magnifyMouseEnter);
		svgRootRef.current.addEventListener('mousemove', magnifyMouseMove);
		svgRootRef.current.addEventListener('mouseleave', magnifyMouseLeave);
		svgRootRef.current.addEventListener('wheel', magnifyWheel);

		// Clean up the event listener when the component unmounts
		return () => {
			if (svgRootRef.current) {
				svgRootRef.current.removeEventListener('mouseenter', magnifyMouseEnter);
				svgRootRef.current.removeEventListener('mousemove', magnifyMouseMove);
				svgRootRef.current.removeEventListener('mouseleave', magnifyMouseLeave);
				svgRootRef.current.removeEventListener('wheel', magnifyWheel);
			}
		};

	}, [svgRootRef.current, editor.app_id, variant.params?.magnify?.active, viewBox]);

	// hide until image load event
	const [ imageLoading, setImageLoading ] = useState(true);
	const [ introLoading, setIntroLoading ] = useState(!!variant.compiled?.intro?.src_avif && variant.params?.intro?.intro_type);

	// retry loading image
	const [ imageLoadAttempt, setImageLoadAttempt ] = useState(0);
	const [ introLoadAttempt, setIntroLoadAttempt ] = useState(0);

	// restarter
	//const introVideoRestartRef = useRef(null);
	useEffect(() => {
		if (imageLoading || introLoading) return;
	
		// reset magnifier deltas
		setMagnifier({ visible: false, zoom: 3, view_delta_x: 0, view_delta_y: 0 });

		let isMounted = true;		
		const playVideo = async (_video) => {
			if (isMounted) {
				try {
					await _video.play();
				} catch (error) {
					// ignore
				}
			}
		};  
			
		if (svgRootRef.current) {
			// svg
			[svgRootRef.current, ...svgRootRef.current.querySelectorAll('svg')].forEach((_svg) => {
				_svg.setCurrentTime(animationRestart.begin || 0);
				_svg.unpauseAnimations();
			});
			
			// css
			svgRootRef.current.getAnimations({ subtree: true }).forEach((_animation) => {
				_animation.currentTime = (animationRestart.begin || 0) * 1000;
				_animation.play();
			});

			// videos - for intro while compiling until avif is ready
			svgRootRef.current.querySelectorAll('foreignObject video').forEach((_video) => {
				_video.currentTime = 0;
				playVideo(_video);
			});
			
			// intro
			svgRootRef.current.querySelectorAll('.bi-intro image').forEach((_image) => {
				const _src = _image.href.baseVal;
				_image.href.baseVal = '';
				_image.href.baseVal = _src;
			});	 
		}
		
		return () => {
			isMounted = false; // Clean up to prevent setting state on unmounted component
		};	
	}, [animationRestart.key, imageLoading, introLoading]);


	// reveal the preview (and triggers restart)
	const onImageLoad = (event) => {
		setImageLoading(false);
	}	
	// retry
	const onImageError = (error) => {
		if (imageLoadAttempt < 10) {
			setTimeout(() => {
				setImageLoadAttempt(imageLoadAttempt + 1);
			}, 2000 + Math.random() * 5000 * imageLoadAttempt);
		}
	}

	// reveal the preview (and triggers restart)
	const onIntroLoad = () => {
		setIntroLoading(false);
	}
	const onIntroError = () => {
		if (introLoadAttempt < 10) {
			setTimeout(() => {
				setIntroLoadAttempt(introLoadAttempt + 1);
			}, 2000 + Math.random() * 5000 * introLoadAttempt);
		}
	}

	const introDuration = useMemo(
		() => {
			let duration = 0;
			
			const intro_type = variant.params?.intro?.intro_type;
			
			if (intro_type == 'camera') {
				duration = variant.compiled?.intro?.duration || 0;
			
			} else if (intro_type == 'fx') {
				duration = variant.params.intro.fx_duration || 5.25;
				
			} else if (intro_type == 'manual') {
				duration = variant.params.intro.manual_src_duration || variant.compiled?.intro?.duration || 0;
			}
			
			return duration;

		}, [variant.params?.intro]
	);

	// trigger is kebab, context, button
	const [ menuStyle, setMenuStyle ] = useState({});
	const displayMenu = (trigger, event) => {
		event.preventDefault();
		event.stopPropagation();

		// right-click on menu item => simulate normal click
		const bi_menu = event.target.closest(`.bi-${variant.variant_id}-menu`);
		if (bi_menu && trigger == 'context') {
            const clickEvent = new MouseEvent('click', {
                bubbles: true,
				cancelable: true
            });
            event.target.dispatchEvent(clickEvent);
            return;
		}
		
		const fixedContainer = document.querySelector("html");				
		const fixedRect = fixedContainer.getBoundingClientRect();
		
		const menu = document.querySelector(`.bi-${variant.variant_id}-menu`);		
		let menuX = Math.max(8, Math.min(window.innerWidth - menu.clientWidth - 20, event.clientX - Math.max(0, fixedRect.left)));
		const menuY = Math.max(8, Math.min(window.innerHeight - menu.clientHeight - 8, event.clientY - Math.max(0, fixedRect.top) - 8));

		setMenuStyle({
			left: menuX,
			top: menuY,
			display: 'inline-block'
		});
	}

	// when menu is visible, all click events that bubble hide the menu
	useEffect(() => {
		const handleClick = (event) => {
			if (menuStyle.display != 'none') {
				setMenuStyle({
					display: 'none'
				})
			}
		}

		if (menuStyle.display != 'none') {
			document.addEventListener('click', (event) => handleClick(event));			
		}
		
		return () => {
			document.removeEventListener('click', (event) => handleClick(event));
		}
	}, [menuStyle.display]);

	// legacy - show compiled svg
	return (
		<>
			{ (variant.legacy || variant.variant_type == 'baseline') ? (
				<img 
					key={ `img-${imageLoadAttempt}` }
					src={ getImageHref(variant, width, editor, edge) }
					style={{ 
						maxHeight: { maxHeight },
						width: 'auto',
						maxWidth: '100%',
						opacity: imageLoading ? 0 : (variant.variant_status == 'coming soon' ? 0.3 : 1),
						pointerEvents: 'none',
						filter: variant.variant_status == 'coming soon' ? 'blur(2px) grayscale(1)' : 'none',
					}}
					onLoad={ onImageLoad }
					onError={ onImageError }
				/>
			) : (
				<>
					<svg { ...getSVGProps(editor.app_id, viewBox, maxHeight, magnifier, variant, imageLoading, introLoading, pointerEvents) } 
						ref={ svgRootRef }
					>
						<PreviewTitle 
							variant={ variant }
							magnifier={ magnifier }
						/>
						<PreviewStyle 
							variant={ variant }
							editor={ editor }
						/>
						<PreviewFilters 
							variant={ variant }
							editor={ editor }
						/>

						<PreviewImage
							key={ `image-${imageLoadAttempt}` }
							svgRect={ svgRect }
							viewBox={ viewBox }
							magnifier={ magnifier }
							variant={ variant }
							width={ width }
							onImageLoad={ onImageLoad }
							onImageError={ onImageError }							
							editor={ editor }
							zoomMouseOverBox={ zoomMouseOverBox }
							edge={ edge }
							animationRestart={ animationRestart }
							introDuration={ introDuration }
						/>
						
						<PreviewIntro
							key={ `intro-${introLoadAttempt}` }
							viewBox={ viewBox }
							variant={ variant }
							//introVideoRestartRef={ introVideoRestartRef }
							onIntroLoad={ onIntroLoad }
							onIntroError={ onIntroError }
							editor={ editor }
							introDuration={ introDuration }
						/>
						
						<PreviewCaptions
							viewBox={ viewBox }
							variant={ variant }
							editor={ editor }						
							localContext={ localContext }
							zoomMouseOverBox={ zoomMouseOverBox }
							animationRestart={ animationRestart }
						/>

						<PreviewStickers
							// remount when number of buttons changes to avoid 'more hooks' error
							key={ 'nstickers-' + (variant.params.stickers || []).length }				
							svgRect={ svgRect }
							viewBox={ viewBox }
							variant={ variant }
							editor={ editor }
							zoomMouseOverBox={ zoomMouseOverBox }
						/>

						<PreviewMagnify
							viewBox={ viewBox }
							magnifier={ magnifier }
							variant={ variant }
							editor={ editor }
							edge={ edge }
						/>

						<PreviewButtons
							// remount when number of buttons changes to avoid 'more hooks' error
							key={ 'nbuttons-' + (variant.params.buttons || []).length }				
							svgRect={ svgRect }
							viewBox={ viewBox }
							variant={ variant }
							editor={ editor }
							localContext={ localContext }
							zoomMouseOverBox={ zoomMouseOverBox }
							displayMenu={ displayMenu }
						/>

						<PreviewBranding 
							viewBox={ viewBox }
							variant={ variant }
						/>

						<PreviewParticles
							viewBox={ viewBox }
							variant={ variant }
							editor={ editor }
						/>
						
						<PreviewFooter
							viewBox={ viewBox }
							variant={ variant }
						/>
					</svg>
					<PreviewMenu 
						variant={ variant }
						kebab={ kebab }
						displayMenu={ displayMenu }
						menuStyle={ menuStyle }
					/>
				</>
			) }
		</>
	)
}

export default VariantPreview;