import React, { useRef, useState, useEffect, useMemo } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";

// actions
// dispatch actions:  box, begin, end, zoom, transition, ? manual zoom
import { useDispatch } from "react-redux";
import { setVariantParams } from "../../store/actions";


// controls for editing variant.params.intro
//
const EditSEO = ({ variant, site, restartFrom }) => {

	const getSEOValue = (field, default_value) => {
		let rv = (variant.params.seo || {})[field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// update params
    const dispatch = useDispatch();
	const setSEOParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			seo: {
				...variant.params.seo,
				...params
			} 
		}));
	}
	
	return (
		<>
			<Card>		
				<CardHeader>
					<strong>Search Engine Optimization</strong>
				</CardHeader>
				<CardBody>
					<Row className="mb-3">
						<Col xs={ 12 }>
							<label className="form-label">Alt Text / Title</label>
							<br/>
							<textarea 
								rows={3}
								style={{
									border: 'var(--bs-border-width) solid var(--bs-border-color-translucent)',
									borderRadius: 'var(--bs-border-radius)',
									width: '100%',
									padding: '0.47rem 0.75rem'
								}}
								value={ getSEOValue('alt', variant.svg_title) }
								onChange={ (event) => setSEOParams({
									alt: event.target.value
								}) }
							/>

						</Col>	
					</Row>
					<Row>
						<Col xs={ 12 }>
							<label className="form-label">Label / Filename</label>
						</Col>	
					</Row>
					<Row className="mb-3 align-items-center">
						<Col xs={ 9 }>
							<Input
								type="text"
								value={ getSEOValue('filename', variant.label.toLowerCase().replace(/[ :"/]/g, '-')) }
								onChange={ (event) => setSEOParams({
									filename: event.target.value
								}) }
							/>
						</Col>
						<Col xs={ 3 }>
							<div
								style={{
									color: 'gray'
								}}
							>
								.jpeg
							</div>
						</Col>	
					</Row>
					<Row>
						<Col xs={ 12 }>
							<label className="form-label">Sitemap</label>
							<div
							  className="form-check form-switch"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="seo_sitemap"
								defaultChecked={ !!getSEOValue('sitemap', true) }
								onChange={ (event) => setSEOParams({
									'sitemap': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="seo_sitemap"
							  >
								Include in Google Images Sitemap
							  </label>
							  {" "}<i className="mdi mdi-help-circle" id="tooltip_sitemap" />
								<UncontrolledTooltip placement="top" target="tooltip_sitemap">
								  Tell Google about your sitemap.
								</UncontrolledTooltip>
							</div>
						</Col>	
					</Row>
				</CardBody>
			</Card>
			
		</>		
	)
}

export default EditSEO;