import React, { useRef, useState, useEffect, useCallback } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
} from "reactstrap";

// actions
// dispatch actions:  box, begin, end, zoom, transition, ? manual zoom
import { useDispatch } from "react-redux";
import { setVariantParams, updateEditor } from "../../store/actions";


// controls for editing variant.params.intro
//
const EditClick = ({ variant, restartFrom }) => {

	const getClickValue = (field, default_value) => {
		let rv = (variant.params.click || {})[field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// update params
    const dispatch = useDispatch();
	const setClickParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			click: {
				...variant.params.click,
				...params
			} 
		}));
	}

	return (
		<>

			<Card>		
				<CardHeader>
					<strong>Click</strong>
				</CardHeader>
				<CardBody>
					<Row className="align-items-top mb-3">
						<Col>
							<label className="form-label">Link</label>
							<Input
								type="text"
								value={ getClickValue('link', '') }
								onChange={ (event) => setClickParams({
									link: event.target.value
								}) }
							/>				
						</Col>	
					</Row>

					<Row className="mb-3">
						<Col>
							<i className="mdi mdi-lightbulb-on-outline me-1" />
							You can change this link at any time, even after your email campaign has been sent out.
						</Col>
					</Row>
					
					<Row className="mb-3">	
						<Col className="col-6">
							<label className="form-label">Target</label>
							<Input
								type="select"
								value={ getClickValue('target', '_self') }
								onChange={ (event) => setClickParams({
									target: event.target.value
								}) }
							>
								<option value="_blank">New tab</option>
								<option value="_self">Same tab</option>
							</Input>
						</Col>
					</Row>

					<Row>
						<Col>
							<i className="mdi mdi-lightbulb-on-outline me-1" />
							You can also add 
							{" "}
							<Link
								onClick={ () => dispatch(updateEditor({ app_id: 'buttons' })) }
							>
								Buttons
							</Link>, each with different links.
						</Col>
					</Row>
				</CardBody>
			</Card>
		</>		
	)
}

export default EditClick;