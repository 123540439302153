import {
	GET_VARIANTS_REQUEST,
	GET_VARIANTS_SUCCESS,
	GET_VARIANTS_FAILURE,
		
	VARIANT_STATUS_REQUEST,
	VARIANT_STATUS_SUCCESS,
	VARIANT_STATUS_FAILURE,
	
	SHOW_MODAL_IMAGE, HIDE_MODAL_IMAGE,
	POST_IMAGE_REQUEST,
	POST_IMAGE_SUCCESS,
	POST_IMAGE_FAILURE,
	GET_IMAGE_REQUEST,
	GET_IMAGE_SUCCESS,
	GET_IMAGE_FAILURE,
	STOP_IMAGE_POLLING,

	// better variant
	SHOW_MODAL_VARIANT, HIDE_MODAL_VARIANT,
	POST_VARIANT_REQUEST,
	POST_VARIANT_SUCCESS,
	POST_VARIANT_FAILURE,	
	GET_NEW_VARIANT_REQUEST,
	GET_NEW_VARIANT_SUCCESS,
	GET_NEW_VARIANT_FAILURE,
	STOP_VARIANT_POLLING,
	
	VARIANT_EDIT,
	VARIANT_RELOAD,
	VARIANT_CLONE,
	
} from "./actionTypes"

export const getVariantsRequest = (account_id, site_id) => ({
  type: GET_VARIANTS_REQUEST,
  payload: { account_id, site_id }
});
export const getVariantsSuccess = (images, polling) => ({
  type: GET_VARIANTS_SUCCESS,
  payload: { images, polling },
});
export const getVariantsFailure = (error) => ({
  type: GET_VARIANTS_FAILURE,
  payload: error,
});


export const variantStatusRequest = (account_id, site_id, image_id, variant_id, variant_status) => ({
  type: VARIANT_STATUS_REQUEST,
  payload: { account_id, site_id, image_id, variant_id, variant_status }
});
export const variantStatusSuccess = (image_id, variant_id, variant) => ({
  type: VARIANT_STATUS_SUCCESS,
  payload: { image_id, variant_id, variant }
});
export const variantStatusFailure = (error) => ({
  type: VARIANT_STATUS_FAILURE,
  payload: error,
});


export const showModalImage = () => ({
  type: SHOW_MODAL_IMAGE
});
export const hideModalImage = () => ({
  type: HIDE_MODAL_IMAGE
});
export const postImageRequest = (account_id, site_id, image) => ({
  type: POST_IMAGE_REQUEST,
  payload: { account_id, site_id, image }
});
export const postImageSuccess = (image, variant) => ({
  type: POST_IMAGE_SUCCESS,
  payload: { image, variant }
});
export const postImageFailure = (error) => ({
  type: POST_IMAGE_FAILURE,
  payload: error,
});
export const getImageRequest = (account_id, image_id) => ({
  type: GET_IMAGE_REQUEST,
  payload: { account_id, image_id },
})
export const getImageSuccess = (image) => ({
  type: GET_IMAGE_SUCCESS,
  payload: { image },
});
export const getImageFailure = (error) => ({
  type: GET_IMAGE_FAILURE,
  payload: { error },
});
export const stopImagePolling = () => ({
  type: STOP_IMAGE_POLLING
});


export const showModalVariant = () => ({
  type: SHOW_MODAL_VARIANT
});
export const hideModalVariant = () => ({
  type: HIDE_MODAL_VARIANT
});
export const postVariantRequest = (params) => ({
  type: POST_VARIANT_REQUEST,
  payload: { params },
})
export const postVariantSuccess = (variant) => ({
  type: POST_VARIANT_SUCCESS,
  payload: { variant },
})
export const postVariantFailure = (error) => ({
  type: POST_VARIANT_FAILURE,
  payload: error,
});
export const getNewVariantRequest = (account_id, site_id, image_id, variant_id) => ({
  type: GET_NEW_VARIANT_REQUEST,
  payload: { account_id, site_id, image_id, variant_id },
})
export const getNewVariantSuccess = (variant) => ({
  type: GET_NEW_VARIANT_SUCCESS,
  payload: { variant },
});
export const getNewVariantFailure = (error) => ({
  type: GET_NEW_VARIANT_FAILURE,
  payload: { error },
});
export const stopVariantPolling = () => ({
  type: STOP_VARIANT_POLLING
});


export const variantEdit = (account_id, site_id, image_id, variant_id, history) => ({
  type: VARIANT_EDIT,
  payload: { account_id, site_id, image_id, variant_id, history },
})
export const variantReload = (variant_id) => ({
  type: VARIANT_RELOAD,
  payload: variant_id,
})
export const variantClone = (account_id, site_id, image_id, variant_id) => ({
  type: VARIANT_CLONE,
  payload: { account_id, site_id, image_id, variant_id },
})