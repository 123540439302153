import React, { useState } from "react";

import { 
	Spinner,
	Row, Col, 
	Modal, ModalHeader, ModalBody, ModalFooter,
	Button,
	Alert,
	Form, Label, Input, FormFeedback,
} from "reactstrap"

import * as Yup from "yup";
import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";

import GeneratingReport from './GeneratingReport';
import AssetLibrary from './AssetLibrary';

// control style
import "./site.scss";

// actions
import {
	hideModalImage,
	postImageRequest,
} from "../../store/actions";


// site to offer images that were crawled, image to track polled image/variant status
const AddImageModal = ({ show, site, image }) => {

    const dispatch = useDispatch();
		
	const postImage = (result) => {
		const payload = {
			source_url: result.source_url,
			context: result.context,
			autogen: {
				captions: {
					active: true
				},
				motions: {
					active: true
				},
				stickers: {
					active: true
				},
				buttons: {
					active: true
				},
				particles: {
					active: false
				},
				crop: {
					active: true
				}
			}
		}
	
		dispatch(postImageRequest(site.account_id, site.site_id, payload));
	}
	
	// initial form
	if (!image.image_id && image.status != 'loading') {
		return (
			<Modal 
				isOpen={ show } 
				toggle={ () => dispatch(hideModalImage()) }
			>
				<ModalHeader 
					toggle={ () => dispatch(hideModalImage()) }
					tag="h4"
				>
					Add Image
				</ModalHeader>
				<ModalBody>
					{ image.error && (
						<Alert color="danger" role="alert">
						  { image.error }
						</Alert>
					) }
					<AssetLibrary
						account_id={ site?.account_id }
						site_id={ site?.site_id }
						assetType='source'
						acceptMatch='image/*'
						context="[Optional] Describe this image"
						onResult={ postImage }
					/>
				</ModalBody>
			</Modal>
		);

	} else if (image.status == 'loading' || !image.variants || !image.variants.every(variant => !!variant.web_assets_updated)) {
		// spinning
		return (
			<Modal 
				isOpen={ show } 
			>
				<ModalHeader 
					tag="h4"
				>
					Add Image
				</ModalHeader>
				<ModalBody>
					{ image.error && (
						<Alert color="danger" role="alert">
						  { image.error }
						</Alert>
					) }
					<p>Please wait. This should only take a minute...</p>
					<Spinner />
				</ModalBody>
			</Modal>
		);
		
	} else {
		// ready
		return (
			<GeneratingReport 
				variant={ image.variants[0] }
				site_type={ site?.site_type }
				show={ show }
				hideModal={ () => dispatch(hideModalImage()) }
				continueClick={ () => dispatch(hideModalImage()) }
			/> 
		);	
	}

}

export default AddImageModal;