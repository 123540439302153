import React, { useEffect, useState, useMemo } from "react"

import { Link } from "react-router-dom";
import { 
  Spinner,
  Container, Row, Col,
  Button,
  Input,
  Nav, NavItem, NavLink,
  Form, FormFeedback, Label,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";

import classnames from "classnames";

import VariantPreview from './VariantPreview';

import * as Yup from "yup";
import { useFormik } from "formik";

import { connect, useDispatch, useSelector } from "react-redux";

import { 
	setAssetTab, 
	setAssetState,	
	getAssetUrlRequest, 
	getAssetRecentsRequest,

} from "../../store/actions";

const METHOD_LABELS = {
	copy: 'Clone',
	vary: 'Vary',
	prompt: 'Prompt',
	upload: 'Upload',
	url: 'URL',
	recent: 'Recent'
};

const VARY_STYLES = ['3d-model', 'analog-film', 'anime', 'cinematic', 'comic-book', 'digital-art', 'enhance', 'fantasy-art', 'isometric', 'line-art', 'low-poly', 'modeling-compound', 'neon-punk', 'origami', 'photographic', 'pixel-art', 'tile-texture'];


// for addImage, addVariant, intro.bumper video, add sticker - because they can all use upload.bi.ai
// assetType: source, intro, sticker
// acceptMatch image/* or video/*
// onResult: function that handles the form values
const AssetLibrary = ({ account_id, site_id, site_role, image_id, sourceVariant, assetType, acceptMatch, context, onResult, 
	error, uploadFile, uploadBlob, uploadPreviewLoaded, uploadUrl, s3_upload_url, uploading, importPreviewLoaded, recents }) => {

	const dispatch = useDispatch();
	
	// what methods are available?
	let methods = ['upload', 'url', 'recent'];
	// add image: upload and recent only available for public
	if (assetType == 'source' && !sourceVariant && account_id != 'public') {
		methods = ['url'];
	} else if (sourceVariant) {
		methods = ['copy', 'vary', 'prompt', 'upload', 'url', 'recent'];
	} 
	
	// for lambda auth check
	//const accessToken = useSelector(state => state.Auth?.accessToken);

	const resetState = () => {
		dispatch(setAssetState({
			uploadFile: null,
			uploadBlob: null,
			uploadPreviewLoaded: null,
			uploadUrl: null,
			s3_upload_url: null,
			uploading: false,
			importPreviewLoaded: null,
			error: null,
		}));
	}

	// reset
	useEffect(() => {	
		resetState();
		
		// get list of recents
		dispatch(getAssetRecentsRequest(account_id, site_id, assetType));
	
		// Cleanup function to revoke the Blob URL when the component unmounts or file changes
		return () => {
			if (uploadBlob) {
				URL.revokeObjectURL(uploadBlob);
				dispatch(setAssetState({
					uploadBlob: null
				}));
			}
		};	
	}, []);

	// dynamic form validation and submission based on source_type method
	const [ validationSchema, setValidationSchema ] = useState();

	// assemble base result
	const getResult = (values) => {
		let result = { 
			source_type: values.source_type, 
			account_id, 
			site_id, 
			suggest: values.suggest
		};
		
		// variant - we need image_id
		if (image_id) {
			result.image_id = image_id;
		}
		
		if (values.bi) {
			result.bi = values.bi;
		}
		
		// image, video onLoad data
		if (values.width) result.width = values.width;
		if (values.height) result.height = values.height;
		if (values.duration) result.duration = values.duration;
		if (values.context) result.context = values.context;

		return result;
	}

	const assetValidation = useFormik({
		enableReinitialize: true,

		initialValues: {
			source_type: methods[0],
			ai_vary_add: 'more cats',
			ai_vary_remove: 'background',
			ai_vary_change: 0.35,
			ai_vary_style: '',
			ai_new_prompt: sourceVariant?.ai_prompt,
			url: '',
			context: '',
			recentUrl: null,
			width: null, 			// image width
			height: null,			// image height
			duration: null,			// video duration
			suggest: true,
			bi: false,				// clone into BI website
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			let result = getResult(values);
		
			if (values.source_type == 'copy') {
				result = {
					...result,
					source_variant_id: sourceVariant.variant_id,
				};

			} else if (values.source_type == 'vary') {
				result = {
					...result,
					source_variant_id: sourceVariant.variant_id,
					ai_vary_add: values.ai_vary_add,
					ai_vary_remove: values.ai_vary_remove,
					ai_vary_change: values.ai_vary_change,
					ai_vary_style: values.ai_vary_style,
				};

			} else if (values.source_type == 'prompt') {
				result = {
					...result,
					source_variant_id: sourceVariant.variant_id,
					ai_new_prompt: values.ai_new_prompt,
				};

			} else if (values.source_type == 'upload') {
				// get s3_upload_url
				dispatch(getAssetUrlRequest(account_id, site_id, assetType, uploadFile?.type));
				return;
				// then useEffect below will put and submit and clean up

			} else if (values.source_type == 'url') {
				result = {
					...result,
					source_url: values.url
				};

			} else if (values.source_type == 'recent') {
				result = {
					...result,
					source_url: values.recentUrl
				};
			}
			
			onResult(result);
			assetValidation.resetForm();
			resetState();
		}
	});	
	
	useEffect(() => {
		switch (assetValidation.values.source_type) {
			case 'copy':
			case 'upload':
			case 'recent':
				setValidationSchema(null);
				break;

			case 'vary':
				setValidationSchema(Yup.object({
					ai_vary_add: Yup.string().required("Please describe what to add to the image"),
					ai_vary_remove: Yup.string().required("Please describe what to remove from the image"),
				}));			
				break;
				
			case 'prompt':
				setValidationSchema(Yup.object({
					ai_new_prompt: Yup.string().required("Please describe the image you want"),
				}));			
				break;

			case 'url':
				setValidationSchema(Yup.object({
					url: Yup.string()
						.required("Please enter URL")
						.matches(
							/^(https:\/\/)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/,
							'Invalid URL. Must start with https://'
						)						
				}));				
				break;					
		}
	}, [assetValidation.values.source_type]);
	

	// upload is special...
	const handleFileSelection = (event) => {
		const file = event.target.files[0];
		if (file) {
			dispatch(setAssetState({
				uploadFile: file,
				uploadBlob: URL.createObjectURL(file)
			}));
		}
	};
	// when we get a url, upload the blob!
	useEffect(() => {
		const uploadToS3 = async () => {
		
			try {
				dispatch(setAssetState({
					uploading: true
				}));
				
				const response = await fetch(s3_upload_url, {
				method: 'PUT',
					headers: {
						'Content-Type': uploadFile.type
					},
					body: uploadFile,
				});

				dispatch(setAssetState({
					uploading: false
				}));
				
				if (response.ok) {
					const result = {
						...getResult(assetValidation.values),
						source_url: uploadUrl,
						content_type: uploadFile.type,
					}
					
					onResult(result);
					assetValidation.resetForm();
					resetState();
					
				} else {
					dispatch(setAssetState({
						error: 'Upload failed. Please try again.'
					}));
				}

			} catch (error) {
				console.log('error', error);
				dispatch(setAssetState({
					error: 'Upload failed! Please try again.'
				}));
			}
		};

		if (uploadFile && s3_upload_url) {	
			uploadToS3();
		}
	}, [uploadFile, s3_upload_url]);

	useEffect(() => {
		dispatch(setAssetState({
			error: null
		}));
	}, [assetValidation.values.url]);
	
	
	const AutoSuggest = () => {
		if (assetType == 'source') {
			return (	
				<div
				  className="form-check form-switch"
				>
				  <input
					type="checkbox"
					className="form-check-input"
					name="suggest"
					id="suggest"
					defaultChecked={ assetValidation.values.suggest }
					onChange={ (event) => assetValidation.setFieldValue('suggest', event.target.checked) }					
				  />
				  <label
					className="form-check-label"
					htmlFor="suggest"
				  >
					Automatically suggest better images
				  </label>
				</div>
			)
		}
	}

	return (	

		<>
			{ false && JSON.stringify(assetValidation.values) }
			
			<Nav tabs className="bi-apps align-items-center">
				{ ['copy', 'vary', 'prompt', 'upload', 'url', 'recent'].map((method, idx) => {
					if (methods.includes(method)) {
						return (
							<NavItem
								key={ idx }
							>
								<NavLink
									className={ classnames({
										active: assetValidation.values.source_type === method,
									}) }
									onClick={ () => {
										assetValidation.setFieldValue('source_type', method);
										//dispatch(setAssetTab(method)) 
									} }
								>
									{ METHOD_LABELS[method] }
								</NavLink>
							</NavItem>
						)					
					}
				}) }
			</Nav>

			{ !!error && (
				<Alert color="danger" role="alert" className="mt-3 mb-3">
					{ error }
				</Alert>			
			) }

			<Form
				onSubmit={e => {
					e.preventDefault();
					assetValidation.handleSubmit();
					return false;
				}}
			>
				{ assetValidation.values.source_type == 'copy' && (
					<>
						<Row className="mt-3">
							<Col>
								Make a copy of '{ sourceVariant?.label }':
							</Col>
						</Row>
						<Row className="mt-3">
							<Col xs={ 6 }>			
								<VariantPreview 
									variant={ sourceVariant }
									width={ 320 }
									animationRestart={{
										key: 0
									}}
								/>
							</Col>
						</Row>
						
						<Row className="mt-3 align-items-center">
							<Col xs={ 8 }>
								<AutoSuggest />						
							</Col>
							<Col xs={ 4 } className="text-end">
								<button
									type="submit"
									className="btn btn-success"
								>
									Continue &rsaquo;
								</button>
							</Col>
						</Row>

						{ site_role == 'superadmin' && (
							<Row className="mt-3">
								<Col>			
									<div
									  className="form-check form-switch"
									>
									  <input
										type="checkbox"
										className="form-check-input"
										name="bi"
										id="bi"
										defaultChecked={ assetValidation.values.bi }
										onChange={ (event) => assetValidation.setFieldValue('bi', event.target.checked) }					
									  />
									  <label
										className="form-check-label"
										htmlFor="bi"
									  >
										[superadmin] Clone into Better Images website
									  </label>
									</div>
								</Col>
							</Row>				
						) }

					</>
				) }

				{ assetValidation.values.source_type == 'vary' && (
					<>
						<Row className="mt-3">
							<Col>
								Suggest a variation of '{ sourceVariant?.label }', using AI:
							</Col>
						</Row>
						<Row className="mt-3">
							<Col xs={ 6 }>			
								<VariantPreview 
									variant={ sourceVariant }
									width={ 320 }
									animationRestart={{
										key: 0
									}}
								/>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col xs={ 6 }>
								<label className="form-label">Add</label>							
								<Input
								  name="ai_vary_add"
								  type="text"
								  className=""
								  placeholder="more cats"
								  onChange={ assetValidation.handleChange }
								  onBlur={ assetValidation.handleBlur }
								  value={ assetValidation.values.ai_vary_add }
								  invalid={ assetValidation.touched.ai_vary_add && assetValidation.errors.ai_vary_add }
								/>
								{ (assetValidation.touched.ai_vary_add && assetValidation.errors.ai_vary_add) ? (
								  <FormFeedback type="invalid">
									{ assetValidation.errors.ai_vary_add }
								  </FormFeedback>
								) : null }
							</Col>
							<Col xs={ 6 }>
								<label className="form-label">Remove</label>							
								<Input
								  name="ai_vary_remove"
								  type="text"
								  className=""
								  placeholder="background"
								  onChange={ assetValidation.handleChange }
								  onBlur={ assetValidation.handleBlur }
								  value={ assetValidation.values.ai_vary_remove }
								  invalid={ assetValidation.touched.ai_vary_remove && assetValidation.errors.ai_vary_remove }
								/>
								{ (assetValidation.touched.ai_vary_remove && assetValidation.errors.ai_vary_remove) ? (
								  <FormFeedback type="invalid">
									{ assetValidation.errors.ai_vary_remove }
								  </FormFeedback>
								) : null }
							</Col>
						</Row>						
						<Row className="mt-3">
							<Col xs={ 6 }>
								<label className="form-label">Magnitude</label>
								<Input
									type="range"
									name="ai_vary_change"
									value={ assetValidation.values.ai_vary_change }
									onChange={ assetValidation.handleChange }
									min={ 0 }
									max={ 1 }
									step={ 0.01 }
									className=""
								/>
							</Col>
							<Col xs={ 6 }>
								<label className="form-label">Style</label>
								<Input
									type="select"
									name="ai_vary_style"
									value={ assetValidation.values.ai_vary_style }
									onChange={ assetValidation.handleChange }
								>
									<option value="">-</option>
									{ VARY_STYLES.map((style, idx) => (
										<option key={ idx } value={ style }>{ style }</option>
									)) }
								</Input>
							</Col>
						</Row>						
						<Row className="mt-3 align-items-center">
							<Col xs={ 8 }>
								<AutoSuggest />						
							</Col>
							<Col xs={ 4 } className="text-end">
								<button
									type="submit"
									className="btn btn-success"
									disabled={ !!Object.keys(assetValidation.errors).length }
								>
									Continue &rsaquo;
								</button>
							</Col>
						</Row>
					</>
				) }

				{ assetValidation.values.source_type == 'prompt' && (
					<>
						<Row className="mt-3">
							<Col>
								Suggest a new image, using AI:
							</Col>
						</Row>
						<Row className="mt-3">
							<Col>
								<label className="form-label">Prompt</label>
								<br/>
								<textarea 
									name="ai_new_prompt"
									rows={3}
									style={{
										border: 'var(--bs-border-width) solid var(--bs-border-color-translucent)',
										borderRadius: 'var(--bs-border-radius)',
										width: '100%',
										padding: '0.47rem 0.75rem'
									}}
									value={ assetValidation.values.ai_new_prompt }
									invalid={ assetValidation.touched.ai_new_prompt && assetValidation.errors.ai_new_prompt }
									className={ assetValidation.touched.ai_new_prompt && assetValidation.errors.ai_new_prompt ? 'is-invalid' : '' }
									onChange={ assetValidation.handleChange }
									onBlur={ assetValidation.handleBlur }
								/>

								{ assetValidation.touched.ai_new_prompt && !!assetValidation.errors.ai_new_prompt && (
								  <FormFeedback type="invalid">
									{ assetValidation.errors.ai_new_prompt }
								  </FormFeedback>
								) }
							</Col>
						</Row>
						<Row className="mt-3 align-items-center">
							<Col xs={ 8 }>
								<AutoSuggest />						
							</Col>
							<Col xs={ 4 } className="text-end">
								<button
									type="submit"
									className="btn btn-success"
									disabled={ !!Object.keys(assetValidation.errors).length }
								>
									Continue &rsaquo;
								</button>
							</Col>
						</Row>
					</>
				) }

				{ assetValidation.values.source_type == 'upload' && (
					<>
						<Row className="mt-3">
							<Col>
								<Input
									type="file"
									accept={ acceptMatch }
									disabled={ uploading }
									onChange={ handleFileSelection }
								/>
							</Col>
						</Row>
					
						{ !!uploadBlob && (
							<Row 
								className={ uploadPreviewLoaded ? "mt-3 mb-3" : null }
							>
								<Col xs={ 6 }>
									{ acceptMatch.startsWith('image') && (
										<img
											src={ uploadBlob }
											style={{
												width: '100%',
												display: uploadPreviewLoaded ? 'block' : 'none',
												background: 'repeating-conic-gradient(#eeeeee 0% 25%, transparent 0% 50%) 50% / 20px 20px'
											}}
											onLoad={ (event) => {
												dispatch(setAssetState({ uploadPreviewLoaded: true }));
												assetValidation.setFieldValue('width', event.target.naturalWidth);
												assetValidation.setFieldValue('height', event.target.naturalHeight);
											} }
											onError={ (error) => dispatch(setAssetState({
												error: 'Image failed to load. Please try again.'
											})) }
										/>
									) }
									{ acceptMatch.startsWith('video') && (
										<video
											src={ uploadBlob }
											style={{
												width: '100%',
												display: uploadPreviewLoaded ? 'block' : 'none'
											}}
											autoPlay="autoplay"
											loop="loop"
											muted="muted"
											onLoadedData={ (event) => {
												dispatch(setAssetState({ uploadPreviewLoaded: true }));
												assetValidation.setFieldValue('width', event.target.videoWidth);
												assetValidation.setFieldValue('height', event.target.videoHeight);
												assetValidation.setFieldValue('duration', event.target.duration);
											} }
											onError={ (error) => dispatch(setAssetState({
												error: 'Video failed to load. Please try again.'
											})) }
										/>
									) }
								</Col>
							</Row>
						) }

						{ context && (
							<Row className="mt-3">
								<Col>
									<label className="form-label">
										Description
										{" "}<i className="mdi mdi-help-circle" id="context-help" />
										<UncontrolledTooltip placement="top" target="context-help">
											{ context }
										</UncontrolledTooltip>
									</label>
									<textarea 
										name="context"
										rows={3}
										style={{
											border: 'var(--bs-border-width) solid var(--bs-border-color-translucent)',
											borderRadius: 'var(--bs-border-radius)',
											width: '100%',
											padding: '0.47rem 0.75rem'
										}}
										value={ assetValidation.values.context }
										onChange={ assetValidation.handleChange }
										onBlur={ assetValidation.handleBlur }
									/>
								</Col>
							</Row>			
						) }
					
						<Row className="mt-3 align-items-center">
							<Col xs={ 8 }>						
								<AutoSuggest />						
							</Col>
							<Col xs={ 4 } className="text-end">
								<button
									type="submit"
									className="btn btn-success"
									disabled={ !uploadPreviewLoaded || uploading }
								>
									{ !!uploading && (
										<Spinner color="light" size="sm" className="me-2" />
									) }								
									Continue &rsaquo;
								</button>
							</Col>					
						</Row>
					</>
				) }

				{ assetValidation.values.source_type == 'url' && (
					<>
						<Row className="mt-3">
							<Col>
								<Input
									name="url"
									type="text"
									placeholder="https://"
									onChange={ assetValidation.handleChange }
									onBlur={ assetValidation.handleBlur }
									value={ assetValidation.values.url }
									invalid={ assetValidation.touched.url && !!assetValidation.errors.url }
								/>
								{ (assetValidation.touched.url && assetValidation.errors.url) ? (
									<FormFeedback type="invalid">
										{ assetValidation.errors.url }
									</FormFeedback>
								) : null }
							</Col>
						</Row>

						{ !!assetValidation.values.url && !assetValidation.errors.url && (
							<Row 
								className={ importPreviewLoaded ? "mt-3 mb-3" : null }
							>
								<Col xs={ 6 }>
									{ acceptMatch.startsWith('image') && (
										<img
											src={ assetValidation.values.url }
											width="100%"
											style={{
												display: importPreviewLoaded ? 'block' : 'none',
												background: 'repeating-conic-gradient(#eeeeee 0% 25%, transparent 0% 50%) 50% / 20px 20px'
											}}
											onLoad={ (event) => {
												dispatch(setAssetState({ importPreviewLoaded: true }));
												assetValidation.setFieldValue('width', event.target.naturalWidth);
												assetValidation.setFieldValue('height', event.target.naturalHeight);
											} }
											onError={ (error) => dispatch(setAssetState({
												error: 'Image failed to load. Please try again.'
											})) }
										/>
									) }
									{ acceptMatch.startsWith('video') && (
										<video
											src={ assetValidation.values.url }
											style={{
												width: '100%',
												display: importPreviewLoaded ? 'block' : 'none'
											}}
											autoPlay="autoplay"
											loop="loop"
											muted="muted"
											onLoadedData={ (event) => { 
												dispatch(setAssetState({ importPreviewLoaded: true }));
												assetValidation.setFieldValue('width', event.target.videoWidth);
												assetValidation.setFieldValue('height', event.target.videoHeight);
												assetValidation.setFieldValue('duration', event.target.duration);
											} }
											onError={ (error) => dispatch(setAssetState({
												error: 'Video failed to load. Please try again.'
											})) }
										/>
									) }
								</Col>
							</Row>
						) }
					
						{ context && (
							<Row className="mt-3">
								<Col>
									<label className="form-label">
										Description
										{" "}<i className="mdi mdi-help-circle" id="context-help" />
										<UncontrolledTooltip placement="top" target="context-help">
											{ context }
										</UncontrolledTooltip>
									</label>
									<textarea 
										name="context"
										rows={3}
										style={{
											border: 'var(--bs-border-width) solid var(--bs-border-color-translucent)',
											borderRadius: 'var(--bs-border-radius)',
											width: '100%',
											padding: '0.47rem 0.75rem'
										}}
										value={ assetValidation.values.context }
										onChange={ assetValidation.handleChange }
										onBlur={ assetValidation.handleBlur }
									/>
								</Col>
							</Row>			
						) }

						<Row className="mt-3 align-items-center">
							<Col xs={ 8 }>
								<AutoSuggest />						
							</Col>
							<Col xs={ 4 } className="text-end">
								<button
									type="submit"
									className="btn btn-success"
									disabled={ !importPreviewLoaded }
								>
									Continue &rsaquo;
								</button>
							</Col>
						</Row>
					</>
				) }

				{ assetValidation.values.source_type == 'recent' && (
					<>
						{ !assetValidation.values.recentUrl && (					
							<Row style={{
								maxHeight: '240px',
								overflowY: 'scroll'		
							}}>
								{ recents.map((recent, idx) => {
									return (				
										<Col 
											xs={ 6 } 
											key={ idx }
											className="mt-3"
										>
											{ acceptMatch.startsWith('image') && (								
												<img							
													src={ recent.url }
													style={{
														width: '100%',
														cursor: 'pointer',
														background: 'repeating-conic-gradient(#eeeeee 0% 25%, transparent 0% 50%) 50% / 20px 20px'
													}}
													onClick={ () => assetValidation.setFieldValue('recentUrl', recent.url) }
												/>
											) }										

											{ acceptMatch.startsWith('video') && (
												<video
													src={ recent.url }
													style={{
														width: '100%',
														cursor: 'pointer',
													}}
													autoPlay="autoplay"
													loop="loop"
													muted="muted"
													onClick={ () => assetValidation.setFieldValue('recentUrl', recent.url) }
												/>
											) }
																		
										</Col>
									);
								}) }
							</Row>
						) }

						{ !!assetValidation.values.recentUrl && (
							<Row className="mt-3 mb-3">
								<Col xs={ 6 }>
									{ acceptMatch.startsWith('image') && (
										<img
											src={ assetValidation.values.recentUrl }
											style={{
												width: '100%',
												background: 'repeating-conic-gradient(#eeeeee 0% 25%, transparent 0% 50%) 50% / 20px 20px'
											}}
											onLoad={ (event) => {
												assetValidation.setFieldValue('width', event.target.naturalWidth);
												assetValidation.setFieldValue('height', event.target.naturalHeight);
											} }
										/>
									) }
									{ acceptMatch.startsWith('video') && (
										<video
											src={ assetValidation.values.recentUrl }
											style={{
												width: '100%',
											}}
											autoPlay="autoplay"
											loop="loop"
											muted="muted"
											onLoadedData={ (event) => {
												assetValidation.setFieldValue('width', event.target.videoWidth);
												assetValidation.setFieldValue('height', event.target.videoHeight);
												assetValidation.setFieldValue('duration', event.target.duration) 
											} }
										/>
									) }
								</Col>
							</Row>
						) }
					
						{ context && (
							<Row className="mt-3">
								<Col>
									<label className="form-label">
										Description
										{" "}<i className="mdi mdi-help-circle" id="context-help" />
										<UncontrolledTooltip placement="top" target="context-help">
											{ context }
										</UncontrolledTooltip>
									</label>
									<textarea 
										name="context"
										rows={3}
										style={{
											border: 'var(--bs-border-width) solid var(--bs-border-color-translucent)',
											borderRadius: 'var(--bs-border-radius)',
											width: '100%',
											padding: '0.47rem 0.75rem'
										}}
										value={ assetValidation.values.context }
										onChange={ assetValidation.handleChange }
										onBlur={ assetValidation.handleBlur }
									/>
								</Col>
							</Row>			
						) }

						<Row className="mt-3 align-items-center">
							<Col xs={ 8 }>
								<AutoSuggest />						
							</Col>
							<Col xs={ 4 } className="text-end">
								<button
									type="submit"
									className="btn btn-success"
									disabled={ !assetValidation.values.recentUrl }
								>
									Continue &rsaquo;
								</button>
							</Col>
						</Row>
					</>
				) }
				
				{ false && JSON.stringify(assetValidation.values) }

			</Form>
		</>
	)
}

const mapStateToProps = (state, ownProps) => {
	return state.Asset;
};

export default connect(mapStateToProps)(AssetLibrary);