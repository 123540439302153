import { combineReducers } from "redux";

import Layout from "./layout/reducer";
import Auth from "./auth/reducer";
import Profile from "./profile/reducer";
import User from "./user/reducer";

import Images from "./images/reducer";
import Variant from "./variant/reducer";

import Asset from "./asset/reducer";

import Publish from "./publish/reducer";

const rootReducer = combineReducers({
	Layout,
	Auth,
	Profile,
	User,
	Images,
	Variant,
	Asset,
	Publish
});

export default rootReducer;
