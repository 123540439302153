import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { updateProfileRequest } from "../../store/actions";

const UserProfile = props => {

	const dispatch = useDispatch();

	const logged_in_user = useSelector(state => state.Profile?.data?.user);
	const users = useSelector(state => state.User?.data || []);

	// default to profile
	const [user, setUser] = useState(null);

	// get user_id from url
	useEffect(() => {
		if (props.router.params.user_id) {
			setUser(users.find(({ user_id }) => user_id == props.router.params.user_id));  	
		} else {
			setUser(logged_in_user);
		}
	}, [props.router.params.user_id]);

	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			name: user?.name || '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required("Please enter your name"),
		}),
		onSubmit: (values) => {
			dispatch(updateProfileRequest(user?.user_id, values));
		}
	});

	let error = null;
	let success = null;

	//meta title
	document.title = "Better Images | Edit User";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Better Images" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
						{ user?.profile_pic && (
						  <img
							src={ user.profile_pic }
							alt={ user?.name }
							className="avatar-md rounded-circle img-thumbnail"
						  />
						) }
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{ user?.name }</h5>
                        <p className="mb-1">{ user?.email }</p>
                        <p className="mb-0">{ user?.user_id }</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change Name</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                	<Col>
						<div className="form-group">
						  <Label className="form-label">Name</Label>
						  <Input
							name="name"
							className="form-control"
							placeholder="Enter Name"
							type="text"
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.name || ''}
							invalid={
							  validation.touched.name && validation.errors.name ? true : false
							}
						  />
						  {validation.touched.name && validation.errors.name ? (
							<FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
						  ) : null}
						  <Input name="user_id" value={ user?.user_id } type="hidden" />
						</div>
					</Col>
				</Row>

                <div className="text-center mt-4">
                  <Button type="submit" color="primary">
                    Save
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
