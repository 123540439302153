import { call, put, takeEvery, takeLatest, all, fork, delay, take, cancel } from "redux-saga/effects";

import {
	GET_VARIANTS_REQUEST,
	GET_VARIANTS_SUCCESS,
	GET_VARIANTS_FAILURE,
		
	VARIANT_STATUS_REQUEST,
	VARIANT_STATUS_SUCCESS,
	VARIANT_STATUS_FAILURE,
	
	SHOW_MODAL_IMAGE, HIDE_MODAL_IMAGE,
	POST_IMAGE_REQUEST,
	POST_IMAGE_SUCCESS,
	POST_IMAGE_FAILURE,
	GET_IMAGE_REQUEST,
	GET_IMAGE_SUCCESS,
	GET_IMAGE_FAILURE,
	STOP_IMAGE_POLLING,

	// better variant
	SHOW_MODAL_VARIANT, HIDE_MODAL_VARIANT,
	POST_VARIANT_REQUEST,
	POST_VARIANT_SUCCESS,
	POST_VARIANT_FAILURE,	
	GET_NEW_VARIANT_REQUEST,
	GET_NEW_VARIANT_SUCCESS,
	GET_NEW_VARIANT_FAILURE,
	STOP_VARIANT_POLLING,
	
	VARIANT_EDIT,
	VARIANT_RELOAD,
	VARIANT_CLONE,

} from "./actionTypes"


import { 
	getVariantsRequest, getVariantsSuccess,	getVariantsFailure,

  	hideModalImage, postImageSuccess, postImageFailure, getImageRequest, getImageSuccess, getImageFailure, stopImagePolling,

	variantStatusSuccess,		variantStatusFailure,

	postVariantSuccess,	postVariantFailure, getNewVariantRequest, getNewVariantSuccess, getNewVariantFailure, stopVariantPolling,

} from './actions'

import {
  getVariants,
  postImage, getImage,
  putVariant,
  postVariant, getVariant,
} from "../../helpers/backend_helper";

// get and enrich variants
// TODO Approvals generic version with account_id and site_id null
function* getVariantsSaga(action) {

  try {
    const response = yield call(getVariants, action);

	// build image/variants
	// TODO build searchable text index of descriptions and labels
	let images = {};
	let polling;
	response.variants.forEach((variant) => {
		
		variant.account_id = action.payload.account_id;
		variant.site_id = action.payload.site_id;
	
		if (!(variant.image_id in images)) {
			images[variant.image_id] = {
				variants: {},
				updated: null,	// library sorting desc
				approval: null,	// filter for /approvals
				views: 0
			};
		}
		images[variant.image_id].variants[variant.variant_id] = variant;		
		if (variant.variant_type == 'baseline') {
			images[variant.image_id].baseline_variant_id = variant.variant_id
		}
		
		// used for Generate Now
		if (variant.variant_type == 'primary') {
			images[variant.image_id].primary_variant_id = variant.variant_id
		}
		
		// updated
		if (!images[variant.image_id].updated || variant.updated > images[variant.image_id].updated) {
			images[variant.image_id].updated = variant.updated;
		}
		
		// total views calc %views for each variant of image
		images[variant.image_id].views += variant.events?.view || 0;
		
		// is polling required?
		if (variant.variant_status == 'loading') {
			polling = true;
		}
		
		// included on /approvals
		if (variant.variant_status == 'suggested') {
			images[variant.image_id].approval = true;
		}
		
	});
	
	// remove any with a deleted baseline
	images = Object.fromEntries(
		Object.entries(images)
			.filter(([key, value]) => value.hasOwnProperty('baseline_variant_id'))
	);
	
	yield put(getVariantsSuccess(images, polling));
	
  } catch (error) {
    yield put(getVariantsFailure(error.message));
  }
}

// update variant status: active, inactive, deleted
function* variantStatusSaga(action) {
  try {
    const response = yield call(putVariant, action, {
    	'variant_status': action.payload.variant_status
    });

    yield put(variantStatusSuccess(action.payload.image_id, action.payload.variant_id, response.variant));
    
  } catch (error) {
    yield put(variantStatusFailure(error.message));
  }
}

// addImage ------------------------------------------------------------------------------
function* postImageSaga(action) {
  try {
    const response = yield call(postImage, action);

	// loading...
    yield put(postImageSuccess(response.image, response.variant));
    
  } catch (error) {
    yield put(postImageFailure(error.message));
  }
}

function* getImageSaga(action) {
  try { 
    const response = yield call(getImage, action);
    
    yield put(getImageSuccess(response.image));
    
    // stop polling when we have variants compiled by policy
    if (response.image.variants && response.image.variants.every(variant => !!variant.web_assets_updated)) {
    	yield put(getVariantsRequest(response.image.account_id, response.image.site_id));
    	yield put(stopImagePolling());
    }
    
  } catch (error) {
    yield put(getImageFailure(error.message));
  }
}

function* pollImage(account_id, image_id) {
	while (true) {
		yield delay(10000);
		yield put(getImageRequest(account_id, image_id));
	}
}

export function* watchPostImage() {
  yield takeLatest(POST_IMAGE_REQUEST, postImageSaga);
}
export function* watchGetImage() {
  yield takeLatest(GET_IMAGE_REQUEST, getImageSaga);
}
export function* watchPollImage() {
	while (true) {
		const action = yield take(POST_IMAGE_SUCCESS);
		const pollTask = yield fork(pollImage, action.payload.image.account_id, action.payload.image.image_id);
		// cancel polling when modal is hidden
		yield take([HIDE_MODAL_IMAGE, STOP_IMAGE_POLLING]);
		yield cancel(pollTask);
	}
}
// end: addImage ------------------------------------------------------------------------------

// addVariant ------------------------------------------------------------------------------
function* postVariantSaga(action) {
  try {
  	// generate better image
    const response = yield call(postVariant, action);

	// update library
    yield put(postVariantSuccess(response.variant));
        
  } catch (error) {
    yield put(postVariantFailure(error.message));
  }
}

function* getNewVariantSaga(action) {
  try { 
    const response = yield call(getVariant, action);
    
    yield put(getNewVariantSuccess(response.variant));
    
    // stop polling when we have variants compiled by policy
    if (response.variant.web_assets_updated) {
    	yield put(getVariantsRequest(response.variant.account_id, response.variant.site_id));
    	yield put(stopVariantPolling());
    }
    
  } catch (error) {
    yield put(getNewVariantFailure(error.message));
  }
}

function* pollNewVariant(action) {
	console.log('pollNewVariant', action);
	
	while (true) {
		yield delay(10000);
		yield put(getNewVariantRequest(action.payload.variant.account_id, action.payload.variant.site_id, 
			action.payload.variant.image_id, action.payload.variant.variant_id));
	}
}

export function* watchPostVariant() {
  yield takeLatest(POST_VARIANT_REQUEST, postVariantSaga);
}
export function* watchGetNewVariant() {
  yield takeLatest(GET_NEW_VARIANT_REQUEST, getNewVariantSaga);
}
export function* watchPollNewVariant() {
	while (true) {
		const action = yield take(POST_VARIANT_SUCCESS);
		const pollTask = yield fork(pollNewVariant, action);
		// cancel polling when modal is hidden
		yield take([HIDE_MODAL_VARIANT, STOP_VARIANT_POLLING]);
		yield cancel(pollTask);
	}
}
// end: addVariant ------------------------------------------------------------------------------


function* variantEditSaga({ payload: { account_id, site_id, image_id, variant_id, history } }) {
  try {
    call(history, `/${account_id}/${site_id}/${image_id}/${variant_id}/edit`);

  } catch(error) {
	console.error(error);  
  }
}

export function* watchGetVariants() {
  yield takeLatest(GET_VARIANTS_REQUEST, getVariantsSaga);
}

export function* watchVariantStatus() {
  yield takeLatest(VARIANT_STATUS_REQUEST, variantStatusSaga);
}

export function* watchVariantEdit() {
  yield takeLatest(VARIANT_EDIT, variantEditSaga);
}

function* LibrarySaga() {
	yield all([
		fork(watchGetVariants),
		fork(watchVariantStatus),  	
		
		fork(watchPostImage),
		fork(watchGetImage),
		fork(watchPollImage),

		fork(watchPostVariant),  	
		fork(watchGetNewVariant),  	
		fork(watchPollNewVariant),  	

		fork(watchVariantEdit),
	])
}

export default LibrarySaga;