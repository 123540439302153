import React from "react"

// controls
import { 
  Card, CardHeader, CardBody, CardTitle,
  Row, Col,
  Input, FormFeedback,
} from "reactstrap";

import Select, { createFilter } from 'react-select';

import { useDispatch } from "react-redux";
import { setVariantParams } from "../../store/actions";

import geoDict from './geo.json';
import languageDict from './language.json';

const targetOptions = {
	geo: Object.entries(geoDict).map(([key, value]) => ({ value: key, label: value })),
	language: Object.entries(languageDict).map(([key, value]) => ({ value: key, label: value })),
	time: [
		{ value: 0, label: '12midnight - 1am' }, { value: 1, label: '1am - 2am' }, { value: 2, label: '2am - 3am' },
		{ value: 3, label: '3am - 4am' }, { value: 4, label: '4am - 5am' }, { value: 5, label: '5am - 6am' },
		{ value: 6, label: '6am - 7am' }, { value: 7, label: '7am - 8am' }, { value: 8, label: '8am - 9am' },
		{ value: 9, label: '9am - 10am' }, { value: 10, label: '10am - 11am' }, { value: 11, label: '11am - 12noon' },
		{ value: 12, label: '12noon - 1pm' }, { value: 13, label: '1pm - 2pm' }, { value: 14, label: '2pm - 3pm' },
		{ value: 15, label: '3pm - 4pm' }, { value: 16, label: '4pm - 5pm' }, { value: 17, label: '5pm - 6pm' },
		{ value: 18, label: '6pm - 7pm' }, { value: 19, label: '7pm - 8pm' }, { value: 20, label: '8pm - 9pm' },
		{ value: 21, label: '9pm - 10pm' }, { value: 22, label: '10pm - 11pm' }, { value: 23, label: '11pm - 12midight' },
	]
}

const targetCopy = {
	geo: { item: 'location', others: 'In other locations, viewers' },
	language: { item: 'language', others: 'Viewers with other language preferences' },
	time: { item: 'timeslot', others: 'At other times, viewers' }
}

// target is one of: geo, language, time
const EditTarget = ({ variant, target, restartFrom }) => {

	const getTargetValue = (field, default_value) => {
		let rv = (variant.params[target] || {})[field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// update params
    const dispatch = useDispatch();
	const setTargetParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			[target]: {
				...variant.params[target],
				...params
			} 
		}));
	}

	return (
		<>
			<Card>		
				<CardHeader>
					<strong>Method</strong>
				</CardHeader>
				<CardBody>
					<Row className="mb-3">
						<Col>
							<div className="form-check mb-2">
							  <input
								className="form-check-input"
								type="radio"
								name="method"
								id="method_target"
								checked={ getTargetValue('method') == 'target' }
								onChange={ (event) => setTargetParams({ 'method': 'target' }) }
							  />
							  <label
								className="form-check-label"
								htmlFor="method_target"
							  >
								Target
							  </label>
							</div>
							<div className="form-check mb-2">
							  <input
								className="form-check-input"
								type="radio"
								name="method"
								id="method_exclude"
								checked={ getTargetValue('method') == 'exclude' }
								onChange={ (event) => setTargetParams({ 'method': 'exclude' }) }
							  />
							  <label
								className="form-check-label"
								htmlFor="method_exclude"
							  >
								Exclude
							  </label>
							</div>
						</Col>
					</Row>
					
					{ getTargetValue('method') == 'target' && (
						<>
							<Row>
								<Col>
									<label className="form-label">Target { targetCopy[target].item }s</label>
									<Select
										value={ getTargetValue('target') }
										onChange={ (newValue, actionMeta) => setTargetParams({ target: newValue }) }
										isMulti
										name="target"
										options={ targetOptions[target] }
										className="basic-multi-select"
										classNamePrefix="select"
										filterOption={ createFilter({ ignoreAccepts: false }) }
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<small>
										<i className="mdi mdi-lightbulb-on-outline me-1" />
											{ getTargetValue('target', []).length ? (
												<small>{ targetCopy[target].others } will see a different variant of this image</small>
											) : (
												<small>Type or select { targetCopy[target].item }(s)</small>
											) }
									</small>					
								</Col>
							</Row>
						</>
					) }
						
					{ getTargetValue('method') == 'exclude' && (
						<>
							<Row>
								<Col>
									<label className="form-label">Exclude { targetCopy[target].item }s</label>
									<Select
										value={ getTargetValue('exclude') }
										onChange={ (newValue, actionMeta) => setTargetParams({ exclude: newValue }) }
										isMulti
										name="exclude"
										options={ targetOptions[target] }
										className="basic-multi-select"
										classNamePrefix="select"
										filterOption={ createFilter({ ignoreAccepts: false }) }
									/>

								</Col>
							</Row>
							<Row>
								<Col>
									<small>
										<i className="mdi mdi-lightbulb-on-outline me-1" />
											{ getTargetValue('exclude', []).length ? (
												<small>{ targetCopy[target].others } will see this or another variant of the image</small>
											) : (
												<small>Type or select { targetCopy[target].item }(s)</small>
											) }
									</small>					
								</Col>
							</Row>
						</>
					) }
				</CardBody>
			</Card>
		</>		
	)
}

export default EditTarget;