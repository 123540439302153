import React, { useRef, useState, useEffect, useCallback } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";

// actions
// dispatch actions:  box, begin, end, zoom, transition, ? manual zoom
import { useDispatch } from "react-redux";
import { setVariantParams, updateEditor } from "../../store/actions";


// controls for editing variant.params.upscale
//
const EditUpscale = ({ variant, restartFrom }) => {

	const upscale = variant.params?.upscale || { active: false };

	const getUpscaleValue = (field, default_value) => {
		let rv = upscale[field];

		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// update params
    const dispatch = useDispatch();
	const setParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			upscale: {
				...upscale,
				...params
			} 
		}));
		//restartFrom(0);
	}

	const source_dimensions = variant.source_asset.replace('source-', '').split('x');
	
	// TODO calc based on viewbox / source dimensions
	const max_zoom = 5;
	
	// 4096 width tbc
	const max_zoom_if_upscaled = 8;
	
	return (
		<>
			<Card>		
				<CardHeader>
					<strong>Upscale</strong>
				</CardHeader>
				<CardBody>
					<Row className="mb-3">
						<Col>
							<div
							  className="form-check form-switch"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="upscale_active"
								defaultChecked={ !!getUpscaleValue('active', false) }
								onChange={ (event) => setParams({
									'active': event.target.checked,
									'status': event.target.checked ? 'requested' : null
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="upscale_active"
							  >
								Active
							  </label>
							</div>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</>
	)
}

export default EditUpscale;