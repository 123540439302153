import { call, put, takeLatest, all, fork, delay, take, cancel } from 'redux-saga/effects';

import {
	FETCH_PROFILE_REQUEST,
	FETCH_PROFILE_SUCCESS,
	FETCH_PROFILE_FAILURE,

	GET_SPEED_REQUEST,
	GET_SPEED_SUCCESS,
	GET_SPEED_FAILURE,	

	SELECT_ACCOUNT,
	SELECT_SITE,
	GOTO_LIBRARY,

	SHOW_MODAL_INBOX, HIDE_MODAL_INBOX,
	POST_INBOX_REQUEST,
	POST_INBOX_SUCCESS,
	POST_INBOX_FAILURE,
	GET_INBOX_REQUEST,
	GET_INBOX_SUCCESS,
	GET_INBOX_FAILURE,
	STOP_INBOX_POLLING,

	SHOW_MODAL_SITE, HIDE_MODAL_SITE,
	POST_SITE_REQUEST,
	POST_SITE_SUCCESS,
	POST_SITE_FAILURE,
	GET_SITE_REQUEST,
	GET_SITE_SUCCESS,
	GET_SITE_FAILURE,
	STOP_SITE_POLLING,

	SHOW_MODAL_DELETE, HIDE_MODAL_DELETE,

	PUT_ACCOUNT_REQUEST, PUT_ACCOUNT_SUCCESS, PUT_ACCOUNT_FAILURE,
	PUT_SITE_REQUEST, PUT_SITE_SUCCESS, PUT_SITE_FAILURE,
	
	UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE,

	REDIRECT_BILLING,
	
} from "./actionTypes"

import { 
	fetchProfileRequest, fetchProfileSuccess, fetchProfileFailure,
	getSpeedSuccess, getSpeedFailure,
	selectSite,
  	hideModalInbox, postInboxSuccess, postInboxFailure, getInboxRequest, getInboxSuccess, getInboxFailure, stopInboxPolling,
  	hideModalSite, postSiteSuccess, postSiteFailure, getSiteRequest, getSiteSuccess, getSiteFailure, stopSitePolling,
  	putAccountRequest, putAccountSuccess, putAccountFailure,
  	putSiteRequest, putSiteSuccess, putSiteFailure,
  	updateProfileRequest, updateProfileSuccess, updateProfileFailure, 
} from './actions'


import {
	getProfile, getSpeed,
	postInbox, getInbox,
	postSite, getSite,
	putAccount, 
	putSite,
  	putUser,
  	getBillingPortal,
} from "../../helpers/backend_helper";


function* fetchProfileSaga(action) {
  try {
    const response = yield call(getProfile, action);

    yield put(fetchProfileSuccess(response));

  } catch (error) {
    yield put(fetchProfileFailure(error.message));
  }
}

function* getSpeedSaga() {
  try {
    const response = yield call(getSpeed);
    yield put(getSpeedSuccess({'speed': response}));

  } catch (error) {
    yield put(getSpeedFailure(error.message));
  }
}

// save to localStorage
function* persistAccount({ payload: account_id }) {
  localStorage.setItem("account_id", account_id);
}

// save to localStorage
function* persistSite({ payload: site_id }) {
  localStorage.setItem("site_id", site_id);
}
function* gotoLibrarySaga({ payload: { account_id, site_id, history } }) {
  yield put(selectSite(site_id));
  history(`/${account_id}/${site_id}/images`);
}

// request inbox (email)
function* postInboxSaga(action) {
  try {
    const response = yield call(postInbox, action);

	// loading...
    yield put(postInboxSuccess(response.inbox));
    
  } catch (error) {
    yield put(postInboxFailure(error.message));
  }
}

// request inbox (email)
function* getInboxSaga(action) {
  try {
  
    const response = yield call(getInbox, action);
    
    yield put(getInboxSuccess(response.inbox));
    
    // stop polling when we have variants compiled by policy
    if (response.inbox.inbox_status == 'generating' && (response.inbox.site?.suggest === false || (response.inbox.site?.variants && response.inbox.site.variants.every(variant => !!variant.web_assets_updated)))) {
    	yield put(fetchProfileRequest());
    	yield put(stopInboxPolling());
    }
    
  } catch (error) {
    yield put(getInboxFailure(error.message));
  }
}

// request new site
function* postSiteSaga(action) {
  try {
    const response = yield call(postSite, action);

	// loading...
    yield put(postSiteSuccess(response.site));
    
  } catch (error) {
    yield put(postSiteFailure(error.message));
  }
}

// request new site
function* getSiteSaga(action) {
  try {
  
    const response = yield call(getSite, action);
    
    yield put(getSiteSuccess(response.site));
    
    // stop polling when we have variants compiled by policy
    if (response.site.site_status == 'active' && (response.site.suggest === false || (response.site.variants && response.site.variants.every(variant => !!variant.web_assets_updated)))) {
    	yield put(fetchProfileRequest());
    	yield put(stopSitePolling());
    }
    
  } catch (error) {
    yield put(getSiteFailure(error.message));
  }
}

export function* watchFetchProfile() {
  yield takeLatest(FETCH_PROFILE_REQUEST, fetchProfileSaga);
}

export function* watchGetSpeed() {
  yield takeLatest(GET_SPEED_REQUEST, getSpeedSaga);
}

export function* watchSelectAccount() {
  yield takeLatest(SELECT_ACCOUNT, persistAccount);
}

export function* watchSelectSite() {
  yield takeLatest(SELECT_SITE, persistSite);
}
export function* watchGotoLibrary() {
  yield takeLatest(GOTO_LIBRARY, gotoLibrarySaga);
}

export function* watchPostInbox() {
  yield takeLatest(POST_INBOX_REQUEST, postInboxSaga);
}

export function* watchGetInbox() {
  yield takeLatest(GET_INBOX_REQUEST, getInboxSaga);
}

export function* watchPostSite() {
  yield takeLatest(POST_SITE_REQUEST, postSiteSaga);
}

export function* watchGetSite() {
  yield takeLatest(GET_SITE_REQUEST, getSiteSaga);
}

// polling - inbox
function* pollInbox(account_id, inbox_key) {
	while (true) {
		yield delay(10000);
		yield put(getInboxRequest(account_id, inbox_key));
	}
}
export function* watchPollInbox() {
	while (true) {
		const action = yield take(POST_INBOX_SUCCESS);		
		const pollTask = yield fork(pollInbox, action.payload.inbox.account_id, action.payload.inbox.inbox_key);
		// cancel polling when modal is hidden
		yield take([HIDE_MODAL_INBOX, STOP_INBOX_POLLING]);
		yield cancel(pollTask);
	}
}

// polling - site
function* pollSite(account_id, site_id) {
	while (true) {
		yield delay(10000);
		yield put(getSiteRequest(account_id, site_id));
	}
}
export function* watchPollSite() {
	while (true) {
		const action = yield take(POST_SITE_SUCCESS);
		const pollTask = yield fork(pollSite, action.payload.site.account_id, action.payload.site.site_id);
		// cancel polling when modal is hidden
		yield take([HIDE_MODAL_SITE, STOP_SITE_POLLING]);
		yield cancel(pollTask);
	}
}

// request new site
function* putAccountSaga(action) {
  try { 
    const response = yield call(putAccount, action);
    
    yield put(putAccountSuccess(response.account));
    
	yield put(fetchProfileRequest());
	
	// close modal
	if (action.payload.onSuccess) {
		action.payload.onSuccess();
	}
  } catch (error) {
    yield put(putAccountFailure(error.message));
  }
}
export function* watchPutAccount() {
  yield takeLatest(PUT_ACCOUNT_REQUEST, putAccountSaga);
}

// request new site
function* putSiteSaga(action) {
  try { 
    const response = yield call(putSite, action);
    
    yield put(putSiteSuccess(response.site));
    
	yield put(fetchProfileRequest());

	// close modal
	if (action.payload.onSuccess) {
		action.payload.onSuccess();
	}
    
  } catch (error) {
    yield put(putSiteFailure(error.message));
  }
}
export function* watchPutSite() {
  yield takeLatest(PUT_SITE_REQUEST, putSiteSaga);
}


function* updateProfileSaga(action) {
  try {
    const response = yield call(putUser, action);

    yield put(updateProfileSuccess(response.user));

  } catch (error) {
    yield put(updateProfileFailure(error.message));
  }
}

export function* watchPutProfile() {
  yield takeLatest(UPDATE_PROFILE_REQUEST, updateProfileSaga);
}


// redirect to stripe portal
function* navBillingSaga(action) {
	//console.log('nav');
	const response = yield call(getBillingPortal, action);
	window.location.href = response.redirect;
}

export function* watchRedirectBilling() {
  yield takeLatest(REDIRECT_BILLING, navBillingSaga);
}


function* ProfileSaga() {
  yield all([
  	fork(watchFetchProfile),
  	fork(watchGetSpeed),
  	fork(watchSelectAccount),
  	fork(watchSelectSite),
  	fork(watchGotoLibrary),
  	fork(watchPostInbox),
  	fork(watchGetInbox),
  	fork(watchPollInbox),
  	fork(watchPostSite),
  	fork(watchGetSite),
  	fork(watchPollSite),
  	fork(watchPutAccount),
  	fork(watchPutSite),
  	fork(watchPutProfile),
  	fork(watchRedirectBilling),
  ])
}

export default ProfileSaga;
