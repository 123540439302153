import React, { useRef, useState, useEffect } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
  Nav, NavItem, NavLink,
} from "reactstrap";

import classnames from "classnames";

import { useDispatch, useSelector } from "react-redux";
import { setVariantParams, updateEditor } from "../../store/actions";


const EditMenu = ({ variant, site, restartFrom }) => {

	const menu = variant.params.menu || {};

	const editor = useSelector(state => state.Variant.editor);
	const dispatch = useDispatch();

	// default editor state
	useEffect(() => {
		if (typeof variant.params.menu == 'undefined') {
			dispatch(setVariantParams(variant.variant_id, { 
				menu: {
					items: [{
						text: 'Menu Item',
						link: 'https://example.com'
					}]
				}
			}));
		}
	}, []);

	const getMenuValue = (field, default_value) => {
		let rv = menu[field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}
	const setMenuParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			menu: {
				...menu,
				...params
			}
		}));
	}

	// resize all buttons
	useEffect(() => {

		let buttons = [];

		document.querySelectorAll('.bi-buttons text').forEach((button_el, idx) => {
			const sizingRect = button_el.getBBox();
			
			let button = {
				...variant.params.buttons[idx],
				width: sizingRect.width,
				height: sizingRect.height
			}
			
			buttons.push(button);
			
		});
			
		dispatch(setVariantParams(variant.variant_id, { 
			buttons: buttons
		}));

	}, [menu.button, menu.button_idx]);


	const updateItem = (idx, field, value) => {
		dispatch(setVariantParams(variant.variant_id, { 
			menu: {
				...menu,
				items: [
					...menu.items.slice(0, idx),
					{
						...menu.items[idx],
						[field]: value
					},
					...menu.items.slice(idx + 1)
				]
			}
		}));
	};


	const addItem = () => {
		dispatch(setVariantParams(variant.variant_id, { 
			menu: {
				...menu,
				items: [
					...menu.items,
					{
						text: 'Menu Item',
						link: 'https://example.com'
					}
				]
			}
		}));
	};
	
	
	const deleteItem = (idx) => {
		dispatch(setVariantParams(variant.variant_id, { 
			menu: {
				...menu,
				items: [
					...menu.items.slice(0, idx),
					...menu.items.slice(idx + 1),
				]
			}
		}));
	};


	return (

		<>
			{ false && JSON.stringify(menu) }
		
			<Card>		
				<CardHeader>
					<strong>Triggers</strong>
				</CardHeader>
				<CardBody>
					<Row className="mb-3">
						<Col>
							<div
							  className="form-check form-switch form-switch-md"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="menu-kebab"
								checked={ !!getMenuValue('kebab', false) }
								onChange={ (event) => setMenuParams({
									'kebab': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="menu-kebab"
							  >
								Kebab (top-right)
							  </label>
							</div>
						</Col>
					</Row>	

					<Row className="mb-3">
						<Col>
							<div
							  className="form-check form-switch form-switch-md"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="menu-context"
								checked={ !!getMenuValue('context', false) }
								onChange={ (event) => setMenuParams({
									'context': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="menu-context"
							  >
								Context (right-click)
							  </label>
							</div>
						</Col>
					</Row>	

					<Row className="align-items-center">
						<Col xs={6}>
							<div
							  className="form-check form-switch form-switch-md"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="menu-button"
								checked={ !!getMenuValue('button', false) }
								onChange={ (event) => setMenuParams({
									'button': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="menu-button"
							  >
								Button
							  </label>
							</div>
						</Col>
						
						{ !!getMenuValue('button') && (
							<Col xs={6}>
									<Input
										type="select"
										value={ getMenuValue('button_idx') }
										onChange={ (event) => setMenuParams({
											button_idx: event.target.value
										}) }
									>
										{ (variant.params.buttons || []).map((button, idx) => {
											return (
												<option 
													key={ idx }
													value={ idx }
												>{ button.text }</option>
											)
										}) }
									</Input>
							</Col>
						) }
						
					</Row>	

				</CardBody>
			</Card>

			<Card>		
				<CardHeader>
					<strong>Menu Items</strong>
				</CardHeader>
				<CardBody>

						<Row className="align-items-center">
							<Col xs={ 5 }>
								<b>Text</b>{" "}
										<i className="mdi mdi-help-circle" id="text_tooltip" />
										<UncontrolledTooltip placement="top" target="text_tooltip">
										  Use a dash '-' to insert a horizontal line.
										</UncontrolledTooltip>

							</Col>
							<Col xs={ 5 } className="text-start">
								<b>Link</b>
							</Col>
						</Row>

						{ getMenuValue('items', []).map((item, idx) => {
							return (
								<Row key={ idx }
									className="align-items-center mt-3"
								>
									<Col xs={ 5 }>
										<Input
											type="text"
											value={ item.text }
											onChange={ (event) => updateItem(idx, 'text', event.target.value) }
										/>
									</Col>
									<Col xs={ 5 }>
									{ !!item.text && item.text != '-' && (
											<Input
												type="text"
												value={ item.link }
												onChange={ (event) => updateItem(idx, 'link', event.target.value) }
											/>
									) }
									</Col>
									<Col xs={ 2 }>
										  <Link
											to="#"
											className="text-danger"
											onClick={ () => deleteItem(idx) }
										  >
											<i className="mdi mdi-delete font-size-18" id="deletetooltip" />
											<UncontrolledTooltip placement="top" target="deletetooltip">
											  Delete
											</UncontrolledTooltip>
										  </Link>				
									</Col>						
								</Row>
							);
						}) }

						<Row className="mt-3">
							<Col xs={12}>
								<Button
									type="button"
									color="secondary"
									className="mb-2 me-2"
									onClick={ addItem }
								>
									<i className="mdi mdi-plus me-1" />
									Add
								</Button>
							</Col>
						</Row>
						

				</CardBody>
			</Card>

		</>
	)
}

export default EditMenu;