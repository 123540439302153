import {
	SET_ASSET_STATE,
	
	GET_ASSET_URL_REQUEST,
	GET_ASSET_URL_SUCCESS,

	GET_ASSET_RECENTS_REQUEST,
	GET_ASSET_RECENTS_SUCCESS,

} from "./actionTypes"

export const setAssetState = (state) => ({
  type: SET_ASSET_STATE,
  payload: state,
});

export const getAssetUrlRequest = (account_id, site_id, asset_type, content_type) => ({
  type: GET_ASSET_URL_REQUEST,
  payload: { account_id, site_id, asset_type, content_type }
});
export const getAssetUrlSuccess = (url, s3_upload_url) => ({
  type: GET_ASSET_URL_SUCCESS,
  payload: { url, s3_upload_url },
});

export const getAssetRecentsRequest = (account_id, site_id, asset_type) => ({
  type: GET_ASSET_RECENTS_REQUEST,
  payload: { account_id, site_id, asset_type }
});
export const getAssetRecentsSuccess = (recents) => ({
  type: GET_ASSET_RECENTS_SUCCESS,
  payload: recents,
});