import React, { useRef, useState, useEffect, useCallback } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
} from "reactstrap";

// actions
// dispatch actions:  box, begin, end, zoom, transition, ? manual zoom
import { useDispatch } from "react-redux";
import { setVariantParams } from "../../store/actions";


// controls for editing variant.params.magnify
//
const EditTilt = ({ variant, restartFrom }) => {
	
	const getTiltValue = (field, default_value) => {
		const tilt = variant.params.tilt || {};
	
		let rv = tilt[field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// update params
    const dispatch = useDispatch();
	const setTiltParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			tilt: {
				...variant.params.tilt,
				...params
			} 
		}));
	}

	return (
		<>
			<Card>		
				<CardHeader>
					<strong>3D Tilt - origin</strong>
				</CardHeader>
				<CardBody>
					<Row className="mb-3">
						<Col>
							<div
							  className="form-check form-switch"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="tilt_active"
								defaultChecked={ !!getTiltValue('active', false) }
								onChange={ (event) => setTiltParams({
									'active': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="tilt_active"
							  >
								Active
							  </label>
							</div>
						</Col>
					</Row>
					<Row>
						<Col className="col-6">
							<label className="form-label">Movement</label>
							<Input
								type="range"
								value={ getTiltValue('movement', 10) }
								onChange={ (event) => setTiltParams({
									'movement': parseFloat(event.target.value)
								}) }
								min={ 1 }	// ...constrain by 100
								max={ 100 } // ...constrain by 1
								className="mt-2 mb-2"
							/>
						</Col>												
					</Row>								

				</CardBody>
			</Card>

		</>
	)
}

export default EditTilt;