
// src/components/filter.
import React, { useRef, useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";

import { connect, useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";

// actions
import {
	getVariantsRequest,
	showModalImage,
	showModalVariant,
} from "../../store/actions";

//import components
import DeleteModal from "../../components/Common/DeleteModal";
import TableContainer from '../../components/Common/TableContainer';
import VariantCard from '../../components/Common/VariantCard';
import PublishingModal from "../../components/Common/PublishingModal";

import AddImageModal from '../../components/Common/AddImageModal';
import AddVariantModal from '../../components/Common/AddVariantModal';

import {
  Spinner,
  Row, Col,
  Card, CardBody,
  Alert,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";


const ImagesTable = ({ account, site, images, newImage, newVariant, loading, error, modals, router }) => {

    const dispatch = useDispatch();
  
	// kickoff
	// TODO approvals API request should be multi-account / multi-site
	useEffect(() => {
		if (account?.account_id &&  site?.site_id) {
			dispatch(getVariantsRequest(account.account_id, site.site_id));
		}
	}, [account?.account_id, site?.site_id]);


	// reference to intersection observer
	const observerRef = useRef(null);

    // Callback function to observe newly added elements
	const observeElement = (svg) => {
		if (observerRef.current) {
			observerRef.current.observe(svg);
		}
	};

	useEffect(() => {
		// Callback function to handle intersection changes
		const handleIntersection = (entries) => {
		
			const playVideo = async (_video) => {
				try {
					await _video.play();
				} catch (error) {
					// ignore
					console.error('video.play error', error);
				}			
			}
		
			entries.forEach(entry => {
				// svg
				[entry.target, ...entry.target.querySelectorAll('svg')].forEach((_svg) => {
					_svg.setCurrentTime(0);
					if (entry.isIntersecting) {
						_svg.unpauseAnimations();
					} else {
						_svg.pauseAnimations();
					}
				});
			
				// css
				entry.target.getAnimations({ subtree: true }).forEach((_animation) => {
					_animation.currentTime = 0;
					if (entry.isIntersecting) {
						_animation.play();
					} else {
						_animation.pause();
					}
				});

				// videos
				entry.target.querySelectorAll('foreignObject video').forEach((_video) => {
					_video.currentTime = 0;
					if (entry.isIntersecting) {
						playVideo(_video);
					} else {
						_video.pause();
					}
				});
				
				// intro
				entry.target.querySelectorAll('.bi-intro image').forEach((_image) => {
					if (entry.isIntersecting) {
						const _src = _image.href.baseVal;
						_image.href.baseVal = '';
						_image.href.baseVal = _src;
					}
				});	 
			});
		};

		// Create a new IntersectionObserver instance if it doesn't exist
		if (!observerRef.current) {
			observerRef.current = new IntersectionObserver(handleIntersection, { threshold: 0 });
		}

		// Cleanup function to disconnect observer when component unmounts
		return () => {
			observerRef.current.disconnect();
		};
	}, [observerRef]);

	// open AddVariant modal with params
	const [ addVariantSource, setAddVariantSource ] = useState({});
	const openAddVariant = (source_variant) => {
		setAddVariantSource(source_variant);
		dispatch(showModalVariant());
	}

	// open Publishing modal with params
	const [ publishingModalParams, setPublishingModalParams ] = useState({});
	const openPublishing = (site, variant) => {
		console.log('openPublishing', site, variant);
		setPublishingModalParams({
			site,
			variant,
			show: true
		});
	}

	// hide these variant_status
	let statusExcluded = ['deleted'];
	if (router.location.pathname == '/approvals') {
		// focus on suggested
		statusExcluded += ['active', 'inactive'];
	}

    const columns = useMemo(
        () => [
			{
				header: 'Updated',
				accessorKey: 'updated',
			},
            {
                header: 'Original Image',
		        cell: (cell) => {
					return (
						<VariantCard 
							key={ cell.row.original.baseline_variant_id }
							variant={ cell.row.original.variants[cell.row.original.baseline_variant_id] }
							imageViews={ cell.row.original.views }
							openPublishing={ openPublishing }
						/>
					)
    		    },
    		    cellStyle: {
    		    	verticalAlign: 'top',
    		    	width: '280px'
    		    }
            },
            {
                header: 'Better Images',
                accessorFn: (row) => {
                	return Object.entries(row?.variants || {})
                		.map(([variant_id, variant]) => variant.label
					).join(' ');
                },
                enableColumnFilter: false,
		        cell: (cell) => {
					return (
						<Row>
							{ Object.entries(cell.row.original.variants || {})
								.filter(([variant_id, variant]) => variant.variant_type != 'baseline' && !statusExcluded.includes(variant.variant_status))
								.map(([variant_id, variant]) => (
									<Col sm="auto" className="mb-3" key={ variant_id }>
										<VariantCard 
											variant={ variant }
											imageViews={ cell.row.original.views }
											openAddVariant={ openAddVariant }
											openPublishing={ openPublishing }
											observeElement={ observeElement }
										/>
									</Col>
							) )}
							{ router.location.pathname != '/approvals' && !cell.row.original.variants[cell.row.original.baseline_variant_id].legacy && (
								<Col sm="auto" className="mb-3" key={ 'pending' }>
									<VariantCard 
										variant={{
											...cell.row.original.variants[cell.row.original.baseline_variant_id],
											variant_status: 'coming soon',
											label: 'Another Suggestion'
										}}
										imageViews={ 0 }
										openAddVariant={ (event) => openAddVariant(cell.row.original.variants[cell.row.original.primary_variant_id]) }
									/>
								</Col>
							) }
						</Row>
					);
    		    },
    		    cellStyle: {
    		    	verticalAlign: 'top'
    		    }
            },

        ],
        [images, statusExcluded]
    );
            
	const defaultSort = [{ id: 'updated', desc: true }];
	
    document.title = "Better Images";
	
    return (
		<React.Fragment>
			<AddImageModal
				show={ modals.addImage }
				site={ site }
				image={ newImage }
			/>
			<AddVariantModal 
				show={ modals.addVariant }
				sourceVariant={ addVariantSource }
				newVariant={ newVariant }
			/>
			<PublishingModal 
				{ ...publishingModalParams }
				hideModal={ () => setPublishingModalParams((params) => ({
					...params,
					show: false
				})) }
			/>

			<div className="page-content">
				<div className="container-fluid">
					<Row>
						<Col className="col-10">
							<div className="page-title-box d-sm-flex align-items-center">
								<h4 className="mb-0 font-size-18">
									{ router.location.pathname == '/approvals' 
										? 'APPROVALS' 
										: (site?.site_type == 'web' ? site?.sitename : site?.email_subject)
									}
								</h4>
							</div>
							
							{ !!error && false && (
								<Alert color="danger" role="alert">
								  { error }
								</Alert>
							) }
						</Col>
						{ !!loading && (
							<Col className="col-2 text-end">
								<Spinner color="secondary" size="sm" className="me-2" />
							</Col>
						) }
					</Row>
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>				
									<TableContainer
										columns={columns}
										data={ Object.values(images) }
										isGlobalFilter={true}
										customPageSize={ 5 }
										isPagination={true}
										isShowingPageLength={true}
										tableClass="table align-middle table-nowrap table-hover"
										theadClass="table-light"
										paginationDiv="col-sm-12 col-md-7"
										pagination="pagination pagination-rounded justify-content-end mt-4"
										//buttonClass="btn-rounded"
										buttonName="Add Image"
										isAddButton={ router.location.pathname != '/approvals' }
										handleAddClick={ () => dispatch(showModalImage()) }
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
        </React.Fragment>
    );
}

const mapStateToProps = (state, ownProps) => {
	let { data, loading, error, modals } = state.Images;

	let account = {};
	let site = {};
	let images = data.images;

	if (ownProps.router.location.pathname == '/approvals') {
		// filter images for approval - could be from multiple accounts / sites
		images = Object.keys(images).filter(key => data.images[key].approval).reduce((acc, key) => {
			acc[key] = data.images[key];
			return acc;
		}, {});
		
	} else {
		// .../images specific to account / site
		if (ownProps.router.params.account_id) {
			account = state.Profile.data.accounts[ownProps.router.params.account_id];
		}
		if (ownProps.router.params.site_id) {
			site = state.Profile.data.sites[ownProps.router.params.site_id];
		}
	}
	
	return { account, site, images, newImage: data.newImage, newVariant: data.newVariant, loading, error, modals };
};

export default withRouter(connect(mapStateToProps)(ImagesTable));
