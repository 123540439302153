import React, { useState } from "react";

import { 
	Spinner,
	Row, Col, 
	Modal, ModalHeader, ModalBody, ModalFooter,
	Button,
	Alert
} from "reactstrap"

import withRouter from "./withRouter";

import { useDispatch } from "react-redux";

import GeneratingReport from './GeneratingReport';
// control style
import "./site.scss";

// actions
import {
	hideModalInbox,
	gotoLibrary,
} from "../../store/actions";


const AddEmailModal = ({ show, inbox, router }) => {

    const dispatch = useDispatch();

	const copyToClipboard = (value) => {
		const tempInput = document.createElement('input');
		tempInput.value = value;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand('copy');
		document.body.removeChild(tempInput);	
	}


	if (!inbox.inbox_status) {
		return (
			<Modal 
				isOpen={ show } 
				toggle={ () => dispatch(hideModalInbox()) }
			>
				<ModalHeader 
					toggle={ () => dispatch(hideModalInbox()) }
					tag="h4"
				>
					Add Email
				</ModalHeader>
				<ModalBody>
					{ inbox.error && (
						<Alert color="danger" role="alert">
						  { inbox.error }
						</Alert>
					) }
					<Spinner />
				</ModalBody>
			</Modal>
		);
		
	} else if (inbox.inbox_status == 'waiting') {
		return (
			<Modal 
				isOpen={ show } 
				toggle={ () => dispatch(hideModalInbox()) }
			>
				<ModalHeader 
					toggle={ () => dispatch(hideModalInbox()) }
					tag="h4"
				>
					Add Email
				</ModalHeader>
				<ModalBody>
					{ inbox.error && (
						<Alert color="danger" role="alert">
						  { inbox.error }
						</Alert>
					) }

					<Row>
						<Col col={12} className="mb-3">
							Send your draft email here to get started:
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col xs={9} className="mb-3 text-center">
							<b>{ inbox.inbox_email }</b>							
						</Col>
						<Col xs={3} className="mb-3">
							<Button 
								type="button" className="btn-sm"
								onClick={ copyToClipboard(inbox.inbox_email) }
							>
							 Copy
							</Button>							
						</Col>
					</Row>	


							<Row className="align-items-center mb-3">
								<Col xs={ 12 }>
									<div
									  className="form-check form-switch"
									>
									  <input
										type="checkbox"
										className="form-check-input"
										id="ai"
										defaultChecked
									  />
									  <label
										className="form-check-label"
										htmlFor="ai"
									  >
										Automatically suggest better images
									  </label>
									</div>
								</Col>
							</Row>

										
					<Row>
						<Col col={12}>
							For the best results, send directly from your email platform, rather than forwarding from your inbox.
						</Col>
					</Row>						
				</ModalBody>
			</Modal>
		);
	
	} else if (inbox.inbox_status == 'crawling' && !inbox.site?.screenshots) {
		return (
			<Modal 
				isOpen={ show } 
			>
				<ModalHeader 
					tag="h4"
				>
					Email Received
				</ModalHeader>
				<ModalBody>
					{ inbox.error && (
						<Alert color="danger" role="alert">
						  { inbox.error }
						</Alert>
					) }
					<p>Please wait. This should only take a minute...</p>
					<Spinner />
				</ModalBody>
			</Modal>
		);
	
	} else if (inbox.inbox_status == 'crawling' && inbox.site?.screenshots) {
		return (
			<Modal 
				isOpen={ show } 
			>
				<ModalHeader 
					toggle={ () => dispatch(hideModalInbox()) }
					tag="h4"
				>
					Looking for Hero Images...
				</ModalHeader>
				<ModalBody>
					{ inbox.error && (
						<Alert color="danger" role="alert">
						  { inbox.error }
						</Alert>
					) }
					<Row>
						<Col xs={12}>
							<img src={ `https://crawl.${process.env.REACT_APP_CONTROLLER}/c/${inbox.site.account_id}/${inbox.site.site_id}/main-desktop.jpeg` } width="100%" />
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		);
	
	} else if (inbox.inbox_status == 'generating' && (!inbox.site?.variants || !inbox.site.variants.every(variant => !!variant.web_assets_updated))) {
		return (
			<Modal 
				isOpen={ show } 
			>
				<ModalHeader 
					toggle={ () => dispatch(hideModalInbox()) }
					tag="h4"
				>
					Looking for Hero Images...
				</ModalHeader>
				<ModalBody>
					{ inbox.error && (
						<Alert color="danger" role="alert">
						  { inbox.error }
						</Alert>
					) }

					{ inbox.site.crawl_results?.heros && (
						<Row>
							{ inbox.site.crawl_results?.heros.map((hero, idx) => {
								return (
									<Col col={6} key={ idx }>
										<img src={ hero.src_s3 } width="100%" />
									</Col>									
								)										
							}) }
						</Row>
					) }
				</ModalBody>
			</Modal>
		);
	
	} else {
		return (
			<GeneratingReport 
				variant={ inbox.site.variants[0] }
				site_type="email"
				show={ show }
				hideModal={ () => dispatch(hideModalInbox()) }
				continueClick={ () => dispatch(gotoLibrary(inbox.site.site_id, router.navigate)) }
			/> 
		);
	}

}

export default withRouter(AddEmailModal);
