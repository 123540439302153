import {
	GET_VARIANTS_REQUEST,
	GET_VARIANTS_SUCCESS,
	GET_VARIANTS_FAILURE,
		
	VARIANT_STATUS_REQUEST,
	VARIANT_STATUS_SUCCESS,
	VARIANT_STATUS_FAILURE,
	
	SHOW_MODAL_IMAGE, HIDE_MODAL_IMAGE,
	POST_IMAGE_REQUEST,
	POST_IMAGE_SUCCESS,
	POST_IMAGE_FAILURE,
	GET_IMAGE_REQUEST,
	GET_IMAGE_SUCCESS,
	GET_IMAGE_FAILURE,
	STOP_IMAGE_POLLING,

	// better variant
	SHOW_MODAL_VARIANT, HIDE_MODAL_VARIANT,
	POST_VARIANT_REQUEST,
	POST_VARIANT_SUCCESS,
	POST_VARIANT_FAILURE,	
	GET_NEW_VARIANT_REQUEST,
	GET_NEW_VARIANT_SUCCESS,
	GET_NEW_VARIANT_FAILURE,
	STOP_VARIANT_POLLING,
	
	VARIANT_EDIT,
	VARIANT_RELOAD,
	VARIANT_CLONE,

} from "./actionTypes"

const initialState = {
	data: {
		images: {},			// all images/variants in site
		newImage: {},
		newVariant: {},
	},				
	loading: false,
	error: null,
	modals: {
		addImage: false,
		addVariant: false,
	}
};

const Images = (state = initialState, action) => {
  switch (action.type) {
    case GET_VARIANTS_REQUEST:
      return { 
      	...state, 
      	loading: true 
      };      
    case GET_VARIANTS_SUCCESS:
      return { 
      	...state, 
      	loading: false, 
      	data: {
      		...state.data,
      		images: action.payload.images,
      	}
      };      
    case GET_VARIANTS_FAILURE:
      return { 
      	...state, 
      	loading: false, 
      	error: action.payload 
      };

    case VARIANT_STATUS_REQUEST:
       return { 
      	...state, 
      	data: {
      		...state.data,
      		images: {
      			...state.data.images,
				[action.payload.image_id]: {
					...state.data.images[action.payload.image_id],
					variants: {
						...state.data.images[action.payload.image_id].variants,
						[action.payload.variant_id]: {
							...state.data.images[action.payload.image_id].variants[action.payload.variant_id],
							variant_status: 'loading'
						}
					}
				}		
      		}
      	}
      };
    case VARIANT_STATUS_SUCCESS:
       return { 
      	...state, 
      	data: {
      		...state.data,
      		images: {
				...state.data.images,
				[action.payload.image_id]: {
					...state.data.images[action.payload.image_id],
					variants: {
						...state.data.images[action.payload.image_id].variants,
						[action.payload.variant_id]: {
							...state.data.images[action.payload.image_id].variants[action.payload.variant_id],
							...action.payload.variant
						}
					}
				}
			}
      	}
      };


	case SHOW_MODAL_IMAGE:
		return {
			...state,
			data: {
				...state.data,
				newImage: {}
			},
			modals: {
				...state.modals,
				addImage: true
			}
		}
	case HIDE_MODAL_IMAGE:
		return {
			...state,
			modals: {
				...state.modals,
				addImage: false
			}
		}
	case POST_IMAGE_REQUEST:
      return { 
      	...state, 
      	data: {
      		...state.data,
      		newImage: {
      			status: 'loading'
      		}
      	}
      };      
	case POST_IMAGE_SUCCESS:
	case GET_IMAGE_SUCCESS:
      return { 
      	...state, 
      	data: {
      		...state.data,
      		newImage: action.payload.image,
      	}
      };


	case SHOW_MODAL_VARIANT:
		return {
			...state,
			data: {
				...state.data,
				newVariant: {}
			},
			modals: {
				...state.modals,
				addVariant: true
			}
		}	
	case HIDE_MODAL_VARIANT:
		return {
			...state,
			modals: {
				...state.modals,
				addVariant: false
			}
		}	

	case POST_VARIANT_REQUEST:
      return { 
      	...state, 
      	data: {
      		...state.data,
      		newVariant: {
      			status: 'loading'
      		}
      	}
      };      

	// add new variant
	case POST_VARIANT_SUCCESS:
	case GET_NEW_VARIANT_SUCCESS:
      return { 
      	...state, 
      	data: {
      		...state.data,
 			newVariant: action.payload.variant
      	}
      };

    default:
      return state;
  }
}

export default Images