import React, { useRef, useState, useEffect } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
  Nav, NavItem, NavLink,
} from "reactstrap";

import classnames from "classnames";

import { useDispatch, useSelector } from "react-redux";
import { setVariantParams, updateEditor } from "../../store/actions";


const EditTracking = ({ variant, site, restartFrom }) => {

	const tracking = variant.params.tracking || {};

	const editor = useSelector(state => state.Variant.editor);
	const dispatch = useDispatch();

	// default editor state
	useEffect(() => {
		if (typeof variant.params.tracking == 'undefined') {
			dispatch(setVariantParams(variant.variant_id, { 
				tracking: {
					view_notify_type: site?.view_notify_type,
					view_notify: site?.view_notify
				}
			}));
		}
	}, []);

	const getTrackingValue = (field, default_value) => {
		let rv = tracking[field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}
	const setTrackingParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			tracking: {
				...tracking,
				...params
			}
		}));
	}


	return (

		<>
			{ false && JSON.stringify(tracking) }
		
			<Card>		
				<CardHeader>
					<strong>Image Views</strong>
				</CardHeader>
				<CardBody>
					<Row>
						<Col>
							Trigger a receipt or notification each time this image is viewed:
						</Col>
					</Row>	
					<Row className="mt-3">
						<Col xs={ 6 }>
							<label className="form-label">View Receipts</label>
							<Input
								type="select"
								value={ tracking?.view_notify_type }
								onChange={ (event) => setTrackingParams({
									view_notify_type: event.target.value
								}) }
							>
								<option value="">-</option>
								<option value="email">Email</option>
								<option value="slack">Slack</option>
								<option value="url">URL</option>
							</Input>
						</Col>
						{ !!tracking?.view_notify_type && (
							<Col xs={ 6 }>
								{ tracking?.view_notify_type == 'email' && <label className="form-label">Recipient</label> }
								{ tracking?.view_notify_type == 'slack' && <label className="form-label">Webhook URL</label> }
								{ tracking?.view_notify_type == 'url' && <label className="form-label">URL</label> }
								<Input
									type="text"
									value={ tracking?.view_notify }
									onChange={ (event) => setTrackingParams({
										view_notify: event.target.value
									}) }
								/>
							</Col>
						) }
					</Row>
	

				</CardBody>
			</Card>

			<Card>		
				<CardHeader>
					<strong>Image Engagement</strong>
				</CardHeader>
				<CardBody>
					<Row>
						<Col>
							Capture these events to train Better Images to make better image suggestions:
						</Col>
					</Row>	
					<Row className="mt-3">
						<Col>
							<div
							  className="form-check form-switch form-switch-md"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="tracking-click"
								checked={ !!getTrackingValue('click', true) }
								onChange={ (event) => setTrackingParams({
									'click': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="tracking-click"
							  >
								Clicks
							  </label>
							</div>
						</Col>
					</Row>	
					<Row className="mt-3">
						<Col>
							<div
							  className="form-check form-switch form-switch-md"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="tracking-lead"
								checked={ !!getTrackingValue('lead', true) }
								onChange={ (event) => setTrackingParams({
									'lead': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="tracking-lead"
							  >
								Leads
							  </label>
							</div>
						</Col>
					</Row>	
					<Row className="mt-3">
						<Col>
							<div
							  className="form-check form-switch form-switch-md"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="tracking-cart"
								checked={ !!getTrackingValue('cart', true) }
								onChange={ (event) => setTrackingParams({
									'cart': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="tracking-cart"
							  >
								Carts
							  </label>
							</div>
						</Col>
					</Row>	
					<Row className="mt-3">
						<Col>
							<div
							  className="form-check form-switch form-switch-md"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="tracking-sale"
								checked={ !!getTrackingValue('sale', true) }
								onChange={ (event) => setTrackingParams({
									'sale': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="tracking-sale"
							  >
								Sales
							  </label>
							</div>
						</Col>
					</Row>	
				</CardBody>
			</Card>

		</>
	)
}

export default EditTracking;