import React, { useEffect } from "react";
import PropTypes from "prop-types";

//redux
import { useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";

// actions
import { externalAuth, authSession } from "../../store/actions";

const Auth = props => {

	//meta title
	document.title = "Redirecting...";

	const dispatch = useDispatch();

	// from cognito
	const code = props.router.params.code;
  
	// bi state
	const state = props.router.params.state;
	
	console.log('router', props.router);

	useEffect(() => {
		// get token from cognito query string
		if (code) {
			// Dispatch the action to fetch the access token
			dispatch(authSession(code, state, props.router.navigate));
  
		} else {
			// Redirect to cognito to get token      
			dispatch(externalAuth());
		}
	}, [code]);

};

Auth.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Auth);