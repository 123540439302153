// src/components/filter.
import React, { useMemo } from "react";

import { connect, useDispatch, useSelector } from "react-redux";

// actions
import { 
	selectAccount,
	showModalDelete
} from "../../store/actions";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from "../../components/Common/DeleteModal";
import TableContainer from '../../components/Common/TableContainer';

import { Link, Navigate } from "react-router-dom";
import {
  Spinner,
  Row, Col,
  Card, CardBody,
  Button,
  UncontrolledTooltip
} from "reactstrap";


//i18n
import { withTranslation } from "react-i18next";

const AccountsTable = ({ data, error, loading, modals }) => {

    //meta title
    document.title = "Better Images | Accounts";

    const dispatch = useDispatch();

	const changeAccount = (account_id) => {
		dispatch(selectAccount(account_id));
	}

    const columns = useMemo(
        () => [
			{
				header: 'Updated',
				accessorKey: 'updated',
			},
            {
                header: 'Account',
                enableColumnFilter: false,
                enableSorting: true,
                accessorKey: 'account_name',
				cell: ({ cell }) => {
				  return cell.row.original.account_name || cell.row.original.account_id;
				}

            },
            {
                header: 'Status',
                enableColumnFilter: false,
                accessorKey: 'account_status'
            },
            {
                header: 'Plan',
                enableColumnFilter: false,
                accessorKey: 'plan_id'
            },
            {
                header: 'Period',
				cell: ({ cell }) => {
				  return (
				  	<>
						{ cell.row.original.current_period_start }
						- 
						{ cell.row.original.current_period_end }
					</>
				  )
				}
            },
            {
                header: 'Views',
				cell: ({ row }) => (
					<>
						{ (row.original.current_period_views || 0).toLocaleString() } of { (row.original.quota || 0).toLocaleString() }
					</>
				)
            },
            {
                header: 'Sites',
				cell: ({ row }) => (
					  <Link
						to={ `/${row.original.account_id}/sites` }
						onClick={() => {
						  changeAccount(row.original.account_id)
						}}
					  >
						{ row.original.site_count || 0 }
					  </Link>
				)
            },
            {
                header: 'Emails',
				cell: ({ row }) => (
					  <Link
						to={ `/${row.original.account_id}/emails` }
						onClick={() => {
						  changeAccount(row.original.account_id)
						}}
					  >
						{ row.original.email_count || 0 }
					  </Link>
				)
            },
            {
                header: 'Actions',
				  cell: ({ row }) => (
					<div className="d-flex gap-3">
					  <Link
						to={ `/account/${row.original.account_id}` }
						className="text-success"
					  >
						<i className="mdi mdi-pencil font-size-18" id="edittooltip" />
						<UncontrolledTooltip placement="top" target="edittooltip">
						  Edit
						</UncontrolledTooltip>
					  </Link>
					  <Link
						to="#"
						className="text-danger"
						onClick={ () => {
							dispatch(showModalDelete('account', cell.row.original))
						} }
					  >
						<i className="mdi mdi-delete font-size-18" id="deletetooltip" />
						<UncontrolledTooltip placement="top" target="deletetooltip">
						  Delete
						</UncontrolledTooltip>
					  </Link>
					</div>
				  )
            },

        ],
        [data.account_id]
    );

	const defaultSort = [{ id: 'updated', desc: true }];
	
	// go direct to single account
	if (Object.keys(data.accounts).length == 1) {
		const account_id = Object.values(data.accounts)[0].account_id;
		return (
			<Navigate to={ `/account/${account_id}` } />
		)
	}

    return (
		<React.Fragment>
		  <DeleteModal
			show={ !!modals.delete }
			delete_type={ modals.delete.delete_type }
			delete_params={ modals.delete.delete_params }
		  />
        <div className="page-content">
            <div className="container-fluid">
					<Row>
						<Col className="col-10">
							<div className="page-title-box d-sm-flex align-items-center">
								<h4 className="mb-0 font-size-18">
									ACCOUNTS
								</h4>
							</div>
							{ !!error && (
								<Alert color="danger" role="alert">
								  { error.message }
								</Alert>
							) }
						</Col>
						{ !!loading && (
							<Col className="col-2 text-end">
								<Spinner color="secondary" size="sm" className="me-2" />
							</Col>
						) }
					</Row>

				<Row>
                { loading ? <Spinner color="primary" className='m-3' />
                	:
                	
                	<>
						{ !!error && error.message }	  
						
						<Col lg="12">
						  <Card>
							<CardBody>
				
								<TableContainer
									columns={columns}
									data={ Object.values(data?.accounts || {})}
									isGlobalFilter={true}
									customPageSize={10}
									isPagination={true}
									isShowingPageLength={true}
									tableClass="table align-middle table-nowrap table-hover"
									theadClass="table-light"
									paginationDiv="col-sm-12 col-md-7"
									pagination="pagination pagination-rounded justify-content-end mt-4"
									addLabel="Account"
									//addClick={ refreshAccounts }
									defaultSorted={ defaultSort }
								/>

							</CardBody>
						  </Card>
						</Col>
					</>
				}
				</Row>
            </div>
        </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
  const { data, error, loading, modals } = state.Profile;

  return { data, error, loading, modals };
};

export default connect(mapStatetoProps, {})(withTranslation()(AccountsTable));
