import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,

  POST_USER_REQUEST,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,

  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE
} from "./actionTypes"

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return { 
      	...state, 
      	loading: true 
      };
      
    case FETCH_USERS_SUCCESS:
      return { 
      	...state, 
      	loading: false, 
      	data: action.payload
      };
      
    case FETCH_USERS_FAILURE:
      return { 
      	...state, 
      	loading: false, 
      	error: action.payload 
      };     
      
    default:
      return state;
  }
}

export default User