// Variant Edit
export const GET_VARIANT_REQUEST = 'GET_VARIANT_REQUEST';
export const GET_VARIANT_SUCCESS = 'GET_VARIANT_SUCCESS';
export const GET_VARIANT_FAILURE = 'GET_VARIANT_FAILURE';

// update local react params
export const SET_VARIANT_PARAMS = 'SET_VARIANT_PARAMS';

export const PUT_VARIANT_REQUEST = 'PUT_VARIANT_REQUEST';
export const PUT_VARIANT_SUCCESS = 'PUT_VARIANT_SUCCESS';
export const PUT_VARIANT_FAILURE = 'PUT_VARIANT_FAILURE';

export const GET_EDGE_REQUEST = 'GET_EDGE_REQUEST';
export const GET_EDGE_SUCCESS = 'GET_EDGE_SUCCESS';
export const GET_EDGE_FAILURE = 'GET_EDGE_FAILURE';

export const UPDATE_EDITOR = 'UPDATE_EDITOR';

// heatmap
export const GET_HEATMAP_REQUEST = 'GET_HEATMAP_REQUEST';
export const GET_HEATMAP_SUCCESS = 'GET_HEATMAP_SUCCESS';
export const GET_HEATMAP_FAILURE = 'GET_HEATMAP_FAILURE';
