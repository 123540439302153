/* intro conversion to avif:

ffmpeg -r 24 -i "img%04d.png" -map 0 -map 0 -filter:0 "format=yuv420p" -filter:1 "format=yuva444p,alphaextract" -crf 34 ../channel.avif


https://www.reddit.com/r/ffmpeg/comments/1bz8e7y/animated_avif_with_transparent_background/
ffmpeg -r 24 -i "img%04d.png" -map 0 -map 0 -filter:0 "format=yuv420p" -filter:1 "format=yuva444p,alphaextract" -crf 34 curtains.avif
ffmpeg -r 8 -i "img%04d.png" -map 0 -map 0 -filter:0 "format=yuv420p" -filter:1 "format=yuva444p,alphaextract" -crf 34 curtains-slow.avif

and h265 for safari, because bug not supporting tranparency on animated avifs - check on ezgif.com
ffmpeg -r 24 -i "img%04d.png" -vf "premultiply=inplace=1" -c:v hevc_videotoolbox -require_sw 1 -allow_sw 1 -alpha_quality 0.9 -vtag hvc1 curtains-hevc.mp4

video memes
*/

import React, { useRef, useState, useEffect } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
  Progress,
  Alert
} from "reactstrap";

import AssetLibrary from '../../components/Common/AssetLibrary';


// actions
import { useDispatch } from "react-redux";
import { postVariantIntroRequest, setVariantParams, updateEditor } from "../../store/actions";

// control style
import "./zoom.scss";

const fx_durations = {
	'curtains': 5.25,
	'channel': 2.5
}

// controls for editing variant.params.intro
//
const EditIntro = ({ variant, restartFrom, saveVariant, setPolling }) => {

	const intro = variant.params?.intro || {};

	// update params
    const dispatch = useDispatch();

	// default editor state
	useEffect(() => {
		if (!intro.fx) {
			dispatch(setVariantParams(variant.variant_id, { 
				intro: {
					fx: 'curtains',
					fx_duration: fx_durations['curtains']
				}
			}));
		}
	}, []);

	const setParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			intro: {
				...intro,
				...params
			} 
		}));
		restartFrom(0);
	}

	function getIntroValue(field, default_value) {
		let rv = intro[field];

		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}
	
	
	// update params.intro and save so backend kicks off ai generation
	// and start polling
	const requestIntro = (camera) => {		
		// update intro
		setParams({
			status: 'requested'
		});
	
		// signal backend to resize source for stability i2v
		saveVariant({
			...variant,
			params: {
				...variant.params,
				intro: {
					...variant.params.intro,
					status: 'requested'
				}
			}
		});
	}

	// generate 90s progress bar
	const [progress, setProgress] = useState(0);
	useEffect(() => {
		if (['processing'].includes(intro.status)) {
			setPolling(true);
		} else {
			setPolling(false);
		}

		if (intro.status == 'ready') {
			restartFrom(0);
		}
		
		if (intro.status != 'processing') {
			setProgress(0);
			return;
		}
	
		// processing...
		const interval = setInterval(() => {
			setProgress(prevProgress => {
				const newProgress = prevProgress + 1;
				return newProgress >= 100 ? 100 : newProgress;
			});
		}, 900); // Adjust the interval based on the desired animation duration (90 seconds)

		return () => clearInterval(interval);
	}, [intro.status]);
	
	
	return (
		<>
			{ !!window.matchMedia && window.matchMedia('(prefers-reduced-motion)').matches && (
				<Alert color="info" role="alert">
					Intro is currently restricted on this device because the device is configured for <Link
						to="https://www.boia.org/blog/what-to-know-about-the-css-prefers-reduced-motion-feature"
						target="_blank"
					>
						Reduced Motion					
					</Link>. Disable this setting to see your Intro.
				</Alert>			
			) }

			<Card>		
				<CardHeader>
					<strong>Type</strong>
				</CardHeader>
				<CardBody>
					<Row>
						<Col>
							<div className="form-check mb-2">
							  <input
								className="form-check-input"
								type="radio"
								name="intro_type"
								id="intro_type_camera"
								checked={ getIntroValue('intro_type') == 'camera' }
								onChange={ (event) => setParams({ 'intro_type': 'camera' }) }
							  />
							  <label
								className="form-check-label"
								htmlFor="intro_type_camera"
							  >
								AI-Generated Camera Motion
							  </label>
							</div>

							<div className="form-check mb-2">
							  <input
								className="form-check-input"
								type="radio"
								name="intro_type"
								id="intro_type_manual"
								checked={ getIntroValue('intro_type') == 'manual' }
								onChange={ (event) => setParams({ 'intro_type': 'manual' }) }
							  />
							  <label
								className="form-check-label"
								htmlFor="intro_type_manual"
							  >
								Bumper Video
							  </label>
							</div>

							<div className="form-check mb-2">
							  <input
								className="form-check-input"
								type="radio"
								name="intro_type"
								id="intro_type_fx"
								checked={ getIntroValue('intro_type') == 'fx' }
								onChange={ (event) => setParams({ 'intro_type': 'fx' }) }
							  />
							  <label
								className="form-check-label"
								htmlFor="intro_type_fx"
							  >
								Special Effects
							  </label>
							</div>

							<div className="form-check">
							  <input
								className="form-check-input"
								type="radio"
								name="intro_type"
								id="intro_type_none"
								checked={ !getIntroValue('intro_type') }
								onChange={ (event) => setParams({ 'intro_type': null }) }
							  />
							  <label
								className="form-check-label"
								htmlFor="intro_type_none"
							  >
								None
							  </label>
							</div>
						</Col>
					</Row>
				</CardBody>
			</Card>
			
			{ getIntroValue('intro_type') == 'camera' && (
				<Card>		
					<CardHeader>
						<strong>Camera Motion</strong>
					</CardHeader>
					<CardBody>
						<Row className="mb-3">
							<Col>
								<Input
									type="select"
									value={ getIntroValue('camera') }
									onChange={ (event) => setParams({
										'camera': event.target.value 
									}) }
								>
									<option value="zoom">Zoom In</option>
									<option value="dolly">Dolly Zoom</option>
									<option value="panleft">Pan Left</option>
									<option value="panright">Pan Right</option>
									<option value="arc">Arc Shot</option>
									<option value="crane">Crane Shot</option>
									<option value="drone">Drone Shot</option>
								</Input>
							</Col>
						</Row>
						<Row className="align-items-center mb-3">
							<Col className="col-6">
								<label className="form-label">Motion</label>
								<Input
									type="range"
									min={ 1 }
									max={ 255 }
									value={ getIntroValue('motion_bucket_id', 127) }
									onChange={ (event) => setParams({
										motion_bucket_id: parseInt(event.target.value)
									}) }
								/>
							</Col>
						</Row>

						{ !['requested', 'processing'].includes(getIntroValue('status')) && (
							<Row>
								<Col>
									<Button
										type="button"
										color="primary"
										className="btn-rounded"
										onClick={ (event) => requestIntro(event.target.value) }
										disabled={ ['requested', 'processing'].includes(getIntroValue('status')) }
									>
										Generate...
									</Button>
								</Col>
							</Row>
						) }

						{ getIntroValue('status') == 'processing' && (
							<Row>
								<Col>
									<Progress 
										value={progress}
										style={{
											height: '20px'
										}}
									>
										{progress}%
									</Progress>
								</Col>
							</Row>
						) }

						{ getIntroValue('status') == 'error' && (
							<Row>
								<Col>
									{ getIntroValue('error') }
								</Col>
							</Row>
						) }

					</CardBody>
				</Card>			
			) }


			{ getIntroValue('intro_type') == 'fx' && (
				<Card>		
					<CardHeader>
						<strong>Special Effects</strong>
					</CardHeader>
					<CardBody>
						<Row className="mb-3">
							<Col>
								<Input
									type="select"
									value={ getIntroValue('fx') }
									onChange={ (event) => setParams({
										fx: event.target.value,
										fx_duration: fx_durations[event.target.value]
									}) }
								>
									<option value="curtains">Red Curtains</option>
									<option value="channel">Channel Storm</option>
								</Input>
							</Col>
						</Row>
					</CardBody>
				</Card>			
			) }

			{ getIntroValue('intro_type') == 'manual' && (
				<Card>		
					<CardHeader>
						<strong>Bumper Video</strong>
					</CardHeader>
					<CardBody>
					
						{ false && JSON.stringify(intro) }

						{ !getIntroValue('manual_src') && (							
							<Row className="mb-3">
								<Col>
									<AssetLibrary
										account_id={ variant.account_id }
										site_id={ variant.site_id }
										image_id={ variant.image_id }
										assetType='intro'
										acceptMatch='video/*'
										onResult={ (result) => setParams({
											manual_src: result.source_url,
											manual_src_duration: result.duration,
											status: 'dirty'
										}) }
									/>
								</Col>
							</Row>
						) }

						{ !!getIntroValue('manual_src') && (							
							<Row className="mb-3 align-items-center">
								<Col xs={ 6 }>
									<video
										src={ getIntroValue('manual_src') }
										autoPlay="autoplay" muted="muted" playsInline="playsinline" loop="loop"
										style={{
											width: '100%',
										}}
									/>

								</Col>
								<Col xs={ 6 }>
									<Button
										type="button"
										color="secondary"
										className="btn"
										onClick={ (event) => setParams({
											manual_src: null,
											manual_src_duration: null,
											status: null
										}) }
									>
										Change Video
									</Button>
								</Col>
							</Row>
						) }

					</CardBody>
				</Card>			
			) }

		</>
	)
}

export default EditIntro;