import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
import { fetchProfileRequest, putAccountRequest, redirectBilling } from "../../store/actions";

const PLANS = {
	'lifetime_1' : {
		'title': 'Lifetime Tier 1',
		'price_usd': 49,
		'quotas': { 'generate': 50, 'views': 50000, 'sites': 1, 'users': 1 },
		'max_width' : 1280,
	},
	'lifetime_2' : {
		'title': 'Lifetime Tier 2',
		'price_usd': 89,
		'quotas': { 'generate': 100, 'views': 100000, 'sites': 2, 'users': 2 },
		'max_width' : 1280,
	},
	'lifetime_3' : {
		'title': 'Lifetime Tier 3',
		'price_usd': 199,
		'quotas': { 'generate': 200, 'views': 300000, 'sites': 10, 'users': 5 },
		'max_width' : 1920,
	},
	'lifetime_4' : {
		'title': 'Lifetime Tier 4',
		'price_usd': 349,
		'quotas': { 'generate': 300, 'views': 600000, 'sites': 20, 'users': 10 },
		'max_width' : 1920,
	},
	'lifetime_5' : {
		'title': 'Lifetime Tier 5 (Agency)',
		'price_usd': 499,
		'quotas': { 'generate': 600, 'views': 1500000, 'sites': 50, 'users': -1 },
		'max_width' : 2560,
	},

	'enhance': {
		'title': 'Enhance',
		'quotas': { 'generate': 100, 'views': 100000, 'sites': 2, 'users': 1 },
		'max_width' : 1280,
	},
	'optimizer': {
		'title': 'The Optimizer',
		'quotas': { 'generate': 200, 'views': 500000, 'sites': 5, 'users': 5 },
		'max_width' : 1920,
	},

	'free': {
		'title': 'Guest',
		'headline': '1,000 free image views per month, then images are branded',
		'quotas': { 'generate': 100, 'views': 1000, 'sites': 1, 'users': 1 },
		'max_width' : 1280,
		'meter_event': 'guest_image_views',
	},
	'starter': {
		'title': 'Starter',
		'headline': '10,000 image views per month, then $0.50 per 1,000',
		'quotas': { 'generate': 100, 'views': 10000, 'sites': 1, 'users': 1 },
		'max_width' : 1920,
		'meter_event': 'starter_image_views',
	},
	'champion': {
		'title': 'Champion',
		'headline': '100,000 image views per month, then $0.40 per 1,000',
		'quotas': { 'generate': 1000, 'views': 100000, 'sites': 1, 'users': 1 },
		'max_width' : 1920,
		'meter_event': 'champion_image_views',
	},
	'team': {
		'title': 'Team',
		'headline': '1,000,000 image views per month, then $0.30 per 1,000',
		'quotas': { 'generate': 1000, 'views': 1000000, 'sites': 10, 'users': 10 },
		'max_width' : 2560,
		'meter_event': 'team_image_views',
	},
	'enterprise': {
		'title': 'Enterprise',
		'quotas': { 'generate': 1000, 'views': 1000000000, 'sites': 1000, 'users': 1000  },
		'max_width' : 3840,
		'meter_event': 'enterprise_image_views',
	}
}

const Account = props => {

	const dispatch = useDispatch();

	const profile = useSelector(state => state.Profile.data);
	const loading = useSelector(state => state.Profile.loading);

	// default to profile
	const [ account, setAccount ] = useState(null);

	// get account from url
	useEffect(() => {
		setAccount(Object.values(profile.accounts).find(({ account_id }) => account_id == props.router.params.account_id));  	
	}, [profile.accounts, props.router.params.account_id]);

	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			name: account?.account_name || '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required("Please enter account name"),
		}),
		onSubmit: (values) => {
			dispatch(putAccountRequest(account.account_id, { account_name: values.name }));
		}
	});

	const plan_config = useMemo(
		() => {
			return PLANS[account?.plan_id] || {}
		}, [account?.plan_id]
	)

	let error = null;
	let success = null;

	//meta title
	document.title = "Better Images | Account";
	
	const FormatDate = ({ dateString }) => {
		// Function to format yyyymmdd to yyyy-mm-dd
		const formatDate = (dateString) => {
			if (!dateString || dateString.length !== 8) {
				return ''; // Return an empty string if the input is invalid
			}

			const year = dateString.substring(0, 4);
			const month = dateString.substring(4, 6);
			const day = dateString.substring(6, 8);

			return `${year}-${month}-${day}`;
		};

		return (
			<span>
				{formatDate(dateString)}
			</span>
		);
	};

  return (
    <React.Fragment>
      <div className="page-content">
      
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Better Images" breadcrumbItem="Account" />

			  <Row>
				<Col lg="12">
				  {error ? <Alert color="danger">{error}</Alert> : null}
				  {success ? <Alert color="success">{success}</Alert> : null}
				</Col>
			  </Row>

			{ !account && (
				<Spinner color="secondary" size="sm" className="me-2" />
			) }

			{ !!account && (
				<>
				
				  <Row>
					<Col sm="6">

					  <Card>
						<CardBody>
							<div className="form-group">
							  <Label className="form-label">ID: </Label>
							  { ' ' }
							  { account?.account_id }
							</div>
			
						  <Form
							className="form-horizontal"
							onSubmit={(e) => {
							  e.preventDefault();
							  validation.handleSubmit();
							  return false;
							}}
						  >
							<Row>
								<Col>
									<div className="form-group">
									  <Label className="form-label">Name</Label>
									  <Input
										name="name"
										className="form-control"
										placeholder="Enter Name"
										type="text"
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										value={validation.values.name || ''}
										invalid={
										  validation.touched.name && validation.errors.name ? true : false
										}
									  />
									  {validation.touched.name && validation.errors.name ? (
										<FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
									  ) : null}
									  <Input name="account_id" value={ account?.account_id } type="hidden" />
									</div>
								</Col>
							</Row>

							<div className="mt-3">
							  <Button type="submit" color="primary">
								Save
							  </Button>
							</div>
						  </Form>

						</CardBody>
					  </Card>
		  
					</Col>


					<Col sm="6">

					  <Card>
						<CardBody>
							<div className="form-group">
							  <Label className="form-label">Plan: </Label>
							  { ' ' }
							  { plan_config.title }
							</div>
							{ !!plan_config.headline && (
								<div className="form-group">
								  <Label className="form-label">Quota: </Label>
								  { ' ' }
								  { plan_config.headline }
								</div>
							) }
							
							{ account?.current_period_start && (
								<div className="form-group">
								  <Label className="form-label">Period: </Label>
								  { ' ' }
										<FormatDate 
											dateString={ account?.current_period_start }
										/> to 
										{ ' ' }
										<FormatDate 
											dateString={ account?.current_period_end }
										/> 
								</div>
							) }

							<div className="form-group">
							  <Label className="form-label">Views: </Label>
							  { ' ' }
							  { (account?.current_period_views || 0).toLocaleString() }
							</div>

								{ !!account?.stripe_customer_id && (
									  <Button 
										color="primary"
										disabled={ !!loading }
										onClick={ () => dispatch(redirectBilling(account.account_id)) }
									  >
											{ loading ? <Spinner size="sm" /> : 'Change Plan' }
									  </Button>
								) }
								
							{ account?.plan_id == 'free' && (
								<div className="mt-2">
								  Paid plans start at $5 per month.
								</div>
							) }
						
						</CardBody>
					  </Card>
		  
					</Col>

				  </Row>
		  
				</>			
			) }

			
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Account);
