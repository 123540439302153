import {
	FETCH_PROFILE_REQUEST,
	FETCH_PROFILE_SUCCESS,
	FETCH_PROFILE_FAILURE,

	GET_SPEED_REQUEST,
	GET_SPEED_SUCCESS,
	GET_SPEED_FAILURE,	

	SELECT_ACCOUNT,
	SELECT_SITE,
	GOTO_LIBRARY,

	SHOW_MODAL_INBOX, HIDE_MODAL_INBOX,
	POST_INBOX_REQUEST,
	POST_INBOX_SUCCESS,
	POST_INBOX_FAILURE,
	GET_INBOX_REQUEST,
	GET_INBOX_SUCCESS,
	GET_INBOX_FAILURE,
	STOP_INBOX_POLLING,

	SHOW_MODAL_SITE, HIDE_MODAL_SITE,
	POST_SITE_REQUEST,
	POST_SITE_SUCCESS,
	POST_SITE_FAILURE,
	GET_SITE_REQUEST,
	GET_SITE_SUCCESS,
	GET_SITE_FAILURE,
	STOP_SITE_POLLING,
	
	SHOW_MODAL_DELETE, HIDE_MODAL_DELETE,

	PUT_ACCOUNT_REQUEST, PUT_ACCOUNT_SUCCESS, PUT_ACCOUNT_FAILURE,
	PUT_SITE_REQUEST, PUT_SITE_SUCCESS, PUT_SITE_FAILURE,	
	
	UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE,
	
	REDIRECT_BILLING,

} from "./actionTypes"

const initialState = {
	data: {
		user: null,
		accounts: {}, 
		account_id: localStorage.getItem("account_id"),
		sites: {}, 
		site_id: localStorage.getItem("site_id"),
		inbox: {},
		site: {},	// newSite from adding, or chosen site for editing modal
		speed: {}
	},
	loading: false,
	error: null,
	modals: {
		site: false,
		inbox: false,
		delete: false,
	}
};

const Profile = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE_REQUEST:
      return { 
      	...state, 
      	loading: true 
      };
      
    case FETCH_PROFILE_SUCCESS:
	case GET_SPEED_SUCCESS:
      return { 
      	...state, 
      	loading: false, 
      	data: {
      		...state.data,
      		...action.payload 
      	}
      };
      
    case FETCH_PROFILE_FAILURE:
      return { 
      	...state, 
      	loading: false, 
      	error: action.payload 
      };
      
    case SELECT_ACCOUNT:
      return {
      	...state,
      	data: {
      		...state.data,
      		account_id: action.payload
      	}
      }

    case SELECT_SITE:
      return {
      	...state,
      	data: {
      		...state.data,
      		site_id: action.payload
      	}
      }

	case SHOW_MODAL_INBOX:
		return {
			...state,
			data: {
				...state.data,
				inbox: {}
			},
			modals: {
				...state.modals,
				inbox: true
			}
		}

	case HIDE_MODAL_INBOX:
		return {
			...state,
			modals: {
				...state.modals,
				inbox: false
			}
		}

	case POST_INBOX_REQUEST:
      return {
      	...state,
      	data: {
      		...state.data,
      		inbox: {}
      	}
      }

	case POST_INBOX_SUCCESS:
	case GET_INBOX_SUCCESS:
		return {
			...state,
			data: {
				...state.data,
				inbox: {
					...action.payload.inbox,
				}
			}   	
		}

	case POST_INBOX_FAILURE:
	case GET_INBOX_FAILURE:
		return {
			...state,
			data: {
				...state.data,
				inbox: {
					...state.data.inbox,
					error: action.payload.error
				}
			}   	
		}


	case SHOW_MODAL_SITE:
		return {
			...state,
			data: {
				...state.data,
				site: {}
			},
			modals: {
				...state.modals,
				site: true
			}
		}

	case HIDE_MODAL_SITE:
		return {
			...state,
			modals: {
				...state.modals,
				site: false
			}
		}

	case POST_SITE_REQUEST:
		return {
			...state,
			data: {
				...state.data,
				site: {
					status: 'loading'
				}
			}   	
		}

	case POST_SITE_SUCCESS:
	case GET_SITE_SUCCESS:
		return {
			...state,
			data: {
				...state.data,
				site: {
					...action.payload.site,
				}
			}   	
		}

	case POST_SITE_FAILURE:
	case GET_SITE_FAILURE:
		return {
			...state,
			data: {
				...state.data,
				site: {
					...state.data.site,
					error: action.payload.error
				}
			}   	
		}

	case SHOW_MODAL_DELETE:
		return {
			...state,
			modals: {
				...state.modals,
				delete: {
					delete_type: action.payload.delete_type,
					delete_params: action.payload.delete_params
				}
			}
		}

	case HIDE_MODAL_DELETE:
		return {
			...state,
			modals: {
				...state.modals,
				delete: false
			}
		}

	case UPDATE_PROFILE_SUCCESS:
		return {
			...state,
			data: {
				...state.data,
				user: {
					...state.data.user,
					...action.payload
				}
			}
		}
		
	case REDIRECT_BILLING:
		return {
			...state,
			loading: true
		}
      
    default:
      return state;
  }
}

export default Profile