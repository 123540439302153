// get profile
export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const GET_SPEED_REQUEST = 'GET_SPEED_REQUEST';
export const GET_SPEED_SUCCESS = 'GET_SPEED_SUCCESS';
export const GET_SPEED_FAILURE = 'GET_SPEED_FAILURE';

export const SELECT_ACCOUNT = 'SELECT_ACCOUNT';
export const SELECT_SITE = 'SELECT_SITE';
export const GOTO_LIBRARY = 'GOTO_LIBRARY';

// add email
export const SHOW_MODAL_INBOX = 'SHOW_MODAL_INBOX';
export const HIDE_MODAL_INBOX = 'HIDE_MODAL_INBOX';
export const POST_INBOX_REQUEST = 'POST_INBOX_REQUEST';
export const POST_INBOX_SUCCESS = 'POST_INBOX_SUCCESS';
export const POST_INBOX_FAILURE = 'POST_INBOX_FAILURE';
export const GET_INBOX_REQUEST = 'GET_INBOX_REQUEST';
export const GET_INBOX_SUCCESS = 'GET_INBOX_SUCCESS';
export const GET_INBOX_FAILURE = 'GET_INBOX_FAILURE';
export const STOP_INBOX_POLLING = 'STOP_INBOX_POLLING';

// add site
export const SHOW_MODAL_SITE = 'SHOW_MODAL_SITE';
export const HIDE_MODAL_SITE = 'HIDE_MODAL_SITE';
export const POST_SITE_REQUEST = 'POST_SITE_REQUEST';
export const POST_SITE_SUCCESS = 'POST_SITE_SUCCESS';
export const POST_SITE_FAILURE = 'POST_SITE_FAILURE';
export const GET_SITE_REQUEST = 'GET_SITE_REQUEST';
export const GET_SITE_SUCCESS = 'GET_SITE_SUCCESS';
export const GET_SITE_FAILURE = 'GET_SITE_FAILURE';
export const STOP_SITE_POLLING = 'STOP_SITE_POLLING';

// delete account or site
export const SHOW_MODAL_DELETE = 'SHOW_MODAL_DELETE';
export const HIDE_MODAL_DELETE = 'HIDE_MODAL_DELETE';

export const PUT_ACCOUNT_REQUEST = 'PUT_ACCOUNT_REQUEST';
export const PUT_ACCOUNT_SUCCESS = 'PUT_ACCOUNT_SUCCESS';
export const PUT_ACCOUNT_FAILURE = 'PUT_ACCOUNT_FAILURE';

export const PUT_SITE_REQUEST = 'PUT_SITE_REQUEST';
export const PUT_SITE_SUCCESS = 'PUT_SITE_SUCCESS';
export const PUT_SITE_FAILURE = 'PUT_SITE_FAILURE';

// update profile
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

// goto stripe portal
export const REDIRECT_BILLING = 'REDIRECT_BILLING';