import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Auth from "../pages/Authentication/Auth";
import Logout from "../pages/Authentication/Logout";

import AccountsTable from "../pages/Accounts/AccountsTable"
import Account from "../pages/Accounts/Account"

import SitesTable from "../pages/Sites/SitesTable"
import EmailsTable from "../pages/Emails/EmailsTable"
import UsersTable from "../pages/Users/UsersTable"

import ImagesTable from "../pages/Images/ImagesTable"
import ImageResults from "../pages/Image/ImageResults"
import VariantEdit from "../pages/Variant/VariantEdit"

//import GetStarted from "../pages/Images/Accounts/GetStarted"

import { useSelector, useDispatch } from "react-redux";

const GetStarted = () => {
	const profile = useSelector(state => state.Profile.data);

	// from localhost
	if (profile.account_id && profile.site_id) {
		return (
			<Navigate to={ `/${profile.account_id}/${profile.site_id}/images` } />
		);

	} else {
		// first time
		return (
			<Navigate to={ `/accounts` } />
		);
	}
}

const authProtectedRoutes = [
	{ path: "/logout", component: <Logout /> },

	// profile
	{ path: "/profile", component: <UserProfile /> },

	{ path: "/accounts", component: <AccountsTable /> },
	{ path: "/account/:account_id", component: <Account /> },

	{ path: "/approvals", component: <ImagesTable /> },

	{ path: "/user/:user_id", component: <UserProfile /> },

	{ path: "/:account_id/sites", component: <SitesTable /> },
	{ path: "/:account_id/emails", component: <EmailsTable /> },

	{ path: "/:account_id/:site_id/users", component: <UsersTable /> },

	{ path: "/:account_id/:site_id/images", component: <ImagesTable /> },

	{ path: "/:account_id/:site_id/:image_id/results", component: <ImageResults /> },
	
	{ path: "/:account_id/:site_id/:image_id/:variant_id/edit", component: <VariantEdit /> },

	// this route should be at the end of all other routes
	// eslint-disable-next-line react/display-name
	{
		path: "/",
		exact: true,
		component: <GetStarted />,
	},
];

const publicRoutes = [
	{ path: "/auth", component: <Auth /> },
];

export { authProtectedRoutes, publicRoutes };
