import { all, fork } from "redux-saga/effects";

import LayoutSaga from "./layout/saga";
import AuthSaga from "./auth/saga";
import ProfileSaga from "./profile/saga";
import UserSaga from "./user/saga";

import ImagesSaga from "./images/saga";
import VariantSaga from "./variant/saga";

import AssetSaga from "./asset/saga";
import PublishSaga from "./publish/saga";

export default function* rootSaga() {
	yield all([
		fork(LayoutSaga),
		fork(AuthSaga),
		fork(ProfileSaga),
		fork(UserSaga),
		fork(ImagesSaga),
		fork(VariantSaga),
		fork(AssetSaga),
		fork(PublishSaga),
	]);
}
