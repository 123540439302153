// redirect to cognito
export const EXTERNAL_AUTH = 'EXTERNAL_AUTH'

// get access token from cognito code
export const AUTH_SESSION = 'AUTH_SESSION'

// store session
export const SESSION_SUCCESS = 'SESSION_SUCCESS'

// refresh access token
export const REFRESH_SESSION = 'REFRESH_SESSION'
export const QUEUE_RESET = 'QUEUE_RESET'
export const QUEUE_ADD = 'QUEUE_ADD'

// logout
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

// canva
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'