import React, { useState, useEffect, useRef } from "react"

import { Link } from "react-router-dom";
import { 
  Spinner,
  Container, Row, Col,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  Button,
  Input,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { updateEditor } from "../../store/actions";

import FontPicker from "font-picker-react";

// local cache extracted from https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=...&capability=VF
// family: variable, regular, italic
import fontData from './webfonts.json';
const fontDataKeys = Object.keys(fontData);

/*
import fontData from './webfonts-raw.json';
let fontObj = {};
for (const font of fontData.items.slice(0, 500)) {
	fontObj[font.family] = {}
	if (font.axes) {
		for (const axis of font.axes) {
			if (axis.tag == 'wght') {
				fontObj[font.family].variable = true;
				fontObj[font.family].regular = [axis.start, axis.end];
				if (font.variants.includes('italic')) fontObj[font.family].italic = [axis.start, axis.end];
				break;
			}
		}
		
	}
	// fallback for non-variable fonts
	if (!fontObj[font.family].variable) {
		fontObj[font.family].regular = font.variants.map((variant) => {
			if (!variant.includes('italic')) return parseInt(variant.replace('regular', '400'));
		}).filter((weight) => !!weight);
		
		if (font.variants.includes('italic')) {
			fontObj[font.family].regular = font.variants.map((variant) => {
				if (variant.includes('italic')) return parseInt(variant.replace('italic', '') || '400');
			}).filter((weight) => !!weight);
		}
	}
}
console.log(JSON.stringify(fontObj, null, '\t'));
*/

// categories to help user
let fontVibes = {
	'Anger': ["Anton", "Bebas Neue", "Oswald", "Roboto Condensed", "Russo One", "Alfa Slab One", "Black Ops One", "Bangers", "Rock Salt", "Amaranth"],
	'Authority': ["Roboto", "Montserrat", "Noto Sans", "Oswald", "Roboto Slab", "Playfair Display", "Ubuntu", "Merriweather", "Lato", "Raleway"],
	'Casual': ["Pacifico", "Lobster", "Yellowtail", "Indie Flower", "Dancing Script", "Amatic SC", "Cookie", "Satisfy", "Caveat", "Permanent Marker"],
	'Confident': ["Roboto", "Lato", "Oswald", "Merriweather", "Montserrat", "Ubuntu", "Raleway", "Nunito", "Open Sans", "Playfair Display"],
	'Contemporary': ["Fira Sans", "Lato", "Nunito Sans", "Raleway", "Roboto", "Open Sans", "Poppins", "Montserrat", "Inter", "Work Sans"],
	'Custom': [],
	'Daring': ["Bebas Neue", "Anton", "Russo One", "Alfa Slab One", "Oswald", "Rock Salt", "Black Ops One", "Bangers", "Fjalla One", "Teko"],
	'Excited': ["Bangers", "Lobster", "Pacifico", "Satisfy", "Dancing Script", "Amatic SC", "Permanent Marker", "Cookie", "Yellowtail", "Indie Flower"],
	'Fashion': ["Playfair Display", "Raleway", "Lato", "Oswald", "Montserrat", "Nunito Sans", "Roboto Mono", "Poppins", "Quicksand", "Josefin Sans"],
	'Fear': ["Creepster", "Black Ops One", "Limelight", "Alfa Slab One", "Anton", "Bangers", "Rock Salt", "Russo One", "Cinzel", "Fjalla One"],
	'Formal': ["Playfair Display", "Merriweather", "Roboto Slab", "Noto Serif", "PT Serif", "Lora", "Libre Baskerville", "EB Garamond", "Crimson Text", "Alegreya"],
	'Friendly': ["Nunito", "Quicksand", "Rubik", "Poppins", "Lato", "Open Sans", "PT Sans", "Comfortaa", "Mukta", "Work Sans"],
	'Fun': ["Pacifico", "Lobster", "Bangers", "Permanent Marker", "Amatic SC", "Dancing Script", "Satisfy", "Cookie", "Yellowtail", "Indie Flower"],
	'Futuristic': ["Orbitron", "Exo", "Exo 2", "Rajdhani", "Teko", "Nunito Sans", "Space Grotesk", "Saira Condensed", "Electrolize", "Titillium Web"],
	'Graceful': ["Playfair Display", "Lora", "Raleway", "Merriweather", "Noto Serif", "PT Serif", "Libre Baskerville", "Alegreya", "Crimson Text", "Vollkorn"],
	'Handwriting': ["Dancing Script", "Pacifico", "Amatic SC", "Indie Flower", "Lobster", "Cookie", "Satisfy", "Caveat", "Permanent Marker", "Yellowtail"],
	'Happy': ["Pacifico", "Lobster", "Yellowtail", "Indie Flower", "Dancing Script", "Amatic SC", "Cookie", "Satisfy", "Caveat", "Permanent Marker"],
	'Hope': ["Sunflower", "Gloria Hallelujah", "Merriweather", "Noto Serif", "Lora", "Rubik", "Ubuntu", "Raleway", "Satisfy", "Caveat"],
	'Icons': ['Material Icons', 'Material Icons Outlined', 'Material Icons', 'Material Icons Round', 'Material Icons Sharp', 'Material Icons Two Tone'],
	'Informal': ["Indie Flower", "Pacifico", "Lobster", "Amatic SC", "Permanent Marker", "Dancing Script", "Cookie", "Satisfy", "Caveat", "Yellowtail"],
	'Inspiration': ["Playfair Display", "Raleway", "Merriweather", "Lora", "Noto Serif", "PT Serif", "EB Garamond", "Cormorant Garamond", "Libre Baskerville", "Roboto Slab"],
	'Love': ["Dancing Script", "Great Vibes", "Pacifico", "Satisfy", "Cookie", "Lobster", "Parisienne", "Yellowtail", "Allura", "Amatic SC"],
	'Luxury': ["Playfair Display", "Merriweather", "Lora", "Raleway", "Rubik", "Noto Serif", "PT Serif", "Libre Baskerville", "EB Garamond", "Cormorant Garamond"],
	'Monospace': ["Roboto Mono", "Source Code Pro", "Inconsolata", "IBM Plex Mono", "Fira Mono", "Courier Prime", "PT Mono", "Space Mono", "JetBrains Mono", "Noto Sans Mono"],
	'Old': ["Playfair Display", "Libre Baskerville", "Merriweather", "Cormorant Garamond", "EB Garamond", "Alice", "Lora", "Old Standard TT", "Cinzel", "Cardo"],
	'Peace': ["Lora", "Merriweather", "Noto Serif", "PT Serif", "Crimson Text", "Libre Baskerville", "EB Garamond", "Neuton", "Alice", "Vollkorn"],
	'Personal': ["Dancing Script", "Indie Flower", "Amatic SC", "Lobster", "Pacifico", "Yellowtail", "Cookie", "Satisfy", "Caveat", "Permanent Marker"],
	'Playful': ["Pacifico", "Lobster", "Indie Flower", "Amatic SC", "Baloo 2", "Cookie", "Pattaya", "Satisfy", "Caveat", "Permanent Marker"],
	'Popular': ["Roboto","Open Sans","Noto Sans JP","Montserrat","Poppins","Lato","Inter","Roboto Condensed","Material Icons","Oswald","Roboto Mono","Noto Sans","Raleway"],
	'Professional': ["Roboto", "Open Sans", "Lato", "Montserrat", "Noto Sans", "Ubuntu", "Merriweather", "Roboto Slab", "PT Sans", "Work Sans"],
	'Real Estate': ["Playfair Display", "Roboto Slab", "Merriweather", "Noto Serif", "PT Serif", "Lora", "Libre Baskerville", "Crimson Text", "Bree Serif", "EB Garamond"],
	'Retro': ["Playball", "Lobster", "Pacifico", "Bangers", "Press Start 2P", "Rock Salt", "Yellowtail", "Dancing Script", "Bebas Neue", "Anton"],
	'Sad': ["Libre Baskerville", "Merriweather", "Crimson Text", "Lora", "Noto Serif", "PT Serif", "Bitter", "Cabin", "Dosis", "Inconsolata"],
	'Script': ["Pacifico", "Dancing Script", "Lobster", "Yellowtail", "Indie Flower", "Amatic SC", "Cookie", "Satisfy", "Caveat", "Permanent Marker"],
	'Sexy': ["Playfair Display", "Lobster", "Pacifico", "Dancing Script", "Yellowtail", "Cookie", "Satisfy", "Great Vibes", "Parisienne", "Allura"],
	'Strong': ["Anton", "Bebas Neue", "Alfa Slab One", "Oswald", "Roboto Condensed", "Russo One", "Merriweather", "Roboto Slab", "Montserrat", "Ubuntu"],
	'Surprise': ["Lobster", "Pacifico", "Bangers", "Yellowtail", "Dancing Script", "Cookie", "Amatic SC", "Satisfy", "Caveat", "Permanent Marker"],
	'Traditional': ["Playfair Display", "Merriweather", "PT Serif", "Lora", "Libre Baskerville", "EB Garamond", "Crimson Text", "Bree Serif", "Alegreya", "Noto Serif"],
	'Trustworthy': ["Roboto", "Open Sans", "Lato", "Noto Sans", "Merriweather", "PT Serif", "Ubuntu", "Libre Franklin", "Source Sans 3", "Quattrocento"],
	'Wistful': ["Lora", "Merriweather", "Crimson Text", "Libre Baskerville", "EB Garamond", "Playfair Display", "Noto Serif", "PT Serif", "Alegreya", "Tinos"]
};

const fontControls = ({ fontState, setFontState, defaultPalette }) => {

	const editor = useSelector(state => state.Variant.editor);
	const dispatch = useDispatch();
	
	// color palette
	const [ palette, setPalette ] = useState([]);
	const fontColor = fontState.color || '#ffffff';	

	// assist manual vibe filtering
	const fontPickerContainerRef = useRef(null);
	// show subset based on vibe/emotion
	const [ fontFilter, setFontFilter ] = useState(fontState.font_source == 'custom' ? 'Custom' : (fontState.font_vibe ? fontState.font_vibe : 'All'));

	// setup palette, default filter and font
	useEffect(() => {
		setPalette([...new Set([].concat(defaultPalette, fontColor))]);		
	}, []);

	// which controls are available?
	const [ fontOptions, setFontOptions ] = useState({
		regular: [100, 200, 300, 400, 500, 600, 700, 800, 900],
		italic: [100, 200, 300, 400, 500, 600, 700, 800, 900]
	});

	// fontpicker.filter is not reactive - manually hide/show fonts
	useEffect(() => {
		//console.log('uE fontFilter', fontFilter);
	
		if (fontFilter == 'Custom') {
			setFontState({
				font_source: 'custom',
				font_weight: 400,
				italic: false
			});
			setFontOptions({
				regular: [100, 200, 300, 400, 500, 600, 700, 800, 900],
				italic: [100, 200, 300, 400, 500, 600, 700, 800, 900]
			});	
			setWeightOptions([100, 200, 300, 400, 500, 600, 700, 800, 900]);
			setWeightOptionIdx(3);
			return;
			
		} else {
			let newFontState = {
				font_source: 'google'
			};
			
			setFontState(newFontState);
		}
	
	}, [fontFilter]);

	// handle font mouse over
	const fontPickerMouseOver = (event) => {
		if (event.target.id.startsWith('font-button-')) {			
			dispatch(updateEditor({
				captionFreeze: true,
				fontFamilyMouseOver: window.getComputedStyle(event.target).fontFamily
			}));
		}
	}
	useEffect(() => {
		//console.log('uE fontPickerContainerRef.current', fontPickerContainerRef.current);

		if (!fontPickerContainerRef.current) return;
	
		const fontPicker = fontPickerContainerRef.current.querySelector('#font-picker');
	
		const handleClassListChange = () => {
			if (!fontPicker.classList.contains('expanded')) {				
				dispatch(updateEditor({
					captionFreeze: false,
					fontFamilyMouseOver: undefined
				}));
			}
		};

		// Add event listener to detect classList changes
		fontPicker.addEventListener('transitionend', handleClassListChange);
		//console.log('addEventListener', 'transitionend');

		// Clean up the event listener when the component unmounts
		return () => {
			fontPicker.removeEventListener('transitionend', handleClassListChange);
			//console.log('removeEventListener', 'transitionend');
			
			dispatch(updateEditor({
				captionFreeze: false
			}));
		};
	}, [fontPickerContainerRef.current]);
	
	// for non-variable fonts; array of weights supported
	const [ weightOptions, setWeightOptions ] = useState([]);
	const [ weightOptionIdx, setWeightOptionIdx ] = useState(null);

	// monitor font_family, update controls and maybe italic/weight
	useEffect(() => {
		// console.log('uE fontState.font_family', fontState.font_family);

		let newFontState = {
			...fontState
		};
	
		const newFontOptions = fontData[fontState.font_family] || {
			regular: [100, 200, 300, 400, 500, 600, 700, 800, 900],
			italic: [100, 200, 300, 400, 500, 600, 700, 800, 900]
		};
		setFontOptions(newFontOptions);			

		// disable italic if no variants
		if (!fontOptions.italic) {
			newFontState.italic = false;
		} 
		
		// set weight options for non-variable fonts
		const newWeightOptions = newFontState.italic ? newFontOptions.italic : newFontOptions.regular;
		
		newFontState.font_weight = newFontState.font_weight || 400;
		if (!newFontOptions.variable) {
			setWeightOptions(newWeightOptions);

			let weightIdx = newWeightOptions.indexOf(newFontState.font_weight);
			
			// if new font doesn't have same weight, default to 400
			if (weightIdx == -1) {
				newFontState.font_weight = 400;
				weightIdx = newWeightOptions.indexOf(newFontState.font_weight);
			}
			
			setWeightOptionIdx(weightIdx);

		} else {
			// variable font - check range
			newFontState.font_weight = Math.min(Math.max(newFontState.font_weight, newWeightOptions[0]), newWeightOptions[1]);
		}
		
		setFontState(newFontState);
			
	}, [fontState.font_family]);
	
	
	// monitor italic control, maybe update weight
	useEffect(() => {
		// console.log('uE fontState.italic', fontState.italic);
		
		let newFontState = {
			font_weight: fontState.font_weight || 400
		};
		
		const newWeightOptions = fontState.italic ? fontOptions.italic : fontOptions.regular;

		if (!fontOptions.variable) {
			setWeightOptions(newWeightOptions);
			
			let weightIdx = newWeightOptions.indexOf(newFontState.weight);

			// if new style doesn't have same weight, default to 400
			if (weightIdx == -1) {
				newFontState.font_weight = 400;
				weightIdx = newWeightOptions.indexOf(newFontState.font_weight);
			}
		
			setWeightOptionIdx(weightIdx);
			
		} else {
			// variable font - check range
			newFontState.font_weight = Math.min(Math.max(newFontState.font_weight, newWeightOptions[0]), newWeightOptions[1]);
		}
		
		setFontState(newFontState);

	}, [fontState.italic]);


	useEffect(() => {
		if (weightOptionIdx !== null) {
			setFontState({
				font_weight: weightOptions[weightOptionIdx]
			})
		}
	}, [weightOptionIdx]);


	const [ pickerColor, setPickerColor ] = useState();
	useEffect(() => {
		if (pickerColor) {
			setFontState({
				'color': pickerColor
			});

			setPalette([...new Set([].concat(defaultPalette, pickerColor))]);		
		}
	}, [pickerColor]);
	
	
	return (	
		<Card>
			<CardHeader>
				<Row>
					<strong>Font</strong>
				</Row>
			</CardHeader>
			<CardBody>
				<Row className="mb-3 align-items-center">
					<Col>
						<Input
							type="select"
							value={ fontFilter }
							onChange={ (event) => {							
								dispatch(updateEditor({
									captionFreeze: false
								}));
								setFontFilter(event.target.value);
							} }
						>
							{ ['All'].concat(Object.keys(fontVibes)).map((vibe, idx) => (
								<option key={ idx } value={ vibe }>{ vibe }</option>
							)) }
						</Input>
					</Col>
				</Row>
				{ fontFilter == 'Custom' && (
				
					<>
						<Row className="mb-3">
							<Col>
								<label className="form-label">Font Name</label>
								<Input
									type="text"
									value={ fontState.font_family_custom || 'My Font' }
									onChange={ (event) => setFontState({
										font_family_custom: event.target.value
									}) }
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col>
								<label className="form-label">Font URL</label>
								<Input
									type="text"
									value={ fontState.font_family_url || '' }
									onChange={ (event) => setFontState({
										font_family_url: event.target.value,
									}) }
								/>
							</Col>
						</Row>
					</>
				
				) }
				
					<Row 
						className="mb-3"
						style={{
							display: (fontFilter == 'Custom') ? 'none' : 'block'
						}}
					>
						<Col>
							<div 
								ref={ fontPickerContainerRef }
								onMouseOver={ fontPickerMouseOver }
							>
								<FontPicker
									// re-render when filter changes
									key={ fontFilter }
									apiKey="AIzaSyB82lka5ecfnDmmqgpuSTYUruOgzCiC4Vc"
									families={ fontFilter == 'All' ? fontDataKeys : fontVibes[fontFilter] }
 									limit={ 500 }
									activeFontFamily={ fontState.font_family || 'Open Sans' }
									onChange={ (value) => {
										dispatch(updateEditor({
											captionFreeze: false
										}));
										setFontState({
											font_family: value.family
										});
									} }
								/>
							</div>
						</Col>
					</Row>
				
				<Row className="align-items-top mb-3">
					<Col className="col-6">
						<label className="form-label">Size</label>
						<Input
							type="range"
							value={ fontState.size || 1 }
							onChange={ (event) => {
								dispatch(updateEditor({
									captionFreeze: true
								}));
								setFontState({
									size: parseFloat(event.target.value)
								});
							} }
							onMouseUp={ () => { 
								dispatch(updateEditor({
									captionFreeze: false
								}));
							} }
							onTouchEnd={ () => { 
								dispatch(updateEditor({
									captionFreeze: false
								}));
							} }
							min={ 0.3 }
							max={ 3 }
							step={ 0.1 }
							className="mt-2 mb-2"
						/>
					</Col>		
					{ (!!fontOptions.variable || weightOptions.length > 1) && (
						<Col className="col-6">
							<label className="form-label">Weight</label>
						
							{ !!fontOptions.variable ? (
						
								<Input
									type="range"
									value={ fontState.font_weight || 400 }
									onChange={ (event) => {	
										dispatch(updateEditor({
											captionFreeze: true
										}));
										setFontState({
											font_weight: parseInt(event.target.value)
										});
									} }
									onMouseUp={ () => { 
										dispatch(updateEditor({
											captionFreeze: false
										}));
									} }
									onTouchEnd={ () => { 
										dispatch(updateEditor({
											captionFreeze: false
										}));
									} }
									min={ fontState.italic ? fontOptions.italic[0] : fontOptions.regular[0] }
									max={ fontState.italic ? fontOptions.italic[1] : fontOptions.regular[1] }
									step={ 1 }
									className="mt-2 mb-2"
								/>
						
							) : (				
									<Input
										type="range"
										value={ weightOptionIdx }
										onChange={ (event) => {
											dispatch(updateEditor({
												captionFreeze: true
											}));
											setWeightOptionIdx(parseInt(event.target.value));
										} }
										onMouseUp={ () => { 
											dispatch(updateEditor({
												captionFreeze: false
											}));
										} }
										onTouchEnd={ () => { 
											dispatch(updateEditor({
												captionFreeze: false
											}));
										} }
										min={ 0 }
										max={ weightOptions.length - 1 }
										step={ 1 }
										className="mt-2 mb-2"
									/>
							) }				
						</Col>
					) }					
				</Row>								
				<Row className="mb-3">
					<Col>
						<label className="form-label">Color</label>
						<Row className="align-items-center">
							{ palette.map((color, idx) => (
							  <Col key={idx} className="col-2 mb-3">
								<div
									className={ "palette-color " + (color == fontState.color ? 'palette-color-selected' : '') }
									onClick={ (event) => setFontState({
										'color': color
									}) }
								>
									<div
										style={{
											backgroundColor: color,
										}}
									/>
								</div>
							  </Col>
							)) }

							<Col key="picker" className="col-2 mb-2 text-center">
								<label htmlFor="colorPicker"
									className="pt-2"
									style={{
										height: 38,
										cursor: 'pointer'
									}}
								>
									<i className="mdi mdi-plus font-size-18" />
									<div className="color-picker-hidden">
										<Input
											id="colorPicker"
											type="color"
											value={ fontColor }
											onChange={ (event) => {
												dispatch(updateEditor({
													captionFreeze: true
												}));
												setPickerColor(event.target.value);
											} }							
											onBlur={ () => { 
												dispatch(updateEditor({
													captionFreeze: false
												}));
											} }
										/>									
									</div>
								</label>
							</Col>			
						</Row>
					</Col>
				</Row>
				{ !!fontOptions.italic && (
					<Row>
						<Col>
							<label className="form-label">Style</label>
							<div
							  className="form-check form-switch form-switch-md mb-3"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="font_italics"
								checked={ !!fontState.italic }
								onChange={ (event) => setFontState({
									'italic': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="font_italics"
							  >
								Italic
							  </label>
							</div>
						</Col>
					</Row>
				) }
				<Row>
					<Col>
						<label className="form-label">Effect</label>
						<Input
							type="select"
							value={ fontState.effect }
							onChange={ (event) => setFontState({
								'effect': event.target.value 
							}) }
						>
							<option value={ false }>None</option>
							<option value="shadow">Shadow</option>
							<option value="anaglyph">Anaglyph</option>
							<option value="neon">Neon</option>
							<option value="outline">Outline</option>
							<option value="emboss">Emboss</option>
							<option value="fire">Fire</option>
						</Input>
					</Col>
				</Row>
			</CardBody>
		</Card>

	)
}

export default fontControls;