import { call, put, takeEvery, takeLatest, all, fork, select } from "redux-saga/effects";

// Login Redux States
import {
  EXTERNAL_AUTH,
  AUTH_SESSION,
  SESSION_SUCCESS,
  REFRESH_SESSION,
  QUEUE_RESET,
  QUEUE_ADD,
  AUTH_LOGOUT,
  SET_ACCESS_TOKEN,
} from "./actionTypes"

import { externalAuth, sessionSuccess, queueReset } from "./actions";

import {
  postSession,
  deleteSession
} from "../../helpers/backend_helper";

const getAuthUserFromResponse = (response) => {
	let authUser = {
		accessToken: response.session.access_token,
		email: response.user.email,
		id: response.user.user_id,
		username: response.user.user_id
	};
	
	// not present if it's unchanged
	if (response.session.refresh_token) {
		authUser.refreshToken = response.session.refresh_token;
	}
	
	return authUser;	
}

function* redirectExternalAuth() {
	//console.log('redirect to cognito...');
	const redirect_uri = encodeURIComponent(`${process.env.REACT_APP_PUBLIC_URL}/auth`);
	const cognito_url = `${process.env.REACT_APP_AUTH}/login?client_id=${process.env.REACT_APP_AUTH_CLIENT}&response_type=code&scope=email+openid+phone&redirect_uri=${redirect_uri}`
	window.location.href = cognito_url;
}

function* fetchSession({ payload: { code, state, history } }) {
  try {
  	
    const response = yield call(postSession, {
      client: 'better',
      code: code,
      state: state,
      redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}/auth`
    });

    const authUser = getAuthUserFromResponse(response);
    
    yield put(sessionSuccess(authUser));
    
	history('/');

  } catch (error) {

	// cognito again  
    yield put(externalAuth());
  }
}

function* localStorageAuthUser({ payload: { authUser } }) {
	let previousAuthUser = JSON.parse(localStorage.getItem("authUser")) || {};
	const newAuthUser = {
		...previousAuthUser,
		...authUser
	}
	localStorage.setItem("authUser", JSON.stringify(newAuthUser));
}

function* refreshSession({ payload: { refreshToken, axiosApi, action } }) {
	try {

		// reset queue
		yield put(queueReset([ action ]));

		const response = yield call(postSession, {
			client: 'better',
			refresh_token: refreshToken
		});

		const authUser = getAuthUserFromResponse(response);

		yield put(sessionSuccess(authUser));

		// replay queue
		const refreshQueue = yield select(state => state.Auth.refreshQueue);

		// reset queue
		yield put(queueReset([]));

		for (const action of refreshQueue) {
			// Retry all failed actions with the new access token
			yield put(action);
		}

	} catch (error) {
		// Handle error (e.g., redirect to login)
		yield put(externalAuth());
	}
}

function* deleteSessionSaga() {
  try {
  	
    const response = yield call(deleteSession);

    yield put(sessionSuccess({ authUser: {} }));
    
	yield put(externalAuth());

  } catch (error) {

	// cognito again  
    yield put(externalAuth());
  }
}


// redirect to cognito
export function* watchExternalAuth() {
  yield takeLatest(EXTERNAL_AUTH, redirectExternalAuth);
}

export function* watchAuthSession() {
  yield takeLatest(AUTH_SESSION, fetchSession);
}

export function* watchSessionSuccess() {
  yield takeLatest(SESSION_SUCCESS, localStorageAuthUser);
}

export function* watchRefreshSession() {
  yield takeLatest(REFRESH_SESSION, refreshSession);
}

export function* watchAuthLogout() {
  yield takeLatest(AUTH_LOGOUT, deleteSessionSaga);
}


function* AuthSaga() {
  yield all([
  	fork(watchExternalAuth),
  	fork(watchAuthSession),
  	fork(watchSessionSuccess),
  	fork(watchRefreshSession),
  	fork(watchAuthLogout),
  ])
}

export default AuthSaga;
