import React, { useState } from "react";

import { 
	Spinner,
	Row, Col, 
	Modal, ModalHeader, ModalBody, ModalFooter,
	Button,
	Alert
} from "reactstrap"

// control style
import "./site.scss";

import VariantPreview from '../../components/Common/VariantPreview';


const GeneratingReport = ({ variant, site_type, show, hideModal, continueClick }) => {


	function listToSentence(lst) {
		// Check if the list is empty
		if (lst.length === 0) {
			return '';
		}

		// If the list has only one item, return it as is
		if (lst.length === 1) {
			return lst[0];
		}

		// For more than one item, format with commas and 'and'
		return lst.slice(0, -1).join(', ') + ' and ' + lst[lst.length - 1];
	}

	let adjectives = '';
	if (variant.gpt4_result?.adjectives) {
		adjectives = ". It's " + listToSentence(variant.gpt4_result.adjectives);
	}

	// Define an object to store already processed tags
	const already = {};

	// Function to format the bounding box of an object
	const boxstr = (obj) => {
		return `${Math.max(0, obj.BoundingBox.Left).toFixed(3)}${Math.max(0, obj.BoundingBox.Top).toFixed(3)}${Math.min(0.999, obj.BoundingBox.Width).toFixed(3)}${Math.min(0.999, obj.BoundingBox.Height).toFixed(3)}`.replaceAll('0.', '');
	};

	// Function to generate unique tag list
	const unique_tag_list = (tags, tag_class) => {
		//console.log('unique_tag_list', tags, tag_class);
		if (!tags) return;
		
		let rv = '';
		for (const tag of tags) {
		   // add_to_tag_cloud(tag);
			if (true || !already[tag]) {
				rv += `<span class="${tag_class}">${tag}</span> `;
				already[tag] = true;
			}
		}
		return rv;
	};

	// Process faces and tags
	const faces = variant.faces || [];
	const subject_rows = [];
	const person_excluded = ['Male', 'Female', 'Person', 'Body Part'];

	// const person_tags = (variant.labels['Person Description'] || []).filter(tag => !person_excluded.includes(tag));

	let person_tags = [];
	let emotion_tags = [];
	if (variant.labels) {
		person_tags = Object.keys(variant.labels['Person Description'] || {})
		   .filter(tag => !person_excluded.includes(tag));

		emotion_tags = Object.keys(variant.labels['Expressions and Emotions'] || {});
	}

	// Process subjects and emotions
	if (faces.length !== 1) {
		const person_row = unique_tag_list([...person_tags, ...emotion_tags], 'image_subject_label');
		if (person_row) {
			subject_rows.push(person_row);
		}
	}

	if (faces.length) {
		faces.sort((a, b) => b.Confidence - a.Confidence);
	}

	for (const face of faces.slice(0, 5)) {
		const crop = boxstr(face);
		const face_tags = [];

		if (face.Gender) {
			face_tags.push(face.Gender.Value);
		}
		if (face.AgeRange) {
			face_tags.push(`Age ${face.AgeRange.Low}-${face.AgeRange.High}`);
		}
		if (face.Smile && face.Smile.Value) {
			face_tags.push('Smiling');
		}
		const max_emotion = face.Emotions ? face.Emotions.reduce((prev, current) => (prev.Confidence > current.Confidence) ? prev : current, {}) : null;
		if (max_emotion) {
			face_tags.push(max_emotion.Type);
		}

		// Facial hair taxonomy
		if (face.Beard && face.Beard.Value) {
			face_tags.push('Beard');
		} else if (face.Moustache && face.Moustache.Value) {
			face_tags.push('Moustache');
		}
		if (face.Sunglasses && face.Sunglasses.Value) {
			face_tags.push('Sunglasses');
		} else if (face.Eyeglasses && face.Eyeglasses.Value) {
			face_tags.push('Glasses');
		}

		if (faces.length === 1) {
			face_tags.push(...person_tags);
			face_tags.push(...emotion_tags);
		}

		const rekognition_source = variant.source_url;
		// .source_url_s3 ?
		let face_row = `<div class="image_face">`;

		const width = 48;
		const height = width * (face.BoundingBox.Height * variant.height) / (face.BoundingBox.Width * variant.width);
		const zoom = width / (face.BoundingBox.Width * variant.width);
		const bg_width = variant.width * zoom;

		const bg_x = 100 * (face.BoundingBox.Left + (zoom * face.BoundingBox.Width/2));
		const bg_y = 100 * (face.BoundingBox.Top + (zoom * face.BoundingBox.Height/2));

		face_row += `<div style="width:${width}px;height:${height}px;float:left;margin-right:12px;border-radius: 8px;background-image:url('${rekognition_source}');background-position:${bg_x}% ${bg_y}%;background-size:${bg_width}px auto;"></div>`;

		face_row += unique_tag_list(face_tags, 'image_subject_label');
		face_row += '</div>';

		subject_rows.push(face_row);
	}

// Process content analysis
let content_analysis = '';
const subject_row_count = subject_rows.length;
if (subject_row_count) {
    const subject_label = (faces.length > 1) ? 'Subjects' : 'Subject';
    content_analysis += `<tr><th width="33%" rowspan="${subject_row_count}">${subject_label}</th><td>${subject_rows[0]}</td></tr>`;
    for (let i = 1; i < subject_rows.length; i++) {
        content_analysis += `<tr><td>${subject_rows[i]}</td></tr>`;
    }
}

// Process action tags
const action_tags = Object.keys(variant.labels?.Actions || {});
const action_row = unique_tag_list(action_tags, 'image_action_label');
if (action_row) {
    content_analysis += `<tr><th width="33%">Action</th><td>${action_row}</td></tr>`;
}

// Process other categories
for (const [category, tags] of Object.entries(variant.labels || {})) {
    if (!['Person Description', 'Actions', 'Expressions and Emotions'].includes(category)) {
        // add_to_tag_cloud(category);
        const object_row = unique_tag_list(Object.keys(tags), 'image_object_label');
        if (object_row) {
            content_analysis += `<tr><th width="33%">${category}</th><td>${object_row}</td></tr>`;
        }
    }
}

if (content_analysis) {
    content_analysis = `<table style="width:80%;margin:24px 0 24px 10%;">${content_analysis}</table>`;
}

// Process composition and palette
let composition_analysis = '<table style="width:80%;margin:24px 0 24px 10%;"><tr><td style="border:none;"></td><th style="text-align:center">Foreground</th><th style="text-align:center">Background</th></tr><tr><th>Palette</th>';
	if (variant.palette) {
		for (const palette of ['Foreground', 'Background']) {
			const colors = variant.palette[palette]?.DominantColors || [];
			composition_analysis += '<td class="image_palette">';
			if (colors.length) {
				for (const color of colors) {
					const hex = color.HexCode;
					composition_analysis += `<span class="image_palette_swatch" style="background-color:${hex};"> </span> `;
				}
			}
			composition_analysis += '</td>';
		}
	}
composition_analysis += '</tr>';

for (const quality of ['Brightness', 'Sharpness']) {
    composition_analysis += `<tr><th>${quality}</th>`;
	if (variant.palette) {
		for (const palette of ['Foreground', 'Background']) {
			const quality_value = variant.palette[palette]?.Quality?.[quality];
			const quality_value_str = quality_value ? `${quality_value.toFixed(0)}%` : '-';
			composition_analysis += `<td align="center">${quality_value_str}</td>`;
		}
	}
    composition_analysis += '</tr>';
}

const contrast_value = variant.palette?.Quality.Contrast;
const contrast_value_str = contrast_value ? `${contrast_value.toFixed(0)}%` : '-';
composition_analysis += `<tr><th>Contrast</th><td colspan="2" align="center">${contrast_value_str}</td></tr></table>`;


	// animation restart counter
	const [ animationRestart, setAnimationRestart ] = useState({ begin: 0 });	
	const restartFrom = (begin) => {
		// useEffect in VariantPreview will detect this and restart animations
		setAnimationRestart({
			begin,
			key: Date.now()
		});
	}


	return (
		<Modal 
			isOpen={ show } 
			toggle={ hideModal }
		>
			<ModalHeader 
				toggle={ hideModal }
				tag="h4"
			>
				Generating Better Images
			</ModalHeader>
			<ModalBody>
				<Row>
					<Col xs={12}>
						<h5>Original Image</h5>
						<div>
							We start with your original image{adjectives}:
						</div>
						<div className="text-center mt-2 mb-3">
							<img 
								src={ variant.source_url }
								style={{
									width: '50%',
								}}	 
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={12}>
						<h5>Better Image</h5>
						<div className="mb-3">
							... and here's a suggested Better Image. If you want, you can edit the motion and captions and other features, as described below.
						</div>
						<div style={{
							width: '50%',
							margin: '0 auto'
						}}>
							<VariantPreview 
								key={ variant.variant_id }
								variant={ variant }
								width={ 600 }
								animationRestart={ animationRestart }
							/>
							<Button type="button" className="btn btn-sm mt-3 mb-3" onClick={ () => restartFrom(0) }>
								Replay
							</Button>							
						</div>
					</Col>
				</Row>

				{ site_type == 'web' && variant.gpt4_result?.alt && (
					<Row>
						<Col xs={12}>
							<h5>Search Engine Optimization</h5>
							<div>
								To optimize SEO performance, and improve accessibility, we write an Alt Tag: 
							</div>
							<blockquote className="blockquote font-size-14 ms-5 mt-2 mb-3">
								<em>{variant.gpt4_result.alt}</em>
							</blockquote>
						</Col>
					</Row>
				) }

				{ variant.gpt4_result?.prompt && (
					<Row>
						<Col xs={12}>
							<h5>Generative AI</h5>
							<div>
								To re-create and compare variations of your image, we write a generative AI prompt:
							</div>
							<blockquote className="blockquote font-size-14 ms-5 mt-2 mb-3">
								<em>{variant.gpt4_result.prompt}</em>
							</blockquote>
						</Col>
					</Row>
				) }

					<Row>
						<Col xs={12}>
							<h5>Feature Detection</h5>
							<div>
								Next, we detect engaging features within your image. We know what features engage best. After you use Better Images for a while, we learn what engages your specific audience.
							</div>						
							<div
								dangerouslySetInnerHTML={{ __html: content_analysis }} 
							>
							</div>
						</Col>
					</Row>

				{ variant.params?.motions && (
					<Row>
						<Col xs={12}>
							<h5>Story</h5>
							<div className="mb-3">
								Then we tell a story with your image, attracting and focusing audience attention on those engaging features, by adding cut, cross-fade, pan and zoom motion effects.
							</div>
							<div className="mb-3">
								You can edit the cuts, cross-fades, pans and zooms if you want.
							</div>
						</Col>
					</Row>
				) }

					<Row>
						<Col xs={12}>
							<h5>Composition</h5>
							<div>
								We also analyze the background and foreground of the image:
							</div>						
							<div
								dangerouslySetInnerHTML={{ __html: composition_analysis }} 
							>
							</div>
						</Col>
					</Row>

				{ variant.params?.captions && (
					<Row>
						<Col xs={12}>
							<h5>Captions</h5>
							<div className="">
								Based on the image features and composition, we add eye-catching captions.
							</div>
							<div className="mb-3">
								<ul className="tick_list">
									<li>The caption text is generated to highlight features of your image and drive attention, interest, desire and action. 
									You can edit the caption text if you want.</li>
									<li>The { variant.params.captions.font_vibe && variant.params.captions.font_vibe.toLowerCase() } '{ variant.params.captions.font_family_custom || variant.params.captions.font_family }' font is automatically chosen to match the style of your content. 
									You can change that if you want.</li>
									<li>Caption colors are automatically chosen to match your image composition. 
									You can change them if you want.</li>
									<li>The caption is vector-based and fully responsive so fonts look awesome on both desktop and mobile devices.</li>
									<li>The caption reveal is animated to attract audience attention.</li>
								</ul>
							</div>
						</Col>
					</Row>
				) }

				{ variant.params?.stickers && (
					<Row>
						<Col xs={12}>
							<h5>Your Branding</h5>
							<div className="mb-3">
								We also add a watermark to your image, to emphasize your branding and create a parallax effect with the animation.
							</div>
						</Col>
					</Row>
				) }

					<Row>
						<Col xs={12}>
							<h5>Optimal Delivery</h5>
							<div className="mb-3">
								Then we deliver your image in the optimal format for each viewer, and fast from a nearby server. 
								{" "}
								{ site_type == 'web' ? (
									<span>This saves bandwidth costs, improves page loading speed, and boosts SEO rankings.</span>
								) : (
									<span>This saves bandwidth costs, and improves email loading speed and engagement.</span>
								) }			
							</div>
						</Col>
					</Row>

				{ site_type == 'web' && (
					<Row>
						<Col xs={12}>
							<h5>Lazy Loading & Lazy Animation</h5>
							<div className="mb-3">
								Finally we load the image only when it comes into view, to save more bandwidth costs.
							</div>
							<div className="mb-3">
								We also pause the animation when it's out of view, and resume it when it returns to view, increasing engagement further.
							</div>
						</Col>
					</Row>
				) }
				
			</ModalBody>
			<ModalFooter 
			>
				<Button type="button" className="btn" onClick={ continueClick }>
					Continue
				</Button>
			</ModalFooter>
		</Modal>
	)
}

export default GeneratingReport;