import React, { useState } from "react"

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import withRouter from "./withRouter";

// actions
import { 
	variantStatusRequest,	
	variantEdit,
	variantReload,
	variantClone,
} from "../../store/actions";

import { 
  Spinner,
  Container, Row, Col,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

import VariantPreview from '../../components/Common/VariantPreview';

const variantStatusColor = {
	'active': 'success',
	'inactive': 'danger',
	'suggested': 'warning'
}

const variantCardColor = {
	'active': '#34c38f22',
	'inactive': 'danger',
	'suggested': '#f1b44c22'
}

// control style
import "./card.scss";


// shown on imagesTable
// also shown on dashboard for suggestions that need a decision
const VariantCard = ({ variant, imageViews, openAddVariant, openPublishing, observeElement, router }) => {

	// newly added image is empty
	if (!variant) return;
	
	// get account and site based on variant
	//const account = useSelector(state => state.Profile?.data?.accounts[variant.account_id]);
	const site = useSelector(state => state.Profile?.data?.sites[variant.site_id]);

	// hamburger menu
	const [moreMenu, setMoreMenu] = useState(false);
	
	// animation restart counter
	const [ animationRestart, setAnimationRestart ] = useState({ begin: 0 });	
	const restartFrom = (begin) => {
		// useEffect in VariantPreview will detect this and restart animations
		setAnimationRestart({
			begin,
			key: Date.now()
		});
	}
	
	// variant_status: suggested / active / inactive paused / deleted / workflow-levels?
	
	const dispatch = useDispatch();
	
	let badgeColor = variantStatusColor[variant.variant_status] || 'danger';
	let cardColor = variantCardColor[variant.variant_status] || '#FFFFFF';
	
	let viewMix = parseInt(100*(variant.events?.view || 0)/imageViews);
	
	const maxZoom = (variant.params?.motions || []).reduce((maxValue, obj) => {
	  return obj.zoom > maxValue ? obj.zoom : maxValue;
	}, 1);

	// display send email modal?
	const [ sendEmailModal, setSendEmailModal ] = useState(false);

	return (
		<>
            <Col>
				<Card 
					className="bi-card"
					style={{
						width: router.location.pathname.includes('results') ? '180px' : '20vw'
					}}
				>
					<div
						style={ !!variant.params.tilt?.active ? {
							boxShadow: 'inset 0 0 100px black',
							overflow: 'hidden',
							backgroundColor: '#808080'
						} : {} }
					>
						<Link
							to={ variant.legacy ? false : `/${variant.account_id}/${variant.site_id}/${variant.image_id}/${variant.variant_id}/edit` }
						>
							<VariantPreview 
								key={ variant.variant_id }
								variant={ variant }
								width={ parseInt(360 * maxZoom) }
								animationRestart={ animationRestart }
								observeElement={ observeElement }
							/>
						</Link>	
					</div>
                
					<CardBody className="p-3"
						style={{
							backgroundColor: cardColor
						}}
					>

						{ variant.variant_status == 'loading' ? (
					
							<Spinner color="primary" size="sm" className="mb-2" />
						
						) : (

							<>					
								<CardText 
									className={ `badge badge-soft-${badgeColor} mb-2` }
									style={{
										textTransform: 'capitalize'
									}}
								>
									{ variant.variant_status }

									{ variant.variant_status == 'coming soon' && (
										<>
											{" "}<i className="mdi mdi-help-circle" id="soon" />
											<UncontrolledTooltip placement="top" target="soon">
												Another Better Image will be suggested here for your approval, when there is enough engagement and conversion data. Until then, you can try 'Generate Now'.
											</UncontrolledTooltip>
										</>	
									) }
								</CardText>
								{" "}
								{ !!viewMix && (
									<CardText 
										className={ `badge badge-soft-${badgeColor} mb-2` }
									>
										{ viewMix }%
									</CardText>
								) }
							</>
					
						) }

						<CardTitle style={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis'
						}}>
							 { variant.label }
						</CardTitle>

						{ variant.variant_status != 'loading' && (
							<Row className="ms-0 me-0 align-items-center"> 

								{ variant.variant_status == 'coming soon' && (
									<>
										<Col 
											className="ps-0" 
											style={{
												flexGrow: 0
											}}
										>
											<Link
												to="#"
												className="btn btn-sm btn-primary"
												onClick={ (event) => openAddVariant(variant) }
											>
												Generate Now
											</Link>	
										</Col>
									</>
								) }							
				
								{ !variant.legacy && variant.variant_status != 'coming soon' && (
				
									<>
										<Col 
											className="ps-0"
											style={{
												flexGrow: 0
											}}
										>
											{ variant.variant_status == 'active' ? (
												<Link
													to="#"
													className="btn btn-sm btn-secondary"
													onClick={ () => dispatch(variantStatusRequest(variant.account_id, variant.site_id, variant.image_id, variant.variant_id, 'inactive')) }
												>
													Pause
												</Link>	

											) : (

												<Link
													to="#"
													className="btn btn-sm btn-success"
													onClick={ () => dispatch(variantStatusRequest(variant.account_id, variant.site_id, variant.image_id, variant.variant_id, 'active')) }
												>
													{ variant.variant_type == 'baseline' ? 'Approve' : 'Approve' }
												</Link>						
											) }
										</Col>

										{ variant.variant_type != 'baseline' && (
											<Col
												className="ps-0"
												style={{
													flexGrow: 0
												}}
											>
												<Link
													to={ `/${variant.account_id}/${variant.site_id}/${variant.image_id}/${variant.variant_id}/edit` }
													className="btn btn-sm btn-secondary"
													//onClick={ () => dispatch(variantEdit(variant.account_id, variant.site_id, variant.image_id, variant.variant_id, router.navigate)) }
												>
													Edit
												</Link>	
											</Col>
										) }
									</>						
								) }
				
								{ variant.variant_status != 'coming soon' && (
									<Col 
										className="ps-0 pe-0" 
										style={{
											textAlign: 'right',
											flexGrow: 1
										}}
									>
										<Dropdown
											isOpen={moreMenu}
											toggle={() => setMoreMenu(!moreMenu)}
										>
											<DropdownToggle color="link" className="btn btn-link p-0" caret>
												<i className="mdi mdi-dots-vertical font-size-16" />
											</DropdownToggle>
											<DropdownMenu>
												{ variant.variant_type == 'baseline' && (
													<Link 
														to={ `/${variant.account_id}/${variant.site_id}/${variant.image_id}/results` } 
														className="dropdown-link"
													>
														<DropdownItem>
															Results
														</DropdownItem>
													</Link>
												) }

												{ !variant.legacy && variant.variant_type != 'baseline' && (
													<DropdownItem
														onClick={ () => restartFrom(0) }
													>
														Replay
													</DropdownItem>
												) }

												{ !variant.legacy && site.site_type == 'web' && (
													<DropdownItem
														onClick={ (event) => openPublishing(site, variant) }
													>
														Publishing
													</DropdownItem>									  
												) }

												{ !variant.legacy && site.site_type == 'email' && (									  
													<DropdownItem
														onClick={ (event) => { 
															openPublishing(site, variant);
														} }
													>
														Publishing
													</DropdownItem>
												) }
						  
												{ !variant.legacy && variant.variant_type != 'baseline' && (
													<DropdownItem
														onClick={ (event) => openAddVariant(variant) }
													>
														Clone
													</DropdownItem>
												) }

												<DropdownItem
													onClick={ () => dispatch(variantStatusRequest(variant.account_id, variant.site_id, variant.image_id, variant.variant_id, 'deleted')) }
												>
													Delete
												</DropdownItem>
											</DropdownMenu>
										</Dropdown>
									</Col>
								) }
							</Row>
						) }
					</CardBody>
				</Card>
			</Col>
		</>
	)
}

export default withRouter(VariantCard);