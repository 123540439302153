import React, { useRef, useState, useEffect, useCallback } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button, FormGroup,
  Input,
  UncontrolledTooltip,
} from "reactstrap";

// actions
// dispatch actions:  box, begin, end, zoom, transition, ? manual zoom
import { useDispatch } from "react-redux";
import { setVariantParams } from "../../store/actions";


//
const EditPersonalize = ({ variant, restartFrom }) => {

	let personalize = variant.params?.personalize || {};
	
	if (!personalize.defaults) {
		personalize.defaults = [ [ 'name', 'Friend' ] ];
	}
	
	const getPersonalizeValue = (field, default_value) => {
		let rv = personalize[field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// update params
	const dispatch = useDispatch();
	const setParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			personalize: {
				...personalize,
				...params
			}
		}));
	}

	const updateDefaults = (idx, key_or_value, value) => {
		let def;
		if (key_or_value == 'key') {
			def = [ value, personalize.defaults[idx][1] ];
		} else {
			def = [ personalize.defaults[idx][0], value ];
		}

		setParams({
			defaults: [
				...personalize.defaults.slice(0, idx),
				def,
				...personalize.defaults.slice(idx+1),
			]
		})
	}

	const addDefault = () => {
		setParams({
			defaults: [
				...personalize.defaults,
				[ 'key', 'value' ]
			]
		});
	}

	const deleteDefault = (idx) => {
		setParams({
			defaults: [
				...personalize.defaults.slice(0, idx),
				...personalize.defaults.slice(idx+1),
			]
		});
	}

	return (
		<>
			<Card>		
				<CardHeader>
					<strong>Personalize</strong>
				</CardHeader>
				<CardBody>
					<Row>
						<Col>
							<div
							  className="form-check form-switch"
							>
							  <input
								type="checkbox"
								className="form-check-input"
								id="personalize_active"
								defaultChecked={ !!getPersonalizeValue('active', false) }
								onChange={ (event) => setParams({
									'active': event.target.checked
								}) }
							  />
							  <label
								className="form-check-label"
								htmlFor="personalize_active"
							  >
								Active
							  </label>
							</div>
						</Col>
					</Row>
					{ !!getPersonalizeValue('active') && (
						<Row>
							<Col xs={12} className="mt-3">
								<div className="mb-3">Simply add your data to the image tag on your site:</div>
								<code>
									&lt;img { 
										getPersonalizeValue('defaults', [['name', 'Friend']]).map((def, idx) => {
											if (def[0] && def[1]) {
												return (
														<b key={ idx }>
															{ `data-${def[0]}="${def[1]}"`}
															{" "}
														</b>
												);
											}
										})
									}
									src="https://..." alt="Original Image" ... /&gt;
								</code>
								<div className="mt-3">
									And use
									{" "} 
									{ !!getPersonalizeValue('defaults').length ? (
										<b>#data-{ getPersonalizeValue('defaults')[0][0] }#</b>
									) : (
										<b>#data-name#</b> 
									) }
									{" "}						 
									etc. in your Captions or Buttons where you want the data to be displayed.
								</div>
							</Col>
						</Row>
					) }

				</CardBody>
			</Card>

			{ !!getPersonalizeValue('active') && (
				<Card>		
					<CardHeader>
						<strong>Default values</strong>
					</CardHeader>
					<CardBody>
						<Row>
							<Col xs={12}>
								<div>These values will be displayed when there is no data available:</div>
							</Col>
						</Row>

						<Row className="align-items-center mt-3">
							<Col xs={ 5 }>
								<b>Field</b>
							</Col>
							<Col xs={ 5 } className="text-start">
								<b>Value</b>
							</Col>
						</Row>

						{ getPersonalizeValue('defaults').map((kv, idx) => {
							return (
								<Row key={ idx }
									className="align-items-center mt-3"
								>
									<Col xs={ 5 }>
										<Input
											type="text"
											value={ kv[0] }
											onChange={ (event) => updateDefaults(idx, 'key', event.target.value) }
										/>
									</Col>
									<Col xs={ 5 }>
										<Input
											type="text"
											value={ kv[1] }
											onChange={ (event) => updateDefaults(idx, 'value', event.target.value) }
										/>
									</Col>
									<Col xs={ 2 }>
										  <Link
											to="#"
											className="text-danger"
											onClick={ () => deleteDefault(idx) }
										  >
											<i className="mdi mdi-delete font-size-18" id="deletetooltip" />
											<UncontrolledTooltip placement="top" target="deletetooltip">
											  Delete
											</UncontrolledTooltip>
										  </Link>				
									</Col>						
								</Row>
							);
						}) }

						<Row className="mt-3">
							<Col xs={12}>
								<Button
									type="button"
									color="secondary"
									className="mb-2 me-2"
									onClick={ addDefault }
								>
									<i className="mdi mdi-plus me-1" />
									Add
								</Button>
							</Col>
						</Row>

					</CardBody>
				</Card>
			) }
		</>
	)
}

export default EditPersonalize;