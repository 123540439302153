import {
	POST_NOTIFICATION_REQUEST,
	POST_NOTIFICATION_SUCCESS,
	POST_NOTIFICATION_FAILURE,

	POST_MESSAGING_REQUEST,
	POST_MESSAGING_SUCCESS,
	POST_MESSAGING_FAILURE,

	POST_SAMPLE_REQUEST,
	POST_SAMPLE_SUCCESS,
	POST_SAMPLE_FAILURE,

} from "./actionTypes"

const initialState = {
	loading: false,
	sample_viewer_id: null
};

const Publish = (state = initialState, action) => {
  switch (action.type) {

	case POST_SAMPLE_REQUEST:
      return { 
      	...state, 
      	loading: true,
      	sample_viewer_id: null,
      };

	case POST_SAMPLE_SUCCESS:
      return { 
      	...state, 
      	loading: false,
      	sample_viewer_id: action.payload.sample_viewer_id,
      };


    default:
      return state;
  }
}

export default Publish