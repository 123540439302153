import { call, put, takeEvery, takeLatest, all, fork } from "redux-saga/effects";

import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,

  POST_USER_REQUEST,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,

  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE
} from "./actionTypes"

import { 
  fetchUsersSuccess, fetchUsersFailure,
  postUserSuccess, postUserFailure,
  updateUserSuccess, updateUserFailure
} from './actions'

import {
  getUsers,
  postUser,
  putUser
} from "../../helpers/backend_helper";


function* fetchUsersSaga(action) {
  try {
    const response = yield call(getUsers, action);
    yield put(fetchUsersSuccess(response.users));
    
  } catch (error) {
    yield put(fetchUsersFailure(error.message));
  }
}

export function* watchFetchUsers() {
  yield takeLatest(FETCH_USERS_REQUEST, fetchUsersSaga);
}


function* postUserSaga(action) {
  try {
    const response = yield call(postUser, action);

	action.payload.addUserSuccess();
    yield put(postUserSuccess(response.user));

  } catch (error) {
    yield put(postUserFailure(error.message));
  }
}

export function* watchPostUser() {
  yield takeLatest(POST_USER_REQUEST, postUserSaga);
}


function* updateUserSaga(action) {
  try {
    const response = yield call(putUser, action);

    yield put(updateUserSuccess(response.user));

  } catch (error) {
    yield put(updateUserFailure(error.message));
  }
}

export function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER_REQUEST, updateUserSaga);
}


function* UserSaga() {
  yield all([
  	fork(watchFetchUsers),
  	fork(watchPostUser),
  	fork(watchUpdateUser)
  ])
}

export default UserSaga;