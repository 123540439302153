import {
	// get variant
	GET_VARIANT_REQUEST,
	GET_VARIANT_SUCCESS,
	GET_VARIANT_FAILURE,
	
	// update local state
	SET_VARIANT_PARAMS,
	
	// save state to backend
	PUT_VARIANT_REQUEST,
	PUT_VARIANT_SUCCESS,
	PUT_VARIANT_FAILURE,

	GET_EDGE_REQUEST,
	GET_EDGE_SUCCESS,
	GET_EDGE_FAILURE,
	
	UPDATE_EDITOR,

	// get heatmap
	GET_HEATMAP_REQUEST,
	GET_HEATMAP_SUCCESS,
	GET_HEATMAP_FAILURE,

} from "./actionTypes"

const initialState = {
	data: {},		// variant
	heatmap: {},
	dirty: false,
	loading: false,
	error: null,
	edge: {},		// from controller refresh
	editor: {}		// editor config
};

const Variant = (state = initialState, action) => {
  switch (action.type) {

	case GET_VARIANT_REQUEST:
      return { 
      	...state,
      	// only reset if variant_id has changed
      	data: action.payload.variant_id == state.data.variant_id ? state.data : {},
      	loading: true 
      };

	case PUT_VARIANT_REQUEST:
      return { 
      	...state,
      	loading: true 
      };
      
    case GET_VARIANT_SUCCESS:
    case PUT_VARIANT_SUCCESS:
      return { 
      	...state, 
      	data: action.payload,
		loading: false, 
		error: null,
      	dirty: false,
      };
      
    case GET_VARIANT_FAILURE:
    case PUT_VARIANT_FAILURE:
      return { 
      	...state, 
      	loading: false, 
      	error: action.payload 
      };

    case SET_VARIANT_PARAMS:
		return {
	      	...state, 
			data: {
				...state.data,
				params: {
					...state.data.params,
					...action.payload.params
				}
			},
			dirty: true			
		}

	case GET_EDGE_SUCCESS:
		return {
	      	...state, 
			edge: {
				...state.edge,
				...action.payload.edge
			}
		}

	case UPDATE_EDITOR:
		return {
			...state,
			editor: {
				...state.editor,
				...action.payload.params
			}
		}

	case GET_HEATMAP_REQUEST:
      return { 
      	...state,
      	// only reset if variant_id has changed
      	heatmap: action.payload.variant_id == state.data.variant_id ? state.data : {},
      	loading: true 
      };

    case GET_HEATMAP_SUCCESS:
      return { 
      	...state, 
      	heatmap: action.payload,
		loading: false, 
		error: null,
      };

    case GET_HEATMAP_FAILURE:
      return { 
      	...state, 
      	loading: false, 
      	error: action.payload 
      };

    default:
      return state;
  }
}

export default Variant