// src/components/filter.
import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import PropTypes from 'prop-types';

import { connect, useDispatch, useSelector } from "react-redux";

// actions
import { fetchUsersRequest } from "../../store/actions";

//import components
import DeleteModal from "../../components/Common/DeleteModal";
import TableContainer from '../../components/Common/TableContainer';
import AddUserModal from '../../components/Common/AddUserModal';

import {
  Spinner,
  Row, Col,
  Card, CardBody,
  Button,
  UncontrolledTooltip, 
  Alert
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

const UsersTable = ({ account, site, users, error, loading }) => {

    const dispatch = useDispatch();
  
    const account_id = useSelector(state => state.Profile?.data?.account_id);
    const site_id = useSelector(state => state.Profile?.data?.site_id);

  useEffect(() => {
    dispatch(fetchUsersRequest(account_id, site_id));
  }, [account_id, site_id]);


	const [ addModal, setAddModal ] = useState();

    //meta title
    document.title = "Better Images | Users";

    const columns = useMemo(
        () => [
			{
				header: 'Updated',
				accessorKey: 'updated',
			},
            {
                header: ' ',
				cell: ({ row }) => (			
				  <>
					{!row.original.user_img ? (
					  <div className="avatar-xs">
						<span className="avatar-title rounded-circle">
						  { !!row.original.name && row.original.name.charAt(0) }
						</span>
					  </div>
					) : (
					  <div>
						<img
						  className="rounded-circle avatar-xs"
						  src={ row.original.user_img }
						  alt={ row.original.name }
						/>
					  </div>
					)}
				  </>
				)
            },
            {
                header: 'Name',
                enableColumnFilter: false,
				accessorKey: 'name',
            },
            {
                header: 'Email',
                enableColumnFilter: false,
				accessorKey: 'email',
            },
            {
                header: 'Role',
                cell: ({ row }) => (
                	    <span
                            className="badge badge-soft-primary font-size-11 m-1"
                        >
                            { row.original.role }
                        </span>
                )
            },
            {
                header: 'Actions',
				  cell: ({ cell }) => (
					<div className="d-flex gap-3">
					  <Link
						to={ `/user/${ cell.row.original.user_id }` }
						className="text-success"
						onClick={() => {
						  const userData = cell.row.original;
						  //handleAddClick(userData);
						}}
					  >
						<i className="mdi mdi-pencil font-size-18" id="edittooltip" />
						<UncontrolledTooltip placement="top" target="edittooltip">
						  Edit
						</UncontrolledTooltip>
					  </Link>
					  <Link
						to="#"
						className="text-danger"
						onClick={() => {
						  const userData = cell.row.original;
						  //onClickDelete(userData);
						}}
					  >
						<i className="mdi mdi-delete font-size-18" id="deletetooltip" />
						<UncontrolledTooltip placement="top" target="deletetooltip">
						  Delete
						</UncontrolledTooltip>
					  </Link>
					</div>
				  )
            },

        ],
        []
    );

	const defaultSort = [{ id: 'updated', desc: true }];

    return (
		<React.Fragment>
			<AddUserModal
				site={ site }
				show={ addModal }
				hideModal={ () => setAddModal(false) }
			/>
		  <DeleteModal
			//show={deleteModal}
			//onDeleteClick={handleDeleteUser}
			//onCloseClick={() => setDeleteModal(false)}
		  />
        <div className="page-content">
            <div className="container-fluid">
 					<Row>
						<Col className="col-10">
							<div className="page-title-box d-sm-flex align-items-center">
								<h4 className="mb-0 font-size-18">
									{ !!account && `${account.account_id} / USERS` }
								</h4>
							</div>
							{ !!error && (
								<Alert color="danger" role="alert">
								  { error.message }
								</Alert>
							) }
						</Col>
						{ !!loading && (
							<Col className="col-2 text-end">
								<Spinner color="secondary" size="sm" className="me-2" />
							</Col>
						) }
					</Row>
					<Row>
						<Col lg="12">
						  <Card>
							<CardBody>
				
								<TableContainer
									columns={columns}
									data={ users || []}
									isGlobalFilter={true}
									customPageSize={10}
									isPagination={true}
									isShowingPageLength={true}
									tableClass="table align-middle table-nowrap table-hover"
									theadClass="table-light"
									paginationDiv="col-sm-12 col-md-7"
									pagination="pagination pagination-rounded justify-content-end mt-4"
										buttonName="Add User"
										isAddButton={ true }
										handleAddClick={ () => setAddModal(true) }
								/>

							</CardBody>
						  </Card>
						</Col>
					</Row>
            </div>
        </div>
        </React.Fragment>
    );
}
UsersTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

const mapStatetoProps = state => {
  const { data, error, loading } = state.User;

	let account, site;
	if (state.Profile.data.account_id) {
		account = state.Profile.data.accounts[state.Profile.data.account_id];
	}
	if (state.Profile.data.site_id) {
		site = state.Profile.data.sites[state.Profile.data.site_id];
	}		


  return { account, site, users: data, error, loading };
};

export default connect(mapStatetoProps, {})(withTranslation()(UsersTable));
