// src/components/filter.
import React, { useState, useMemo, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import { connect, useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";

// actions
import {
	getVariantRequest,
	setVariantParams,
	putVariantRequest,
	updateEditor
} from "../../store/actions";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import EditableText from '../../components/Common/EditableText';
//import ContentEditableText from '../../components/Common/ContentEditableText';

import VariantPreview from '../../components/Common/VariantPreview';
import PublishingModal from "../../components/Common/PublishingModal";

import { BETTER_APPS, BETTER_APP_CATEGORIES } from '../../components/Common/BetterApps';

import EditIntro from './EditIntro';
import EditZooms from './EditZooms';
import EditMotions from './EditMotions';
import EditCaptions from './EditCaptions';
import EditStickers from './EditStickers';
import EditCrop from './EditCrop';
import EditColors from './EditColors';

import EditButtons from './EditButtons';
import EditMagnify from './EditMagnify';
import EditClick from './EditClick';
import EditScroll from './EditScroll';
import EditTilt from './EditTilt';

import EditPersonalize from './EditPersonalize';
import EditLocalize from './EditLocalize';
import EditParticles from './EditParticles';
import EditSlideshow from './EditSlideshow';
import EditEmail from './EditEmail';
import EditMenu from './EditMenu';
import EditSEO from './EditSEO';
import EditUpscale from './EditUpscale';
import EditTracking from './EditTracking';

import EditTarget from './EditTarget';

import {
  Spinner,
  Container, Row, Col,
  Input,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
	Nav, NavItem, NavLink,
  Button,
  UncontrolledTooltip,
  Alert, UncontrolledAlert
} from "reactstrap";

import classnames from "classnames";

//i18n
import { withTranslation } from "react-i18next";

// control style
import "./edit.scss";
	

const VariantEdit = ({ account, site, variant, dirty, loading, error, edge, editor, router }) => {

 	const { account_id, site_id, image_id, variant_id } = router.params;

    const dispatch = useDispatch();
    
	useEffect(() => {
		dispatch(getVariantRequest(account_id, site_id, image_id, variant_id));
		
		// default editor state
		if (typeof editor.app_id == 'undefined') {
			dispatch(updateEditor({ app_id: 'motions' }));
		}
		
		// Ctrl+r refresh
		const handleKeyPress = (event) => {
		  if (event.ctrlKey && event.key === 'r') {
			event.preventDefault(); // Prevent the default action (reloading the page)
			restartFrom(0);
		  }
		};

		// Add event listener when the component mounts
		document.addEventListener('keydown', handleKeyPress);

		// Clean up the event listener when the component unmounts
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
			// no longer editing
			dispatch(updateEditor({
				app_id: undefined
			}))
		};
	}, []);


	// polling too - intro (and soon colors) can setPolling
	const [ polling, setPolling ] = useState(false);
	const [ pollDelay, setPollDelay ] = useState(10000); // Initial delay of 5 seconds
	useEffect(() => {
        if (polling) {
            const id = setInterval(() => {
                dispatch(getVariantRequest(account_id, site_id, image_id, variant_id));
                setPollDelay((currentDelay) => Math.min(currentDelay + 5000, 30000));
            }, pollDelay);

            // Cleanup interval on component unmount or delay change
            return () => clearInterval(id);
        }
 	}, [polling, pollDelay]); 

	// reset poll delay
	useEffect(() => {
		setPollDelay(10000);
	}, [polling]); 


	// enhancement menu
	const [ moreMenu, setMoreMenu ] = useState(false);
	
	// remember the most recent 3
	useEffect(() => {
		if (editor.app_id && editor.app_id != 'more') {
			let current_apps = variant.params?.apps || ['motions', 'captions'];
			
			if (!current_apps.includes(editor.app_id)) {
				current_apps.push(editor.app_id);
				dispatch(setVariantParams(variant.variant_id, {
					apps: current_apps.slice(-3)
				}))
			}
		}
	}, [editor.app_id, variant.params?.apps]);

	// restart after captionFreeze stops
	useEffect(() => {
		if (editor.captionFreeze === false) {
			restartFrom(0);
		}
	}, [editor.captionFreeze]);

	// kept separate from state.editor
	// because that prevents react-select onchange event from firing
	const [ zoomMouseOverBox, setZoomMouseOverBox ] = useState();

	// animation restart counter
	const [ animationRestart, setAnimationRestart ] = useState({ begin: 0 });
	const restartFrom = (begin) => {
		// useEffect in VariantPreview will detect this and restart animations
		setAnimationRestart({
			begin,
			key: Date.now()
		});
	}
	
    //meta title
    document.title = `Better Images | ${variant.label}`;
    
    // save to backend from here or EditIntro (to generate stability i2v)
    // poll skipped when only saving label
    const saveVariant = (variant) => {
    	dispatch(putVariantRequest(account_id, site_id, image_id, variant_id, variant));
    }

	// display Publishing modal
	const location = useLocation();
	const [ publishingModalParams, setPublishingModalParams ] = useState({ 
		defaultTab: 'web',
		show: false 
	});
	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const publish = queryParams.get('publish');
	
		let publishParams = {}
	
		if (publish) {
			publishParams.show = true;

			const publishParts = publish.split('.');
			publishParams.defaultTab = publishParts[0];
			
			if (publishParts.length > 1) {
				publishParams.defaultOption = publishParts[1];
			}
		}
		
		if (publishParams) {
			setPublishingModalParams((params) => ({
				...params,
				...publishParams,
			}));
		}
		
	}, [location.search]);

    return (
		<React.Fragment>
			<PublishingModal 
				site={ site }
				variant={ variant }
				{ ...publishingModalParams }
				hideModal={ () => setPublishingModalParams((params) => ({
					...params,
					show: false
				})) }
			/>

        <div className="page-content">
            <div className="container-fluid">
            
				{ variant.variant_status && variant.variant_status != 'active' && (
					<UncontrolledAlert color="warning" role="alert" className="alert-dismissible">
						<i className="mdi mdi-alert me-1" />
						This image is not yet approved for use.
						{" "}
						<Link
							onClick={ () => saveVariant({
								...variant,
								variant_status: 'active'
							}) }
						>
							Approve
						</Link>.
					</UncontrolledAlert>
				) }
				
				<div className="page-title-box d-sm-flex align-items-center justify-content-between">
					<div
						style={{
							flex: '0 1 auto'
						}}
					>
						<Link to={ `/${account_id}/${site_id}/images` }>
							<Button
								type="button"
								color="secondary"
								className="btn mb-2 me-2"
							>
								&laquo; Back
							</Button>
						</Link>
					</div>

					<div
						style={{
							flex: '0 1 auto'
						}}
					>
						<Button
							type="button"
							color="secondary"
							className="btn mb-2 me-2"
							onClick={ () => restartFrom(0) }
						>
							<i className={ `mdi mdi-replay` } />
						</Button>
					</div>



					<h4 className="mb-2 font-size-18"
						style={{
							flex: 'auto'
						}}
					>
						<EditableText
							initialText={ variant.label || ' ' }
							onSave={ (value) => saveVariant({
								...variant,
								label: value
							}) }
						/>
					</h4>
					<div>
						{ edge?.status != 'stale' && (
							<Button
								type="button"
								color="primary"
								className="btn mb-2 me-2"
								disabled={ !dirty || loading }
								onClick={ () => saveVariant(variant)  }
							>
								{ loading && (
									<Spinner size="sm" className="me-2" />
								) }
								Save
							</Button>
						) }

						{ edge?.status == 'stale' && (
							<Button
								type="button"
								color="primary"
								className="btn mb-2 me-2"
								disabled={ true }
							>
								<Spinner size="sm" className="me-2" />
								Compiling
							</Button>
						) }

						<Button
							type="button"
							color="light"
							className="btn mb-2 me-2"
							onClick={ () => setPublishingModalParams((params) => ({
								...params,
								show: true 
							})) }
						>
							Publishing
						</Button>
						
							<Dropdown
								isOpen={moreMenu}
								className="d-inline-block"
								toggle={() => setMoreMenu(!moreMenu)}
							>
								<DropdownToggle color="link" className="btn btn-link p-0 mb-2" caret>
									<i className="mdi mdi-dots-vertical font-size-16" />
								</DropdownToggle>
								<DropdownMenu>						
									<Link 
										to={ `/${variant.account_id}/${variant.site_id}/${variant.image_id}/results` }
									>
										<DropdownItem>
											Results
										</DropdownItem>
									</Link>
								</DropdownMenu>
							</Dropdown>
					</div>
				</div>
                
				<Row>
					<Col md={6} xl={8}>
					  <Card>
						<CardBody>
						
							{ !Object.keys(variant).length ? (
								<Spinner color="primary" className="m-3" />
															
							) : (
								<div 
									style={ variant.params.tilt?.active ? {
										position: 'relative',
										boxShadow: 'inset 0 0 100px black',
										overflow: 'hidden',
										backgroundColor: '#808080'
									} : {
										position: 'relative'
									} }
								>
									<VariantPreview 
										key={ variant.variant_id }
										variant={ variant }
										width={ 2048 }
										animationRestart={ animationRestart }										
										zoomMouseOverBox={ zoomMouseOverBox }
										edge={ edge }
									/>
								</div>
							) }
						</CardBody>
					  </Card>
					</Col>
									
					<Col md={6} xl={4} >
						<Nav tabs className="bi-apps align-items-center">
							{ !!variant.params && (variant.params?.apps || ['motions', 'captions', 'buttons']).map((app_id, idx) => {
								if (BETTER_APPS[app_id]) {
									return (
										<NavItem key={ app_id }>
										  <NavLink
											className={classnames({
											  active: editor.app_id == app_id,
											})}
											onClick={ () => dispatch(updateEditor({ app_id })) }
										  >
											<i className={ `mdi mdi-${BETTER_APPS[app_id].icon} me-1` } />
											{ BETTER_APPS[app_id].label || app_id }
										  </NavLink>
										</NavItem>									
									)
								}
							}) }
							<NavItem style={{
								marginLeft: 'auto'
							}}>
								<NavLink
									onClick={() => {
										dispatch(updateEditor({ app_id: 'more' }));
									}}
								>
									More &rsaquo;
								</NavLink>
							</NavItem>
						</Nav>
						{ editor.app_id == 'more' && (						
			  				<Card>
								<CardBody className="bi-more-apps scrollable">
									{ BETTER_APP_CATEGORIES.map((category) => {
										return (
											<Card key={ category }>		
												<CardHeader>
													<strong>{ category[0] }</strong>
												</CardHeader>
												<CardBody>
													<Row className="align-items-center ">				
														{ category[1].map((app_id) => {
															
															const app = BETTER_APPS[app_id];
															const btn_color = (variant.params[app_id] && variant.params[app_id].active !== false) ? 'info' : 'light';
															
															return (
																<Col 
																	xs={4} xl={6} xxl={4}
																	key={ app_id }
																>		
																  <button
																	type="button"
																	className={ `btn btn-${btn_color} w-sm mb-3` }
																	onClick={ () => dispatch(updateEditor({ app_id })) }
																	id={ `app-${app_id}` }
																	disabled={ app[site?.site_type] === false }
																  >
																	<i 
																		className={ `mdi mdi-${app.icon} d-block font-size-16` }
																	></i>{" "}
																	{ app.label || app_id }
																  </button>
									
																	{ !!app.tooltip && (
																		<UncontrolledTooltip placement="top" target={ `app-${app_id}` }>
																		  { app.tooltip }
																		</UncontrolledTooltip>
																	) }
																</Col>
															)
														}) }
													</Row>
												</CardBody>
											</Card>
										)
									}) }
								</CardBody>
						  	</Card>
						) }

						{ editor.app_id == 'intro' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditIntro
										variant={ variant }
										restartFrom={ restartFrom }		
										saveVariant={ saveVariant }
										setPolling={ setPolling }	
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'zooms' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditZooms
										variant={ variant }
										restartFrom={ restartFrom }					
										setZoomMouseOverBox={ setZoomMouseOverBox }
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'motions' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditMotions
										variant={ variant }
										restartFrom={ restartFrom }					
										setZoomMouseOverBox={ setZoomMouseOverBox }
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'captions' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditCaptions
										variant={ variant }
										restartFrom={ restartFrom }											
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'buttons' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditButtons
										variant={ variant }
										site={ site }
										restartFrom={ restartFrom }	
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'stickers' && (
						  <Card className="bi-app-stickers">
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditStickers
										variant={ variant }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'crop' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditCrop
										variant={ variant }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'magnify' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditMagnify
										variant={ variant }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'tilt' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditTilt
										variant={ variant }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'colors' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditColors
										variant={ variant }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'click' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditClick
										variant={ variant }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'scroll' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditScroll
										variant={ variant }
										site={ site }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ ['geo', 'language', 'time'].includes(editor.app_id) && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditTarget
										variant={ variant }
										target={ editor.app_id }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'personalize' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditPersonalize
										variant={ variant }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'localize' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditLocalize
										variant={ variant }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }
						
						{ editor.app_id == 'particles' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditParticles
										variant={ variant }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'slideshow' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditSlideshow
										variant={ variant }
										site={ site }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'email' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditEmail
										variant={ variant }
										site={ site }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'menu' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditMenu
										variant={ variant }
										site={ site }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'seo' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditSEO
										variant={ variant }
										site={ site }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'upscale' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditUpscale
										variant={ variant }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }

						{ editor.app_id == 'tracking' && (
						  <Card>
							<CardBody className="scrollable">
								{ !!Object.keys(variant).length && (
									<EditTracking
										variant={ variant }
										site={ site }
										restartFrom={ restartFrom }		
									/>
								) }					
							</CardBody>
						  </Card>
						) }
						
						{ editor.app_id == 'debug' && (
						  <Card>
							<CardBody className="scrollable">
								{ variant && (
									<pre>{ JSON.stringify(variant, null, "\t") }</pre>
								) }
							</CardBody>
						  </Card>
						) }

					</Col>
				</Row>
            </div>
        </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state, ownProps) => {
	const { data, dirty, loading, error, edge, editor } = state.Variant;
	
	// lookup account and site
	let account;
	if (ownProps.router.params.account_id) {
		account = state.Profile.data.accounts[ownProps.router.params.account_id];
	}
	
	let site;
	if (ownProps.router.params.site_id) {
		site = state.Profile.data.sites[ownProps.router.params.site_id];
	}
	
	// tmp. testing email
	//site.site_type = 'email';
	
	return { account, site, variant: data, dirty, loading, error, edge, editor };
};

export default withRouter(connect(mapStateToProps)(VariantEdit));