import {
	EXTERNAL_AUTH,
	AUTH_SESSION,
	SESSION_SUCCESS,
	REFRESH_SESSION,
	QUEUE_RESET,
	QUEUE_ADD,
    AUTH_LOGOUT,
	SET_ACCESS_TOKEN,
} from "./actionTypes"

// copy credentials from localStorage to redux store
// accessToken, refreshToken
const authUser = JSON.parse(localStorage.getItem("authUser"));

const initialState = {
  ...authUser,
  loading: false,
  refreshQueue: []		// axios requests that will need retrying if session is refreshed
}

const Auth = (state = initialState, action) => {
	console.log('action', action);

  switch (action.type) {
    case AUTH_SESSION:
      return {
        ...state,
        loading: true,
      }

    case SESSION_SUCCESS:
      return {
        ...state,
        ...action.payload.authUser,
        loading: false
      }

    case REFRESH_SESSION:
      return {
        ...state,
        loading: true,
        refreshQueue: [
        	action.payload.request
        ]
      }

    case QUEUE_RESET:
      return {
        ...state,
        refreshQueue: action.payload.refreshQueue,
      }

    case QUEUE_ADD:
      return {
        ...state,
        refreshQueue: [
        	...state.refreshQueue,
        	action.payload.action
        ]
      }
    
    // canva
    case SET_ACCESS_TOKEN:
    	return {
        	...state,
        	accessToken: action.payload.accessToken
    	}
    
    default:
      return state;
  }
}

export default Auth
