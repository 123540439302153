import {
	// get variant
	GET_VARIANT_REQUEST,
	GET_VARIANT_SUCCESS,
	GET_VARIANT_FAILURE,
	
	// update local state
	SET_VARIANT_PARAMS,
	
	// save state to backend
	PUT_VARIANT_REQUEST,
	PUT_VARIANT_SUCCESS,
	PUT_VARIANT_FAILURE,
	
	// update result from controller refresh, are we still compiling?
	GET_EDGE_REQUEST,
	GET_EDGE_SUCCESS,
	GET_EDGE_FAILURE,
	
	UPDATE_EDITOR,

	// get heatmap
	GET_HEATMAP_REQUEST,
	GET_HEATMAP_SUCCESS,
	GET_HEATMAP_FAILURE,

} from "./actionTypes"



export const getVariantRequest = (account_id, site_id, image_id, variant_id) => ({
  type: GET_VARIANT_REQUEST,
  payload: { account_id, site_id, image_id, variant_id }
});
export const getVariantSuccess = (variant) => ({
  type: GET_VARIANT_SUCCESS,
  payload: variant,
});
export const getVariantFailure = (error) => ({
  type: GET_VARIANT_FAILURE,
  payload: error,
});

export const setVariantParams = (variant_id, params) => ({
  type: SET_VARIANT_PARAMS,
  payload: { variant_id, params }
});

export const putVariantRequest = (account_id, site_id, image_id, variant_id, variant) => ({
  type: PUT_VARIANT_REQUEST,
  payload: { account_id, site_id, image_id, variant_id, variant },
})
export const putVariantSuccess = (variant) => ({
  type: PUT_VARIANT_SUCCESS,
  payload: variant,
})
export const putVariantFailure = (error) => ({
  type: PUT_VARIANT_FAILURE,
  payload: error,
});


export const getEdgeRequest = (site_id, image_id, variant_id, edgeChecks, target_version, wait) => ({
  type: GET_EDGE_REQUEST,
  payload: { site_id, image_id, variant_id, edgeChecks, target_version, wait }
});
export const getEdgeSuccess = (edge) => ({
  type: GET_EDGE_SUCCESS,
  payload: { edge },
});
export const getEdgeFailure = (error) => ({
  type: GET_EDGE_FAILURE,
  payload: error,
});

export const updateEditor = (params) => ({
  type: UPDATE_EDITOR,
  payload: { params },
});

export const getHeatmapRequest = (account_id, site_id, image_id, variant_id) => ({
  type: GET_HEATMAP_REQUEST,
  payload: { account_id, site_id, image_id, variant_id }
});
export const getHeatmapSuccess = (variant) => ({
  type: GET_HEATMAP_SUCCESS,
  payload: variant,
});
export const getHeatmapFailure = (error) => ({
  type: GET_HEATMAP_FAILURE,
  payload: error,
});
