import React, { useRef, useState, useEffect } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip, 
  FormFeedback,
  Nav, NavItem, NavLink,
  Alert
} from "reactstrap";
import Select from 'react-select';

import classnames from "classnames";

//import Slider from "react-rangeslider"
//import "react-rangeslider/lib/index.css"

// actions
// dispatch actions:  box, begin, end, zoom, transition, ? manual zoom
import { useDispatch, useSelector } from "react-redux";
import { setVariantParams, updateEditor } from "../../store/actions";

// control style
import "./zoom.scss";


// controls for editing variant.params.zooms
//
const EditZooms = ({ variant, restartFrom, setZoomMouseOverBox }) => {

	const editor = useSelector(state => state.Variant.editor);
	const dispatch = useDispatch();
	
	const full_image_label = 'Full Image';
	const box_full_image = `000000999999:${full_image_label}`;
	const default_zoom = {
		box: box_full_image,
		//label: full_image_label,
		zoom: 1,
		begin: 0,
		transition: 'pan',
		end: 1
	};

	const zooms = variant.params?.zooms || [];

	// lookup focus desriptions
	const [ story, setStory ] = useState({});
	useEffect(() => {
		/*
		if (typeof variant.params.zooms == 'undefined') {
			dispatch(setVariantParams(variant.variant_id, { 
				zooms: []
			}));
		}
		*/
	
		if (typeof editor.zoomIdx == 'undefined') {
			dispatch(updateEditor({ zoomIdx: 0 }));
		}

		if (variant.gpt4_result?.story) {
			setStory(variant.gpt4_result.story.reduce((acc, item) => {
				acc[item.feature] = item;
				return acc;
			}, {}));
		}
	}, []);

	function getZoomValue(idx, field, default_value) {
		if (idx >= zooms.length) return default_value;
	
		let rv = zooms[idx][field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// update params
	const setZoomParams = (idx, params) => {
	
		dispatch(setVariantParams(variant.variant_id, { 
			zooms: [
				...zooms.slice(0, idx),
				{
					...zooms[idx],
					...params
				},
				...zooms.slice(idx + 1),		
			] 
		}));
		
		setZoomMouseOverBox(null);

		// restart from idx offset
		restartFrom(zooms[idx].begin || 0);
	}

	const getOption = (idx) => {
		return {
			label: getZoomValue(idx, 'label'),
			value: getZoomValue(idx, 'box')
		}
	};

	// list of focal boxes
	const getBoxOptions = (idx) => {
		if (variant.variant_status == 'loading') {
			return [{
				label: 'Loading...',
				value: 'loading'
			}];

		} else {
			let box_options = [{
				label: 'Full Image',
				value: box_full_image
			}];

			box_options = box_options.concat(Object.entries(variant.focus).map(([label, values]) => {
				return {
					label: label,
					value: values.box
				}
			}));

			const box_value = getZoomValue(idx, 'box');
			
			// already manual?
			if (box_value && box_value != box_full_image && !Object.values(variant.focus).map((values) => {
				return values.box
			}).includes(box_value)) {
				box_options = box_options.concat([
					{
						label: 'Manual Focus',
						value: box_value
					},
					{
						label: 'Change Manual Focus...',
						value: 'capture'
					}
				]);
			} else {
				box_options = box_options.concat([
					{
						label: 'Set Manual Focus...',
						value: 'capture'
					}
				]);
			}

			return box_options;
		}
	}
	
	const setFocus = (idx, option) => {

		// manual focus
		if (option.value == 'capture') {	
			dispatch(updateEditor({
				manualFocusZoomIdx: idx
			}))

		} else {
			// chosen from list
			setZoomParams(idx, {
				'box': option.value,
				'label': option.label, 
				'zoom': (option.value == box_full_image) ? 1 : 1.5 
			});	

			dispatch(updateEditor({
				manualFocusZoomIdx: undefined
			}))
		}
	}
	
	// Custom Option component
	const optionWithMouseOver = ({ innerProps, data }) => {

		return (
			<div { ...innerProps }
				onMouseOver={ () => {
					setZoomMouseOverBox(data.value);
				} }
				onMouseOut={ () => {
					setZoomMouseOverBox(null);
				} }
				className='focus-option'
			>
				{ data.label }
			</div>
		);
	};

	// calculate the lower zoom bound. lower would extend beyond one of the image borders
	const getMinBoxAdj = (idx) => {
		const _box = getZoomValue(idx, 'box');

		const box_x = parseFloat('0.'+_box.substring(0, 3));
		const box_y = parseFloat('0.'+_box.substring(3, 6));

		const box_w = parseFloat('0.'+_box.substring(6, 9));
		const box_h = parseFloat('0.'+_box.substring(9, 12));

		// calculate limits
		const west = (box_w/2) / (box_x + box_w/2);
		const east = (box_w/2) / (1 - (box_x + box_w/2));

		const north = (box_h/2) / (box_y + box_h/2);
		const south = (box_h/2) / (1 - (box_y + box_h/2));

		return Math.max(north, east, south, west);
	};

	const addZoom = () => {
		let begin = 0;
		let end = 1;
		if (zooms.length) {
			begin = 2 + Math.max(...zooms.map(function(zoom) {
				return Math.max(parseFloat(zoom.begin || 0), parseFloat(zoom.end || 0));
			} ));
			end = begin + 1;
		}
		
		dispatch(setVariantParams(variant.variant_id, { 
			zooms: [
				...zooms || [],
				{
					...default_zoom,
					begin: begin,
					end: end
				}
			] 
		}));

		// add one!
		dispatch(updateEditor({ zoomIdx: zooms.length }));

		//restartFrom(0);
	}
	
	const deleteZoom = (idx) => {

		dispatch(updateEditor({ zoomIdx: 0 }));
	
		if (zooms.length == 1) {
			dispatch(setVariantParams(variant.variant_id, { 
				zooms: [] 
			}));
			
		} else {
			// adjust the previous
			dispatch(setVariantParams(variant.variant_id, { 
				zooms: [
					...zooms.slice(0, idx),
					...zooms.slice(idx + 1)
				] 
			}));
		}

		//restartFrom(0);
	}

	let focalNotes
	if (editor.zoomIdx) {
		focalNotes = variant.focus[ getZoomValue(editor.zoomIdx, 'label') ]?.notes;
	}	

	return (
		<>
			{ !!window.matchMedia && window.matchMedia('(prefers-reduced-motion)').matches && (
				<Alert color="info" role="alert">
					Zoom effects are currently disabled on this device because the device is configured for <Link
						to="https://www.boia.org/blog/what-to-know-about-the-css-prefers-reduced-motion-feature"
						target="_blank"
					>
						Reduced Motion					
					</Link>. Disable this setting to preview your zooms.
				</Alert>			
			) }

			<Nav tabs className="align-items-center">
				{ zooms.map((zoom, idx) => {
					return (
						<NavItem key={ idx }>
							<NavLink
								className={ classnames({
									active: editor.zoomIdx == idx,
								}) }
								onClick={ () => {
									dispatch(updateEditor({ zoomIdx: idx }));
									restartFrom(zoom.begin || 0);
								} }
							>
								{ zoom.label }
								<i className="mdi mdi-close-box ms-1" 
									onClick={ () => deleteZoom(idx) } 
								/>
							</NavLink>
						</NavItem>									
					)
				}) }
				
				<NavItem style={{
					marginLeft: 'auto'
				}}>
					<NavLink
						onClick={ addZoom }
					>
						+ Add
					</NavLink>
				</NavItem>
			</Nav>

			{ typeof editor.zoomIdx != 'undefined' && editor.zoomIdx < zooms.length && (

				<>
					<Card>		
						<CardBody>
							<Row>
								<Col>
									<label className="form-label">Focus</label>
								</Col>
							</Row>								
							<Row className="align-items-center mb-3">
								<Col>
									<Select key={ getZoomValue(editor.zoomIdx, 'box') }
										className="focus-select"
										classNamePrefix="select"
										defaultValue={ getOption(editor.zoomIdx) }
										options={ getBoxOptions(editor.zoomIdx) }
										onChange={ (option) => setFocus(editor.zoomIdx, option) }
										isSearchable={ false }
										components={{
											Option: optionWithMouseOver
										}}
									/>
								</Col>						
							</Row>

							{ !!focalNotes && (
								<Row>
									<Col className="mb-3">
										<small>
											<i className="mdi mdi-lightbulb-on-outline me-1" />
											{ focalNotes }
										</small>					
									</Col>
								</Row>							
							) }
							<Row className="align-items-top">
								<Col>
									<label className="form-label">Begin</label>
									<Input
										type="number"
										min={ 0 }
										max={ getZoomValue(editor.zoomIdx, 'end') || 9e9 }
										step={ 0.1 }
										value={ getZoomValue(editor.zoomIdx, 'begin', 1) }
										onChange={ (event) => setZoomParams(editor.zoomIdx, {
											'begin': parseFloat(event.target.value)
										}) }
									/>
								</Col>		
								<Col>
									<label className="form-label">Zoom</label>
									<Input
										type="range"
										value={ getZoomValue(editor.zoomIdx, 'zoom', 1) }
										onChange={ (event) => setZoomParams(editor.zoomIdx, {
											'zoom': parseFloat(event.target.value) 
										}) }
										min={ getMinBoxAdj(editor.zoomIdx) }
										max={ 2.5 }
										step={ 0.1 }
										className="mt-2 mb-2"
									/>
								</Col>						
							</Row>								
						</CardBody>
					</Card>
				
					{ editor.zoomIdx != zooms.length - 1 && (
						<Card>
							<CardHeader>
								<strong>Transition to { getZoomValue(editor.zoomIdx + 1, 'label') }</strong>
							</CardHeader>						
							<CardBody>
								<Row className="align-items-top">
									<Col>
										<label className="form-label">Effect</label>
										<Input
											type="select"
											value={ getZoomValue(editor.zoomIdx, 'transition') }
											onChange={ (event) => setZoomParams(editor.zoomIdx, {
												'transition': event.target.value 
											}) }
										>
											<option value="cut">Cut</option>
											<option value="pan">Pan</option>
										</Input>
									</Col>
									{ getZoomValue(editor.zoomIdx, 'transition') == 'pan' && (
										<Col>
											<label className="form-label">Begin</label>
											<Input
												type="number"
												min={ getZoomValue(editor.zoomIdx, 'begin') }
												step={ 0.1 }
												value={ getZoomValue(editor.zoomIdx, 'end', 1) }
												onChange={ (event) => setZoomParams(editor.zoomIdx, {
													'end': parseFloat(event.target.value)
												}) }
											/>								
										</Col>		
									) }
								</Row>
							</CardBody>
						</Card>
					) }	
				</>
			
			) }

		</>
	)
}

export default EditZooms;