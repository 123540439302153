// src/components/filter.
import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";

import { connect, useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";

// actions
import { 
	selectSite,
	showModalSite,
	showModalDelete,
	getSiteSuccess,
	putSiteRequest
} from "../../store/actions";

//import components
import DeleteModal from "../../components/Common/DeleteModal";
import TableContainer from '../../components/Common/TableContainer';
import AddSiteModal from '../../components/Common/AddSiteModal';

import {
  Spinner,
  Row, Col,
  Card, CardBody,
  Button,
  UncontrolledTooltip,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Input,
  Nav, NavLink, NavItem
} from "reactstrap";

import classnames from "classnames";

//i18n
import { withTranslation } from "react-i18next";

const SitesTable = ({ account, sites, site, loading, error, modals }) => {

    //meta title
    document.title = "Better Images | Sites";

    const dispatch = useDispatch();

	// filter on web vs. email
	sites = useMemo(
		() => Object.values(sites).filter((site) => site.account_id == account?.account_id && site.site_type == 'web'),
		[account?.account_id, loading]
	)

	const changeSite = (site_id) => {
		dispatch(selectSite(site_id));
	}

    const columns = useMemo(
        () => [
			{
				header: 'Updated',
				accessorKey: 'updated',
			},
            {
                header: 'Site',
                enableColumnFilter: false,
                cell: ({ row }) => (
					<>
						{ row.original.domain && (
							<img 
								src={ `https://www.google.com/s2/favicons?sz=128&domain_url=${row.original.domain}` } 
								width="48"
							/>
						) }

						{ false && (				
							<div style={{
								width: '200px',
								height: '100px',
								overflow: 'hidden'
							}}>
								{ row.original.screenshots && (
									<img 
										src={ `https://crawl.${process.env.REACT_APP_CONTROLLER}/c/${row.original.account_id}/${row.original.site_id}/main-desktop.jpeg` } 
										style={{
											width: '100%',
											//height: '100%',
											objectFit: 'cover',
											objectPosition: 'top'
										}}
									/>
								) }
							</div>
						) }
					</>
                )
            },
			{
                header: 'Name',
                enableColumnFilter: false,
                accessorKey: 'sitename',
            },
            {
                header: 'URL',
                enableColumnFilter: false,
                accessorKey: 'site_url'
            },
			{
                header: 'Status',
                enableColumnFilter: false,
                accessorKey: 'site_status',
            },
            {
                header: 'Role',
                cell: ({ row }) => (
                	    <span
                            className="badge badge-soft-primary font-size-11 m-1"
                        >
                            { row.original.role }
                        </span>
                )
            },
			{
                header: 'Images',
				cell: ({ row }) => (
					  <Link
						to={ `/${row.original.account_id}/${row.original.site_id}/images` }
						onClick={() => {
						  changeSite(row.original.site_id)
						}}
					  >
						View
					  </Link>
				)
            },
            {
                header: 'Users',
				cell: ({ row }) => (
					row.original.role.includes('admin') && (
					  <Link
						to={ `/${row.original.account_id}/${row.original.site_id}/users` }
						onClick={() => {
						  changeSite(row.original.site_id)
						}}
					  >
						View
					  </Link>
					)
				)
            },
            {
                header: 'Actions',
				  cell: ({ cell }) => (
					<div className="d-flex gap-3">
					  <Link
						to="#"
						className="text-success"
						onClick={ () => {
							dispatch(getSiteSuccess(cell.row.original));
							setShowEditSiteModal(true);
						} }
					  >
						<i className="mdi mdi-pencil font-size-18" id="edittooltip" />
						<UncontrolledTooltip placement="top" target="edittooltip">
						  Edit
						</UncontrolledTooltip>
					  </Link>
					  <Link
						to="#"
						className="text-danger"
						onClick={ () => {
							dispatch(showModalDelete('site', cell.row.original))
						} }
					  >
						<i className="mdi mdi-delete font-size-18" id="deletetooltip" />
						<UncontrolledTooltip placement="top" target="deletetooltip">
						  Delete
						</UncontrolledTooltip>
					  </Link>
					</div>
				  )
            },

        ],
        []
    );

	const defaultSort = [{ id: 'updated', desc: true }];

	const [ showEditSiteModal, setShowEditSiteModal ] = useState(false);

	const getSiteValue = (field, default_value) => {
		let rv = (site || {})[field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}
	
	const setSiteParams = (params) => {
		dispatch(getSiteSuccess({
			...site,
			...params
		}))
	};
	
	const [ activeTab, setActiveTab ] = useState('Goals');
	
	const default_manual_goals = {
		lead: 'subscribe\nregister\nget started',
		cart: 'add to basket',
		sale: 'checkout\npay'
	}
	
	const saveSite = () => {
		dispatch(putSiteRequest(site.site_id, {
			account_id: site.account_id,
			google_site_verification: site.google_site_verification,
			email_service: site.email_service,
			view_notify_type: site.view_notify_type,
			view_notify: site.view_notify,
			goal_click_auto: getSiteValue('goal_click_auto', true),
			goal_lead_auto: getSiteValue('goal_lead_auto', true),
			goal_lead_manual: getSiteValue('goal_lead_manual', default_manual_goals.lead),
			goal_cart_auto: getSiteValue('goal_cart_auto', true),
			goal_cart_manual: getSiteValue('goal_cart_manual', default_manual_goals.cart),
			goal_sale_auto: getSiteValue('goal_sale_auto', true),		
			goal_sale_manual: getSiteValue('goal_sale_manual', default_manual_goals.sale),		
		}));
	}

    return (
		<React.Fragment>
			<AddSiteModal
				account={ account }	
				site={ site }
				show={ modals.site }
			/>

			<Modal 
				isOpen={ showEditSiteModal } 
				toggle={ () => setShowEditSiteModal(false) }
			>
				<ModalHeader 
					toggle={ () => setShowEditSiteModal(false) }
					tag="h4"
				>
					{ site.sitename }
				</ModalHeader>
				<ModalBody>
					<Nav tabs className="bi-apps align-items-center">
						{ ['Goals', 'Other'].map((tab, idx) => {
							return (
								<NavItem
									key={ idx }
								>
									<NavLink
										className={ classnames({
											active: activeTab === tab,
										}) }
										onClick={ () => setActiveTab(tab) }
									>
										{ tab }
									</NavLink>
								</NavItem>
							)					
						}) }
					</Nav>

					{ activeTab == 'Goals' && (
						<>
							<Row className="mt-3">
								<Col>
									Train Better Images to show the best images, and improve its image suggestions, based on these events:
								</Col>
							</Row>
							
							<Row className="mt-3">
								<Col>
									<label className="form-label">Clicks</label>
									<div
									  className="form-check form-switch form-switch-md mb-3"
									>
									  <input
										type="checkbox"
										className="form-check-input"
										id="goal_click_auto"
										checked={ getSiteValue('goal_click_auto', true) }
										onChange={ (event) => setSiteParams({
											goal_click_auto: event.target.checked
										}) }
									  />
									  <label
										className="form-check-label"
										htmlFor="goal_click_auto"
									  >
										Automatically detect click events
									  </label>
									</div>
								</Col>
							</Row>
					
							<Row className="mt-3">
								<Col>
									<label className="form-label">Leads</label>
									<div
									  className="form-check form-switch form-switch-md mb-3"
									>
									  <input
										type="checkbox"
										className="form-check-input"
										id="goal_lead_auto"
										checked={ getSiteValue('goal_lead_auto', true) }
										onChange={ (event) => setSiteParams({
											goal_lead_auto: event.target.checked
										}) }
									  />
									  <label
										className="form-check-label"
										htmlFor="goal_lead_auto"
									  >
										Automatically detect lead generation events
									  </label>
									</div>
								</Col>
							</Row>					
							{ site.goal_lead_auto === false && (
								<Row>
									<Col>
										<textarea 
											rows={3}
											style={{
												border: 'var(--bs-border-width) solid var(--bs-border-color-translucent)',
												borderRadius: 'var(--bs-border-radius)',
												width: '100%',
												padding: '0.47rem 0.75rem'
											}}
											value={ getSiteValue('goal_lead_manual', default_manual_goals.lead) }
											onChange={ (event) => setSiteParams({
												goal_lead_manual: event.target.value
											}) }
										/>
										<small>
											Clicks on buttons and links with these keywords will be considered a lead generation event. Enter each keyword on a separate line. Regex supported.
										</small>
									</Col>
								</Row>
							) }

							<Row className="mt-3">
								<Col>
									<label className="form-label">Carts</label>
									<div
									  className="form-check form-switch form-switch-md mb-3"
									>
									  <input
										type="checkbox"
										className="form-check-input"
										id="goal_cart_auto"
										checked={ getSiteValue('goal_cart_auto', true) }
										onChange={ (event) => setSiteParams({
											goal_cart_auto: event.target.checked
										}) }
									  />
									  <label
										className="form-check-label"
										htmlFor="goal_cart_auto"
									  >
										Automatically detect cart events
									  </label>
									</div>
								</Col>
							</Row>					
							{ site.goal_cart_auto === false && (
								<Row>
									<Col>
										<textarea 
											rows={3}
											style={{
												border: 'var(--bs-border-width) solid var(--bs-border-color-translucent)',
												borderRadius: 'var(--bs-border-radius)',
												width: '100%',
												padding: '0.47rem 0.75rem'
											}}
											value={ getSiteValue('goal_cart_manual', default_manual_goals.cart) }
											onChange={ (event) => setSiteParams({
												goal_cart_manual: event.target.value
											}) }
										/>
										<small>
											Clicks on buttons and links with these keywords will be considered a cart event. Enter each keyword on a separate line. Regex supported.
										</small>
									</Col>
								</Row>
							) }

							<Row className="mt-3">
								<Col>
									<label className="form-label">Sales</label>
									<div
									  className="form-check form-switch form-switch-md mb-3"
									>
									  <input
										type="checkbox"
										className="form-check-input"
										id="goal_sale_auto"
										checked={ getSiteValue('goal_sale_auto', true) }
										onChange={ (event) => setSiteParams({
											goal_sale_auto: event.target.checked
										}) }
									  />
									  <label
										className="form-check-label"
										htmlFor="goal_sale_auto"
									  >
										Automatically detect sale events
									  </label>
									</div>
								</Col>
							</Row>					
							{ site.goal_sale_auto === false && (
								<Row>
									<Col>
										<textarea 
											rows={3}
											style={{
												border: 'var(--bs-border-width) solid var(--bs-border-color-translucent)',
												borderRadius: 'var(--bs-border-radius)',
												width: '100%',
												padding: '0.47rem 0.75rem'
											}}
											value={ getSiteValue('goal_sale_manual', default_manual_goals.sale) }
											onChange={ (event) => setSiteParams({
												goal_sale_manual: event.target.value
											}) }
										/>
										<small>
											Clicks on buttons and links with these keywords will be considered a sale event. Enter each keyword on a separate line. Regex supported.
										</small>
									</Col>
								</Row>
							) }

						</>
					) }


					{ activeTab == 'Other' && (
						<>
							<Row className="mt-3">
								<Col>
									<label className="form-label">Google Site Verification</label>
									<Input
										type="text"
										placeholder="google123456789.html"
										value={ site?.google_site_verification }
										onChange={ (event) => setSiteParams({
											google_site_verification: event.target.value
										}) }
									/>
									<small>
										{ !!site?.google_site_verification && `Property: https://${site.site_id}.${process.env.REACT_APP_CONTROLLER}` }
									</small>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col>
									<label className="form-label">Email Service</label>
									<Input
										type="select"
										value={ site?.email_service }
										onChange={ (event) => setSiteParams({
											email_service: event.target.value
										}) }
									>
										<option value="braze">Braze</option>
										<option value="mailchimp">MailChimp</option>
										<option value="sfmc">Salesforce</option>
									</Input>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col xs={ 6 }>
									<label className="form-label">View Receipts</label>
									<Input
										type="select"
										value={ site?.view_notify_type }
										onChange={ (event) => setSiteParams({
											view_notify_type: event.target.value
										}) }
									>
										<option value="">-</option>
										<option value="email">Email</option>
										<option value="slack">Slack</option>
										<option value="url">URL</option>
									</Input>
								</Col>
								{ !!site?.view_notify_type && (
									<Col xs={ 6 }>
										{ site?.view_notify_type == 'email' && <label className="form-label">Recipient</label> }
										{ site?.view_notify_type == 'slack' && <label className="form-label">Webhook URL</label> }
										{ site?.view_notify_type == 'url' && <label className="form-label">URL</label> }
										<Input
											type="text"
											value={ site?.view_notify }
											onChange={ (event) => setSiteParams({
												view_notify: event.target.value
											}) }
										/>
									</Col>
								) }
							</Row>					
						</>
					) }

				</ModalBody>
				<ModalFooter>
					<Button
						type="button"
						color="primary"
						className="btn mb-2 me-2"
						onClick={ saveSite }
					>
						{ loading && (
							<Spinner size="sm" className="me-2" />
						) }
						Save
					</Button>
				</ModalFooter>
			</Modal>

		  <DeleteModal
			show={ !!modals.delete }
			delete_type={ modals.delete.delete_type }
			delete_params={ modals.delete.delete_params }
		  />
			<div className="page-content">
				<div className="container-fluid">
					<Row>
						<Col className="col-10">
							<div className="page-title-box d-sm-flex align-items-center">
								<h4 className="mb-0 font-size-18">
									{ !!account && `${account.account_id} / SITES` }
								</h4>
							</div>
							{ !!error && (
								<Alert color="danger" role="alert">
								  { error.message }
								</Alert>
							) }
						</Col>
						{ !!loading && (
							<Col className="col-2 text-end">
								<Spinner color="secondary" size="sm" className="me-2" />
							</Col>
						) }
					</Row>
					<Row>
						<Col col="12">
							<Card>
								<CardBody>
									<TableContainer
										columns={columns}
										data={ sites || [] }
										isGlobalFilter={true}
										customPageSize={10}
										isPagination={true}
										isShowingPageLength={true}
										tableClass="table align-middle table-hover"
										theadClass="table-light"
										paginationDiv="col-sm-12 col-md-7"
										pagination="pagination pagination-rounded justify-content-end mt-4"
										buttonName="Add Site"
										isAddButton={ true }
										handleAddClick={ () => dispatch(showModalSite()) }
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
        </React.Fragment>
    );
}

const mapStateToProps = (state, ownProps) => {
	const { data, loading, error, modals } = state.Profile;

	// lookup account
	let account;
	if (ownProps.router.params.account_id) {
		account = state.Profile.data.accounts[ownProps.router.params.account_id];
	}

	return { account, sites: data.sites, site: data.site, loading, error, modals };
};

export default withRouter(connect(mapStateToProps)(SitesTable));
