import React from "react";

import { 
	Spinner,
	Row, Col, 
	Modal, ModalHeader, ModalBody, ModalFooter,
	Button,
	Alert,
	Form, Label, Input, FormFeedback,
} from "reactstrap"

import withRouter from "./withRouter";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useDispatch } from "react-redux";

//import GeneratingReport from './GeneratingReport';
// control style
import "./site.scss";

// actions
import {
	hideModalSite,
	postSiteRequest,
	gotoLibrary,
} from "../../store/actions";


const AddSiteModal = ({ show, account, site, router }) => {

    const dispatch = useDispatch();
    
	const addSiteValidation = useFormik({
		enableReinitialize: true,

		initialValues: {
			site_url: '',
			suggest: true
		},
		validationSchema: Yup.object({
			site_url: Yup.string()	
				.required("Please enter URL")
				.matches(
					/^(https:\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/,
					'Invalid URL'
				),
		}),
		onSubmit: values => {
			dispatch(postSiteRequest(account.account_id, values.site_url, values.suggest));        
			addSiteValidation.resetForm();
		},
	});

	if (!site.site_status) {
		return (
			<Modal 
				isOpen={ show } 
				toggle={ () => dispatch(hideModalSite()) }
			>
				<ModalHeader 
					toggle={ () => dispatch(hideModalSite()) }
					tag="h4"
				>
					Add Site
				</ModalHeader>
				<ModalBody>
					{ site.error && (
						<Alert color="danger" role="alert">
						  { site.error }
						</Alert>
					) }
					<Form
						onSubmit={e => {
							e.preventDefault();
							addSiteValidation.handleSubmit();
							return false;
						}}
					>
						<Row>
							<Col xs={12} className="mb-3">
								<Label className="form-label">URL</Label>
								<Input
									name="site_url"
									type="text"
									placeholder="example.com"
									onChange={ addSiteValidation.handleChange }
									onBlur={ addSiteValidation.handleBlur }
									value={ addSiteValidation.values.site_url }
									invalid={ addSiteValidation.touched.site_url && !!addSiteValidation.errors.site_url }
								/>
								{ (addSiteValidation.touched.site_url && addSiteValidation.errors.site_url) ? (
									<FormFeedback type="invalid">
										{addSiteValidation.errors.site_url}
									</FormFeedback>
								) : null }
							</Col>
						</Row>

						<Row className="align-items-center">
							<Col xs={ 9 }>
								<div
								  className="form-check form-switch"
								>
								  <input
									type="checkbox"
									className="form-check-input"
									name="suggest"
									id="suggest"
									defaultChecked={ addSiteValidation.values.suggest }
									onChange={ (event) => addSiteValidation.setFieldValue('suggest', event.target.checked) }					
								  />
								  <label
									className="form-check-label"
									htmlFor="suggest"
								  >
									Automatically suggest better images
								  </label>
								</div>
							</Col>
							<Col xs={ 3 } className="text-end">
								<button
									type="submit"
									className="btn btn-success"
									disabled={ !addSiteValidation.values.site_url || addSiteValidation.errors.site_url }
								>
									Add
								</button>
							</Col>
						</Row>

					</Form>			
				</ModalBody>
			</Modal>
		);
		
	} else if (site.site_status == 'active' && (!site.suggest || (site.variants && site.variants.every(variant => !!variant.web_assets_updated)))) {
	
		return (
			<Modal 
				isOpen={ show } 
			>
				<ModalHeader 
					tag="h4"
				>
					Add Site
				</ModalHeader>
				<ModalBody>
					{ site.error && (
						<Alert color="danger" role="alert">
						  { site.error }
						</Alert>
					) }
					<p>Ready!</p>
					
					<button
						type="submit"
						className="btn btn-success"
						onClick={ () => {
							dispatch(hideModalSite());
							dispatch(gotoLibrary(site.account_id, site.site_id, router.navigate));
						} }
					>
						Continue to Images &rsaquo;
					</button>
					
				</ModalBody>
			</Modal>
		);

		/*	
		return (
			<GeneratingReport 
				variant={ site.variants[0] }
				site_type="web"
				show={ show }
				hideModal={ () => dispatch(hideModalSite()) }
				continueClick={ () => dispatch(gotoLibrary(site.site_id, router.navigate)) }
			/> 
		);
		*/
		
	} else {
		return (
			<Modal 
				isOpen={ show } 
			>
				<ModalHeader 
					tag="h4"
				>
					Add Site
				</ModalHeader>
				<ModalBody>
					{ site.error && (
						<Alert color="danger" role="alert">
						  { site.error }
						</Alert>
					) }
					<p>Please wait. This should only take a minute...</p>
					<Spinner />
				</ModalBody>
			</Modal>
		);
	}

}

export default withRouter(AddSiteModal);
