import React, { useMemo, useEffect, useState, useRef } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
  Nav, NavItem, NavLink,
} from "reactstrap";

import classnames from "classnames";

import FontControls from '../../components/Common/FontControls';

import { useDispatch, useSelector } from "react-redux";
import { setVariantParams, updateEditor } from "../../store/actions";

const EditButtons = ({ variant, site, restartFrom }) => {
	
	const editor = useSelector(state => state.Variant.editor);
	const dispatch = useDispatch();

	// default editor state
	useEffect(() => {
		if (typeof variant.params.buttons == 'undefined') {
			dispatch(setVariantParams(variant.variant_id, { 
				buttons: []
			}));
		}

		if (typeof editor.buttonIdx == 'undefined') {
			dispatch(updateEditor({ buttonIdx: 0 }));
		}
	}, []);
	
	// default bootstrap button colors
	const colors = ['#0d6efd', '#6c757d', '#198754', '#0dcaf0', '#ffc107', '#dc3545', '#f8f9fa', '#212529'];

	// colors from bootstrap default buttons plus computer vision
	const palette = useMemo(
		() => {
			const cvColors = (variant.palette?.Foreground?.DominantColors || []).concat(variant.palette?.Background?.DominantColors || []).map((color) => (
				color.HexCode
			));

			return [...new Set(colors.concat(cvColors))];
		}, [variant.palette]
	)

	const [ buttonSizingMeasure, setButtonSizingMeasure ] = useState();

	const getButtonValue = (idx, field, default_value) => {
		let rv = variant.params.buttons[idx][field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}
	const setButtonParams = (idx, params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			buttons: [
				...variant.params.buttons.slice(0, idx),
				{
					...variant.params.buttons[idx],
					...params
				},
				...variant.params.buttons.slice(idx + 1)
			] 
		}));
		
		// trigger sizing measure (after next render)
		if (['text', 'size', 'font_family_url', 'font_family', 'font_weight', 'italic'].some(field => field in params)) {
			setButtonSizingMeasure(Date.now());
		}
	}

	// trigger sizing measure (after next render)
	useEffect(() => {
		if (editor.fontFamilyMouseOver) {
			setButtonSizingMeasure(Date.now());
		}
	}, [editor.fontFamilyMouseOver]);

	// do the measure - after render
	useEffect(() => {
		if (buttonSizingMeasure && typeof editor.buttonIdx != 'undefined') {
			const button = document.querySelectorAll('.bi-buttons text')[editor.buttonIdx];
			
			if (button) { 
				const sizingRect = button.getBBox();
			
				setButtonParams(editor.buttonIdx, {
					width: sizingRect.width,
					height: sizingRect.height
				});
			}
		}
	}, [buttonSizingMeasure]);

	const addButton = () => {
		dispatch(setVariantParams(variant.variant_id, { 
			buttons: [
				...variant.params.buttons,
				{
					text: 'Click Me',
					// rotate style and color
					button_type: ['solid', 'outline', 'contrast'][(editor.buttonIdx || 0) % 3],
					color: colors[(editor.buttonIdx || 0) % colors.length],
					x: variant.width/2,
					y: variant.height/2,
					size: 1,
					font_family: 'Montserrat',
					font_weight: 400,
					href: 'https://example.com',
					target: '_blank',
					begin: 0,
					opacity: 1
				}
			] 
		}));

		// add one!
		dispatch(updateEditor({ buttonIdx: variant.params.buttons.length }));
		
		// size!
		setButtonSizingMeasure(Date.now());
	}

	const deleteButton = (idx) => {
		dispatch(setVariantParams(variant.variant_id, { 
			buttons: [
				...variant.params.buttons.slice(0, idx),
				...variant.params.buttons.slice(idx + 1)
			] 
		}));
		
		dispatch(updateEditor({ buttonIdx: 0 }));
	}

	const getButtonStyle = () => {
	
		if (!editor.viewBox || !variant.params.buttons.length) return;
	
		const base_font_size = editor.viewBox.width / Math.max(...variant.params.buttons.map(button => Math.max(5, (button.text || '').length)));

		const button = variant.params.buttons[editor.buttonIdx];

		let font_family;
		if (editor.fontFamilyMouseOver) {
			font_family = editor.fontFamilyMouseOver;
		} else {
			font_family = button.font_source == 'custom' ? button.font_family_custom : button.font_family;
		}

		const font_size = 0.5 * button.size * base_font_size;

		let style = {
			fontSize: font_size,
			fontFamily: font_family,
			fontWeight: button.font_weight || 400,
			textWrap: 'nowrap',
			position: 'fixed', left: '-5000px'
		}
		if (button.italic) {
			style.fontStyle = 'italic';
		}

		return style;
	}

	return (
		<>

			<Nav tabs className="align-items-center">
				{ variant.params.buttons && variant.params.buttons.map((button, idx) => {
					return (
						<NavItem key={ idx }>
							<NavLink
								className={ classnames({
									active: editor.buttonIdx == idx,
								}) }
								onClick={ () => dispatch(updateEditor({ buttonIdx: idx })) }
							>
								{ button.text }
								<i className="mdi mdi-close-box ms-1" 
									onClick={ () => deleteButton(idx) } 
								/>
							</NavLink>
						</NavItem>									
					)
				}) }
				
				<NavItem style={{
					marginLeft: 'auto'
				}}>
					<NavLink
						onClick={ addButton }
					>
						+ Add
					</NavLink>
				</NavItem>
			</Nav>

			{ variant.params.buttons && typeof editor.buttonIdx != 'undefined' && editor.buttonIdx < variant.params.buttons.length && (
				<>
					<Card>		
						<CardBody>
							<Row className="mb-3">
								<Col xs={ 12 }>
									<label className="form-label">Text</label>
									<Input
										type="text"
										value={ getButtonValue(editor.buttonIdx, 'text', '') }
										onChange={ (event) => setButtonParams(editor.buttonIdx, {
											text: event.target.value
										}) }
									/>				
								</Col>	
							</Row>

							<Row className="align-items-top mb-3">
								<Col className="col-6">
									<label className="form-label">Begin</label>
									<Input
										type="number"
										min={ 0 }
										value={ getButtonValue(editor.buttonIdx, 'begin', 0) }
										onChange={ (event) => setButtonParams(editor.buttonIdx, {
											begin: parseFloat(event.target.value)
										}) }
									/>
								</Col>		
								<Col className="col-6">
									<label className="form-label">Position</label>
									<div>
										<i className="mdi mdi-cursor-move me-1" />
										Drag the button around
									</div>
								</Col>
							</Row>
							<Row className="align-items-top">
								<Col className="col-6">
									<label className="form-label">Style</label>
									<Input
										type="select"
										value={ getButtonValue(editor.buttonIdx, 'button_type', 'solid') }
										onChange={ (event) => setButtonParams(editor.buttonIdx, {
											button_type: event.target.value
										}) }
									>
										<option value="solid">Solid</option>
										<option value="outline">Outline</option>
										<option value="contrast">Contrast</option>
									</Input>
								</Col>		
								<Col className="col-6">
									<label className="form-label">Opacity</label>
									<Input
										type="range"
										value={ getButtonValue(editor.buttonIdx, 'opacity', 1) }
										onChange={ (event) => setButtonParams(editor.buttonIdx, {
											opacity: parseFloat(event.target.value) 
										}) }
										min={ 0.2 }
										max={ 1 }
										step={ 0.01 }
										className="mt-2"
									/>
								</Col>
							</Row>
						</CardBody>
					</Card>

					<Card>		
						<CardHeader>
							Link
						</CardHeader>
						<CardBody>
							{ site.site_type == 'web' ? (
								<>
									<Row className="mb-3">
										<Col>
											<label className="form-label">URL</label>
											<Input
												type="text"
												value={ getButtonValue(editor.buttonIdx, 'href', '') }
												onChange={ (event) => setButtonParams(editor.buttonIdx, {
													href: event.target.value
												}) }
											/>				
										</Col>	
									</Row>					
									<Row>	
										<Col className="col-6">
											<label className="form-label">Target</label>
											<Input
												type="select"
												value={ getButtonValue(editor.buttonIdx, 'target', '_self') }
												onChange={ (event) => setButtonParams(editor.buttonIdx, {
													target: event.target.value
												}) }
											>
												<option value="_blank">New tab</option>
												<option value="_self">Same tab</option>
											</Input>
										</Col>
									</Row>	
								</>
							) : (
							
								<Row>
									<Col>
										<p>Email clients including Gmail do not support button-specific links.</p>
										Therefore, make sure your email links the image to a page where subscribers can take all the actions indicated by your buttons.
									</Col>
								</Row>
							) }
											
						</CardBody>
					</Card>

					<FontControls 
						fontState={ variant.params.buttons[editor.buttonIdx] } 
						setFontState={ (params) => setButtonParams(editor.buttonIdx, params) }
						defaultPalette={ palette }						
					/>

				</>
			) }
		</>
	)
}

export default EditButtons;