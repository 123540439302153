import {
	FETCH_PROFILE_REQUEST,
	FETCH_PROFILE_SUCCESS,
	FETCH_PROFILE_FAILURE,
	
	GET_SPEED_REQUEST,
	GET_SPEED_SUCCESS,
	GET_SPEED_FAILURE,	

	SELECT_ACCOUNT,
	SELECT_SITE,
	GOTO_LIBRARY,

	SHOW_MODAL_INBOX, HIDE_MODAL_INBOX,
	POST_INBOX_REQUEST,
	POST_INBOX_SUCCESS,
	POST_INBOX_FAILURE,
	GET_INBOX_REQUEST,
	GET_INBOX_SUCCESS,
	GET_INBOX_FAILURE,
	STOP_INBOX_POLLING,

	SHOW_MODAL_SITE, HIDE_MODAL_SITE,
	POST_SITE_REQUEST,
	POST_SITE_SUCCESS,
	POST_SITE_FAILURE,
	GET_SITE_REQUEST,
	GET_SITE_SUCCESS,
	GET_SITE_FAILURE,
	STOP_SITE_POLLING,

	SHOW_MODAL_DELETE, HIDE_MODAL_DELETE,
	
	PUT_ACCOUNT_REQUEST, PUT_ACCOUNT_SUCCESS, PUT_ACCOUNT_FAILURE,
	PUT_SITE_REQUEST, PUT_SITE_SUCCESS, PUT_SITE_FAILURE,
	
	UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE,
	
	REDIRECT_BILLING,
	
} from "./actionTypes"

export const fetchProfileRequest = () => ({
  type: FETCH_PROFILE_REQUEST
});
export const fetchProfileSuccess = (profile) => ({
  type: FETCH_PROFILE_SUCCESS,
  payload: profile,
});
export const fetchProfileFailure = (error) => ({
  type: FETCH_PROFILE_FAILURE,
  payload: error,
});

export const getSpeedRequest = () => ({
  type: GET_SPEED_REQUEST
});
export const getSpeedSuccess = (speed) => ({
  type: GET_SPEED_SUCCESS,
  payload: speed,
});
export const getSpeedFailure = (error) => ({
  type: GET_SPEED_FAILURE,
  payload: error,
});


export const selectAccount = (account_id) => ({
  type: SELECT_ACCOUNT,
  payload: account_id,
});

export const selectSite = (site_id) => ({
  type: SELECT_SITE,
  payload: site_id,
});
export const gotoLibrary = (account_id, site_id, history) => ({
  type: GOTO_LIBRARY,
  payload: { account_id, site_id, history }
});


export const showModalInbox = () => ({
  type: SHOW_MODAL_INBOX
});
export const hideModalInbox = () => ({
  type: HIDE_MODAL_INBOX
});
export const postInboxRequest = (account_id) => ({
  type: POST_INBOX_REQUEST,
  payload: { account_id },
})
export const postInboxSuccess = (inbox) => ({
  type: POST_INBOX_SUCCESS,
  payload: { inbox },
});
export const postInboxFailure = (error) => ({
  type: POST_INBOX_FAILURE,
  payload: { error },
});
export const getInboxRequest = (account_id, inbox_key) => ({
  type: GET_INBOX_REQUEST,
  payload: { account_id, inbox_key },
})
export const getInboxSuccess = (inbox) => ({
  type: GET_INBOX_SUCCESS,
  payload: { inbox },
});
export const getInboxFailure = (error) => ({
  type: GET_INBOX_FAILURE,
  payload: { error },
});
export const stopInboxPolling = () => ({
  type: STOP_INBOX_POLLING
});


export const showModalSite = () => ({
  type: SHOW_MODAL_SITE
});
export const hideModalSite = () => ({
  type: HIDE_MODAL_SITE
});
export const postSiteRequest = (account_id, site_url, suggest) => ({
  type: POST_SITE_REQUEST,
  payload: { account_id, site_url, suggest },
})
export const postSiteSuccess = (site) => ({
  type: POST_SITE_SUCCESS,
  payload: { site },
});
export const postSiteFailure = (error) => ({
  type: POST_SITE_FAILURE,
  payload: { error },
});
export const getSiteRequest = (account_id, site_id) => ({
  type: GET_SITE_REQUEST,
  payload: { account_id, site_id },
})
export const getSiteSuccess = (site) => ({
  type: GET_SITE_SUCCESS,
  payload: { site },
});
export const getSiteFailure = (error) => ({
  type: GET_SITE_FAILURE,
  payload: { error },
});
export const stopSitePolling = () => ({
  type: STOP_SITE_POLLING
});


export const showModalDelete = (delete_type, delete_params) => ({
  type: SHOW_MODAL_DELETE,
  payload: { delete_type, delete_params },
});
export const hideModalDelete = () => ({
  type: HIDE_MODAL_DELETE
});


export const putAccountRequest = (account_id, account, onSuccess) => ({
  type: PUT_ACCOUNT_REQUEST,
  payload: { account_id, account },
})
export const putAccountSuccess = (account) => ({
  type: PUT_ACCOUNT_SUCCESS,
  payload: account,
})
export const putAccountFailure = (error) => ({
  type: PUT_ACCOUNT_FAILURE,
  payload: error,
});

export const putSiteRequest = (site_id, site, onSuccess) => ({
  type: PUT_SITE_REQUEST,
  payload: { site_id, site },
})
export const putSiteSuccess = (account) => ({
  type: PUT_SITE_SUCCESS,
  payload: account,
})
export const putSiteFailure = (error) => ({
  type: PUT_SITE_FAILURE,
  payload: error,
});


export const updateProfileRequest = (user_id, user) => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { user_id, user }
});
export const updateProfileSuccess = (user) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: user
});
export const updateProfileFailure = (error) => ({
  type: UPDATE_PROFILE_FAILURE,
  payload: error
});

export const redirectBilling = (account_id) => ({
  type: REDIRECT_BILLING,
  payload: { account_id }
});