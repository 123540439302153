import React, { useRef, useState, useEffect } from "react"

// controls
import { Link } from "react-router-dom";
import { 
  Spinner,
  Card, CardHeader, CardBody, CardTitle, CardSubtitle,
  Row, Col,
  Button,
  Input,
  UncontrolledTooltip,
  Nav, NavItem, NavLink,
} from "reactstrap";

import AssetLibrary from '../../components/Common/AssetLibrary';

import classnames from "classnames";

import { useDispatch, useSelector } from "react-redux";
import { setVariantParams, updateEditor } from "../../store/actions";


const EditStickers = ({ variant, restartFrom }) => {

	const editor = useSelector(state => state.Variant.editor);
	const dispatch = useDispatch();

	// default editor state
	useEffect(() => {
		if (typeof variant.params.stickers == 'undefined') {
			dispatch(setVariantParams(variant.variant_id, { 
				stickers: []
			}));
		}
		if (typeof editor.stickerIdx == 'undefined') {
			dispatch(updateEditor({ stickerIdx: 0 }));
		}

		// migrate legacy format
		if (!Array.isArray(variant.params.stickers) && variant.params.stickers?.favicon) {
			dispatch(setVariantParams(variant.variant_id, { 
				stickers: [
					{
						url: `https://www.google.com/s2/favicons?sz=256&domain_url=${variant.params.stickers.favicon}`,
						x: 0,
						y: 0,
						scale: 1,
						opacity: 1
					}
				] 
			}));
		}
		
	}, []);

	const getStickerValue = (idx, field, default_value) => {
		let rv = variant.params.stickers[idx][field];
		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}
	const setStickerParams = (idx, params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			stickers: [
				...variant.params.stickers.slice(0, idx),
				{
					...variant.params.stickers[idx],
					...params
				},
				...variant.params.stickers.slice(idx + 1)
			] 
		}));
	}

	const addSticker = () => {
		dispatch(setVariantParams(variant.variant_id, { 
			stickers: [
				...variant.params.stickers,
				{
					url: null,
					x: editor.viewBox?.x || 0,
					y: editor.viewBox?.y || 0,
					scale: 1,
					opacity: 1
				}
			] 
		}));

		// add one!
		dispatch(updateEditor({ stickerIdx: variant.params.stickers.length }));
	}

	const deleteSticker = (idx) => {
		dispatch(setVariantParams(variant.variant_id, { 
			stickers: [
				...variant.params.stickers.slice(0, idx),
				...variant.params.stickers.slice(idx + 1)
			] 
		}));
		
		dispatch(updateEditor({ stickerIdx: 0 }));
	}


	const postSticker = (idx, result) => {
		console.log('result', result);
		
		setStickerParams(idx, {
			url: result.source_url
		})
	}

	return (

		<>
			<Nav tabs className="align-items-center">
				{ variant.params.stickers && Array.isArray(variant.params.stickers) && variant.params.stickers.map((sticker, idx) => {
					return (
						<NavItem key={ idx }>
							<NavLink
								className={ classnames('stickers-nav-link', {
									active: editor.stickerIdx == idx,
								}) }
								onClick={ () => dispatch(updateEditor({ stickerIdx: idx })) }
							>
								<img src={ sticker.url }
									height="20"
								/>
								<i className="mdi mdi-close-box ms-1" 
									onClick={ () => deleteSticker(idx) } 
								/>
							</NavLink>
						</NavItem>									
					)
				}) }
				
				<NavItem style={{
					marginLeft: 'auto'
				}}>
					<NavLink
						onClick={ addSticker }
					>
						+ Add
					</NavLink>
				</NavItem>
			</Nav>

			{ variant.params.stickers && typeof editor.stickerIdx != 'undefined' && editor.stickerIdx < variant.params.stickers.length && (
				<Card>		
					<CardBody>

						{ !getStickerValue(editor.stickerIdx, 'url') && (
							<Row className="mb-3">
								<Col>
									<AssetLibrary
										account_id={ variant.account_id }
										site_id={ variant.site_id }
										image_id={ variant.image_id }
										assetType='sticker'
										acceptMatch='image/*'
										onResult={ (result) => postSticker(editor.stickerIdx, result) }
									/>
								</Col>
							</Row>
						) }

						<Row className="align-items-top mb-3">
							<Col className="col-6">
								<label className="form-label">Begin</label>
								<Input
									type="number"
									min={ 0 }
									value={ getStickerValue(editor.stickerIdx, 'begin', 0) }
									onChange={ (event) => setStickerParams(editor.stickerIdx, {
										begin: parseFloat(event.target.value)
									}) }
								/>
							</Col>		
							<Col className="col-6">
								<label className="form-label">Position</label>
								<div>
									<i className="mdi mdi-cursor-move me-1" />
									Drag it around!
								</div>
							</Col>
						</Row>

						<Row>
							<Col xs={ 6 }>
								<label className="form-label">Size</label>
								<Input
									type="range"
									value={ getStickerValue(editor.stickerIdx, 'scale', 1) }
									onChange={ (event) => setStickerParams(editor.stickerIdx, {
										scale: parseFloat(event.target.value) 
									}) }
									min={ 0.2 }
									max={ 5 }
									step={ 0.01 }
									className="mt-2"
								/>
							</Col>	
							<Col xs={ 6 }>
								<label className="form-label">Opacity</label>
								<Input
									type="range"
									value={ getStickerValue(editor.stickerIdx, 'opacity', 1) }
									onChange={ (event) => setStickerParams(editor.stickerIdx, {
										opacity: parseFloat(event.target.value) 
									}) }
									min={ 0 }
									max={ 1 }
									step={ 0.01 }
									className="mt-2"
								/>
							</Col>	
						</Row>

					</CardBody>
				</Card>
			) }
		</>
	)
}

export default EditStickers;