// used by editor and card
export const BETTER_APPS = {

	motions: { label: 'Motion', icon: 'movie-open-outline' },
	intro: { icon: 'play-circle-outline' },
	slideshow: { icon: 'image-multiple-outline' },
	captions: { icon: 'closed-caption' },
	particles: { icon: 'snowflake', tooltip: 'Decorate your image with 1,000+ emoji' },

	stickers: { label: 'Branding', icon: 'salesforce' },
	buttons: { icon: 'gesture-tap-button' },

	magnify: { icon: 'magnify', email: false },
	//click: { icon: 'cursor-default-click-outline', email: false },
	scroll: { icon: 'restart', email: false },
	menu: { icon: 'dots-vertical', email: false },

	tilt: { label: '3D Tilt', icon: 'perspective-less', email: false },
	spotlight: { icon: 'flashlight', email: false, tooltip: 'Coming Soon' },

	geo: { icon: 'earth', tooltip: 'Target or exclude this image for viewers in in certain countries or regions' },
	language: { icon: 'translate', tooltip: 'Target or exclude this image for viewers who speak specific languages' },
	time: { icon: 'clock-time-ten-outline', tooltip: 'Target or exclude this image at specific local times of the day' },

	personalize: { icon: 'account', tooltip: 'Add Captions or Buttons that show viewer name, or other viewer data', email: false },
	localize: { icon: 'calendar-month-outline', tooltip: 'Add Captions or Buttons that show local times and locations', email: false },

	crop: { icon: 'crop' },
	colors: { icon: 'palette' },
	upscale: { icon: 'arrow-expand-all', tooltip: 'Coming Soon - Upscale your image to 4K resolution' },
	
	seo: { label: 'SEO', icon: 'text-search', email: false },
	access: { icon: 'human' },
	delivery: { icon: 'network' },	
	email: { icon: 'email-outline' },
	tracking: { icon: 'chart-line' },
	
	debug: { icon: 'help-circle-outline' },

}

export const BETTER_APP_CATEGORIES = [
	[ 'Motion', [ 'motions', 'intro', 'slideshow', 'captions', 'particles' ] ], 
	[ 'Branding', [ 'stickers', 'captions', 'buttons' ] ],
	[ 'Interaction', [ 'buttons', 'magnify', 'scroll', 'menu' ] ],
	[ 'Target', [ 'geo', 'language', 'time' ] ],
	[ 'Dynamic', [ 'personalize', 'localize' ] ],
	[ 'Fun', [ 'particles', 'tilt', 'spotlight' ] ],
	[ 'Editing', [ 'crop', 'colors', 'upscale' ] ],
	[ 'Other', [ 'seo', 'access', 'delivery', 'email', 'tracking' ] ],
	[ 'Admin', [ 'debug' ] ]
]