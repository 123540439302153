import React, { useState, useEffect } from "react"

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

// actions
import { 
	hideModalVariant,
	postVariantRequest,
} from "../../store/actions";

import { 
	Spinner,
	Container, Row, Col,
	Button,
	Modal, ModalHeader, ModalBody,
	Form, Input, FormFeedback
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import VariantPreview from './VariantPreview';

import GeneratingReport from './GeneratingReport';
import AssetLibrary from './AssetLibrary';
// control style
import "./site.scss";

const vary_styles = ['3d-model', 'analog-film', 'anime', 'cinematic', 'comic-book', 'digital-art', 'enhance', 'fantasy-art', 'isometric', 'line-art', 'low-poly', 'modeling-compound', 'neon-punk', 'origami', 'photographic', 'pixel-art', 'tile-texture'];

// display modal for adding variant
// source variant for cloning
const AddVariantModal = ({ show, sourceVariant, newVariant }) => {

	const site = useSelector(state => state.Profile?.data?.sites[sourceVariant.site_id]);

	const dispatch = useDispatch();

	// initial form
	if (!newVariant.variant_id && newVariant.status != 'loading') {
		return (
            <Modal 
				isOpen={ show } 
				toggle={ () => dispatch(hideModalVariant()) }
            >
				<ModalHeader 
					toggle={ () => dispatch(hideModalVariant()) }
					tag="h4"
				>
                	Generate Now
            	</ModalHeader>
				<ModalBody>
					<AssetLibrary
						account_id={ site?.account_id }
						site_id={ site?.site_id }
						site_role={ site?.role }
						image_id={ sourceVariant.image_id }
						sourceVariant={ sourceVariant }
						assetType='source'
						acceptMatch='image/*'
						context="[Optional] Describe this image"
						onResult={ (result) => { 
							dispatch(postVariantRequest(result)); 
						} }
					/>
				</ModalBody>
			</Modal>
		);
		
	} else if (newVariant.status == 'loading' || !newVariant.web_assets_updated) {
		return (
			<Modal 
				isOpen={ show } 
			>
				<ModalHeader 
					tag="h4"
				>
					Generate Now
				</ModalHeader>
				<ModalBody>
					{ newVariant.error && (
						<Alert color="danger" role="alert">
						  { newVariant.error }
						</Alert>
					) }
					<p>Please wait. This should only take a minute...</p>
					<Spinner />
				</ModalBody>
			</Modal>
		);
		
	} else {
		// ready - wait for edge/image load that it's ready
		return (
			<GeneratingReport 
				variant={ newVariant }
				site_type={ site?.site_type }
				show={ show }
				hideModal={ () => dispatch(hideModalVariant()) }
				continueClick={ () => dispatch(hideModalVariant()) }
			/> 
		);	
	}
}

export default AddVariantModal;