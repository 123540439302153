import React, { useRef, useState, useEffect, useCallback } from "react"

// controls
import { Link } from "react-router-dom";
import { 
	Spinner,
	Card, CardHeader, CardBody, CardTitle, CardSubtitle,
	Row, Col,
	Button,
	Input,
	UncontrolledTooltip,
	Alert
} from "reactstrap";

// actions
// dispatch actions:  box, begin, end, zoom, transition, ? manual zoom
import { useDispatch } from "react-redux";
import { setVariantParams } from "../../store/actions";


// controls for editing variant.params.intro
//
const EditColors = ({ variant, restartFrom }) => {

	const colors = variant.params?.colors || {};

	const getColorsValue = (field, default_value) => {
		let rv = colors[field];

		if (typeof rv == 'undefined') rv = default_value;
		return rv;
	}

	// update params
    const dispatch = useDispatch();
	const setParams = (params) => {
		dispatch(setVariantParams(variant.variant_id, { 
			colors: {
				...colors,
				...params
			}
		}));

		//restartFrom(0);
	}

	// 


	// Safari uses CPU for feColorMatrix SVG filters, each frame of animation - performance not good enough
	// Safari Bug 246106: CSS filters are not applied to SVG elements: https://bugs.webkit.org/show_bug.cgi?id=246106
	// => server side color adjustment after saving 

	return (
		<>
			{ getColorsValue('status') == 'error' && (
				<Alert color="danger" role="alert">
					{ getColorsValue('error') }
				</Alert>			
			) }
			
			{ ['processing'].includes(getColorsValue('status')) && (
				<Alert color="info" role="alert">
					<Spinner color="secondary" size="sm" className="me-2" />
					Optimizing color settings...
				</Alert>			
			) }

			<Card>		
				<CardHeader>
					<strong>Colors</strong>
				</CardHeader>
				<CardBody>
					<Row className="mb-3">
						<Col>
							<label className="form-label">Saturation</label>
							<Input
								type="range"
								value={ getColorsValue('saturate', 1) }
								onChange={ (event) => setParams({
									'saturate': parseFloat(event.target.value),
									'status': 'dirty'
								}) }
								min={ 0 }
								max={ 5 }
								step={ 0.01 }
							/>
						</Col>
					</Row>

					<Row className="mb-3">
						<Col>
							<label className="form-label">Hue</label>
							<Input
								type="range"
								value={ getColorsValue('hue_rotate', 0) }
								onChange={ (event) => setParams({
									'hue_rotate': parseFloat(event.target.value),
									'status': 'dirty'
								}) }
								min={ -180 }
								max={ 180 }
							/>
						</Col>
					</Row>

				</CardBody>
			</Card>

		</>		
	)
}

export default EditColors;